// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Dropdown, Icon, Menu} from "antd";

// Import Styles
import '../../assets/styles/cards/product-card.scss';
import {ReactComponent as MinusIcon} from "../../assets/images/ic_minus.svg";
import {ReactComponent as PlusIcon} from "../../assets/images/ic_plus.svg";

// Import components
import {LinkButton} from "../buttons/buttons";
import {MaterialIcon} from "../utils/Icon";
import swal from "sweetalert";

// Import utils
import {history} from "../../configs/history";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {
    DeleteProduct, ToggleProductVisibility,
} from "../../redux/actions";
import {resizeImage} from "../../utils/resizeImage";
import {ACCESS_PAGES} from "../../constants/accessPages";
import {CURRENCY_TYPES} from "../../constants/constTypes";


class ProductCard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    deleteProduct = id => {
        swal({
            title: "Զգուշացում!", text: "Ցանկանում եք ջնջել ապրանքը?", icon: "warning", buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteProduct(id);
            }
        });
    };

    getScrollPosition() {
        return document.body.scrollTop || document.documentElement.scrollTop;
    }

    render() {
        const {data, inSelectorModal, viewCard, isSelected, accessPages, isAdmin} = this.props;
        const activeEditing = isAdmin || accessPages.includes(ACCESS_PAGES.products.key);
        const menu = (<Menu>
            <Menu.Item key="1">
                <Link to={"/products/edit/" + data?.id}>Edit</Link>
            </Menu.Item>
            <Menu.Item key="2" onClick={() => {
                this.props.ToggleProductVisibility(data?.id, {isHidden: !data?.isHidden})
            }}>
                {data?.isHidden ? 'Show' : 'Hide'}
            </Menu.Item>
            <Menu.Item key="3">
                <span onClick={this.deleteProduct.bind(this, data?.id)}>Delete</span>
            </Menu.Item>
        </Menu>);

        return <div className={`product-card ${isSelected ? 'isSelected' : ""}`} onClick={() => {
            inSelectorModal && this.props.onSelect(data, isSelected);
        }}>
            {inSelectorModal && <div className={`check-mark ${isSelected ? 'show' : ''}`}>
                <img src={require('../../assets/images/file-manager/check-mark.svg')}/>
            </div>}
            {viewCard && <div className={`delete-btn`} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.onDelete(data?.id)
            }}>
                <MaterialIcon icon="delete"/>
            </div>}
            {!viewCard && !inSelectorModal && activeEditing && <Dropdown overlay={menu}
                                                                         placement={"bottomRight"}>
                <Icon className="more" type="more"/>
            </Dropdown>}
            {data?.isHidden && <img className="hidden-product-icon" src={require("../../assets/images/hide.svg")}/>}
            <div>
                <img className="product-img"
                     src={resizeImage(generateImageMediaUrl(data?.mediaMain?.path || data?.medias?.[0]?.path))} alt=""/>
                <div className="product-title">{data?.title}</div>
                {!viewCard && !inSelectorModal && <>
                    <div className="bottom-part">
                        <div className="price-content">
                            <div
                                className="old-price">{data?.oldPrice ? data?.oldPrice + CURRENCY_TYPES[data?.currency].name : ""}</div>
                            <div className="discounted-price">{data?.price} {CURRENCY_TYPES[data?.currency].name}</div>
                        </div>

                        <div className="details-btn">
                            <LinkButton link={`/products/edit/${data?.id}`}
                                        getScrollPosition={this.getScrollPosition}
                                        disabled={!activeEditing}
                                        title={<MaterialIcon icon="keyboard_arrow_right"/>}/>
                        </div>
                    </div>
                </>}
            </div>
        </div>

    }
}

const mapDispatchToProps = {
    DeleteProduct, ToggleProductVisibility,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard)
