import {AUTH_CONSTS, TRANSACTION_CONSTS} from "../constants";


export const initialState = {
    transactionList: {
        itemsList: [],
        hasMore: false,
        startDate: null,
        endDate: null,
        types: null,
        text: '',
        branch: null,
        status: null,
        source: null,
        cardNumber: null,
        company: null,
    },
    bankTransactionList: {
        itemsList: [],
        hasMore: false,
        startDate: null,
        endDate: null,
        types: null,
        text: '',
        status: null,
        company: null,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TRANSACTION_CONSTS.GET_TRANSACTION:
            return {
                ...state,
                transactionList: {
                    itemsList: action.payload.reset ? action.payload.data :
                        [...state.transactionList.itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                    startDate: action.payload.startDate,
                    endDate: action.payload.endDate,
                    type: action.payload.type,
                    text: action.payload.text,
                    branch: action.payload.branch,
                    status: action.payload.status,
                    source: action.payload.source,
                    cardNumber: action.payload.cardNumber,
                    transactionNumber: action.payload.transactionNumber,
                    company: action.payload.company,
                }
            }
        case TRANSACTION_CONSTS.GET_BANK_TRANSACTION:
            return {
                ...state,
                bankTransactionList: {
                    itemsList: action.payload.reset ? action.payload.data :
                        [...state.bankTransactionList.itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                    startDate: action.payload.startDate,
                    endDate: action.payload.endDate,
                    type: action.payload.type,
                    text: action.payload.text,
                    status: action.payload.status,
                    transactionNumber: action.payload.transactionNumber,
                    company: action.payload.company,
                }
            }
        case TRANSACTION_CONSTS.CLEAR_TRANSACTION:
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
