import {COUNTRY_CONSTS} from "../constants";

export const initialState = {
    countriesList: [],
    countryById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case COUNTRY_CONSTS.GET_COUNTRIES:
            return {
                ...state,
                countriesList: action.payload || [],
            };
        case COUNTRY_CONSTS.GET_COUNTRY_BY_ID:
            return {
                ...state,
                countryById: action.payload
            };
        case COUNTRY_CONSTS.CREATE_COUNTRY:
            return {
                ...state,
                countriesList: [action.payload].concat(state.countriesList)
            };
        case COUNTRY_CONSTS.DELETE_COUNTRY_BY_ID:
            return {
                ...state,
                countriesList: state.countriesList.filter(country => country.id !== action.payload)
            };
        default:
            return state;
    }
}
