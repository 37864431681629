// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import "../../assets/styles/containerStyles/review-details.scss";

// Import Components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import ReviewCard from "../../components/cards/ReviewCard";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {generateFileMediaUrl} from "../../utils/generateMemberMediaUrl";


class ReviewDetails extends Component {
    constructor() {
        super();
        this.state = {
            userData: null,
            reviewData: null,
        };
    }

    componentDidMount() {
        const {reviews, id} = this.props;
        const reviewData = reviews?.itemsList?.find(review => review.id === id);
        reviewData && this.setState({
            reviewData
        })
    }


    render() {
        const {reviewData} = this.state;
        return <PageWrapper>
            <section className="review-details">
                <div className="top-part">
                    <div className="product-image">
                        <img src={generateFileMediaUrl(reviewData?.product?.mediaMain?.path)} alt="product"/>
                    </div>
                    <div className="product-info">
                        <span className="title">{reviewData?.product?.title}</span>
                        <div className={'info-text'}>Գին։ {reviewData?.product?.price}</div>
                        <div className={'info-text'}>Կոդ։ {reviewData?.product?.productCounter}</div>
                    </div>
                </div>
                <div className={'review-label'}>Մեկնաբանություն</div>
                {reviewData && <ReviewCard reviewData={reviewData}/>}
            </section>
        </PageWrapper>

    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewDetails)
