import { ACCEPT_FILE_TYPES, MAX_FILE_SIZE } from "../../../../constants/acceptedTypes";
import swal from "sweetalert";
import { FILE_TYPES } from "../../../../constants/fileTypes";
import { UPLOAD_TYPES } from "../../../../constants/constTypes";
import isURL from "validator/es/lib/isURL";
import { GetImageFileByUrl, setUpLoadProgresses } from "../../../../redux/actions";
import { getFileUrlPrefix } from "../../../../configs/getFileUrlPrefix";
import { request } from "../../../../redux/api";
import { store } from "../../../../redux/store";
import { uploadBox } from "../../../uiElements/UploadBox";


export async function uploadMedias(){
    const { folderList, uploadProgresses } = this.props;
    const currentFolder = folderList?.[folderList?.length - 1];

    const { addingFiles, currentUploadingId } = this.state;
    let formData = new FormData();
    for ( let i = 0; i < addingFiles.length; i++ ) {
        const item = addingFiles[i];
        const uploadingFile = item.croppedImageFile || item.file
        //  console.log('uploadingFile', uploadingFile);
        formData.append('files', uploadingFile,);
    }
    currentFolder && currentFolder.id && formData.append('parent', currentFolder.id);

    const uploadId = currentUploadingId || Date.now() // errori jamanak vor eli upload ani currentUploadingId nuyn@ piti mna,
    await this.setState({
        uploading: true,
        currentUploadingId: uploadId
    })
    await setUpLoadProgresses({
        ...uploadProgresses,
        [uploadId]: {
            progress: 0,
            itemsCount:addingFiles.length,
            status: 'active',
            folderId: currentFolder?.id,
            folderList,
            pathName:'Ֆայլեր' + folderList.map(f => '/'+f.name )
        }
    })

    this.props.UploadFile(formData, this.onUploadProgress, uploadId).then(async() => {
        const { uploadProgresses, folderList } = store.getState().media;
        const currentFolder = folderList?.[folderList?.length - 1];
        setUpLoadProgresses({
            ...uploadProgresses,
            [uploadId]: {
                ...uploadProgresses[uploadId],
                progress: 100,
                status: 'success',
            }
        })
        if ( currentFolder?.id === uploadProgresses[uploadId].folderId ) {
            this.props.GetMedia({ parent: currentFolder?.id });
        }
        if ( this.state.currentUploadingId === uploadId ) {
            this.closeAddMedia();
            const newUploadedProgresses = { ...uploadProgresses }
            delete newUploadedProgresses[uploadId];
            setUpLoadProgresses(newUploadedProgresses)
        }

    }).catch(() => {
        const { uploadProgresses, } = store.getState().media;
        setUpLoadProgresses({
            ...uploadProgresses,
            [uploadId]: {
                ...uploadProgresses[uploadId],
                status: 'exception',
            }
        })
        this.setState({
            uploading: false,
        })
    });
}

export function minimizeUpload(){
    const { currentUploadingId } = this.state;
    uploadBox(currentUploadingId)
    this.closeAddMedia();
}

export function onUploadProgress(progressEvent, uploadId){
    const uploadProgresses = store.getState().media.uploadProgresses;
    // console.log('onUploadProgress', uploadProgresses)
    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

    // console.log('percentCompleted', percentCompleted)
    setUpLoadProgresses({
        ...uploadProgresses,
        [uploadId]: {
            ...uploadProgresses[uploadId],
            progress: percentCompleted !== 100 ? percentCompleted : 99
        }
    })
}

export function hasExtension(fileType){
    const type = FILE_TYPES[fileType];
    if ( !type ) {
        return false;
    }
    return ACCEPT_FILE_TYPES.some(acceptType => acceptType === type);
}

export async function onDropFile(e){
    e.stopPropagation();
    e.preventDefault();
    const files = e?.target?.files || e?.dataTransfer?.files;
    // console.log('files',files)
    if ( !files || !files.length ) {
        return
    }
    let errorFiles = [];
    for ( let i = 0; i < files.length; i++ ) {
        let file = files[i];
        /////////////////////////////////////////..

        /*        const audio = document.createElement('audio');
                const reader = new FileReader();
                reader.onload = function (e) {
                    audio.src = e.target.result;
                    audio.addEventListener('loadedmetadata', function(){
                        // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
                        const duration = audio.duration;

                        // example 12.3234 seconds
                        console.log("The duration of the song", file.name, " is of: " + duration + " seconds");
                        // Alternatively, just display the integer value with
                        // parseInt(duration)
                        // 12 seconds
                    },false);
                };

                reader.readAsDataURL(file);*/


        /*        console.log('file.type', file.type)
                const  reader = new FileReader();
                reader.onload = function (event) {

                    // Create an instance of AudioContext
                    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

                    // Asynchronously decode audio file data contained in an ArrayBuffer.
                    audioContext.decodeAudioData(event.target.result, function(buffer) {
                        // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
                        const duration = buffer.duration;

                        // example 12.3234 seconds
                        console.log("The duration of the song is of: " + duration + " seconds");
                        // Alternatively, just display the integer value with
                        // parseInt(duration)
                        // 12 seconds
                    });
                };

                // In case that the file couldn't be read
                reader.onerror = function (event) {
                    console.error("An error ocurred reading the file: ", event);
                };

                // Read file as an ArrayBuffer, important !
                reader.readAsArrayBuffer(file)*/
        /////////////////////////////
        const extensionSupported = hasExtension(file.type);
        if (
            !extensionSupported
            //  || file.size > MAX_FILE_SIZE
        ) {

            errorFiles.push({
                name: file.name,
                extension: !extensionSupported,
                size: file.size > MAX_FILE_SIZE
            })
        }
    }
    if ( errorFiles.length ) {
        swal({
            title: "Զգուշացում!",
            text: errorFiles.map(errFile => {
                return `${errFile.name} - ${errFile.extension ? ' չթույլատրվաց ֆորմատով է, ' : ''} ${errFile.size ? ' չափսը 200mb -ից մեծ է,' : ''}`
            }).join('\n'),
            icon: "info",
            button: "Ok"
        });

    } else {
        const addingFiles = [];
        for ( let i = 0; i < files.length; i++ ) {
            const file = files[i];
            addingFiles.push({
                imageUrl: URL.createObjectURL(file),
                file
            })
        }

        this.setState({
            addingFiles,
            filesIsChoose: true,
            addFileCardOpen: false,
            chooseType: null,
            name: '',
            url: '',
            errors: {},
        })
    }
}

export function getMedias(addingFiles){
    this.setState({
        addingFiles
    });
    !addingFiles.length && this.closeAddMedia();
}

export function onUploadClick(e){
    e.target.value = null;
}

export function triggerFileUpload(){
    this.inputElement.current.click();
}

export function closeAddMedia(){
    this.setState({
        uploading: false,
        filesIsChoose: false,
        currentUploadingId: null,
        addingFiles: []
    })
}


// Media add by url

export function toggleAddFileCard(visible){
    this.setState({
        addFileCardOpen: visible
    })
}

export function cancelAddFileByUrl(){
    this.setState({
        chooseType: null,
        errors: {},
        name: '',
        url: '',
        shazamUrl: '',
        fileByUrl: null,
        addFileCardOpen: true,
    })
}

export function chooseUploadType(){
    this.setState({
        chooseType: UPLOAD_TYPES.URL
    })
}

export function getFileUploadByUrlValues({ name, value }){
    const newState = {
        [name]: value,
    }
    if ( name === 'url' && value ) {
        const path = value.split('/')?.slice(-1)?.[0];
        if ( path && path.includes('.') ) {
            const _name = path.split('.')[0];

            if ( _name.endsWith('200x200') ) {
                //Yandex
                newState.url = value.replace('200x200', '400x400');
            }
            if ( _name.endsWith('_s400') ) {
                //Shazam
                newState.url = value.replace('_s400', '_s800');
            }
            if ( _name.endsWith('400x400bb') ) {
                //Shazam
                newState.url = value.replace('400x400bb', '800x800bb');
            }
            if ( _name.endsWith('500x500bb-60') ) {
                //Apple
                newState.url = value.replace('500x500bb-60', '800x800bb-60');
            }

            // if (!this.state.name) {
            //     _name && (newState.name = _name);
            // }
        }
    }
    this.setState(newState);
}

export async function addFile(){
    const { url, shazamUrl, name } = this.state;
    if ( (!url && !shazamUrl) || (url && !isURL(url)) ) {
        this.setState({
            errors: {
                url: true
            },
        });
        return;
    } else {
        this.setState({
            errors: {}
        })
    }
    await this.setState({
        loadingGetFileByUrl: true
    })
    if ( url ) {
        await GetImageFileByUrl(url, name || 'file').then(file => {
            this.state.addFileCardOpen && this.onDropFile({
                target: {
                    files: [file]
                }
            })
        }).finally(() => {
            this.setState({
                loadingGetFileByUrl: false,
            })
        });
    } else if ( shazamUrl ) {
        const pathItems = shazamUrl.split('/');
        const alias = pathItems?.[pathItems?.length - 1];
        const id = pathItems?.[pathItems?.length - 2];
        const files = [];

        const artistPromise = getShazamArtist(id).then(res => {
            return res?.data?.avatar &&
                GetImageFileByUrl(res?.data?.avatar, res?.data?.name || 'file').then(
                    file => {
                        files.push(file);
                    })
        })
        const artistTracksPromise = getShazamArtistTopTracks(id).then(res => {
            const tracks = res?.data?.tracks;
            const imagesFilesPromises = [];
            if ( tracks ) {
                tracks.forEach(track => {
                    //window.open(`https://www.youtube.com/results?search_query=Eminem ${track?.title}`, '_blank')
                    track.images.coverarthq && imagesFilesPromises.push(
                        GetImageFileByUrl(track.images.coverarthq,
                            track?.title || 'file').then(file => {
                            files.push(file);
                        })
                    )
                });
                return Promise.all(imagesFilesPromises)
            }
        })
        Promise.all([artistPromise, artistTracksPromise]).then(() => {
            console.log(files);
            this.state.addFileCardOpen && files.length && this.onDropFile({
                target: {
                    files: files
                }
            })
        }).finally(() => {
            this.setState({
                loadingGetFileByUrl: false,
            })
        });
    }
}

export const getShazamArtist = (id) => {
    const reqData = {
        url: `${getFileUrlPrefix}/https://www.shazam.com/discovery/v3/ru/AM/web/artist/${id}?shazamapiversion=v3&video=v3`,
        method: "GET",
    }
    return request(reqData)
}

export const getShazamArtistTopTracks = (id) => {
    const reqData = {
        url: `${getFileUrlPrefix}/https://www.shazam.com/shazam/v3/ru/AM/web/-/tracks/artisttoptracks_${id}?startFrom=0&pageSize=20&connected=&channel=`,
        method: "GET",
    }
    return request(reqData)
}
