import {NEWS_CONSTS} from "../constants";

export const initialState = {
    newsList: [],
    newsById: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case NEWS_CONSTS.GET_NEWS_LIST:
            return {
                ...state,
                newsList: action.payload || [],
            };
        case NEWS_CONSTS.GET_NEWS_BY_ID:
            return {
                ...state,
                newsById: action.payload
            };
        case NEWS_CONSTS.CREATE_NEWS:
            return {
                ...state,
                newsList: [action.payload].concat(state.newsList)
            };
        case NEWS_CONSTS.UPDATE_NEWS_BY_ID:
            return {
                ...state,
                newsList: state.newsList.map(item => item.id === action.payload?.id ? action.payload : item)
            };
        case NEWS_CONSTS.DELETE_NEWS_BY_ID:
            return {
                ...state,
                newsList: state.newsList.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
