import moment from "moment";
import {deleteItem} from "../helperFunctions";
import {generateFileMediaUrl} from "../generateMemberMediaUrl";
import React from "react";

export const tabs = [
    // {
    //     name: "Նոր",
    //     key: "newCompanies",
    // },
    {
        name: "Բոլորը",
        key: "companiesList",
    },
];

export const companiesListHeaderInfo = {
    generalInfo: [
        "Նկար", "Անվանում", "Տնօրեն", "Օգտատեր", "Բալանս", "Քարտի Բալանս", "Պարտք","Trial", "Ամսաթիվ"
    ],
    status: true,
    largeItems: [],
    actions: ["edit", 'delete'],
};
export const branchListHeaderInfo = {
    generalInfo: ["Անվանում", "Հեռախոսահամար", "Տնօրեն", "Հասցե"],
    status: true,
    largeItems: [],
    actions: ["edit", 'delete'],
};

export function companiesDataParser(companiesList, deleteCb) {
    let itemListInfo = [];
    companiesList?.length && companiesList.forEach(item => {
        itemListInfo.push({
            id: item.id,
            status: {
                title: item?.activatedByAdmin ? 'Ակտիվ' : 'Ոչ ակտիվ',
                color: '#0ACC84',
            },
            newItem: !(item?.seen ?? true),
            details: {
                orderById: item,
            },
            // onClick: this.setOrderAsSeen.bind(this, item.id),
            values: [
                <img className={'news-image'} src={item?.logo ? item?.logo : generateFileMediaUrl(item?.logo?.path)}
                     alt="company"/>,
                item?.name || "-",
                (item?.user?.firstName || "-") + " " + (item?.user?.lastName || "-"),
                item?.user?.username || "-",
                Number.isInteger(item?.balance) ? item?.balance : item?.balance?.toFixed(2) || 0,
                Number.isInteger(item?.businessCard?.balance) ? item?.businessCard?.balance || 0 : item?.businessCard?.balance?.toFixed(2) || 0,
                !!item?.debt ? <div className="debt">-{Number.isInteger(item?.debt) ? item?.debt : item?.debt?.toFixed(2) || 0}</div>
                    : 0,
               item.trial ? 'Trial': 'No trial',
                moment(item.createdAt).format('DD.MM.YY'),
            ],
            deleteCb: deleteItem.bind(this, deleteCb, 'կազմակերպությունը', item.id),
        });
    });
    return itemListInfo;
}

export function branchesDataParser(branchList, deleteCb) {
    let itemListInfo = [];
    branchList?.length && branchList.forEach(item => {
        itemListInfo.push({
            id: item.id,
            status: {
                title: item?.isActive ? 'Ակտիվ' : 'Ոչ ակտիվ',
                color: '#0ACC84',
            },
            newItem: !(item?.seen ?? true),
            // details: {
            //     orderById: item,
            // },
            values: [
                item?.name || "-",
                item?.phone1 || "-",
                (item?.user?.firstName || "-") + " " + (item?.user?.lastName || "-"),
                item?.address?.name || "-",
            ],
            deleteCb: deleteItem.bind(this, deleteCb, 'կազմակերպությունը', item.id),
        });
    });
    return itemListInfo;
}
