import {AUTH_CONSTS, BRANCH_CONSTS} from "../constants";
import {ClearBranches} from "../actions";

export const initialState = {
    branchList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case BRANCH_CONSTS.GET_BRANCHES:
            return {
                ...state,
                branchList: action.payload
            };
        case BRANCH_CONSTS.CLEAR_BRANCHES:
            return {
                ...state,
                branchList: []
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
