import {HISTORY_TYPES} from "../../../constants/constTypes";

export const tabs = [
    {
        name: "Բոլորը",
        key: "allHistories",
    },
    // {
    //     name: "Հաշվի համալրում (ATM)",
    //     key: "filledBalance",
    //     types: [
    //         HISTORY_TYPES.FILLED_BALANCE_VIA_ATM,
    //     ],
    // },
    // {
    //     name: "Վճարման չեղարկում",
    //     key: "cancelPayment",
    //     types: [
    //         HISTORY_TYPES.CANCEL_PAYMENT_VIA_ATM
    //     ],
    // },
];

export const filledBalanceHeaderInfo = {
    generalInfo: [
        "Գործարքի Համար",
        "Քարտի Համար",
        "Օգտատեր",
        "Հեռ․",
        "Գումար",
        "Ամսաթիվ"],
    largeItems: [],
    status: false,
};
export const cancelPaymentHeaderInfo = {
    generalInfo: [
        "Գործարքի Համար",
        "Քարտի Համար",
        "Օգտատեր",
        "Հեռ․",
        "Գումար",
        "Ամսաթիվ"
    ],
    largeItems: [],
    status: false,
};

export const allPaymentHeaderInfo = {
    generalInfo: [
        "Գործարքի Համար",
        "Օգտատեր",
        "Քարտի համար",
        "Տեսակ",
        "Տիպ",
        "Գումար",
        "Ամսաթիվ"
    ],
    largeItems: [],
    status: false,
};
