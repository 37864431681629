// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch, Tree} from "antd";

//import styles
import '../../assets/styles/containerStyles/appended-product-section.scss';

// Import components
import Nestable from "react-nestable";
import {DatePicker} from 'antd';
import moment from "moment";
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";
import ProductsSelectorModal from "../../components/ProductsSelectorModal";
import ProductCard from "../../components/cards/ProductCard";

// Import utils
import {
    arraysEquals,
    changeStateField, disabledEndDate,
    getInputValues, getPatchReqData, getPostReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations, toggleStateField,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    CreateBrand,
    GetBrandById,
    UpdateBrandById,
    AddProductsToBrand,
    DeleteProductsFromBrand,
    UpdateProductsPositionsInBrand,
} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import swal from "sweetalert";
import {history} from "../../configs/history";

class AddEditBrand extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
                categories: [],
                products: [],
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
            mediaModalOpen: false,
            productsModalOpen: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.toggleProductsModal = toggleStateField.bind(this, 'productsModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.checkProductListDifferent = this.checkProductListDifferent.bind(this);
        this.productCardWrapper = this.productCardWrapper.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
        this.addEditBrand = this.addEditBrand.bind(this);
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;

        if (isEditing) {
            const translationsData = {};
            const id = this.props?.id;
            await this.props.GetBrandById(id);
            const {brandById} = this.props;
            if (brandById) {
                brandById.translations.forEach(item => {
                    translationsData[item?.languageId] = {
                        ...translationsData[item?.languageId],
                        title: item.title || '',
                        description: item.description || '',
                    };
                });
            }
            // const products = Array.isArray(brandById?.products) ?
            //     brandById.products : []
            const initStateData = {
                translations: translationsData,
                mediaMain: brandById?.mediaMain,
                categories: brandById.categories || [],
                products: brandById?.products,
                isHidden: brandById?.isHidden,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    checkProductListDifferent(currProducts) {
        let result = false;
        const initProducts = this.currentData.products;
        if (!Array.isArray(currProducts) || (currProducts?.length !== initProducts.length)) {
            result = true;
        } else {
            currProducts.forEach((item, index) => {
                if (initProducts[index]?.id !== item?.id) {
                    result = true;
                }
            })
        }

        if (result) {
            const productsList = currProducts.map(item => item?.id)
            this.updatedDataMap.set('products', productsList)

        } else {
            this.updatedDataMap.delete('products');
        }
    }

    getProducts(productsArray) {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.checkProductListDifferent(productsArray)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                products: productsArray,
            }
        })
    }

    deleteProduct(id) {
        const {fieldsData, isEditing} = this.state;
        let products = fieldsData.products.filter(p => p.id !== id);

        if (isEditing) {
            this.checkProductListDifferent(products)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                products: products,
            }
        })
    }

    onPositionChange(products) {
        const {fieldsData, isEditing} = this.state;
        // const products = items.map(item => item.product);
        // console.log(items, 'products');
        if (isEditing) {
            this.checkProductListDifferent(products)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                products: products
            }
        })
    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }

    getMultiSelectData = (checkedKeys, name) => {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            const updatedValues = checkedKeys?.length ? checkedKeys : []
            arraysEquals(this.currentData?.[name], checkedKeys)
                ? this.updatedDataMap.delete(name)
                : this.updatedDataMap.set(name, updatedValues);

        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [name]: checkedKeys,
            }
        })
    };

    treeData = (data, children) => {
        const treeData = [];
        data.forEach(item => {
            const treeItem = {
                title: item.title,
                key: item.id,
            };
            if (item[children] && item[children]?.length) {
                treeItem.children = this.treeData(item[children], children)
            }
            treeData.push(treeItem);
        })
        return treeData;
    };

    async addEditBrand() {
        await this.setState({
            requestLoading: true
        });
        const {fieldsData} = this.state;
        const {translations, mediaMain, url, isHidden, products} = fieldsData;
        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }

        // if (!products.length) {
        //     errors.products = true;
        //     result = false;
        // }
        if (result) {

            if (!this.state.isEditing) {
                const reqData = this.getPostReqData(fieldsData);
                url && (reqData.url = url);
                mediaMain && (reqData.mediaMain = mediaMain.id);
                console.log(reqData, 'reqData');
                this.props.CreateBrand(reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {
                const trData = getTranslationData(translations);
                const reqData = this.getPatchReqData(trData);
                this.props.UpdateBrandById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });

            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    productCardWrapper(data) {
        return <ProductCard data={data?.item}
                            onDelete={this.deleteProduct}
                            viewCard={true}/>
    }

    render() {
        const {categoriesList}=this.props
        const {fieldsData, errors, languageTab, mediaModalOpen, productsModalOpen, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};
        const items = fieldsData.products;

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Բրենդ' : 'Փոփոխել Բրենդը'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className="top-side">
                    <label className={`${errors.mediaMain ? 'error' : ''}`}>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <div style={{marginBottom: "15px"}}>
                    <label>Կատեգորիաներ</label>
                    <Tree
                        checkable
                        onCheck={(keys) => this.getMultiSelectData(keys, 'categories')}
                        checkedKeys={fieldsData.categories}
                        showLine={true}
                        treeData={this.treeData(categoriesList, "subCategories")}
                    />
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <InputGroup inputType="input"
                            type="text"
                            label="Անվանում"
                            placeholder="Անվանում"
                            name="title"
                            required={true}
                            value={currentData.title}
                            initValue={initData.title}
                            error={errors['title' + languageTab]}
                            maxLength={100}
                            onChange={this.getTranslationValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label="Նկարագրություն"
                            placeholder="Նկարագրություն"
                            name="description"
                            required={true}
                            value={currentData.description}
                            initValue={initData.description}
                            error={errors['description' + languageTab]}
                            maxLength={1000}
                            onChange={this.getTranslationValues}/>
                <section className={'products-medias-list-wrapper'}>
                    <div className={'label-and-action'}>
                        <label className={`${errors.products ? 'error' : ''}`}>Ապրանքներ <span className={'required-badge'}>*</span></label>
                        <LinkButton title="Ընտրել Ապրանքներից"
                                    className={`media-select-btn ${errors.products ? 'media-select-error' : ''}`}
                                    cb={this.toggleProductsModal}/>
                    </div>
                    <div className={'items-wrapper'}>

                        {items.length ? <Nestable
                                items={items}
                                maxDepth={1}
                                onChange={this.onPositionChange}
                                renderItem={this.productCardWrapper}
                            /> :
                            <p className={'empty-text'}>Կցված ապրանքներ չկան</p>}
                    </div>
                </section>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditBrand}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
            <ProductsSelectorModal
                isOpen={!!productsModalOpen}
                getSelectedProducts={this.getProducts}
                appendProducts={fieldsData.products}
                closeModal={this.toggleProductsModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateBrand,
    GetBrandById,
    UpdateBrandById,
    AddProductsToBrand,
    DeleteProductsFromBrand,
    UpdateProductsPositionsInBrand,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditBrand)
