// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/companies.scss';
import {ReactComponent as ClearIcon} from "../../assets/images/ic_clear.svg";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetPayments,
    AttachBusinessCard,
    GetBranches,
    UpdateCompany,
    DeleteBranchById, GetCompanyCard, ClearCompanies, ActivateCompany, DeleteBusinessCard, ClearBranches, GetCompanies
} from "../../redux/actions";
import {branchesDataParser, branchListHeaderInfo, tabs} from "../../utils/listDataHelpers/company";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import GetCardType from "../../utils/setCsrdType";
import {CARD_TYPES} from "../../constants/constTypes";
import {priceWithCommas} from "../../utils/helperFunctions";
import {Switch} from "antd";
import swal from "sweetalert";

class CompanyDetails extends Component {
    constructor() {
        super();
        this.state = {
            cardId: undefined,
            trial: false,
            blocked: false,
            loading: false,
            companyById: null
        };
        this.listRef = React.createRef();

        this.getInputValues = this.getInputValues.bind(this);
        this.deleteBranchItem = this.deleteBranchItem.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.activateCompany = this.activateCompany.bind(this);
        this.attachedCard = this.attachedCard.bind(this);
        this.deleteCard = this.deleteCard.bind(this);
        this.blockedCompany = this.blockedCompany.bind(this);
        this.toggleTrialVersion = this.toggleTrialVersion.bind(this);
    }

    deleteBranchItem(id) {
        this.props.DeleteBranchById(id).then(() => {
            this.props.GetBranches(this.props?.id);
        })
    }

    componentDidMount() {
        const {id} = this.props;
        this.props.GetPayments()
        this.props.GetBranches(id)
        this.props.GetCompanyCard(id)
        this.props.GetCompanies({
            key: "companiesList",
        }).then(() => {
            let companyById = this.props.companiesList.itemsList.find(item => item.id === id)
            this.setState({
                blocked: companyById?.blocked,
                trial: !!companyById?.trial,
                companyById: JSON.parse(JSON.stringify(companyById))
            })
        })
    }

    componentWillUnmount() {
        this.props.ClearCompanies()
        this.props.ClearBranches()
        this.setState({
            cardId: undefined,
            companyById: null
        })
    }

    handleChangeData(name, value) {
        this.searchItems({[name]: value})
    }

    searchItems(updatedData = {}) {
        // this.props.GetCompanies(filterData);
    }

    async getInputValues({name, value}) {
        await this.setState({
            [name]: value,
        });
        // this.handleChangeData(name, value)
    }

    attachedCard() {
        this.setState({
            loading: true
        })
        this.props.AttachBusinessCard({
            cardId: this.state.cardId,
            companyId: this.props?.id
        })
            .then(() => {
                this.setState({
                    cardId: undefined
                })
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })
    }

    deleteCard() {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել քարտը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteBusinessCard({
                    cardId: this.props.companyCard.id,
                    companyId: this.props?.id
                })
                this.props.GetPayments()
            }
        });
    }

    activateCompany() {
        this.props.ActivateCompany(this.props.id).finally(() => {
            this.setState({loading: false});
        });
    }

    blockedCompany(state) {
        this.getInputValues(state)
        this.props.UpdateCompany(this.props.id, {blocked: state.value})
    }

    toggleTrialVersion(state) {
        this.getInputValues(state)
        this.props.UpdateCompany(this.props.id, {trial: state.value})
    }

    render() {
        const {cardId, loading, blocked, trial, companyById} = this.state;
        const {businessCards, requestLoading, branchList, companyCard} = this.props;

        let itemsInfo = branchesDataParser(branchList, this.deleteBranchItem);
        // console.log(this.state.companyById, 'this.state.companyById');
        const cardsOptions = businessCards?.map(card => {
            return {
                name: card.holderName,
                id: card.id,
            }
        });
        // console.log(companyById, 'companyById');
        return <PageWrapper pageTitle={companyById?.name || 'Կազմակերպություններ'}>
            <section className="companies">
                <div className="top-side">
                    {!companyCard ? <div className="select-business-card">
                        <InputGroup
                            inputType="selectCustom"
                            value={cardId}
                            placeholder="Ընտրել քարտ"
                            name="cardId"
                            onChange={this.getInputValues}
                            options={cardsOptions}
                        />
                        <LinkButton title={`Կցել`}
                                    loading={loading}
                                    disabled={!cardId}
                                    cb={this.attachedCard}/>
                    </div> : <div className="card-wrapper">
                        <img src={CARD_TYPES[GetCardType(companyCard?.maskedCardNumber)]?.img} alt=""/>
                        <div className="card-content">
                            <div className="left-side">
                                <div className="card-name">{companyCard?.holderName?.toUpperCase()}</div>
                                <div className="card-number">{priceWithCommas(companyCard?.maskedCardNumber)}</div>
                            </div>
                            <div className="left-side">
                                <div className="balance-title">Balance</div>
                                <div className="balance">
                                    {Number.isInteger(companyCard?.balance)
                                        ? companyCard?.balance : companyCard?.balance?.toFixed(2)}
                                </div>
                            </div>
                        </div>
                        <button className="delete-btn" onClick={this.deleteCard}>
                            <ClearIcon title={''}/>
                        </button>
                    </div>}
                    <div className="">
                        {/*<LinkButton title={`Ակտիվացնել`}*/}
                        {/*            className={"bg-green"}*/}
                        {/*            cb={() => this.activateCompany()}/>*/}
                        <div className="blocked-wrapper">
                            <label>{!blocked ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                            <Switch checked={!blocked}
                                    onChange={(checked) => this.blockedCompany({
                                        name: 'blocked',
                                        value: !checked
                                    })}/>
                        </div>
                        <div className="trial-wrapper">
                            <label>{trial ? "trial " : "no trial "}</label>
                            <Switch checked={trial}
                                    onChange={(checked) => this.toggleTrialVersion({
                                        name: 'trial',
                                        value: checked
                                    })}/>
                        </div>
                    </div>
                </div>
                {
                    !!companyById?.debt && <div className="company-debt">
                        Պարտք:<span>{Number.isInteger(companyById?.debt) ? companyById?.debt : companyById?.debt?.toFixed(2)}</span>
                    </div>
                }
                <LinkButton title={'Ավելացնել մասնաճյուղ'}
                            className={'add-branch'}
                            link={`/companies/${this.props.id}/branch/add`}/>
                <ItemsList itemListInfo={itemsInfo}
                           itemListHeaderInfo={branchListHeaderInfo}
                           detailsLink={`companies/${this.props.id}/branch`}
                           editLink={`/companies/${this.props.id}/branch`}
                           listRef={this.listRef}
                           loading={requestLoading}
                />
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetPayments,
    GetBranches,
    DeleteBranchById,
    UpdateCompany,
    GetCompanyCard,
    ClearCompanies,
    ActivateCompany,
    AttachBusinessCard,
    DeleteBusinessCard,
    ClearBranches,
    GetCompanies
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails)
