import {TERMS_CONDITIONS_CONSTS} from "../constants/termsConditionsConsts";
import {_urlTerms, request} from "../api";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetTermsConditions = () => {
    console.log('GetTermsConditions');
    const requestData = {
        url: `${_urlTerms}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(async res => {
                await dispatch({
                    type: TERMS_CONDITIONS_CONSTS.GET_TERMS_CONDITIONS,
                    payload: res.data
                })
            })
    }
};

export const UpdateTermsConditions = data => {
    console.log('UpdateTermsConditions', data);
    const requestData = {
        url: `${_urlTerms}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: TERMS_CONDITIONS_CONSTS.UPDATE_TERMS_CONDITIONS,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Պայմաններ եւ դրույթները հաջողությամբ փոփոխվեց!"
                })
            })
    }
};

