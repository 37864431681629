import {_urlDirection, request} from "../api";
import {DIRECTION_CONSTS} from "../constants";
import {notificationSuccess} from "../../components/uiElements/Notifications";
import {history} from "../../configs/history";

export const GetDirectionList = () => {
    const requestData = {
        url: `${_urlDirection}/all`,
        token: true,
        scope: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: DIRECTION_CONSTS.GET_DIRECTION_LIST,
                    payload: data
                });
            })
    }
};

export const DeleteDirectionById = id => {
    const requestData = {
        url: `${_urlDirection}/${id}`,
        token: true,
        scope: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: DIRECTION_CONSTS.DELETE_DIRECTION_BY_ID,
                    payload: id
                });
            })
    }
};

export const CreateDirection = data => {
    const requestData = {
        url: _urlDirection,
        token: true,
        scope: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: DIRECTION_CONSTS.CREATE_DIRECTION,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ուղղությունը հաջողությամբ ստեղծվեց!"
                })
                history.push('/directions')
            })
    }
};

export const UpdateDirectionById = (id, data) => {
    const requestData = {
        url: `${_urlDirection}/${id}`,
        token: true,
        scope: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: DIRECTION_CONSTS.UPDATE_DIRECTION_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ուղղությունը հաջողությամբ փոփոխվեց!"
                })
                history.push('/directions')
            })
    }
};
