// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import 'moment/locale/hy-am';

// Import Styles
import '../assets/styles/containerStyles/history.scss';

// Import components
import PageWrapper from '../components/pageContentViews/pageWrapper'
import PageHeader from "../components/pageContentViews/pageHeader";
import ItemsList from "../components/ItemsList";
import {DatePicker} from "antd";
import {InputGroup} from "../components/uiElements/inputGroup";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {ClearHistory, GetHistory, setFolderList, GetMedia} from "../redux/actions";
import {
    tabs,allPaymentHeaderInfo
} from "./history/utils/constants";
import {changeStateField, disabledEndDate, disabledStartDate} from "../utils/helperFunctions";
import {parseAtmHistoryData} from "./history/utils/actionFunctions";
import {history} from "../configs/history";
import {ATM_HISTORIES} from "../constants/constTypes";

const dateFormat = 'DD.MM.YYYY';

class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            activeTab: tabs[0].key,
            text: ''
        };
        this.listRef = React.createRef();
        this.changeTab = changeStateField.bind(this, "activeTab");
        this.getSearchValue = this.getSearchValue.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this,);
        this.typeOptions = Object.keys(ATM_HISTORIES).map(key => ({
            id: key,
            name: ATM_HISTORIES?.[key]?.from
        }))
    }

    componentDidMount() {
        this.props.GetHistory({
            key: tabs[0].key
        }).then(() => {
            tabs.slice(1).forEach(tab => {
                this.props.GetHistory({
                    key: tab.key
                })
            })
        });
        this.props.GetMedia({parent: null, searchValue: '', type: null}).catch(() => {
            history.push({
                pathname: '/media',
            });
            setFolderList([])
        });
    }

    componentWillUnmount() {
        this.props.ClearHistory()
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (this.state.activeTab !== prevState.activeTab) {
            // console.group('snapshot');
            // console.log('scrollTop', this.listRef?.current?.scrollTop);
            // console.log('searchValue', prevState.searchValue);
            // console.groupEnd();
            this[prevState.activeTab] = {
                scrollTop: this.listRef?.current?.scrollTop ?? 0,
                text: prevState.text
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {activeTab} = this.state;
        if (activeTab !== prevState.activeTab) {
            this.listRef.current.scrollTop = this[activeTab]?.scrollTop ?? 0;
            this.setState({
                text: this[activeTab]?.text ?? ''
            })
        }
    }

    getSearchValue(text) {
        this.setState({
            text
        }, () => !text && this.searchItems({text}))
    }

    handleChangeData(name, value) {
        this.searchItems({[name]: value})
    }

    searchItems(updatedData = {}) {
        const {activeTab} = this.state;
        const currentHistory = this.props[activeTab]
        const {text, startDate, endDate, type, stationId, stationNetworkId} = currentHistory;
        let filterData = {
            key: activeTab,
            startDate, endDate, text, type, stationId, stationNetworkId,
            ...updatedData,
        };
        if (filterData?.text && filterData.text?.length === 1) {
            return;
        }
        this.props.GetHistory(filterData);
    }

    loadMoreItems() {
        const {activeTab} = this.state;
        const historyData = this.props[activeTab];
        //console.log('historyData', historyData);
        historyData.hasMore && this.searchItems({
            reset: false,
            offset: historyData.itemsList.length,
        });
    };

    render() {
        const {requestLoading} = this.props;
        const {activeTab, text} = this.state;

        const currentHistory = this.props[activeTab];
        const hasMore = currentHistory?.hasMore;
        const {startDate, endDate, type} = currentHistory;

        let headerInfo, itemsInfo, className;

        switch (activeTab) {
            case tabs[0].key:
                headerInfo = allPaymentHeaderInfo;
                itemsInfo = parseAtmHistoryData(currentHistory);
                className = 'atm-history';
                break;
            // case tabs[1].key:
            //     headerInfo = cancelPaymentHeaderInfo;
            //     itemsInfo = parseAtmHistoryData(currentHistory);
            //     className = 'atm-history';
            //     break;
            // case tabs[2].key:
            //     headerInfo = filledBalanceHeaderInfo;
            //     itemsInfo = parseAtmHistoryData(currentHistory);
            //     className = 'atm-history';
            //     break;
        }

        return <PageWrapper withActions={true} pageTitle={'Պատմություն'}>
            <section className="history">
                <PageHeader
                    getSearchValue={this.getSearchValue}
                    searchItems={() => this.searchItems({text})}
                    searchValue={text}
                    search={true}
                    renderingCustomLeftPart={<div className="history-filters-date-part">
                        <InputGroup inputType={"wrapper"}>
                            <DatePicker value={startDate}
                                        format={dateFormat}
                                        showToday={false}
                                        allowClear={true}
                                        disabledDate={(date) => disabledStartDate(date, endDate)}
                                        placeholder="Սկիզբ"
                                        className={`date-picker`}
                                        onChange={(value) =>
                                            this.handleChangeData('startDate', value)}>
                            </DatePicker>
                        </InputGroup>
                        <InputGroup inputType={"wrapper"}>
                            <DatePicker value={endDate}
                                        format={dateFormat}
                                        showToday={false}
                                        allowClear={true}
                                        disabledDate={(date) => disabledEndDate(date, startDate)}
                                        placeholder="Ավարտ"
                                        className={`date-picker`}
                                        onChange={(value) =>
                                            this.handleChangeData('endDate', value)}>
                            </DatePicker>
                        </InputGroup>
                    </div>}
                    filters={<div className="history-filters-wrapper">


                        <div className="history-filters-part">
                            {<InputGroup inputType="selectCustom"
                                         placeholder="Կարգավիճակ"
                                         name="type"
                                         showSearch={false}
                                         value={type || undefined}
                                         withClear={true}
                                         onChange={({value}) => this.handleChangeData('type', value)}
                                         options={this.typeOptions}>
                                {/*{type && <Select.Option value={undefined}>{'Բոլորը'}</Select.Option>}*/}
                            </InputGroup>}
                            {/*<InputGroup inputType="selectCustom"*/}
                            {/*            placeholder="Տեսակ"*/}
                            {/*            name="type"*/}
                            {/*            tooltip={true}*/}
                            {/*            showSearch={false}*/}
                            {/*            value={type || undefined}*/}
                            {/*            onChange={({value}) => this.handleChangeData('type', value)}*/}
                            {/*            options={typesOptions}>*/}
                            {/*    {type && <Select.Option value={undefined}>Բոլորը</Select.Option>}*/}
                            {/*</InputGroup>*/}
                        </div>

                    </div>}
                />
                {/*<div className="tabs-wrapper" style={{position: "relative"}}>*/}
                {/*    <CustomTabs changeTab={this.changeTab} activeTab={activeTab} tabsList={tabs}/>*/}
                {/*</div>*/}
                {/*                {activeTab === 'stations' && <div className={'type-select'}>

                    <InputGroup inputType="selectCustom"
                                placeholder="Ընտրել ցանց"
                                name="stationNetworkId"
                                showSearch={false}
                                value={stationNetworkId || undefined}
                                onChange={({value}) => this.handleChangeData('stationNetworkId', value)}
                                options={networksOptions}>
                        {stationNetworkId && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                    </InputGroup>
                    <InputGroup inputType="selectCustom"
                                placeholder="Ընտրել լցակայան"
                                name="stationId"
                                showSearch={false}
                                value={stationId || undefined}
                                onChange={({value}) => this.handleChangeData('stationId', value)}
                                options={stationsOptions}>
                        {stationId && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                    </InputGroup>
                </div>}*/}
                <ItemsList
                    className={className}
                    itemListHeaderInfo={headerInfo}
                    itemListInfo={itemsInfo}
                    loading={requestLoading}
                    infiniteScroll={true}
                    hasMore={hasMore}
                    listRef={this.listRef}
                    loadMoreItems={this.loadMoreItems}/>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetHistory,
    ClearHistory,
    GetMedia
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
