import {request, _urlCountries} from "../api";
import {COUNTRY_CONSTS} from "../constants";
import swal from "sweetalert";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetCountries = ({isTop = null} = {}) => {
    let url = `${_urlCountries}`;
    isTop !== null && (url += `?isTop=${isTop}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: COUNTRY_CONSTS.GET_COUNTRIES,
                    payload: data
                });
            })
    }
};

export const GetCountryById = (id) => {
    const requestData = {
        url: `${_urlCountries}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: COUNTRY_CONSTS.GET_COUNTRY_BY_ID,
                    payload: res.data
                })
            })
    }
};

export const DeleteCountryById = id => {
    const requestData = {
        url: `${_urlCountries}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: COUNTRY_CONSTS.DELETE_COUNTRY_BY_ID,
                    payload: id
                });
            })
    }
};

export const CreateCountry = data => {
    const requestData = {
        url: _urlCountries,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: COUNTRY_CONSTS.CREATE_COUNTRY,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Երկիր հաջողությամբ ստեղծվեց!"
                })
                history.push('/categories')

            })
    }
};

export const UpdateCountryById = (id, data, redirect=true) => {
    const requestData = {
        url: `${_urlCountries}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                notificationSuccess({
                    description: "Երկիր հաջողությամբ փոփոխվեց!"
                });
                redirect && history.push('/categories')
            })
    }
};

export const UpdateCountriesPositions = items => {
    const requestData = {
        url: _urlCountries,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Երկիր հաջողությամբ թարմացվեց!"
                })
            })
    }
};
