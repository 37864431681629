import {SECTION_CONSTS} from "../constants";

export const initialState = {
    sectionList: [],
    sectionById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SECTION_CONSTS.GET_SECTIONS:
            return {
                ...state,
                sectionList: action.payload || [],
            };
        case SECTION_CONSTS.GET_SECTION_BY_ID:
            return {
                ...state,
                sectionById: action.payload,
            };
        case SECTION_CONSTS.CREATE_SECTION:
            return {
                ...state,
                sectionList: [action.payload].concat(state.sectionList)
            };
        case SECTION_CONSTS.UPDATE_SECTION_BY_ID:
            return {
                ...state,
                sectionList: state.sectionList.map(item => item.id === action.payload?.id ? action.payload : item)
            };
        case SECTION_CONSTS.DELETE_SECTION_BY_ID:
            return {
                ...state,
                sectionList: state.sectionList.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
