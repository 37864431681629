// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch} from "antd";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreateCategory, GetCategoryById, UpdateCategoryById} from "../../redux/actions";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {validateTranslations} from "../../utils/validateTranslations";
import {ColorPicker} from "../../components/uiElements/ColorPicker";


class AddEditCategory extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                color: '',
                iconSquare: undefined,
                iconRectangle: undefined,
                parent: undefined,
                isHidden: false,
                isHotel: false,
                isTop: false,
            },
            errors: {},
            errorsTabs: [],
            displayColorPicker: false,
            isEditing: !!props?.id,
            // languageTab: props.mainLanguage,
            languageTab: '5db99765e2019423d6e0df83',
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.toggleRectangleMediaModal = toggleStateField.bind(this, 'mediaRectangleModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);

        this.getRectangleMedia = this.getRectangleMedia.bind(this);

        this.getColor = this.getColor.bind(this);

        this.deleteMedia = this.deleteMedia.bind(this);
        this.deleteRectangleMedia = this.deleteRectangleMedia.bind(this);

        this.addEditCategory = this.addEditCategory.bind(this);
    }

    async componentDidMount() {
        const {isEditing, languageTab} = this.state;
        const translationsData = {};

        // init Categories Options
        this.categoriesOptions = [];
        Array.isArray(this.props.categoriesList) && this.props.categoriesList.forEach(item => {
            this.categoriesOptions.push({
                name: item?.title,
                id: item?.id,
            });
            Array.isArray(item?.subCategories) && item.subCategories.forEach(sub => this.categoriesOptions.push({
                name: sub?.title,
                id: sub?.id,
            }))
        });

        if (isEditing) {
            await this.props.GetCategoryById(this.props.id);
            const editingData = this.props.categoryById;
            this.categoriesOptions = this.categoriesOptions.filter(item => item.id !== this.props.id);
            if (editingData) {
                editingData.translations.forEach(item => {
                    translationsData[item?.languageId] = {
                        ...translationsData[item?.languageId],
                        title: item.title || '',
                    };
                });

            }
            const initStateData = {
                translations: translationsData,
                iconSquare: editingData?.iconSquare,
                iconRectangle: editingData?.iconRectangle,
                color: editingData?.color,
                parent: editingData?.parent,
                isHidden: editingData?.isHidden,
                isHotel: editingData?.isHotel,
                isTop: editingData?.isTop,
            };
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                ...this.state,
                fieldsData: initStateData
            });
        } else {
            //for categories options
            this.forceUpdate()
        }
    }

    getMedia(mediaArray) {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.iconSquare?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('iconSquare', mediaArray[0]?.id)
                : this.updatedDataMap.delete('iconSquare');


        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                iconSquare: mediaArray[0]
            },
        })
    }

    getRectangleMedia(mediaArray) {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.iconRectangle?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('iconRectangle', mediaArray[0]?.id)
                : this.updatedDataMap.delete('iconRectangle');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                iconRectangle: mediaArray[0]
            },
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {

            this.currentData.iconSquare ?
                this.updatedDataMap.set("iconSquare", null) :
                this.updatedDataMap.delete("iconSquare");
            //
            // this.currentData.iconRectangle ?
            //     this.updatedDataMap.set("iconRectangle", null) :
            //     this.updatedDataMap.delete("iconRectangle");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                iconSquare: null,
                // iconRectangle: null
            }
        })
    }

    deleteRectangleMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {

            this.currentData.iconRectangle ?
                this.updatedDataMap.set("iconRectangle", null) :
                this.updatedDataMap.delete("iconRectangle");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                iconRectangle: null
            }
        })
    }

    getColor({color, haveChanges}) {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            haveChanges
                ? this.updatedDataMap.set('color', color.hex)
                : this.updatedDataMap.delete('color');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                color: color.hex
            }
        })
    };

    addEditCategory() {
        this.setState({
            requestLoading: true
        });
        const {translations, iconSquare, iconRectangle, isHidden, isHotel, isTop, parent} = this.state.fieldsData;

        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (result) {
            const trData = getTranslationData(translations);
            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    isTop,
                    isHotel,
                    translations: trData,
                };
                if (iconSquare) reqData.iconSquare = iconSquare.id;
                if (iconRectangle) reqData.iconRectangle = iconRectangle.id;
                if (parent) reqData.parent = parent;
                this.props.CreateCategory(reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    })
            } else {
                const reqData = this.getPatchReqData(trData);

                this.props.UpdateCategoryById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {fieldsData, errors, languageTab, mediaModalOpen, mediaRectangleModalOpen, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Կատեգորիա' : 'Փոփոխել Կատեգորիան'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <ColorPicker color={fieldsData.color}
                                 initColor={this.currentData?.color}
                                 onChange={this.getInputValues}/>
                    <div className="switch-wrapper">
                        <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                        <Switch checked={!fieldsData.isHidden}
                                onChange={(checked) => this.getInputValues({
                                    name: "isHidden",
                                    value: !checked,
                                    haveChanges: this.currentData?.isHidden !== !checked
                                })}/>
                    </div>
                    <div className="switch-wrapper">
                        <label>{"Թոփ"}</label>
                        <Switch checked={fieldsData.isTop}
                                onChange={(checked) => this.getInputValues({
                                    name: "isTop",
                                    value: checked,
                                    haveChanges: this.currentData?.isTop !== checked
                                })}/>
                    </div>
                </div>
                <div className="fixed-top-right is-hotel">
                    <label>Հյուրանոց</label>
                    <Switch checked={fieldsData.isHotel}
                            onChange={(checked) => this.getInputValues({
                                name: 'isHotel',
                                value: checked,
                                haveChanges: this.currentData?.isHotel !== checked
                            })}/>
                </div>

                <div className="top-side row">
                    <div>
                        <label>Քառակուսի նկար</label>
                        <LinkButton title="Ընտրել մեդիադարանից"
                                    className={`media-select-btn ${errors.icon ? 'media-select-error' : ''}`}
                                    cb={this.toggleMediaModal}
                        />
                    </div>
                   <div>
                       <label>Ուղղանկյուն նկար</label>
                       <LinkButton title="Ընտրել մեդիադարանից"
                                   className={`media-select-btn ${errors.icon ? 'media-select-error' : ''}`}
                                   cb={this.toggleRectangleMediaModal}
                       />
                   </div>
                </div>
                <div className="row">
                    <div className={'main-media'}>
                        {fieldsData.iconSquare && <MediaFileCard item={fieldsData?.iconSquare}
                                       customDelete={this.deleteMedia}
                        />}
                    </div>
                    {fieldsData.iconRectangle && <div className={'main-media'}>
                        <MediaFileCard item={fieldsData?.iconRectangle}
                                       customDelete={this.deleteRectangleMedia}
                        />
                    </div>}
                </div>


                <InputGroup inputType="wrapper"
                            label={"Ծնող Կատեգորիա"}>
                    <InputGroup inputType="selectCustom"
                                name="parent"
                                value={fieldsData.parent}
                                initValue={this.currentData?.parent}
                                withClear={true}
                                placeholder={'Ծնող Կատեգորիա'}
                                onChange={this.getInputValues}
                                options={this.categoriesOptions}
                    />
                </InputGroup>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}
                />
                <div className="category-left-part">
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>

                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditCategory}/>
                </div>

            </section>

            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />

            <MediaSelectorModal
                isOpen={!!mediaRectangleModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getRectangleMedia}
                closeModal={this.toggleRectangleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateCategory,
    GetCategoryById,
    UpdateCategoryById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCategory)
