import {_urlNews, request} from "../api";
import {NEWS_CONSTS} from "../constants";
import {notificationSuccess} from "../../components/uiElements/Notifications";
import {history} from "../../configs/history";

export const GetNewsList = () => {
    const requestData = {
        url: _urlNews,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: NEWS_CONSTS.GET_NEWS_LIST,
                    payload: data
                });
            })
    }
};


export const GetNewsById = (id) => {
    const requestData = {
        url: `${_urlNews}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                return dispatch({
                    type: NEWS_CONSTS.GET_NEWS_BY_ID,
                    payload: res.data
                })
            })
    }
};


export const DeleteNewsById = id => {
    const requestData = {
        url: `${_urlNews}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: NEWS_CONSTS.DELETE_NEWS_BY_ID,
                    payload: id
                });
            })
    }
};

export const CreateNews = data => {
    const requestData = {
        url: _urlNews,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: NEWS_CONSTS.CREATE_NEWS,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Նորությունը հաջողությամբ ստեղծվեց!"
                })
                history.push('/news')
            })
    }
};

export const UpdateNewsById = (id, data) => {
    const requestData = {
        url: `${_urlNews}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: NEWS_CONSTS.UPDATE_NEWS_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Նորությունը հաջողությամբ փոփոխվեց!"
                })
                history.push('/news')
            })
    }
};
