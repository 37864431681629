// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {history} from "../configs/history";
import {withRouter} from 'react-router-dom';

//Import styles
import "../assets/styles/containerStyles/adminLayout.scss";

//Import components
import {MainHeader} from "../components/headerViews/mainHeader";
import {Footer} from "./Footer";
import {Icon} from "antd";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {
    GetLanguages, LogOut, RedirectFirstTime, ToggleLeftSideMenu, TokenValidation, GetFilters, GetCategories,
    GetStatuses, GetNotSeenOrdersCount, GetCompanies, GetDirectionList, GetFacilities
} from "../redux/actions";
import {LeftSideMenu} from "../components/navbars/LeftSideMenu";
import {socketNewLogConnection} from "../socket/api/socketNewLogConnection";


class AdminLayout extends Component {
    state = {
        isMounted: false,
    };

    async componentDidMount() {
        let redirectLoginPage = false;
        const {location} = this.props;
        if (!this.props.isLoggedIn) {
            redirectLoginPage = true;
        } else {
            await this.props.TokenValidation().then(async () => {
                await this.props.GetLanguages();
                await this.props.GetFilters();
                await this.props.GetCategories();
                await this.props.GetStatuses();
                await this.props.GetNotSeenOrdersCount();
                // await this.props.GetDirectionList();
                await this.props.GetFacilities();

                // if (!socketNewLogConnection._instance && this.props.isLoggedIn) {
                //     socketNewLogConnection.connectToSocket();
                // }

                // await this.props.GetCompanies({
                //     key: 'companiesList'
                // });
                //  console.log('accessPages', this.props.accessPages)
            }).catch(() => {
                redirectLoginPage = true;
            });
        }
        if (redirectLoginPage) {
            this.props.LogOut();
        } else {
            //const {accessPages} = this.props;
            // if (location && location.pathname.includes('moderators') && !this.props.isAdmin) {
            //     history.push('/404');
            // }
            // this.setState({
            //     isMounted: true,
            // })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location} = this.props;
        if (location?.pathname !== prevProps?.location?.pathname) {
            window.scrollTo(0, 0);
        }
        if (location && location.pathname.includes('moderators') && !this.props.isAdmin) {
            history.push('/404');
        }
    }

    render() {
        const {isMounted} = this.state;
        const {
            children, leftSideMenuOpen, ToggleLeftSideMenu, LogOut, isAdmin, location, user, notSeenOrdersCount
        } = this.props;
        const spinIcon = <Icon type="loading" style={{fontSize: 54, color: "#1F3A62"}} spin/>;

        return !isMounted ? <div className={`admin-layout ${leftSideMenuOpen ? "menu-open" : ""}`}>
                <MainHeader toggleMenu={ToggleLeftSideMenu}
                            leftSideMenuOpen={leftSideMenuOpen}
                            isAdmin={isAdmin}
                            user={user}
                            LogOut={LogOut}/>
                <LeftSideMenu leftSideMenuOpen={leftSideMenuOpen}
                              isAdmin={isAdmin}
                              notSeenOrdersCount={notSeenOrdersCount}
                              path={location && location.pathname}/>

                {children}
                {/*   <Footer  leftSideMenuOpen={leftSideMenuOpen}/>*/}
            </div> :
            <div className={'loading'}>
                {spinIcon}
            </div>
    }
}

const mapDispatchToProps = {
    ToggleLeftSideMenu,
    LogOut,
    GetLanguages,
    RedirectFirstTime,
    TokenValidation,
    GetFilters,
    GetCategories,
    GetStatuses,
    GetNotSeenOrdersCount,
    GetCompanies,
    GetDirectionList,
    GetFacilities
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminLayout));
