import moment from "moment";
import React from "react";

const oneHourInMilliseconds = 1000 * 60 * 60; //1 hour

export function parseShopsData (shopsList) {
    return shopsList?.itemsList?.map(item => {
        const { name, address, lastActivity, shopUserCount } = item || {};
        return {
            id: item.id,
            values: [
                name ?? '-',
                address ?? '-',
                shopUserCount ?? '-',
                <div className={'activity'}>
                    <span style={{ backgroundColor: getActivityStatus(lastActivity) }}/>
                    {moment(lastActivity).format("DD.MM.YY / HH:mm")}
                </div>,
            ],
        }
    });
}

const getActivityStatus = (lastActivity) => {
    let lastActivation = new Date(lastActivity);
    let currentDate = new Date();
    let diff = currentDate.getTime() - lastActivation.getTime();

    let hoursCount = Math.ceil(diff / oneHourInMilliseconds);

    if (hoursCount <= 2) {
        return '#1ea501'
    }
    if (hoursCount <= 6) {
        return '#ffed1e'
    }
    return '#ff0d20'
};


const dateHours = [1, 2, 3, 4, 5, 6, 12]
const dateDays = [1, 2, 3, 4, 5, 6, 7]
const dateMonth = [1, 2, 3, 4, 5, 6]

export const dateOptions = []

dateHours.forEach(hour => {
    dateOptions.push({
        id: hour * oneHourInMilliseconds,
        name: hour + ' Ժամ',
        label: 'Ակտիվ չեն ' + hour + ' Ժամ'
    })
})
dateDays.forEach(day => {
    dateOptions.push({
        id: oneHourInMilliseconds * 24 * day,
        name: day + ' Օր',
        label: 'Ակտիվ չեն ' + day + ' Օր'
    })
})
dateMonth.forEach(month => {
    dateOptions.push({
        id: oneHourInMilliseconds * 24 * 30 * month,
        name: month + ' Ամիս',
        label: 'Ակտիվ չեն ' + month + ' Ամիս'
    })
})

