import React from 'react';
import {connect} from 'react-redux';

//import styles
import '../../../assets/styles/media/add-file-dropDown-card.scss';

//import components
import MediaUploader from './MediaUploader';
import {Dropdown, Modal} from "antd";
import {InputGroup} from "../../uiElements/inputGroup";
import {LinkButton} from "../../buttons/buttons";

//import utils
import {mapStateToProps} from "../../../redux/mapStateToProps";
import {
    addFile,
    cancelAddFileByUrl,
    closeAddMedia,
    chooseUploadType,
    getFileUploadByUrlValues,
    getMedias,
    onDropFile,
    onUploadClick,
    onUploadProgress,
    toggleAddFileCard,
    triggerFileUpload,
    uploadMedias,
    minimizeUpload
} from "./functions/action-functions";
import {GetMedia, UploadFile} from "../../../redux/actions";
import {UPLOAD_TYPES} from "../../../constants/constTypes";

class MediaUploaderWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUploadingId: null,
            uploading: false,

            loadingGetFileByUrl: false,

            addingFiles: [],
            filesIsChoose: false,

            addFileCardOpen: false,
            chooseType: null,
            name: '',
            shazamUrl: '',
            url: '',
            errors: {},
            fileByUrl: null,
        };
        this.inputElement = React.createRef();
        this.inputDirectory = React.createRef();

        this.closeAddMedia = closeAddMedia.bind(this);
        this.onUploadClick = onUploadClick.bind(this);
        this.triggerFileUpload = triggerFileUpload.bind(this);
        this.getMedias = getMedias.bind(this);
        this.onDropFile = onDropFile.bind(this);
        this.uploadMedias = uploadMedias.bind(this);
        this.minimizeUpload = minimizeUpload.bind(this);
        this.onUploadProgress = onUploadProgress.bind(this);
        // new
        this.getFileUploadByUrlValues = getFileUploadByUrlValues.bind(this);
        this.chooseUploadType = chooseUploadType.bind(this);
        this.toggleAddFileCard = toggleAddFileCard.bind(this);
        this.cancelAddFileByUrl = cancelAddFileByUrl.bind(this);
        this.addFile = addFile.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }


    dragEnter(e) {
        // console.log('dragEnter wrapper')
        e.stopPropagation();
        e.preventDefault();
    }

    onDragLeave(e) {
        // console.log('onDragLeave wrapper')
        e.stopPropagation();
        e.preventDefault();
    }

    dragOver(e) {
        // console.log('dragOver wrapper')
        e.stopPropagation();
        e.preventDefault();
    }

    closeModal() {
        this.closeAddMedia();
        this.state.uploading && this.minimizeUpload();
    }

    render() {
        const {uploadProgresses} = this.props;
        const {
            filesIsChoose, addingFiles, uploading, addFileCardOpen, chooseType, errors, name,
            shazamUrl, url, loadingGetFileByUrl, currentUploadingId
        } = this.state;
        const {progress = 0, status = null} = uploadProgresses[currentUploadingId] || {}
        return (
            <div className={"file-uploader "}
                 onDragEnter={this.dragEnter}
                 onDragLeave={this.onDragLeave}
                 onDragOver={this.dragOver}
                 onDrop={this.onDropFile}>
                <Dropdown placement="bottomLeft"
                          trigger={["click"]}
                          className={"add-file-btn"}
                          overlayClassName={`add-file-dropdown `}
                          onVisibleChange={this.toggleAddFileCard}
                          visible={addFileCardOpen}
                          overlay={
                              <div className={'add-file-dropdown-card'}>
                                  {!chooseType && <div className={'type-select-icons'}>
                                      {/*<div className={"dropdown-card-img-wrapper"}*/}
                                      {/*     onClick={() => this.inputDirectory.current.click()}>*/}
                                      {/*    <img*/}
                                      {/*        src={require('../../../assets/images/file-manager/ic_choose_from_computer.svg')}*/}
                                      {/*        alt={''}/>*/}
                                      {/*    <span>Ներբեռնել համակարգչից Թղթապանակ</span>*/}
                                      {/*</div>*/}
                                      <div className={"dropdown-card-img-wrapper"} onClick={this.triggerFileUpload}>
                                          <img
                                              src={require('../../../assets/images/file-manager/ic_choose_from_computer.svg')}
                                              alt={''}/>
                                          <span>Ներբեռնել համակարգչից</span>
                                      </div>
                                      <div className={"dropdown-card-img-wrapper"} onClick={this.chooseUploadType}>
                                          <img src={require('../../../assets/images/file-manager/ic_choose_by_url.svg')}
                                               alt={''}/>
                                          <span>Ներբեռնել հղման միջոցով</span>

                                      </div>
                                  </div>}
                                  {chooseType === UPLOAD_TYPES.URL &&
                                  <div>
                                      <div className={"form-wrapper"}>
                                          {/*<InputGroup inputType={"input"}*/}
                                          {/*            type={"text"}*/}
                                          {/*            placeholder={"Shazam Artist Url"}*/}
                                          {/*            name={"shazamUrl"}*/}
                                          {/*            value={shazamUrl}*/}
                                          {/*            maxLength={500}*/}
                                          {/*            required={true}*/}
                                          {/*            error={errors.shazamUrl}*/}
                                          {/*            onChange={this.getFileUploadByUrlValues}>*/}
                                          {/*    /!*<img className={"placeholder-img"}*!/*/}
                                          {/*    /!*     src={require("../../../assets/images/file-manager/ic_choose_by_url.svg")}*!/*/}
                                          {/*    /!*     alt=""/>*!/*/}
                                          {/*</InputGroup>*/}
                                          <InputGroup inputType={"input"}
                                                      type={"text"}
                                                      placeholder={"Հղում"}
                                                      name={"url"}
                                                      value={url}
                                                      maxLength={500}
                                                      required={true}
                                                      error={errors.url}
                                                      onChange={this.getFileUploadByUrlValues}>
                                              <img className={"placeholder-img"}
                                                   src={require("../../../assets/images/file-manager/ic_choose_by_url.svg")}
                                                   alt=""/>
                                          </InputGroup>
                                          <InputGroup inputType={"input"}
                                                      type={"text"}
                                                      placeholder={"Անուն"}
                                                      name={"name"}
                                                      value={name}
                                                      maxLength={100}
                                                      error={errors.name}
                                                      required={true}
                                                      onChange={this.getFileUploadByUrlValues}>
                                              <img className={"placeholder-img"}
                                                   src={require("../../../assets/images/file-manager/ic_file.svg")}
                                                   alt=""/>
                                          </InputGroup>
                                      </div>
                                      <div className="buttons-wrapper">
                                          <LinkButton title="Չեղարկել"
                                                      className={"bg-white"}
                                                      cb={this.cancelAddFileByUrl}/>
                                          <LinkButton title="Ավելացնել"
                                                      className={"bg-green"}
                                                      loadingWithTitle
                                                      loading={loadingGetFileByUrl}
                                                      cb={this.addFile}/>
                                      </div>
                                  </div>}
                              </div>

                          }>
                    <div>
                        <img src={require("../../../assets/images/file-manager/new_file.svg")} alt=""/>
                        Ավելացնել Ֆայլ
                    </div>
                </Dropdown>

                <input
                    style={{display: 'none'}}
                    type="file"
                    ref={this.inputElement}
                    multiple={true}
                    onChange={this.onDropFile}
                    onClick={this.onUploadClick}

                />
                <input
                    style={{display: 'none'}}
                    type="file"
                    ref={this.inputDirectory}
                    multiple={true}
                    onChange={this.onDropFile}
                    onClick={this.onUploadClick}
                    webkitdirectory=""
                    directory=""
                    mozdirectory=""
                />
                <Modal
                    visible={filesIsChoose}
                    wrapClassName={'custom-modal'}
                    onCancel={this.closeModal}>
                    <div className={'media-uploader-wrapper-modal'}>

                        <MediaUploader addingFiles={addingFiles}
                                       uploading={uploading}
                                       status={status}
                                       progress={progress}
                                       getMedias={this.getMedias}
                                       uploadMedias={this.uploadMedias}
                                       minimizeUpload={this.minimizeUpload}
                                       cacnel={this.closeAddMedia}/>
                    </div>
                </Modal>

            </div>
        )
    }
}

const mapDispatchToProps = {
    UploadFile,
    GetMedia
};
export default connect(mapStateToProps, mapDispatchToProps)(MediaUploaderWrapper);
