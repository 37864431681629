// Import packages
import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {history} from "../../configs/history";

// Import Styles
import '../../assets/styles/cards/review-card.scss';
import userAvatar from "../../assets/images/user_avatar.png";

// Import components
import {LinkButton} from "../buttons/buttons";
import {Rate} from "antd";
import swal from "sweetalert";
import moment from 'moment';

// Import utils
import {REVIEW_STATUS_TYPES} from "../../constants/constTypes";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {toggleStateField} from "../../utils/helperFunctions";
import {SetReviewStatus, GetReviewReplies, DeleteReview, AddReply, UpdateReply} from "../../redux/actions";
import {generateFileMediaUrl} from "../../utils/generateMemberMediaUrl";
import {InputGroup} from "../uiElements/inputGroup";
import ReplyCard from "./ReplyCard";

class ReviewCard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            reviewData: props.reviewData,
            replies: [],
            openRepliesList: false,
            loading: false,
            rate: 0,
            text: '',

        };
        this.reviewList = React.createRef();
        this.toggleNotificationModal = toggleStateField.bind(this, 'modalOpen');
        this.toggleRepliesBox = this.toggleRepliesBox.bind(this);
        this.getInputValue = this.getInputValue.bind(this);
        this.deleteReview = this.deleteReview.bind(this);
        this.deleteReply = this.deleteReply.bind(this);
        this.addReply = this.addReply.bind(this);
        this.updateReply = this.updateReply.bind(this);
    }

    changeStatus(status) {
        const {reviewData, reply} = this.state;
        const reqData = {
            status
        };
        !reply && this.props.SetReviewStatus(reviewData.id, reqData).then(() => {
            history.push('/reviews');
            swal({
                title: "Հաջողվեց!",
                text: "Գործողությունը հաջողությամբ կատարվեց",
                icon: "success",
                button: "Լավ",
            })
        })
    }

    deleteReview() {
        const {reviewData} = this.state;
        swal({
            title: "Զգուշացում!",
            text: `Ցանկանում եք ջնջել մեկնաբանությունը?`,
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteReview(reviewData?.id).then(() => {
                    history.push('/reviews')
                })
            }
        });
    }

    deleteReply(id) {
        const {reviewData} = this.state;
        //console.log('reviewData, ', reviewData)
        swal({
            title: "Զգուշացում!",
            text: `Ցանկանում եք ջնջել մեկնաբանությունը?`,
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteReview(id).then(() => {
                    this.setState({
                        replies: this.state.replies.filter(reply => reply?.id !== id),
                        reviewData: {
                            ...reviewData,
                            repliesCount: reviewData?.repliesCount - 1
                        }
                    })
                })
            }
        });
    }

    async toggleRepliesBox() {
        const {openRepliesList, replies, reviewData} = this.state;
        const {reply} = this.props;
        this.setState({
            openRepliesList: !openRepliesList
        });
        if (!reply && !replies.length && reviewData?.repliesCount) {
            await this.setState({loading: true});
            this.props.GetReviewReplies(reviewData?.id).then(replies => {
                if (replies && Array.isArray(replies)) {
                    this.setState({replies});
                    console.log(replies)
                }
            }).finally(() => this.setState({loading: false}))
        }
    }

    getRate(name, rate) {
        this.setState({[name]: rate});
    }

    getInputValue({name, value}) {
        this.setState({[name]: value});
    }

    addReply() {
        const {reviewData, rate, text, replies} = this.state;
        const reqData = {
            text,
        };
        rate && (reqData.rate = rate);
        this.props.AddReply(reviewData.id, reqData).then((res) => {
            this.setState({
                text: '',
                rate: 0,
                replies: [res?.data, ...replies],
                reviewData: {
                    ...reviewData,
                    repliesCount: reviewData?.repliesCount + 1
                }
            });
        })
    }

    updateReply(replyId, reqData) {
        const {reviewData, replies} = this.state;

        return this.props.UpdateReply(reviewData.id, replyId, reqData).then((res) => {
            console.log(res.data);
            const reply = res?.data;
            reply && this.setState({
                replies: [reply, ...replies.filter(_reply => _reply?.id !== reply?.id)],
            });
        })
    }

    render() {
        const {reviewData, replies, openRepliesList, loading, rate, text} = this.state;
        return <div className={'review-item-wrapper'}>
            <img className={'delete-review'} alt={''}
                 onClick={this.deleteReview}
                 src={require('../../assets/images/ic_close_crop.svg')}/>
            <div className={'user-image'}>
                <img src={reviewData?.reviewer?.profilePicturePath?.path ?
                    generateFileMediaUrl(reviewData?.reviewer?.profilePicturePath?.path)
                    : userAvatar}
                     alt="user"/>
            </div>
            <div className={'info-part'}>
                <div className={'user-name-and-rate'}>
                    <span className={'user-name'}>
                        {reviewData?.reviewer?.firstName + " " + reviewData?.reviewer?.lastName}
                    </span>
                    <div className={'right-part'}>
                        {reviewData.updatedAt && <span>
                            {moment(reviewData.updatedAt).format("DD.MM.YYYY / HH:mm")}
                        </span>}
                        <Rate value={reviewData?.rate}
                              disabled={true}
                              className={'review-rate'}/>
                    </div>
                </div>
                <div className={'review-content'}>
                    <p>
                        {reviewData?.text}
                    </p>
                </div>

                {reviewData?.status !== REVIEW_STATUS_TYPES.INITIAL.key &&
                <div className={`replies-wrapper ${openRepliesList ? "" : 'hide'}`}>
                    <div className={'reply-header'}>
                        <div className={'left-part'}>
                            <div className={'left-top-part'}>
                                <span>Ավելացնել Մեկնաբանություն</span>
                                <Rate value={rate} className={'review-rate'}
                                      onChange={this.getRate.bind(this, 'rate')}/>
                            </div>
                            <InputGroup inputType="textarea"
                                        placeholder="Մեկնաբանություն"
                                        name={"text"}
                                        maxLength={256}
                                        value={text}
                                        onChange={this.getInputValue}/>
                        </div>
                        <div className={'right-part'}>
                            <span className={'replies-count'} onClick={this.toggleRepliesBox}>
                                {reviewData?.repliesCount || 0} Պատասխան
                                <img src={require('../../assets/images/ic_arrow_down_dark.svg')}
                                     className={openRepliesList ? 'rotate' : ''}/></span>
                            <LinkButton title={'Ավելացնել'}
                                        disabled={!text}
                                        cb={this.addReply}/>
                        </div>
                    </div>
                    <div className={`replies-list`}>
                        {(!!replies.length || loading) && <div className={'inner-box'} ref={this.reviewList}>
                            {loading ? <div className={'loading-list'}>loading...</div> : replies.map(reply => {
                                return <ReplyCard key={reply.id}
                                                  replyData={reply}
                                                  updateReply={this.updateReply}
                                                  deleteReply={this.deleteReply}/>

                            })}
                        </div>}

                    </div>
                </div>}

                {reviewData?.status === REVIEW_STATUS_TYPES.INITIAL.key && <div className={'action-buttons'}>
                    <LinkButton title="Մերժել"
                                className={'bg-red'}
                                cb={this.changeStatus.bind(this, REVIEW_STATUS_TYPES.REJECTED.key)}/>
                    <LinkButton title="Հաստատել"
                                className={'bg-green'}
                                cb={this.changeStatus.bind(this, REVIEW_STATUS_TYPES.APPROVED.key)}/>
                </div>}
            </div>
        </div>

    }
}

const mapDispatchToProps = {
    SetReviewStatus,
    GetReviewReplies,
    DeleteReview,
    AddReply,
    UpdateReply
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewCard)
