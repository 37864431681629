import {request, _urlCompany, _urlPayment} from "../api";
import {COMPANY_CONSTS, UTIL_CONSTS} from "../constants";
import {notificationSuccess} from "../../components/uiElements/Notifications";
import {history} from "../../configs/history";

export const GetCompanies = ({key} = {}) => {
    const requestData = {
        url: `${_urlCompany}/all`,
        method: "GET",
        token: true,
        scope: true,
    };

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                    dispatch({
                        type: COMPANY_CONSTS.GET_COMPANIES,
                        payload: {
                            data: data,
                            key,
                        }
                    })
            })
            .finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const ClearCompanies = () => {
    return dispatch => {
        dispatch({
            type: COMPANY_CONSTS.CLEAR_COMPANIES,
        });
    }
};

export const UpdateCompany = (id, data) => {
    const requestData = {
        url: `${_urlCompany}/${id}`,
        method: "PATCH",
        token: true,
        scope: true,
        data,
    };

    return (dispatch) => {
        return request(requestData)
            .then(({data}) => {
                notificationSuccess({
                    description: "Կազմակերպությունը հաջողությամբ փոփոխվեց!"
                })
                history.push({
                    pathname: '/companies',
                })
            })
    }
};

export const ActivateCompany = (id) => {
    const requestData = {
        url: `${_urlCompany}/activate/${id}`,
        method: "PUT",
        token: true,
        scope: true,
    };

    return (dispatch) => {
        return request(requestData)
            .then(({data}) => {
                notificationSuccess({
                    description: "Կազմակերպությունը հաջողությամբ ակտիվացվեց!"
                })
                history.push({
                    pathname: '/companies',
                })
            })
    }
};

export const DeleteCompanyById = (id) => {
    const requestData = {
        url: `${_urlCompany}/${id}`,
        method: "DELETE",
        token: true,
        scope: true,
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: COMPANY_CONSTS.DELETE_COMPANY_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const GetNotSeenCompaniesCount = () => {
    const requestData = {
        url: `${_urlCompany}/not-seen-count`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                data && dispatch({
                    type: COMPANY_CONSTS.GET_NOT_SEEN_COMPANIES_COUNT,
                    payload: data
                });
            })
    }
};

export const SetCompanyAsSeen = (id) => {
    const requestData = {
        url: `${_urlCompany}/${id}/seen`,
        token: true,
        method: "PUT",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: COMPANY_CONSTS.SET_COMPANY_AS_SEEN,
                    payload: id
                });
            })
    }
};

export const GetPayments = () => {
    const requestData = {
        url: `${_urlPayment}`,
        method: "GET",
        token: true,
        scope: true,
    };

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: COMPANY_CONSTS.GET_PAYMENTS,
                    payload: data
                })
            })
            .finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const AttachBusinessCard = data => {
    const requestData = {
        url: `${_urlPayment}`,
        token: true,
        method: "PUT",
        scope: true,
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: COMPANY_CONSTS.ATTACH_BUSINESS_CARD,
                    payload: data
                })
                notificationSuccess({
                    description: "Քարտը հաջողությամբ կցվեց!"
                })
            })
    }
};

export const DeleteBusinessCard = data => {
    const requestData = {
        url: `${_urlPayment}`,
        method: "DELETE",
        token: true,
        scope: true,
        data
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: COMPANY_CONSTS.DELETE_BUSINESS_CARD,
                    payload: data
                })
                notificationSuccess({
                    description: "Քարտը հաջողությամբ ջնջվեց!"
                })
            })
    }
};

export const GetCompanyCard = (id) => {
    const requestData = {
        url: `${_urlPayment}/company/${id}`,
        method: "GET",
        token: true,
        scope: true,
    };

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: COMPANY_CONSTS.GET_COMPANY_CARD,
                    payload: data
                })
            })
            .finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};
