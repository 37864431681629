import {request, _urlProducts} from "../api";
import {PRODUCT_CONSTS, UTIL_CONSTS} from "../constants";
import swal from "sweetalert";
import {LIMIT_GET_PRODUCTS} from "../../constants/constLimitCounts";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";
// import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetProducts = ({
                                reset = true,
                                offset = 0,
                                text = '',
                                filterValueId,
                                categoryId,
                                minPrice,
                                maxPrice,
                                limit = LIMIT_GET_PRODUCTS,
                                getResponse = false,
                            } = {}) => {
    let url = `${_urlProducts}?offset=${offset}&limit=${limit}`;
    text && (url += `&text=${encodeURIComponent(text)}`);
    categoryId && (url += `&categoryIds=${categoryId}`);
    filterValueId && (url += `&filterValueIds=${filterValueId}`);
    minPrice && (url += `&minPrice=${minPrice}`);
    maxPrice && (url += `&maxPrice=${maxPrice}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                const filtered = !!(text || filterValueId || categoryId || minPrice || maxPrice);
                if (!getResponse) {
                data && dispatch({
                    type: PRODUCT_CONSTS.GET_PRODUCTS,
                    payload: {
                        data: data,
                        text,
                        filterValueId,
                        categoryId,
                        minPrice,
                        maxPrice,
                        reset,
                        hasMore: data.length === limit,
                        filtered,
                    }
                })} else {
                    // console.log(data, 'DATA');
                    return {
                        data,
                    }
                }
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const GetProductById = (id) => {
    const requestData = {
        url: `${_urlProducts}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: PRODUCT_CONSTS.GET_PRODUCT_BY_ID,
                    payload: res.data
                })
            })
            .catch(() => {
            })
    }
};

export const AddProduct = (data) => {
    const requestData = {
        url: _urlProducts,
        token: true,
        method: "POST",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                notificationSuccess({
                    description: "Ապրանքը հաջողությամբ ստեղծվեց!"
                })
                history.push('/products')
            })
    }
};

export const UpdateProduct = (id, data) => {
    const requestData = {
        url: `${_urlProducts}/${id}`,
        method: "PATCH",
        token: true,
        data,
    };

    return (dispatch) => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: PRODUCT_CONSTS.UPDATE_PRODUCT,
                    payload: data
                });
                notificationSuccess({
                    description: "Ապրանքը հաջողությամբ փոփոխվեց!"
                })
                history.push({
                    pathname: '/products',
                })
            })
    }
};

export const ToggleProductVisibility = (id, data) => {
    const requestData = {
        url: `${_urlProducts}/${id}`,
        method: "PATCH",
        token: true,
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: PRODUCT_CONSTS.TOGGLE_PRODUCT_VISIBILITY,
                    payload: {
                        id,
                        isHidden: data.isHidden
                    }
                });
                // notificationSuccess({
                //     description: "Ապրանքը հաջողությամբ փոփոխվեց!"
                // })
            })
    }
};

export const DeleteProduct = (id) => {
    const requestData = {
        url: `${_urlProducts}/${id}`,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: PRODUCT_CONSTS.DELETE_PRODUCT,
                    payload: id
                });
                // notificationSuccess({
                //     description: "Ապրանքը հաջողությամբ ջնջվեց!"
                // })

            })
    }
};
