import React, {useState} from "react";

import {HeaderLogo} from "./headerLogo";
import {MenuDropdown} from "./MenuDropdown";

import "../../assets/styles/headerStyles/mainHeader.scss";
// import {ReactComponent as MoreIcon} from "../../assets/images/more.svg";
import SideMenu from "../uiElements/sideMenu/SideMenu";

export function MainHeader(props) {
    const {toggleMenu, LogOut, isAdmin, leftSideMenuOpen, user} = props;

    const [showMenu, setShowMenu] = useState(false)

    function toggleSideMenu(state){
        setShowMenu(state)
    }

    return <header className="main-header">
        <HeaderLogo toggleMenu={toggleMenu}
                    leftSideMenuOpen={leftSideMenuOpen}/>
        <div className="right-part">
            <MenuDropdown LogOut={LogOut}
                          isAdmin={isAdmin}
                          user={user}/>
            {/*<button className={'more-btn'} onClick={() => {*/}
            {/*    toggleSideMenu(true)*/}
            {/*}}>*/}
            {/*    /!*<MoreIcon title={''}/>*!/*/}
            {/*    ... Show menu*/}
            {/*</button>*/}

        </div>
        {/*<SideMenu showMenu={showMenu} toggleMenu={toggleSideMenu}/>*/}
    </header>
}