import {AUTH_CONSTS, COMPANY_CONSTS} from "../constants";

export const initialState = {
    companiesList: {
        itemsList: [],
        hasMore: false,
    },
    notSeenCompaniesCount: 0,
    businessCards: [],
    companyCard: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case COMPANY_CONSTS.GET_COMPANIES:
            const stateField = action.payload.key;
            return {
                ...state,
                // [stateField]: {
                //     itemsList: [...action.payload.data],
                // },
                [stateField]: {
                    itemsList: action.payload.data.map((company, index) => index === 0 ? {
                        ...company,
                        card: index === 0 ? {
                            arcaCardId: "b22f09ed-9c7a-4f47-9bd9-e03084d86180",
                            balance: 839.79,
                            createdAt: "2021-10-11T14:05:18.559Z",
                            holderName: "Ethyl Swaniawski",
                            id: "6164449e33e66811147c3210",
                            maskedCardNumber: "4578********1627",
                            updatedAt: "2021-10-11T14:05:18.559Z",
                        } : null,
                    } : company),
                },
            };
        case COMPANY_CONSTS.CLEAR_COMPANIES:
            return {
                ...state,
                companiesList: {...initialState.companiesList},
                companyCard: null,
            };
        case COMPANY_CONSTS.DELETE_COMPANY_BY_ID:
            return {
                ...state,
                companiesList: {
                    ...state.companiesList,
                    itemsList: state.companiesList.itemsList.filter(item => item.id !== action.payload)
                }
            };
        case COMPANY_CONSTS.GET_NOT_SEEN_COMPANIES_COUNT:
            return {
                ...state,
                notSeenCompaniesCount: action.payload?.count ?? 0
            };
        case COMPANY_CONSTS.SET_COMPANY_AS_SEEN:
            return {
                ...state,
                companiesList: {
                    itemsList: state.companiesList.itemsList.map(company => company.id === action.payload ? {
                        ...company,
                        seen: true
                    } : company),
                },
                notSeenCompaniesCount: state.notSeenCompaniesCount - 1
            };
        case COMPANY_CONSTS.GET_PAYMENTS:
            return {
                ...state,
                businessCards: action.payload
            };
        case COMPANY_CONSTS.GET_COMPANY_CARD:
            return {
                ...state,
                companyCard: action.payload
            };
        case COMPANY_CONSTS.ATTACH_BUSINESS_CARD:
            return {
                ...state,
                companyCard: state.businessCards.find(item => item.id === action.payload.cardId)
            };
        case COMPANY_CONSTS.DELETE_BUSINESS_CARD:
            return {
                ...state,
                companyCard: null
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
