// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Checkbox, Switch} from "antd";
import {MaterialIcon} from "../../components/utils/Icon";
import {history} from "../../configs/history";

// Import Styles
import '../../assets/styles/containerStyles/filter-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import swal from "sweetalert";

// Import utils
import {
    changeStateField,
    getInputValues,
    getTranslatableInputValues, getTranslationData,
    initTranslations
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetFilterById,
    UpdateFilterById,
    CreateFilterValue,
    DeleteFilterValueById,
    UpdateFilterValueById,
} from "../../redux/actions";

import {validateTranslations} from "../../utils/validateTranslations";
import {notificationSuccess} from "../../components/uiElements/Notifications";


class EditFilter extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
        ];
        this.state = {
            fieldsData: {
                isHidden: false,
                categories: undefined,
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                values: [],
            },
            isEditing: !!props?.id,
            errors: {},
            errorsTabs: [],
            languageTab: props.mainLanguage,
        };
        // init Categories Options
        this.categoriesOptions = Array.isArray(props.categoriesList) ? props.categoriesList.map(item => ({
            name: item?.title,
            id: item?.id,
        })) : [];
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.removedIds = [];
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.addFilterValue = this.addFilterValue.bind(this);
        this.removeFilterValue = this.removeFilterValue.bind(this);
        this.getFilterValues = this.getFilterValues.bind(this);
        this.editFilter = this.editFilter.bind(this);
    }

    async componentDidMount() {
        if (this.props?.id) {
            await this.props.GetFilterById(this.props.id);
            const editingData = this.props.filterById;
            const translationsData = {...this.state.fieldsData.translations};
            let initValues = [];

            this.props.activeLanguages.forEach(lg => {
                const TrItem = editingData?.translations.find(tr => tr.languageId === lg.id);
                translationsData[lg.id] = {
                    title: TrItem?.title || '',
                };

            });

            editingData.values.forEach(value => {
                let valueTR = {};
                this.props.activeLanguages.forEach(lg => {
                    const valueTr = value?.translations.find(tr => tr.languageId === lg.id);
                    valueTR[lg.id] = {
                        title: valueTr?.title || '',
                    };
                });
                initValues.push({
                    isHidden: value.isHidden,
                    position: value?.position,
                    id: value.id,
                    translations: valueTR,
                })
            });

            let categories = [];
            editingData.categories && editingData.categories.forEach(item => {
                categories.push(item.id)
            });
            this.currentData = {
                isHidden: editingData.isHidden,
                categories: categories,
                translations: translationsData,
                values: initValues,
            };
            this.setState({
                fieldsData: JSON.parse(JSON.stringify(this.currentData)),
            });

        } else {
            history.push('/filters');
        }
    }

    getFilterValues({name, value, index}) {
        const {languageTab} = this.state;
        const {values, isHidden} = this.state.fieldsData;
        let filterIsHidden = isHidden;

        const editingValue = values[index];
        let newFilterList = [...values];

        if (name === 'isHidden') {
            if (editingValue.id) {
                this.currentData?.values?.find(v => v.id === editingValue.id)?.isHidden !== !values?.[index]?.isHidden
                    ? this.updatedDataMap.set(`${editingValue.id}_value`, editingValue.id)
                    : this.updatedDataMap.delete(`${editingValue.id}_value`);
            }
            newFilterList[index].isHidden = !newFilterList[index].isHidden;
            const allFiltersDisabled = newFilterList.every(f => f.isHidden);
            filterIsHidden = allFiltersDisabled ? true : isHidden;
            if (allFiltersDisabled) {
                this.updatedDataMap.set(`isHidden`, true)
            }
        } else {
            if (editingValue.id) {
                console.log(this.currentData?.values)
                this.currentData?.values?.find(v =>
                    v.id === editingValue.id
                )?.translations?.[languageTab]?.title !== value
                    ? this.updatedDataMap.set(`${editingValue.id}_value_${languageTab}`, editingValue.id)
                    : this.updatedDataMap.delete(`${editingValue.id}_value_${languageTab}`);
            }
            newFilterList[index].translations[languageTab].title = value;
        }
// console.log(this.updatedDataMap)
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                values: newFilterList,
                isHidden: filterIsHidden
            }
        })
    }

    addFilterValue() {
        const {values} = this.state.fieldsData;
        let position = 0;
        values.forEach(v => {
            if (v.position > position) {
                position = v.position
            }
        })
        let filterItem = {
            translations: initTranslations(this.translationsFields, this.props.activeLanguages),
            isHidden: false,
            position: position + 1,
            fakeId: Date.now()
        };
        this.updatedDataMap.set(`newItem_${filterItem.fakeId}`, filterItem.fakeId)
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                values: [...this.state.fieldsData.values, filterItem]
            },
        })
    }

    removeFilterValue(position) {
        const {values} = this.state.fieldsData;
        let newFilterList = values.filter((item, index) => {
            if (index === position) {
                if (item?.id) {
                    this.removedIds.push(item.id);
                    this.updatedDataMap.set('deleted-item', null)
                } else {
                    this.updatedDataMap.delete(`newItem_${item.fakeId}`)
                }
            }
            return index !== position
        });
        this.setState({
            ...this.state,
            fieldsData: {
                ...this.state.fieldsData,
                values: newFilterList,
            },
        })
    }

    async editFilter() {
        const {fieldsData} = this.state;
        const {translations, isHidden, values, categories} = fieldsData;
        await this.setState({
            requestLoading: true
        });
        const translationRequiredFields = ['title']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = validationTr.errors;
        let TR_data = getTranslationData(translations);

        const reqData = {};
        const newValuesList = [];
        const oldValues = {};

        values.forEach((item, index) => {
            let valueTranslations = [];
            Object.keys(item.translations).forEach((key) => {
                let value_TR_item = {
                    languageId: key,
                };
                if (item?.translations?.[key]?.title) {
                    value_TR_item.title = item.translations[key].title;
                    valueTranslations.push(value_TR_item);
                } else {
                    errors['value' + index + key] = true;
                    result = false;
                    validationTr.errorsTabs.push(key)
                }
            });
            const valueItem = {
                isHidden: item.isHidden,
                position: item?.position,
                translations: valueTranslations
            }
            valueTranslations?.length && (item.id ? oldValues[item.id] = valueItem : newValuesList.push(valueItem));

        });
        if (result) {
            const RequestPromisesList = []
            const updatedValues = [];
            this.updatedDataMap.has(`categories`) && (reqData.categories = categories);
            this.updatedDataMap.has(`isHidden`) && (reqData.isHidden = isHidden);
            this.updatedDataMap.forEach((value, key) => {
                key.includes('value') && updatedValues.push(value);
                key.includes('title') && (reqData.translations = TR_data);
            });
            updatedValues.forEach(id => {
                const data = oldValues[id];
                console.log(id)
                console.log(oldValues[id])
                console.log(data)
                RequestPromisesList.push(this.props.UpdateFilterValueById(this.props.id, id, data));
            });
            this.removedIds.forEach(id => {
                RequestPromisesList.push(this.props.DeleteFilterValueById(this.props.id, id))
            });
            newValuesList.forEach(reqData => {
                RequestPromisesList.push(this.props.CreateFilterValue(this.props.id, reqData));
            });
            Object.keys(reqData).length && RequestPromisesList.push(this.props.UpdateFilterById(this.props.id, reqData))
            await Promise.all(RequestPromisesList).then(() => {
                notificationSuccess({
                    description: "Ֆիլտրը հաջողությամբ փոփոխվեց!"
                })
            }).finally(() => {
                this.setState({requestLoading: false});
                history.push('/filters');
            })
        } else {
            this.setState({
                errors,
                requestLoading: false,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {fieldsData, languageTab, requestLoading, errors, errorsTabs} = this.state;
        const currentData = fieldsData.translations[languageTab] || {};
        const initData = this.currentData?.translations[languageTab] || {};
        const initValues = this.currentData?.values || {};
        return <PageWrapper pageTitle={'Փոփոխել ֆիլտրը'}>
            <section className="filter-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            disabled={fieldsData?.values.every(f => f.isHidden)}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              activeTab={languageTab}
                              errorsTabs={errorsTabs}
                />
                <div className="filter-wrapper">
                    <div className="filter-left-part">
                        <div className="w-100">
                            <InputGroup inputType="input"
                                        type="text"
                                        label="Անվանում"
                                        placeholder="Անվանում"
                                        name="title"
                                        value={currentData?.title}
                                        initValue={initData?.title}
                                        error={errors['title' + languageTab]}
                                        maxLength={100}
                                        required={true}
                                        onChange={this.getTranslationValues}/>
                        </div>
                        <div className="w-100 select-category">
                            <InputGroup
                                value={fieldsData.categories}
                                label={'ֆիլտրի կատեգորիաներ'}
                                options={this.categoriesOptions}
                                initValue={this.currentData?.categories}
                                inputType="selectCustom"
                                mode="multiple"
                                name="categories"
                                placeholder={'Ընտեք կարեգորիա'}
                                onChange={this.getInputValues}
                            />
                        </div>
                    </div>
                    <div className="filters">
                        <label className={'label'}>Ֆիլտրեր <span className={'required-badge'}>*</span></label>
                        {fieldsData?.values?.map((filter, index) => {
                            return <div className="filter-row" key={index}>
                                <InputGroup
                                    value={filter.translations?.[languageTab]?.title}
                                    initValue={initValues?.[index]?.translations[languageTab]?.title}
                                    error={errors['value' + index + languageTab]}
                                    name={index}
                                    inputType={"input"}
                                    placeholder={"Ֆիլտր"}
                                    maxLength={100}
                                    onChange={(data) => this.getFilterValues({...data, index})}
                                />

                                <div className="checkbox-wrapper">
                                    <Checkbox checked={!filter.isHidden}
                                              onChange={() => this.getFilterValues({
                                                  name: "isHidden",
                                                  index,
                                              })}/>
                                </div>
                                {fieldsData?.values?.length > 1
                                && <LinkButton className="bg-red delete-filter-btn"
                                               title={<MaterialIcon icon="delete"/>}
                                               cb={() => this.removeFilterValue(index)}/>}

                            </div>
                        })}
                        <LinkButton title={<MaterialIcon icon="add"/>}
                                    cb={this.addFilterValue}/>
                    </div>


                </div>

                <div className="flex-wrapper-right">
                    <LinkButton
                        title={"Փոփոխել"}
                        loading={requestLoading}
                        disabled={!this.updatedDataMap.size}
                        cb={this.editFilter}
                    />
                </div>

            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetFilterById,
    UpdateFilterById,
    CreateFilterValue,
    DeleteFilterValueById,
    UpdateFilterValueById,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFilter)
