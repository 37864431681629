// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/transactions.scss'
// Import components
import {InputGroup} from "../../components/uiElements/inputGroup";
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import ItemsList from "../../components/ItemsList";
import PageHeader from "../../components/pageContentViews/pageHeader";
import {DatePicker} from "antd";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetBankTransactions, ClearTransactions} from "../../redux/actions";
import {
    TRANSACTION_STATUSES_TYPES,
    BANK_TRANSACTIONS_TYPES
} from "../../constants/constTypes";
import {disabledEndDate, disabledStartDate} from "../../utils/helperFunctions";
import moment from "moment";

const dateFormat = 'DD.MM.YYYY';

const statusOptions = Object.keys(TRANSACTION_STATUSES_TYPES).map(key => {
    return {
        id: TRANSACTION_STATUSES_TYPES[key].key,
        name: TRANSACTION_STATUSES_TYPES[key].name
    }
})
const typeOptions = Object.keys(BANK_TRANSACTIONS_TYPES).map(key => {
    return {
        id: BANK_TRANSACTIONS_TYPES[key].key,
        name: BANK_TRANSACTIONS_TYPES[key].name
    }
})

class BankTransactions extends Component {
    constructor() {
        super();
        this.state = {
            text: '',
            transactionNumber: '',
            cardNumber: ''
        };
        this.listRef = React.createRef();

        this.getInputValues = this.getInputValues.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.getSearchValue = this.getSearchValue.bind(this);
    }

    componentDidMount() {
        this.props.GetBankTransactions()
    }

    componentWillUnmount() {
        this.props.ClearTransactions()
    }

    handleChangeData(name, value) {
        this.searchItems({[name]: value})
    }

    getSearchValue(text) {
        this.setState({
            text
        }, () => !text && this.searchItems({text}))
    }

    searchItems(updatedData = {}) {
        const {
            text,
            startDate,
            endDate,
            type,
            status,
            transactionNumber,
            company
        } = this.props.bankTransactionList;
        let filterData = {
            startDate, endDate, text, type, status, transactionNumber, company,
            ...updatedData,
        };
        if (filterData?.text && filterData.text?.length === 1) {
            return;
        }
        this.props.GetBankTransactions(filterData);
    }

    async getInputValues({name, value}) {
        await this.setState({
            [name]: value,
        });
        // console.log(name, 'name');
        if (name === 'transactionNumber' && value) {
            if (value.length > 13) {
                this.handleChangeData(name, value)
            }
        } else if (name === 'cardNumber' && value) {
            if (value.length > 7) {
                this.handleChangeData(name, value)
            }
        } else {
            this.handleChangeData(name, value)
        }

    }

    async loadMoreItems() {
        const {bankTransactionList} = this.props;

        bankTransactionList.hasMore && this.searchItems({
            reset: false,
            offset: bankTransactionList.itemsList.length,
        });
    };

    render() {
        const {text, transactionNumber, cardNumber} = this.state;
        const {bankTransactionList, requestLoading, companiesList} = this.props;
        // console.log(bankTransactionList, 'this.props.bankTransactionList');

        const hasMore = bankTransactionList?.hasMore;
        const {startDate, endDate, type, status, company} = this.props.bankTransactionList;
        const companyOptions = companiesList?.itemsList?.map(item => {
            return {
                id: item?.id,
                name: item?.name
            }
        })

        const itemsInfo = bankTransactionList?.itemsList?.map(item => {
            let debt = item?.amount - item?.transferAmount || 0
            return {
                id: item.id,
                status: {
                    title: TRANSACTION_STATUSES_TYPES?.[item?.status]?.name || '',
                    color: TRANSACTION_STATUSES_TYPES?.[item?.status]?.color || '',
                },
                values: [
                    item?.transactionNumber || '-',
                    item?.maskedCardNumber || '-',
                    item?.company?.name || '-',
                    BANK_TRANSACTIONS_TYPES[item?.type]?.name || '-',
                    Number.isInteger(item?.amount) ? item?.amount : item?.amount?.toFixed(2) || '-',
                    Number.isInteger(item?.transferAmount) ? item?.transferAmount : item?.transferAmount?.toFixed(2) || '-',
                    Number.isInteger(debt) ? debt : debt?.toFixed(2),
                    item?.createdAt ? moment(item?.createdAt).format("DD.MM.YY / HH:mm") : '-',
                ],
            }
        });
        const transactionsListHeaderInfo = {
            generalInfo: [
                "Գործարքի համարը", "Քարտի համար", "Կազմակերպություն", "Տեսակ", "Գումար", "Գանձում", "Պարտք", "Ամսաթիվ",
            ],
            status: true,
            largeItems: [],
        };

        return <PageWrapper pageTitle={'Գործարքներ'}>
            <section className="transactions">
            <PageHeader
                getSearchValue={this.getSearchValue}
                searchItems={() => this.searchItems({text})}
                searchValue={text}
                search={true}
                renderingCustomLeftPart={<div className="history-filters-date-part">
                    <InputGroup
                        inputType={'input'}
                        type={'text'}
                        placeholder={'Գործարքի համարը'}
                        name={'transactionNumber'}
                        value={transactionNumber}
                        maxLength={50}
                        onChange={this.getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={'text'}
                        placeholder={'Քարտի համար'}
                        name={'cardNumber'}
                        value={cardNumber}
                        maxLength={12}
                        onChange={this.getInputValues}
                    />
                    <InputGroup inputType={"wrapper"}>
                        <DatePicker
                            // value={startDate}
                            format={dateFormat}
                            showToday={false}
                            allowClear={true}
                            disabledDate={(date) => disabledStartDate(date, endDate)}
                            placeholder="Սկիզբ"
                            className={`date-picker`}
                            onChange={(value) =>
                                this.handleChangeData('startDate', value)}>
                        </DatePicker>
                    </InputGroup>
                    <InputGroup inputType={"wrapper"}>
                        <DatePicker
                            // value={endDate}
                            format={dateFormat}
                            showToday={false}
                            allowClear={true}
                            disabledDate={(date) => disabledEndDate(date, startDate)}
                            placeholder="Ավարտ"
                            className={`date-picker`}
                            onChange={(value) =>
                                this.handleChangeData('endDate', value)}>
                        </DatePicker>
                    </InputGroup>
                </div>}
                filters={<div className="history-filters-wrapper">
                    <div className="history-filters-part">
                        <InputGroup inputType="selectCustom"
                                    placeholder="Տեսակ"
                                    name="type"
                                    showSearch={false}
                                    value={type || undefined}
                                    withClear={true}
                                    onChange={({value}) => this.handleChangeData('type', value)}
                                    options={typeOptions}/>
                        <InputGroup inputType="selectCustom"
                                    placeholder="Կարգավիճակ"
                                    name="status"
                                    showSearch={false}
                                    value={status || undefined}
                                    withClear={true}
                                    onChange={({value}) => this.handleChangeData('status', value)}
                                    options={statusOptions}/>
                        <InputGroup inputType="selectCustom"
                                    placeholder="Կազմակերպություն"
                                    name="company"
                                    showSearch={false}
                                    value={company || undefined}
                                    withClear={true}
                                    onChange={({value}) => this.handleChangeData('company', value)}
                                    options={companyOptions}/>
                    </div>

                </div>}
            />
            <ItemsList itemListInfo={itemsInfo}
                       itemListHeaderInfo={transactionsListHeaderInfo}
                       listRef={this.listRef}
                       loading={requestLoading}
                       infiniteScroll={true}
                       hasMore={hasMore}
                       loadMoreItems={this.loadMoreItems}
            />
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetBankTransactions,
    ClearTransactions
};

export default connect(mapStateToProps, mapDispatchToProps)(BankTransactions)
