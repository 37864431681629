//import Packages
import React, {useEffect, useState} from "react";
import Modal from 'react-modal';
import {connect} from "react-redux";

//import assets
import "./weekdayModal.scss";
import {ReactComponent as ClearIcon} from "../../../assets/images/ic_clear.svg";

//import Components
import {LinkButton} from "../../buttons/buttons";

//import utils
import {WEEKDAYS_TYPES} from "../../../constants/constTypes";
import {TimePicker} from "antd";
import moment from "moment";
import {mapStateToProps} from "../../../redux/mapStateToProps";


function WeekdayModal(props) {
    const {isOpen} = props;
    const [openedTime, setOpenedTime] = useState({
        openingTime: null,
        closingTime: null,
    });
    const [isValid, setIsValid] = useState(false)
    const [weekdays, setWeekdays] = useState([WEEKDAYS_TYPES.Monday.type])

    useEffect(()=>{
        if (openedTime.openingTime && openedTime.closingTime) {
            setIsValid(true)
        }
        !weekdays?.length && setIsValid(false)
    }, [openedTime, weekdays])

    function closeModal() {
        props.closeModal()
        setOpenedTime({
            openingTime: null,
            closingTime: null,
        })
        setIsValid(false)
        setWeekdays([WEEKDAYS_TYPES.Monday.type])
    }

    function getOpenedTime(value, field) {
        setOpenedTime({
            ...openedTime,
            [field]: value
        })
    }

    function toggleWeekdays(item) {
        if (weekdays.includes(item)) {
            setWeekdays(weekdays.filter(day => day !== item))
        } else {
            setWeekdays([...weekdays, item])
        }
    }

    function getWeekdays() {
        if (isValid) {
            let weekdayList = {}
            weekdays.forEach(item => {
                weekdayList[WEEKDAYS_TYPES[item].index] = {
                    openingTime: moment(openedTime.openingTime).format("HH:mm:ss"),
                    closingTime: moment(openedTime.closingTime).format("HH:mm:ss"),
                    day: item
                }
            })
            props.getWeekdays(weekdayList)
            setOpenedTime({
                openingTime: null,
                closingTime: null,
            })
            setWeekdays([WEEKDAYS_TYPES.Monday.type])
            props.closeModal()
            setIsValid(false)
        }
    }

    const format = 'HH:mm';

    return <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className={'modal-wrapper'}
        closeTimeoutMS={150}
        appElement={document.getElementById('root')}
        overlayClassName={'modal-overlay'}>
        <div className="weekday-modal">
            <header className={'modal-header'}>
                <span>Choose a weekday</span>
                <ClearIcon className={"close-icon"} onClick={closeModal}/>
            </header>
            <div className="weekday-modal-title">Select days and hours</div>
            <div className="weekday-items">
                {Object.keys(WEEKDAYS_TYPES).map(item => {
                    return <div className={`week-item ${weekdays.includes(item) ? 'active' : ''}`}
                                key={item}
                                onClick={() => toggleWeekdays(item)}
                    >{WEEKDAYS_TYPES[item]?.name}</div>
                })}
            </div>
            <div className="chose-day-wrapper">
                <TimePicker format={format}
                            value={openedTime.openingTime}
                            defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                            placeholder={'Start time'}
                            popupClassName={'popup-chose-day'}
                            onChange={(time) => getOpenedTime(time, 'openingTime')}
                            allowClear={false}
                            showNow={false}/>
                <TimePicker format={format}
                            defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                            popupClassName={'popup-chose-day'}
                            value={openedTime.closingTime}
                            placeholder={'End time'}
                            onChange={(time) => getOpenedTime(time, 'closingTime')}
                            allowClear={false}
                            showNow={false}/>
            </div>
            <div className="flex-wrapper-left">
                <LinkButton
                    disabled={!isValid}
                    title={'Save'}
                    cb={getWeekdays}
                />
            </div>
        </div>
    </Modal>
}


const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WeekdayModal)
