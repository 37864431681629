// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";

// Import Styles
import '../../assets/styles/containerStyles/orders.scss';

// Import components
import {InputGroup} from "../../components/uiElements/inputGroup";
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetOrders, GetStatuses, DeleteOrderById, ArchiveOrderById} from "../../redux/actions";
import {ordersDataParser, ordersListHeaderInfo, tabs} from "../../utils/listDataHelpers/order";
import {changeStateField} from "../../utils/helperFunctions";
import {CustomTabs} from "../../components/uiElements/Tabs";
import {cancelPaymentHeaderInfo, filledBalanceHeaderInfo} from "../history/utils/constants";
import {parseAtmHistoryData} from "../history/utils/actionFunctions";

class Orders extends Component {
    constructor() {
        super();
        this.state = {
            activeTab: tabs[0].key,
            orderStatus: undefined,
        };
        this.listRef = React.createRef();

        this.changeTab = changeStateField.bind(this, "activeTab");
        this.getInputValues = this.getInputValues.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.deleteOrderItem = this.deleteOrderItem.bind(this);
        this.toggleArchiveOrderItem = this.toggleArchiveOrderItem.bind(this);
        this.searchItems = this.searchItems.bind(this);
    }

    componentDidMount() {
        this.props.GetOrders({
            key: tabs[0].key,
            archived: tabs[0].archived,
            isReservation: tabs[0].isReservation,
        }).then(() => {
            tabs.slice(1).forEach(tab => {
                this.props.GetOrders({
                    key: tab.key,
                    isReservation: tab.isReservation,
                    archived: tab.archived,
                })
            })
        });
    }

    toggleArchiveOrderItem(id, archived) {
        this.props.ArchiveOrderById(id, archived)
    }

    deleteOrderItem(id) {
        this.props.DeleteOrderById(id)
    }

    handleChangeData(name, value) {
        this.searchItems({[name]: value})
    }

    searchItems(updatedData = {}) {
        const {activeTab} = this.state;
        const currentOrders = this.props[activeTab];
        const {archived, status} = currentOrders;
        let filterData = {
            key: activeTab,
            archived: tabs.find(item => item.key === activeTab).archived,
            isReservation: tabs.find(item => item.key === activeTab).isReservation,
            status: this.state.orderStatus === 'ALL' ? null : this.state.orderStatus,
            ...updatedData,
        };
        this.props.GetOrders(filterData);
    }

    async getInputValues({name, value}) {
        await this.setState({
            [name]: value,
        });
        this.handleChangeData(name, value)
    }

    async loadMoreItems() {
        const {activeTab} = this.state;
        const historyOrders = this.props[activeTab];
        //console.log('historyOrders', historyOrders);
        historyOrders.hasMore && this.searchItems({
            reset: false,
            offset: historyOrders.itemsList.length,
        });
    };

    render() {
        const {activeTab, orderStatus} = this.state;
        const {requestLoading, statusList, mainLanguage} = this.props;
        // const itemListInfo = ordersDataParser(ordersList, this.deleteOrderItem, this.toggleArchiveOrderItem);

        const currentOrders = this.props[activeTab];
        const hasMore = currentOrders?.hasMore;
        const {type, status} = currentOrders;

        const statusOptions = statusList.map(status => {
            return {
                name: status.translations.find(tr => tr.languageId === mainLanguage)?.title || '',
                id: status.id,
            }
        });

        let itemsInfo = ordersDataParser(currentOrders, this.deleteOrderItem, this.toggleArchiveOrderItem);

        return <PageWrapper pageTitle={'Պատվերներ'}>
            <section className="orders">
                <div className="fixed-top-right">
                    <InputGroup
                        inputType="selectCustom"
                        value={orderStatus}
                        placeholder="Ընտրել"
                        name="orderStatus"
                        withClear={'Բոլորը'}
                        onChange={this.getInputValues}
                        options={statusOptions}
                    />
                </div>
                <div className="tabs-wrapper" style={{position: "relative"}}>
                    <CustomTabs changeTab={this.changeTab} activeTab={activeTab} tabsList={tabs}/>
                </div>
                <ItemsList itemListInfo={itemsInfo}
                           itemListHeaderInfo={ordersListHeaderInfo}
                           detailsLink={"orders/order"}
                           editLink={"orders/order"}
                           listRef={this.listRef}
                           loading={requestLoading}
                           infiniteScroll={true}
                           hasMore={hasMore}
                           loadMoreItems={this.loadMoreItems}
                />
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetOrders,
    GetStatuses,
    DeleteOrderById,
    ArchiveOrderById
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
