import {AUTH_CONSTS, ORDER_CONSTS} from "../constants";

export const initialState = {
    ordersList: {
        itemsList: [],
        hasMore: false,
    },
    reservationsList: {
        itemsList: [],
        hasMore: false,
    },
    archivedOrders: {
        itemsList: [],
        hasMore: false,
    },
    orderById: null,
    notSeenOrdersCount: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ORDER_CONSTS.GET_ORDERS:
            const stateField = action.payload.key;
            return {
                ...state,
                [stateField]: {
                    itemsList: action.payload.reset ? action.payload.data :
                        [...state[stateField]?.itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore
                },
            };
        case ORDER_CONSTS.CLEAR_ORDERS:
            return {
                ...state,
                orderById: {...initialState.orderById},
            };
        case ORDER_CONSTS.GET_ORDER_BY_ID:
            return {
                ...state,
                orderById: action.payload,
            };
        case ORDER_CONSTS.UPDATE_ORDER_STATUS:
            return {
                ...state,
                ordersList: {
                    itemsList: state.ordersList.itemsList.map(order => order.id === action.payload.id ? {
                        ...order,
                        status: action.payload.status
                    } : order),
                },
                reservationsList: {
                    itemsList: state.reservationsList.itemsList.map(order => order.id === action.payload.id ? {
                        ...order,
                        status: action.payload.status
                    } : order),
                },
            };
        case ORDER_CONSTS.ARCHIVE_ORDER_BY_ID:
            let archivedOrder = null
            let newOrdersList = []
            let newReservationsList = []
            let newArchivedOrders = state.archivedOrders.itemsList

            state.ordersList.itemsList.forEach(order => {
                if (order.id !== action.payload.id) {
                    newOrdersList.push(order)
                } else {
                    archivedOrder = order
                }
            })
            state.reservationsList.itemsList.forEach(order => {
                if (order.id !== action.payload.id) {
                    newReservationsList.push(order)
                } else {
                    archivedOrder = order
                }
            })

            archivedOrder && newArchivedOrders.unshift(archivedOrder)

            return {
                ...state,
                ordersList: {
                    ...state.ordersList,
                    itemsList: newOrdersList
                },
                reservationsList: {
                    ...state.reservationsList,
                    itemsList: newReservationsList
                },
                archivedOrders: {
                    ...state.archivedOrders,
                    itemsList: !action.payload.archived ? newArchivedOrders
                        : state.archivedOrders.itemsList.filter(order => order.id !== action.payload.id)
                }
            };
        case ORDER_CONSTS.DELETE_ORDER_BY_ID:
            return {
                ...state,
                ordersList: {
                    ...state.ordersList,
                    itemsList: state.ordersList.itemsList.filter(order => order.id !== action.payload)
                },
                reservationsList: {
                    ...state.reservationsList,
                    itemsList: state.reservationsList.itemsList.filter(order => order.id !== action.payload)
                },
                archivedOrders: {
                    ...state.archivedOrders,
                    itemsList: state.archivedOrders.itemsList.filter(order => order.id !== action.payload)
                }
            };
        case ORDER_CONSTS.GET_NOT_SEEN_ORDERS_COUNT:
            return {
                ...state,
                notSeenOrdersCount: action.payload?.count ?? 0
            };
        case ORDER_CONSTS.SET_ORDER_AS_SEEN:
            return {
                ...state,
                ordersList: {
                    itemsList: state.ordersList.itemsList.map(order => order.id === action.payload ? {
                        ...order,
                        seen: true
                    } : order),
                },
                reservationsList: {
                    itemsList: state.reservationsList.itemsList.map(order => order.id === action.payload ? {
                        ...order,
                        seen: true
                    } : order),
                },
                notSeenOrdersCount: state.notSeenOrdersCount - 1
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
