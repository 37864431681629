// Import packages
import React from "react";
import {connect} from "react-redux";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";

// Import styles
import "../assets/styles/containerStyles/notFound.scss";

function NotFound(props) {
    const {leftSideMenuOpen} = props;
    return <div className={`not-found admin-inner-component ${leftSideMenuOpen ? "size-sm" : ""}`}>
        <div className="inner-content">
            <span className="number">404</span>
            <p className="lost-text">This page doesn't exist. It looks like you're lost.</p>
        </div>
    </div>
}

export default connect(mapStateToProps)(NotFound);