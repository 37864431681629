// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/filter-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {Checkbox, Switch} from "antd";
import {MaterialIcon} from "../../components/utils/Icon";

// Import utils
import {
    changeStateField,
    getInputValues,
    getTranslatableInputValues, getTranslationData,
    initTranslations
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreateFilter} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";


class AddFilter extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
        ];
        this.state = {
            fieldsData: {
                isHidden: false,
                categories: undefined,
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                values: [{
                    translations: initTranslations(this.translationsFields, props.activeLanguages),
                    isHidden: false,
                }],
            },
            errors: {},
            errorsTabs: [],
            languageTab: props.mainLanguage,
        };
        // init Categories Options
        this.categoriesOptions = Array.isArray(props.categoriesList) ? props.categoriesList.map(item => ({
            name: item?.title,
            id: item?.id,
        })) : [];

        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.addFilterValue = this.addFilterValue.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.getFilterValues = this.getFilterValues.bind(this);
        this.addFilter = this.addFilter.bind(this);
    }

    getFilterValues({name, value, index}) {
        const {languageTab} = this.state;
        const {values, isHidden} = this.state.fieldsData;
        let filterIsHidden = isHidden;
        let newFilterList = [...values];
        if (name === 'isHidden') {
            newFilterList[index].isHidden = !newFilterList[index].isHidden;
            filterIsHidden = newFilterList.every(f => f.isHidden) ? true : isHidden
        } else {
            newFilterList[name].translations[languageTab].title = value;
        }
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                values: newFilterList,
                isHidden: filterIsHidden
            }
        })
    }

    addFilterValue() {
        let filterItem = {
            translations: initTranslations(this.translationsFields, this.props.activeLanguages),
            isHidden: false,
        };
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                values: [...this.state.fieldsData.values, filterItem]
            },
        })
    }

    removeFilter(position) {
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                values: this.state.fieldsData.values.filter((_, index) => position !== index)
            }
        })
    }

    async addFilter() {
        const {fieldsData} = this.state;
        const {translations, isHidden, values, categories} = fieldsData;
        await this.setState({
            requestLoading: true
        });
        const translationRequiredFields = ['title']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;

        const errors = validationTr.errors;
        let TR_data = getTranslationData(translations);
        let valuesList = [];

        values.forEach((item, index) => {
            let valueTranslations = [];
            Object.keys(item.translations).forEach((key) => {
                let value_TR_item = {
                    languageId: key,
                };
                if (item?.translations?.[key]?.title) {
                    value_TR_item.title = item.translations[key].title;
                    valueTranslations.push(value_TR_item);
                } else {
                    errors['value' + index + key] = true;
                    result = false;
                    validationTr.errorsTabs.push(key)
                }
            });
            valueTranslations?.length && valuesList.push({
                isHidden: item.isHidden,
                position: index,
                translations: valueTranslations
            });
        });

        if (result) {
            let position = 0;
            this.props.filtersList.forEach(item => {
                if (item.position > position) {
                    position = item.position
                }
            });
            const reqData = {
                isHidden,
                position: position + 1,
                values: valuesList,
                translations: TR_data
            };
            if (categories) {
                reqData.categories = categories;
            }
            await this.props.CreateFilter(reqData)
                .finally(() => {
                    this.setState({requestLoading: false});
                });
        } else {
            this.setState({
                requestLoading: false,
                errors,
                errorsTabs: validationTr.errorsTabs
            })
        }


    }

    render() {
        const {fieldsData, languageTab, requestLoading, errorsTabs, errors} = this.state;
        const trData = fieldsData.translations[languageTab] || {};
        return <PageWrapper pageTitle={'Ավելացնել ֆիլտր'}>
            <section className="filter-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            disabled={fieldsData?.values.every(f => f.isHidden)}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                            })}/>
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              activeTab={languageTab}
                              errorsTabs={errorsTabs}
                />
                <div className="filter-wrapper">
                    <div className="filter-left-part">
                        <div className="w-100">
                            <InputGroup inputType="input"
                                        type="text"
                                        label="Անվանում"
                                        placeholder="Անվանում"
                                        name="title"
                                        value={trData.title}
                                        error={errors['title' + languageTab]}
                                        maxLength={100}
                                        required={true}
                                        onChange={this.getTranslationValues}/>
                        </div>
                        <div className="w-100 select-category">
                            <InputGroup
                                value={fieldsData.categories}
                                inputType="selectCustom"
                                label={'ֆիլտրի կատեգորիաներ'}
                                mode="multiple"
                                name="categories"
                                placeholder={'Ընտեք կարեգորիա'}
                                onChange={this.getInputValues}
                                options={this.categoriesOptions}
                            />
                        </div>
                    </div>
                    <div className="filters">
                        <label className={'label'}>Ֆիլտրեր <span className={'required-badge'}>*</span></label>
                        {fieldsData?.values?.map((filter, index) => {
                            return <div className="filter-row" key={index}>

                                <InputGroup
                                    value={filter?.translations[languageTab]?.title}
                                    error={errors['value' + index + languageTab]}
                                    name={`${index}`}
                                    inputType={"input"}
                                    placeholder={"Ֆիլտր"}
                                    maxLength={100}
                                    onChange={this.getFilterValues}
                                />
                                <div className="checkbox-wrapper">
                                    <Checkbox checked={!filter.isHidden}
                                              onChange={(checked) => this.getFilterValues({
                                                  name: "isHidden",
                                                  value: !checked,
                                                  index,
                                              })}/>
                                </div>
                                {fieldsData?.values?.length > 1 &&
                                <LinkButton className="bg-red delete-filter-btn"
                                            title={<MaterialIcon icon="delete"/>}
                                            cb={() => this.removeFilter(index)}/>
                                }
                            </div>
                        })}
                        <LinkButton title={<MaterialIcon icon="add"/>}
                                    cb={this.addFilterValue}/>
                    </div>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton
                        title={"Ավելացնել"}
                        loading={requestLoading}
                        cb={this.addFilter}
                    />
                </div>

            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFilter)
