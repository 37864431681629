// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetFilters, UpdateFiltersPositions, DeleteFilterById} from "../../redux/actions";
import {deleteItem} from "../../utils/helperFunctions";

class Filters extends Component {
    constructor(props) {
        super(props);
        this.deleteFilter = deleteItem.bind(this, props.DeleteFilterById, 'ֆիլտրը');
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    componentDidMount() {
       this.props.GetFilters();
    }

    getNestableItems(list) {
        const items = [];
        list && !!list.length && list.forEach((item, index) => {

            items.push({
                id: item.id,
                index: index,
                title: item.title,
                isHidden: item.isHidden,
                withImage: false,
                largeItem: false,
                link: `filters/edit/${item.id}`,
                deleteCb: () => this.deleteFilter(item.id),
            })
        });
        return items;
    }

    async onPositionChange(items, item) {
        const movedFilterId = item.id;
        const {filtersList} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~filtersList.findIndex(m => m.id === movedFilterId) &&
            ~positionedItems.findIndex(m => m.id === movedFilterId)) {
            // first level menu was re-positioned in first level
            await this.props.UpdateFiltersPositions(positionedItems);
            this.props.GetFilters();
        }
    }

    render() {
        let {filtersList} = this.props;
        let items = this.getNestableItems(filtersList);

        return <PageWrapper pageTitle={'Ֆիլտրեր'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/filters/add"}/>
            {items && items.length !== 0 &&
            <Nestable
                items={items}
                maxDepth={1}
                onChange={this.onPositionChange}
                renderItem={NestableItem}
            />}
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetFilters,
    UpdateFiltersPositions,
    DeleteFilterById
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
