// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { deleteItem } from "../../utils/helperFunctions";
import {
    GetDirectionList,
    DeleteDirectionById
} from "../../redux/actions";


class Directions extends Component {
    constructor (props) {
        super(props);
        this.listRef = React.createRef();
    }

    componentDidMount () {
        this.props.GetDirectionList();
    }

    render () {
        let { directionList, requestLoading, mainLanguage } = this.props;
        let headerInfo = {
            generalInfo: [
                "Անվանում",
                "Բոնուս",
                "Կոմիսիոն վճար",
                "Ստեղծվել է"
            ],
            largeItems: [],
            status: false,
            actions: ["edit", 'delete'],
        };

        const itemsInfo = directionList?.map(item => {
            const { id, translations, commission, bonus, createdAt } = item || {};

            return {
                id,
                values: [
                    translations.find(tr => tr.languageId === mainLanguage)?.name || '',
                    bonus ?? 0,
                    commission ?? 0,
                    <div className={'activity'}>
                        {moment(createdAt).format("DD.MM.YY")}
                    </div>,
                ],
                deleteCb: deleteItem.bind(this, this.props.DeleteDirectionById, 'ուղղությունը', id),
            }
        });
        return <PageWrapper pageTitle={'Ուղղություններ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/directions/add"}/>
            <ItemsList
                className={'directions-list'}
                itemListHeaderInfo={headerInfo}
                itemListInfo={itemsInfo}
                editLink={"directions/edit"}
                loading={requestLoading}
                listRef={this.listRef}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetDirectionList,
    DeleteDirectionById
};

export default connect(mapStateToProps, mapDispatchToProps)(Directions);
