import {request, _urlUsers} from "../api";
import {USERS_CONSTS, UTIL_CONSTS} from "../constants";
import {LIMIT_GET_USERS} from "../../constants/constLimitCounts";
import {history} from "../../configs/history";

export const GetUsers = ({
                             reset = true,
                             offset = 0,
                             text = '',
                             createdAtStartDate = null,
                             createdAtEndDate = null,
                             makeOrdersStartDate = null,
                             makeOrdersEndDate = null,
                             isVerified,
                             isBlocked,
                             agents,
                             limit = LIMIT_GET_USERS,
                             getResponse = false
                         } = {}) => {

    let url = `${_urlUsers}?offset=${offset}&limit=${limit}`;
    text && (url += `&search=${encodeURIComponent(text)}`);
    isVerified !== undefined && (url += `&isVerified=${Boolean(isVerified)}`);
    isBlocked !== undefined && (url += `&isBlocked=${Boolean(isBlocked)}`);
    createdAtStartDate && (url += `&createdAtStartDate=${createdAtStartDate}`);
    createdAtEndDate && (url += `&createdAtEndDate=${createdAtEndDate}`);
    makeOrdersStartDate && (url += `&lastOrderStartDate=${makeOrdersStartDate}`);
    makeOrdersEndDate && (url += `&lastOrderEndDate=${makeOrdersEndDate}`);
    agents && (url += `&agents=${agents}`);
    const requestData = {
        url: url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && !getResponse && dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then((res) => {
                const data = res.data.data;
                // console.log('%c users', 'color:orange', data?.data?.length === limit);
                data && !getResponse && dispatch({
                    type: USERS_CONSTS.GET_USERS,
                    payload: {
                        data: data?.data,
                        reset,
                        isVerified,
                        isBlocked,
                        agents,
                        count: data?.count,
                        hasMore: data?.data?.length === limit
                    }
                });
                if (getResponse) {
                    return data
                }
            }).catch(() => {
                dispatch({
                    type: USERS_CONSTS.GET_USERS,
                    payload: {
                        data: [],
                        reset,
                        isVerified,
                        isBlocked,
                        agents,
                        // count: data?.count,
                        // hasMore: data?.data?.length === limit
                    }
                })
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const GetUserById = (id) => {
    const requestData = {
        url: `${_urlUsers}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        dispatch({type:UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(async ({data}) => {
                await dispatch({
                    type: USERS_CONSTS.GET_USER_BY_ID,
                    payload: data
                });
                dispatch({type:UTIL_CONSTS.END_LOADING})
            })
            .catch(()=>{
                history.push('/dashboard')
            })
    }
};

export const ClearUserById = () => {
    return dispatch => {
        dispatch({
            type: USERS_CONSTS.CLEAR_USER_BY_ID,
        });
    }
};
