import axios from 'axios';
import { store } from "../store";
import { _urlMedia, request, } from "../api";
import { MEDIA_CONSTS, UTIL_CONSTS } from "../constants";
import { notificationSuccess } from "../../components/uiElements/Notifications";
import { getFileUrlPrefix } from "../../configs/getFileUrlPrefix";
import { FILE_TYPES } from "../../constants/fileTypes";

export const GetMedia = ({
                             reset = true,
                             offset = 0,
                             limit = 30,
                             parent = null,
                             type = null,
                             searchValue=''
                         } = {}) => {
    return dispatch => {
        let url = `${_urlMedia}?offset=${offset}&limit=${limit}`;
        searchValue && (url += `&name=${searchValue}`);
        parent && (url += `&parent=${parent}`);
        // console.log(type, 'TYPE');
        const requestData = {
            url,
            token: true,
            method: "GET",
        };
        return request(requestData)
            .then(async res => {
                res && res.data && await dispatch({
                    type: MEDIA_CONSTS.GET_MEDIA,
                    payload: {
                        data: res.data,
                        reset,
                        offset,
                        limit,
                        parent,
                        searchValue,
                        hasMore: res.data.length === limit
                    },
                })
            })
    }
};

export const UploadFile = (file, onUploadProgress = null, uploadId) => {
    return dispatch => {
        const requestData = {
            url: _urlMedia,
            token: true,
            method: "POST",
            data: file,
            onUploadProgress:(progressEvent)=>onUploadProgress(progressEvent, uploadId),
            customHeaders: {
                "Accept": "application/json",
                "Content-Type": "multipart/form-data",
            }
        };
        return request(requestData).then(res => {
            return res.data;
        })
    }
};

export const GetImageFileByUrl = (url, name) => {
    return axios.get(`${getFileUrlPrefix}/${url}`, {
        responseType: 'blob'
    }).then(res => {
        const blob=res?.data;
        return new File([res.data], `${name}.${FILE_TYPES[blob.type]}`, {type: blob.type});
    })
};



export const UpdateFile = (id, data, type) => {
    return dispatch => {
        const requestData = {
            url: `${_urlMedia}/${type === 'FOLDER' ? 'folder/' : ''}${id}`,
            token: true,
            method: "PATCH",
            data,
        };
        return request(requestData)
            .then(res => {
                dispatch({
                    type: MEDIA_CONSTS.UPDATE_MEDIA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ֆայլը հաջողությամբ փոփոխվեց!"
                })
            })
    }
};

export const DeleteFile = (id) => {
    return dispatch => {
        const requestData = {
            url: `${_urlMedia}/${id}`,
            token: true,
            method: "DELETE",
        };
        return request(requestData)
            .then(() => {
                dispatch({
                    type: MEDIA_CONSTS.DELETE_MEDIA_BY_ID,
                    payload: id
                })
            })
    }
};

export const AddFolder = (data) => {
    return dispatch => {
        const requestData = {
            url: `${_urlMedia}/folder`,
            token: true,
            method: "POST",
            data,
        };
        return request(requestData).then(res => {
            return res.data;
        })
    }
};

export const DeleteFolder = (id) => {
    return dispatch => {
        const requestData = {
            url: `${_urlMedia}/folder/${id}`,
            token: true,
            method: "DELETE",
        };
        return request(requestData).then(res => {
            dispatch({
                type: MEDIA_CONSTS.DELETE_MEDIA_BY_ID,
                payload: id
            });
        })
    }
};


export const UpdateAudioMetaData = (id, data, ) => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });

        const requestData = {
            url: ``,
            token: true,
            method: "PATCH",
            data,
        };
        return request(requestData)
            .then(_ => {
                notificationSuccess({
                    description: "Ֆայլը հաջողությամբ փոփոխվեց!"
                })
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                });
            })
    }
};

export const updateMediaPath = (id) => {
    return dispatch=>{
        dispatch({
            type: MEDIA_CONSTS.UPDATE_MEDIA_PATH,
            payload: id
        });
    }
};

export const toggleMediaView = (state) => {
    store.dispatch({
        type: MEDIA_CONSTS.TOGGLE_MEDIA_VIEW,
        payload: state
    })
};

export const setFolderList = (state = []) => {
    store.dispatch({
        type: MEDIA_CONSTS.SET_FOLDER_LIST,
        payload: state
    })
};

export const setUpLoadProgresses = (progresses = []) => {
    return store.dispatch({
        type: MEDIA_CONSTS.SET_UPLOAD_PROGRESSES,
        payload: progresses
    })
};

export const getMediaHistory = (text) => {
    return dispatch=>{
        dispatch({
            type: MEDIA_CONSTS.GET_MEDIA_HISTORY,
            payload: text
        });
    }
};
export const deleteMediaHistory = (index) => {
    return dispatch=>{
        dispatch({
            type: MEDIA_CONSTS.DELETE_MEDIA_HISTORY,
            payload: index
        });
    }
};
