// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

// Import styles
import "../assets/styles/components/products-selector-modal.scss";

// Import Components
import {Modal} from "antd";
import {LinkButton} from "./buttons/buttons";
import {InputGroup} from "./uiElements/inputGroup";
import ProductCard from "./cards/ProductCard";

// Import utils
import {GetProducts} from "../redux/actions";
import {mapStateToProps} from "../redux/mapStateToProps";
import {changeStateInputValue} from "../utils/helperFunctions";

class ProductsSelectorModal extends Component {
    constructor() {
        super();
        this.state = {
            selectedProducts: [],
            text: '',
        };
        this.inputElement = React.createRef();
        this.getSearchValue = changeStateInputValue.bind(this);
        this.selectProduct = this.selectProduct.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.insertMedia = this.insertMedia.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.getProducts = this.getProducts.bind(this);
    }

    componentDidMount() {
        const {products} = this.props;
        (products?.filtered || !products?.itemsList?.length) && this.props.GetProducts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isOpen, appendProducts} = this.props;
        if (!prevProps.isOpen && isOpen && Array.isArray(appendProducts)) {
            this.setState({
                selectedProducts: appendProducts
            })
        }
    }

    selectProduct(item, isSelected) {
        const {selectedProducts} = this.state;
        this.setState({
            selectedProducts: isSelected ? selectedProducts.filter(m => m.id !== item.id) : [item, ...selectedProducts]
        })
    }

    insertMedia() {
        const {selectedProducts} = this.state;
        this.props.getSelectedProducts(selectedProducts);
        this.closeModal();
    }

    closeModal() {
        this.setState({
            selectedProducts: []
        });
        this.props.closeModal();
    }

    getProducts() {
        const {text} = this.state;
        this.props.GetProducts({
            text
        });
    }

    loadMoreItems() {
        const {text} = this.state;
        const {products} = this.props;
        const {hasMore} = products;
        hasMore && this.props.GetProducts({
            reset: false,
            offset: products.itemsList.length,
            text
        });
    };


    render() {
        const {isOpen, products} = this.props;
        const {selectedProducts, text} = this.state;
        return <Modal
            visible={isOpen}
            onCancel={this.closeModal}
            wrapClassName={"custom-modal"}>

            <div className="products-selector-modal">
                <div className="products-selector-modal-inner">
                    <div className="selector-modal-header">
                        <div className="selector-modal-title">
                            <h1>Ապրանքներ</h1>
                        </div>
                        <InputGroup
                            inputType={"input"}
                            type={'text'}
                            name={'text'}
                            value={text}
                            placeholder={'Փնտրել...'}
                            onChange={this.getSearchValue}>
                            <div className={'search-icon'} onClick={this.getProducts}>
                                <img src={require('../assets/images/ic_search.svg')} alt=""/>
                            </div>
                        </InputGroup>
                        <button className="selector-modal-closing-btn"
                                onClick={this.closeModal}>
                            <img src={require('../assets/images/ic_clear.svg')}
                                 className="close_icon" alt=""/>
                        </button>
                    </div>
                    <div className={'products-items-wrapper'}>
                        <InfiniteScroll
                            hasMore={products.hasMore}
                            loadMore={this.loadMoreItems}
                            pageStart={0}
                            className={'products'}
                            useWindow={false}
                            initialLoad={false}>
                            {products && !!products?.itemsList?.length && products.itemsList.map((item) => {
                                const isSelected = selectedProducts.find(v => v.id === item.id);
                                return <ProductCard key={item?.id}
                                                    isSelected={isSelected}
                                                    inSelectorModal={true}
                                                    onSelect={this.selectProduct}
                                                    data={item}/>
                            })}
                        </InfiniteScroll>

                    </div>
                    <div className="choose-button">
                        <LinkButton title={'Ընտրել'}
                                    disabled={!selectedProducts.length}
                                    cb={this.insertMedia}/>
                    </div>
                </div>
            </div>
        </Modal>
    }
}

const mapDispatchToProps = {
    GetProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsSelectorModal);
