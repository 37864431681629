// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";

// Import Styles
import '../../assets/styles/containerStyles/user-details.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import {SkeletonSmallItem} from '../../components/uiElements/SkelatonItem'

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {
    ClearUserById,
    GetUserById,
} from "../../redux/actions";

class UserDetails extends Component {
    constructor() {
        super();
        this.state = {};
    }

    async componentDidMount() {
        const id = this.props.match.params.id;
        await this.props.GetUserById(id);
    }

    componentWillUnmount() {
        this.props.ClearUserById();
    }

    render() {
        const {userById, requestLoading} = this.props;
        const user = userById?.data;
        return <PageWrapper>
            <section className="user-details">
                <div className="top-part">
                    <div className="user-image">
                        <img src={generateImageMediaUrl(user?.profileImgUrl, true)} alt="user"/>
                    </div>
                    <div className="user-info-header">
                        <span className="username">{
                            requestLoading ?
                                <SkeletonSmallItem/>
                                : `${user?.name || ""} ${user?.surname || ""}`
                        }
                        </span>
                        <div>{
                            requestLoading ?
                                <SkeletonSmallItem/>
                                : `${user?.username || ""}`
                        }</div>
                    </div>
                </div>
                <div className="info-container">
                    <div className="info-section">
                        <div className="section-title">Անձնական տվյալներ</div>
                        <table className="info-part">
                            <tbody>
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {user?.name && <tr>
                                    <td className="description">Օգտատեր։</td>
                                    <td className="text-bold">{`${user?.name || ""} ${user?.surname || ""}`}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {user?.birthDate && <tr>
                                    <td className="description">Ծննդյան օր։</td>
                                    <td className="text-bold">{moment(user?.birthDate).format('DD.MM.YYYY')}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {user?.address && <tr>
                                    <td className="description">Հասցե։</td>
                                    <td className="text-bold">{user?.address}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {user?.emailAddress && <tr>
                                    <td className="description">Էլ․ հասցե։</td>
                                    <td className="text-bold">{user?.emailAddress}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {user?.phoneNumber && <tr>
                                    <td className="description">Հեռախոս։</td>
                                    <td className="text-bold">{user?.phoneNumber}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {user?.passport && <tr>
                                    <td className="description">Անձնագրի համար։</td>
                                    <td className="text-bold">{user?.passport}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {user?.agent && <tr>
                                    <td className="description">Ագենտ։</td>
                                    <td className="text-bold">{ (user?.agent?.name || '-') + (user?.agent?.surname || '-')}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {user?.note && <tr>
                                    <td className="description">Նշումներ։</td>
                                    <td className="text-bold">{user?.note}</td>
                                </tr>}
                            </>}
                            </tbody>
                        </table>
                    </div>
                    <div className="info-section">
                        <div className="section-title">Քարտի տվյալներ</div>
                        <table className="info-part">
                            <tbody>
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {user?.username && <tr>
                                    <td className="description">Քարտի համարը։</td>
                                    <td className="text-bold">{user?.username}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {user?.balance && <tr>
                                    <td className="description">Բալանս։</td>
                                    <td className="text-bold">{user?.balance.toFixed(1)}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {user?.lastCardActivity && <tr>
                                    <td className="description">Վերջին ակտիվություն։</td>
                                    <td className="text-bold">{moment(user?.lastCardActivity).format('DD.MM.YYYY, HH:mm')}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {user?.lastLoginDate && <tr>
                                    <td className="description">Վերջին մուտք։</td>
                                    <td className="text-bold">{moment(user?.lastLoginDate).format('DD.MM.YYYY, HH:mm')}</td>
                                </tr>}
                            </>}
                            </tbody>
                        </table>
                    </div>
                    <div className="info-section">
                        <div className="section-title">Մեքենայի տվյալներ</div>
                        <table className="info-part">
                            <tbody>
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {user?.carNumber && <tr>
                                    <td className="description">Մեքենայի համարանիշ։</td>
                                    <td className="text-bold">{user?.carNumber}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {user?.carFirm && <tr>
                                    <td className="description">Մեքենայի մակնիշ։</td>
                                    <td className="text-bold">{`${user?.carFirm || ""} ${user?.carModel ? user?.carModel : ""}`}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {user?.carYear && <tr>
                                    <td className="description">Արտադրման տարեթիվ։</td>
                                    <td className="text-bold">{user?.carYear}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {user?.contractEndDate && <tr>
                                    <td className="description">Վարորդական իրավունքի վավեր․։</td>
                                    <td className="text-bold">{moment(user?.contractEndDate).format('DD.MM.YYYY')}</td>
                                </tr>}
                            </>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetUserById,
    ClearUserById,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)
