// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/companies.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import ItemsList from "../../components/ItemsList";
import {Icon} from "antd";
import {InputGroup} from "../../components/uiElements/inputGroup";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetCompanies, GetStatuses, DeleteCompanyById, ArchiveOrderById} from "../../redux/actions";
import {companiesDataParser, companiesListHeaderInfo, tabs} from "../../utils/listDataHelpers/company";
import {changeStateField} from "../../utils/helperFunctions";

class Companies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: tabs[0].key,
            search: '',
            itemsList: props.companiesList.itemsList
        };
        this.listRef = React.createRef();

        this.changeTab = changeStateField.bind(this, "activeTab");
        this.getInputValues = this.getInputValues.bind(this);
        this.deleteCompanyItem = this.deleteCompanyItem.bind(this);
    }

    deleteCompanyItem(id) {
        this.props.DeleteCompanyById(id).then(() => {
            this.props.GetCompanies({
                key: tabs[0].key,
            })
                .then(() => {
                    this.setState({
                        itemsList: this.props.companiesList.itemsList
                    })
            //     tabs.slice(1).forEach(tab => {
            //         this.props.GetCompanies({
            //             key: tab.key,
            //         })
            //     })
            });
        })
    }

    componentDidMount() {
        this.props.GetCompanies({
            key: tabs[0].key,
        }).then(() => {
            this.setState({
                itemsList: this.props.companiesList.itemsList
            })
            // tabs.slice(1).forEach(tab => {
            //     this.props.GetCompanies({
            //         key: tab.key,
            //     })
            // })
        });
    }

    handleChangeData(name, value) {
        let val = value.toLowerCase()
        let newList = this.props.companiesList?.itemsList?.filter(item => {
            return item.name?.toLowerCase().includes(val)
                || item?.user?.firstName?.toLowerCase().includes(val)
                || item?.user?.lastName?.toLowerCase().includes(val)
                || item?.user?.username?.toLowerCase().includes(val)
        })
        this.setState({
            itemsList: newList
        });
    }

    async getInputValues({name, value}) {
        await this.setState({
            [name]: value,
        });
        this.handleChangeData(name, value)
    }

    render() {
        const {activeTab, search, itemsList} = this.state;
        const {requestLoading} = this.props;
        // const currentCompanies = this.props?.[activeTab];;

        let itemsInfo;
        switch (activeTab) {
            case tabs[0].key:
                itemsInfo = companiesDataParser(itemsList, this.deleteCompanyItem);
                break;
            case tabs[1].key:
                itemsInfo = companiesDataParser(itemsList, this.deleteCompanyItem);
                break;
        }

        return <PageWrapper pageTitle={'Կազմակերպություններ'}>
            <section className="companies">
                <div className="search_box">
                    <button type="button" className=" search_btn" onClick={this.props.searchItems}>
                        <Icon type="search"/>
                    </button>
                    <InputGroup inputType="input"
                                type="text"
                                placeholder="Որոնել"
                                name="search"
                                maxLength={256}
                                onKeyDown={(e) => e && e.key === 'Enter' && this.props?.searchItems?.()}
                                value={search || ''}
                                onChange={this.getInputValues}
                    />
                </div>
                {/*<div className="tabs-wrapper" style={{position: "relative"}}>*/}
                {/*    <CustomTabs changeTab={this.changeTab} activeTab={activeTab} tabsList={tabs}/>*/}
                {/*</div>*/}
                <ItemsList itemListInfo={itemsInfo}
                           itemListHeaderInfo={companiesListHeaderInfo}
                           detailsLink={"companies/company-details"}
                           editLink={"companies/company"}
                           listRef={this.listRef}
                           loading={requestLoading}
                />
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetCompanies,
    GetStatuses,
    DeleteCompanyById,
    ArchiveOrderById
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies)
