// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Checkbox} from "antd";

//import styles
import '../../assets/styles/containerStyles/branches.scss';
import {ReactComponent as LocationIcon} from "../../assets/images/ic_location.svg";
import {ReactComponent as ClearIcon} from "../../assets/images/ic_clear.svg";
import {ReactComponent as CalendarIcon} from "../../assets/images/ic_calendar.svg";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {Icon} from "../../components/utils/Icon";

// Import utils
import {
    getInputValues, getPatchReqData, toggleStateField,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetBranches, AddBranch, UpdateBranch} from "../../redux/actions";
import {BRANCH_TYPES, WEEKDAYS_TYPES} from "../../constants/constTypes";
import AddressMapModal from "../../components/uiElements/addressMapModal/AddressMapModal";
import WeekdayModal from "../../components/uiElements/weekdayModal/WeekdayModal";
import {validateFieldsData} from "../../utils/validateFields";
import {getPostReqData} from "./utils/actionFunctions";



const branchTypesOptions = Object.keys(BRANCH_TYPES).map(item => {
    return {
        label: BRANCH_TYPES[item]?.title,
        value: BRANCH_TYPES[item]?.type
    }
})
class AddEditBranch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsData: {
                name: "",
                firstName: '',
                lastName: '',
                username: "",
                phone1: '',
                phone2: '',
                password: "",
                repeatPassword: "",
                address: "",
                latitude: '',
                longitude: '',
                workingDays: {},
                bonus: 0,
                commission: 0,
                types: [BRANCH_TYPES.Accumulative.type]
            },
            errors: {
                name: false,
                firstName: false,
                lastName: false,
                username: false,
                phone1: false,
                password: false,
                repeatPassword: false,
                address: false,
                latitude: false,
                longitude: false,
                bonus: false,
                commission: false,
                types: false,
            },
            passwordVisible: false,
            repeatPasswordVisible: false,
            openMapModal: false,
            openWeekdayModal: false,
            isEditing: !!props?.computedMatch.params.branchId,
            loading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.getAddress = this.getAddress.bind(this);
        this.clearWeekday = this.clearWeekday.bind(this);
        this.setBranchType = this.setBranchType.bind(this);
        this.addUpdateBranch = this.addUpdateBranch.bind(this);
    }

    async componentDidMount() {
        const {isEditing} = this.state;
        if (isEditing) {
            const {branchId} = this.props?.computedMatch.params;
            if (!this.props.branchList?.length) {
                await this.props.GetBranches(this.props?.computedMatch.params.id)
            }
            const branchById = this.props.branchList?.find(item => item.id === branchId);

            let initStateData = {
                name: branchById?.name || '',
                firstName: branchById?.user?.firstName || '',
                lastName: branchById?.user?.lastName || '',
                username: branchById?.user?.username || '',
                address: branchById?.address?.name || '',
                latitude: branchById?.address?.latitude || '',
                longitude: branchById?.address?.longitude || '',
                phone1: branchById?.phone1 || '',
                phone2: branchById?.phone2 || '',
                workingDays: branchById?.workingDays || {},
                bonus: branchById?.bonus || {},
                commission: branchById?.commission || {},
                types: branchById?.types || [],
            }

            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getAddress = (coords) => {
        this.setState({
            openMapModal: false
        })
        let setData = null;
        Object.keys(coords).forEach(item => {
            setData = {
                ...setData,
                [item]: coords[item]
            }
        })

        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                ...setData,
            },
            errors: {
                ...this.state.errors,
                address: false
            },
            errorsTexts: {
                ...this.state.errorsTexts,
                address: ''
            },
        })
        if (this.state.isEditing) {
            this.currentData?.address === this.state.fieldsData?.address ?
                this.updatedDataMap.set('address', this.state.fieldsData?.address)
                : this.updatedDataMap.delete('address')
        }
    };

    clearWeekday = (key) => {
        const {fieldsData} = this.state

        delete fieldsData.workingDays[key]
        this.setState({
            fieldsData: {
                ...fieldsData
            }
        })
        if (this.state.isEditing) {
            Object.keys(this.currentData.workingDays).includes(key)
                ? this.updatedDataMap.set('workingDays', this.state.fieldsData.workingDays)
                : this.updatedDataMap.delete('workingDays')
        }
    };

    getWeekdays = (workingDays) => {
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                workingDays: {
                    ...this.state.fieldsData.workingDays,
                    ...workingDays,
                },
            },
            openWeekdayModal: false
        })
        if (this.state.isEditing) {
            Object.keys(workingDays).forEach(key => {
                if (Object.keys(this.state.fieldsData?.workingDays)?.includes(key)) {
                    let oldDataItem = this.state.fieldsData?.workingDays?.[key]
                    if (oldDataItem.day === workingDays[key]?.day
                        || oldDataItem.openingTime === workingDays[key]?.openingTime
                        || oldDataItem.closingTime === workingDays[key]?.closingTime) {
                        this.updatedDataMap.set('workingDays', this.state.fieldsData.workingDays)
                    } else {
                        this.updatedDataMap.delete('workingDays')
                    }
                } else {
                    this.updatedDataMap.set('workingDays', this.state.fieldsData.workingDays)
                }
            })
        }
    };

    setBranchType(checkedValues) {
        if (this.state.isEditing) {
            let isUpdated = false;

            if (this.currentData.types.length !== checkedValues.length) {
                isUpdated = true;
            } else {
                !isUpdated && this.currentData.types.forEach(item => {
                    checkedValues.length
                    && !checkedValues.includes(item) && (isUpdated = true)
                })
            }
            isUpdated ?
                this.updatedDataMap.set('types', this.state.fieldsData?.types)
                : this.updatedDataMap.delete('types')
        }
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                types: checkedValues,
            },
            errors: {
                ...this.state.errors,
                types: false
            },
        })
    }

    async addUpdateBranch() {
        // await this.setState({
        //     loading: true
        // });
        const {fieldsData, isEditing} = this.state;
        let errors = {
            name: false,
            firstName: false,
            lastName: false,
            username: false,
            phone1: false,
            phone2: false,
            password: false,
            repeatPassword: false,
            address: false,
            latitude: false,
            longitude: false,
            bonus: false,
            commission: false,
            types: false,
        };
        let requiredFields = [
            'name',
            'firstName',
            'lastName',
            'username',
            'phone1',
            'address',
            'bonus',
            'commission',
            'types',
        ];
        !isEditing && (requiredFields.push('password', 'repeatPassword'))
        let validationResult = validateFieldsData(fieldsData, errors, requiredFields);

        if (validationResult) {
            this.setState({loading: true})
            if (!isEditing) {
                let reqData = getPostReqData(fieldsData);
                reqData.isActive = true;
                delete reqData.repeatPassword
                console.log(reqData, 'reqData');
                this.props.AddBranch(this.props.id, reqData)
                    .catch((error) => {
                        if (error?.response?.status === 409) {
                            this.setState({
                                errors: {
                                    ...this.state.errors,
                                    [error.response.data.duplicatePath]: true
                                },
                                errorsTexts: {
                                    ...this.state.errorsTexts,
                                    [error.response.data.duplicatePath]: this.props.staticTexts.error_duplicate_entry
                                },
                            })
                        }
                    })
                    .finally(() => {
                        this.setState({loading: false})
                    })
            }
            else {
                let reqData = getPostReqData(fieldsData);
                delete reqData.repeatPassword
                this.props.UpdateBranch(this.props.id,this.props.computedMatch.params.branchId, reqData)
                    .catch((error) => {
                        if (error?.response?.status === 409) {
                            this.setState({
                                errors: {
                                    ...this.state.errors,
                                    [error.response.data.duplicatePath]: true
                                },
                                errorsTexts: {
                                    ...this.state.errorsTexts,
                                    [error.response.data.duplicatePath]: this.props.staticTexts.error_duplicate_entry
                                },
                            })
                        }
                    })
                    .finally(() => {
                        this.setState({loading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: {...errors},
                errorsTexts: validationResult.errorText
            })
        }
    }

    render() {
        const {fieldsData, errors, loading, isEditing, passwordVisible, repeatPasswordVisible,} = this.state;

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Մասնաճյուղ' : 'Փոփոխել Մասնաճյուղը'}>
            <section className="branch-add-edit">
                <div className="branch-wrapper">
                    <div className="col">
                        <InputGroup inputType="input"
                                    type="text"
                                    label={'Անվանում' + '*'}
                                    placeholder={'Անվանում'}
                                    name="name"
                                    value={fieldsData?.name}
                                    required={true}
                                    initValue={this.currentData?.name}
                                    error={errors.name}
                                    maxLength={256}
                                    onChange={this.getInputValues}/>
                        <div className="responsible-label">{'Պատասխանատու' + '*'}</div>
                       <div className="row">
                           <InputGroup inputType="input"
                                       type="text"
                                       label={'Անուն' + '*'}
                                       placeholder={'Անուն'}
                                       name="firstName"
                                       value={fieldsData?.firstName}
                                       required={true}
                                       initValue={this.currentData?.firstName}
                                       error={errors.firstName}
                                       maxLength={50}
                                       onChange={this.getInputValues}/>
                           <InputGroup inputType="input"
                                       type="text"
                                       label={'Ազգանուն' + '*'}
                                       placeholder={'Ազգանուն'}
                                       name="lastName"
                                       value={fieldsData?.lastName}
                                       required={true}
                                       initValue={this.currentData?.lastName}
                                       error={errors.lastName}
                                       maxLength={50}
                                       onChange={this.getInputValues}/>
                       </div>
                        <InputGroup inputType="input"
                                    type="text"
                                    label={'Էլ․ հասցե' + '*'}
                                    placeholder={'Էլ․ հասցե'}
                                    name="username"
                                    value={fieldsData?.username}
                                    required={true}
                                    initValue={this.currentData?.username}
                                    error={errors.username}
                                    maxLength={320}
                                    onChange={this.getInputValues}/>
                        <div className={`address ${errors.address ? 'invalid' : ''}`}
                             onClick={() => {
                                 this.setState({
                                     openMapModal: true,
                                 })
                             }}>
                            {
                                fieldsData?.address ? <span className="text">{fieldsData?.address}</span>
                                    : <span
                                        className="placeholder">{'Մասնաճյուղի հասցեն' + '*'}</span>
                            }
                            <LocationIcon title={''}/>
                        </div>
                      <div className="row">
                          <InputGroup value={fieldsData.password}
                                      label={'Գաղտնաբառ' + '*'}
                                      placeholder={'Գաղտնաբառ'}
                                      inputType={"input"}
                                      type={!passwordVisible ? "password" : 'text'}
                                      name={"password"}
                                      maxLength={32}
                                      required={!isEditing}
                                      autocomplete={'new-password'}
                                      error={errors.password}
                                      onChange={this.getInputValues}
                          >
                            <span className={`visibility-icon ${passwordVisible ? "visible" : ''}`}
                                  onClick={toggleStateField.bind(this, 'passwordVisible')}>
                                <Icon icon={`${passwordVisible ? "eye-invisible" : "eye"}`}/>
                            </span>
                          </InputGroup>
                          <InputGroup
                              inputType={'input'}
                              type={`${repeatPasswordVisible ? "text" : "password"}`}
                              value={fieldsData.repeatPassword}
                              className={'input-group'}
                              label={'Կրկնեք գաղտնաբառը' + '*'}
                              placeholder={'Կրկնեք գաղտնաբառը'}
                              name={'repeatPassword'}
                              maxLength={32}
                              error={errors.repeatPassword}
                              onChange={this.getInputValues}
                          >
                            <span className={'visibility-icon'}
                                  onClick={toggleStateField.bind(this, 'repeatPasswordVisible')}>
                              <Icon icon={`${repeatPasswordVisible ? "eye-invisible" : "eye"}`}/>
                            </span>
                          </InputGroup>
                      </div>
                       <div className="row">
                           <InputGroup inputType="input"
                                       type="text"
                                       label={'Հեռախոսահամար' + '*'}
                                       placeholder={'Հեռախոսահամար'}
                                       name="phone1"
                                       regExp={/^[+]?[0-9]*$/}
                                       value={fieldsData?.phone1}
                                       required={true}
                                       initValue={this.currentData?.phone1}
                                       error={errors.phone1}
                                       onChange={this.getInputValues}/>
                           <InputGroup inputType="input"
                                       type="text"
                                       label={'Հեռախոսահամար 1'}
                                       placeholder={'Հեռախոսահամար 1'}
                                       name="phone2"
                                       regExp={/^[+]?[0-9]*$/}
                                       value={fieldsData?.phone2}
                                       required={true}
                                       initValue={this.currentData?.phone2}
                                       error={errors.phone2}
                                       onChange={this.getInputValues}/>
                       </div>
                        <div className="row">
                            <InputGroup inputType="input"
                                        type={'text'}
                                        regExp={/^[1-9]\d*$/}
                                        placeholder="Բոնուս"
                                        label="Բոնուս*"
                                        name="bonus"
                                        value={fieldsData.bonus}
                                        error={errors.bonus}
                                        initValue={this.currentData?.bonus}
                                        onChange={this.getInputValues}/>
                            <InputGroup inputType="input"
                                        type={'text'}
                                        value={fieldsData.commission}
                                        error={errors.commission}
                                        initValue={this.currentData?.commission}
                                        name="commission"
                                        regExp={/^[1-9]\d*$/}
                                        placeholder="Կոմիսիոն վճար"
                                        label="Կոմիսիոն վճար*"
                                        onChange={this.getInputValues}/>
                        </div>

                        <Checkbox.Group options={branchTypesOptions}
                                        value={fieldsData?.types}
                                        className={`${errors.types ? 'invalid' : ''}`}
                                        onChange={this.setBranchType}/>

                    </div>
                    <div className="col">
                        <div className="weekday"
                             onClick={() => {
                                 this.setState({
                                     openWeekdayModal: true,
                                 })
                             }}>
                            <span className="placeholder">Ընտրեք աշխատանքային օր</span>
                            <CalendarIcon title={''}/>
                        </div>
                        {!!fieldsData?.workingDays && <div className="weekday-list">
                            {
                                Object.keys(fieldsData?.workingDays)?.map(key => {
                                    return <div className="weekday-list-item"
                                                key={fieldsData?.workingDays[key]?.day}>
                                        <span
                                            className={'week-name'}>{WEEKDAYS_TYPES[fieldsData?.workingDays[key]?.day]?.title}</span>
                                        <span className="line">|</span>
                                        <span>{fieldsData?.workingDays[key]?.openingTime.slice(0, 5)}</span>
                                        &nbsp;-&nbsp;
                                        <span>{fieldsData?.workingDays[key]?.closingTime.slice(0, 5)}</span>
                                        <ClearIcon title={''}
                                                   className="clear-item"
                                                   onClick={() => this.clearWeekday(key)}/>
                                    </div>
                                })
                            }
                        </div>}
                    </div>
                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={loading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addUpdateBranch}/>
                </div>

            </section>
            <AddressMapModal fieldsData={this.state.fieldsData}
                             isOpen={this.state.openMapModal}
                             closeModal={() => this.setState({
                                 openMapModal: false
                             })}
                             getAddress={this.getAddress}
            />
            <WeekdayModal fieldsData={this.state.fieldsData}
                          isOpen={this.state.openWeekdayModal}
                          closeModal={() => this.setState({
                              openWeekdayModal: false
                          })}
                          selectedWeekdays={fieldsData?.workingDays}
                          getWeekdays={this.getWeekdays}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetBranches,
    AddBranch,
    UpdateBranch
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditBranch)
