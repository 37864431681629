export function getPostReqData(fieldsData) {
    const reqData = {};

    Object.keys(fieldsData).forEach(field => {
            const dataItem = fieldsData[field];
            if ((field === 'workingDays')) {
                reqData[field] = Object.keys(dataItem).map(item => dataItem[item])
            } else if ((field === 'address') && !!dataItem) {
                reqData.address = {
                    ...reqData.address,
                    name: dataItem
                }
            } else if ((field === 'latitude' || field === 'longitude') && !!dataItem) {
                reqData.address = {
                    ...reqData.address,
                    [field]: dataItem
                }
            } else if ((field === 'password' || field === 'firstName'
                || field === 'lastName' || field === 'username') && !!dataItem) {
                reqData.user = {
                    ...reqData.user,
                    [field]: dataItem
                }
            } else {
                if ((dataItem && dataItem !== '' && dataItem !== 0 && !Array.isArray(dataItem)) || (Array.isArray(dataItem) && dataItem.length)) {
                    reqData[field] = dataItem
                }
            }
        }
    )

    return reqData;
}