import {combineReducers} from "redux";

// import reducers
import auth from "./auth";
import utils from "./utils";
import languages from "./languages";
import history from "./history";
import moderators from "./moderators";
import media from "./media";
import users from "./users";
import product from "./product";
import category from "./category";
import order from "./order";
import partner from "./partner";
import contact from "./contact";
import slider from "./slider";
import status from "./status";
import brands from "./brands";
import section from "./section";
import reviews from "./reviews";
import insurances from "./insurances";
import filter from "./filter";
import notifications from "./notifications";
import countries from "./countries";
import shop from "./shop";
import news from "./news";
import company from "./company";
import staticTexts from "./staticTexts";
import directions from "./directions";
import branch from "./branch";
import transactions from "./transactions";
import termsConditions from "./termsConditions";
import newLog from "./newLog";
import facilities from "./facilities";


export default combineReducers({
    auth,
    utils,
    languages,
    history,
    media,
    moderators,
    users,
    product,
    category,
    order,
    partner,
    contact,
    slider,
    status,
    brands,
    section,
    reviews,
    insurances,
    filter,
    notifications,
    countries,
    shop,
    news,
    company,
    staticTexts,
    directions,
    branch,
    transactions,
    termsConditions,
    newLog,
    facilities
});
