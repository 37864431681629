import {createBrowserHistory} from "history";

export let history;
console.log(process.env.REACT_APP_ENVIRONMENT, 'process.env.REACT_APP_ENVIRONMENT');
if(process.env.REACT_APP_ENVIRONMENT === 'production') {
    console.log('We are running in production mode');
    history = createBrowserHistory({ basename: '/dashboard-v3' });
} else {
    console.log('We are running in development mode');
    history = createBrowserHistory();
}



/*--------- for 4Steps --------*/
// export const history = createBrowserHistory();
