import {request, _urlFacilities} from "../api";
import {FACILITIES_CONSTS} from "../constants";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetFacilities = () => {
    let url = `${_urlFacilities}`;
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: FACILITIES_CONSTS.GET_FACILITIES,
                    payload: data
                });
            })
    }
};

export const GetFacilityById = (id) => {
    const requestData = {
        url: `${_urlFacilities}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: FACILITIES_CONSTS.GET_FACILITIES_BY_ID,
                    payload: res.data
                })
            })
    }
};

export const DeleteFacilityById = id => {
    const requestData = {
        url: `${_urlFacilities}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: FACILITIES_CONSTS.DELETE_FACILITIES_BY_ID,
                    payload: id
                });
            })
    }
};

export const CreateFacility = data => {
    const requestData = {
        url: _urlFacilities,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: FACILITIES_CONSTS.CREATE_FACILITIES,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Գործողությունը հաջողությամբ կատարվեց!"
                })
                history.push('/facilities')

            })
    }
};

export const UpdateFacilityById = (id, data, redirect=true) => {
    const requestData = {
        url: `${_urlFacilities}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Հաջողությամբ փոփոխվեց!"
                });
                redirect && history.push('/facilities')
            })
    }
};

export const UpdateFacilitiesPositions = items => {
    const requestData = {
        url: _urlFacilities,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Հաջողությամբ թարմացվեց!"
                })
            })
    }
};
