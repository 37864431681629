export const ACCESS_PAGES = {
    users: {
        key: 'USERS',
        name: 'Օգտատերեր',
        icon: 'person',
        isHidden: false,
    },
    products: {
        key: 'PRODUCTS',
        name: 'Ապրանքներ',
        icon: 'add_shopping_cart',
        isHidden: false,
    },
    categories: {
        key: 'CATEGORIES',
        name: 'Կատեգորիաներ',
        icon: 'category',
        isHidden: false,
    },
    facilities: {
        key: 'FACILITIES',
        name: 'Ծառայություններ',
        icon: 'thumb_up',
        isHidden: false,
    },
    filters: {
        key: 'FILTERS',
        name: 'Ֆիլտրեր',
        icon: 'style',
        crud: true,
        isHidden: false,
    },
    media: {
        key: 'FILES',
        name: 'Ֆայլեր',
        icon: 'insert_drive_file',
        isHidden: false,
    },
    notifications: {
        key: 'NOTIFICATION',
        name: 'Ծանուցումներ',
        icon: 'notification_important',
        crud: true,
        isHidden: false,
    },
    reviews: {
        key: 'REVIEWS',
        name: 'Մեկնաբանություններ',
        icon: 'rate_review',
        isHidden: true,
    },
    orders: {
        key: 'ORDERS',
        name: 'Պատվերներ',
        icon: 'notes',
        isHidden: false,
    },
    status: {
        key: 'ORDER_STATUSES',
        name: 'Կարգավիճակ',
        icon: 'payment',
        crud: true,
        isHidden: false,
    },
    shops: {
        key: 'SHOPS',
        name: 'Խանութներ',
        icon: 'storefront',
        crud: false,
        isHidden: false,
    },
    brands: {
        key: 'BRANDS',
        name: 'Բրենդ',
        icon: 'branding_watermark',
        crud: true,
        isHidden: false,
    },
    news: {
        key: 'NEWS',
        name: 'Նորություններ',
        icon: 'developer_board',
        crud: true,
        isHidden: false,
    },
    section: {
        key: 'SECTIONS',
        name: 'Բաժիններ',
        icon: 'vertical_split',
        crud: true,
        isHidden: false,
    },
    insurances: {
        key: 'INSURANCES',
        name: 'Ապահովագրություններ',
        icon: 'verified_user',
        crud: true,
        isHidden: false,
    },
    directions: {
        key: 'DIRECTIONS',
        name: 'Ուղղություններ',
        icon: 'near_me',
        crud: false,
        isHidden: false,
    },
    // contact: {
    //     key: 'CONTACTS',
    //     name: 'Կապ',
    //     icon: 'contact_phone',
    //     crud: true,
    //     isHidden: false,
    // },
    termsConditions: {
        key: 'TERMS_CONDITIONS',
        name: 'Պայմաններ և դրույթներ',
        icon: 'assignment',
        crud: true,
        isHidden: false,
    },
    companies: {
        key: 'COMPANIES',
        name: 'Կազմակերպություններ',
        icon: 'business',
        crud: false,
        isHidden: false,
    },
    transactions: {
        key: 'TRANSACTIONS',
        name: 'Գործարքներ',
        icon: 'attach_money',
        crud: false,
        isHidden: false,
    },
    bankTransactions: {
        key: 'BANK_TRANSACTIONS',
        name: 'Բանկային գործարքներ',
        icon: 'account_balance',
        crud: false,
        isHidden: false,
    },
    languages: {
        key: 'LANGUAGES',
        name: 'Լեզուներ',
        icon: 'language',
        crud: true,
        isHidden: false,
    },
    staticTexts: {
        key: 'TRANSLATIONS',
        name: 'Թարգմանություններ',
        icon: 'text_format',
        crud: true,
        isHidden: false,
    },
};
