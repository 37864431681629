import swal from "sweetalert";
import {request, _urlOrders} from "../api";
import {ORDER_CONSTS, UTIL_CONSTS} from "../constants";
import {LIMIT_GET_ORDERS} from "../../constants/constLimitCounts";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetOrders = ({
                              key,
                              reset = true,
                              offset = 0,
                              limit = LIMIT_GET_ORDERS,
                              status = null,
                              userId = null,
                              isReservation = null,
                              archived = false,
                              getResponse = false
                          } = {}) => {
    const requestData = {
        url: `${_urlOrders}?offset=${offset}&limit=${limit}`,
        token: true,
        method: "GET",
    };

    status && (requestData.url += `&status=${status}`);
    userId && (requestData.url += `&userId=${userId}`);
    isReservation !== null && (requestData.url += `&isReservation=${isReservation}`);
    requestData.url += `&archived=${archived}`;

    return dispatch => {
        reset &&  !getResponse && dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                if (getResponse) {
                    return {
                        data,
                        key,
                        archived,
                        hasMore: data.length === limit
                    };
                } else {
                    dispatch({
                        type: ORDER_CONSTS.GET_ORDERS,
                        payload: {
                            key,
                            data: data,
                            reset,
                            archived,
                            isReservation,
                            hasMore: data.length === limit
                        }
                    })
                }
            })
            .finally(() => {
                reset &&  !getResponse && dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const ClearOrders = () => {
    return dispatch => {
        dispatch({
            type: ORDER_CONSTS.CLEAR_ORDERS,
        });
    }
};

export const GetOrderById = (id) => {
    const requestData = {
        url: `${_urlOrders}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ORDER_CONSTS.GET_ORDER_BY_ID,
                    payload: res.data
                })
            })
            .finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
            .catch(() => {
            })
    }
};

export const DeleteOrderById = (id) => {
    const requestData = {
        url: `${_urlOrders}/${id}/delete`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: ORDER_CONSTS.DELETE_ORDER_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const ArchiveOrderById = (id, archived) => {
    const requestData = {
        url: `${_urlOrders}/${id}/${!archived ? 'archive' : 'unarchive'}`,
        token: true,
        method: "PUT",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: ORDER_CONSTS.ARCHIVE_ORDER_BY_ID,
                    payload: {id,archived}
                });
            })
            .catch(() => {
            })
    }
};

export const UpdateOrderStatus = (id, order, status) => {
    const requestData = {
        url: `${_urlOrders}/${id}`,
        token: true,
        method: "PUT",
        data: order
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                // dispatch({
                //     type: ORDER_CONSTS.UPDATE_ORDER_STATUS,
                //     payload: { id, status },
                // });
                notificationSuccess({
                    description: "Պատվերը հաջողությամբ թարմացվեց!"
                })
            })
            .catch(() => {
                swal({
                    title: "Զգուշացում!",
                    text: "Պատվերի կարգավիճակը հնարավոր չի փոփոխել",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const GetNotSeenOrdersCount = () => {
    const requestData = {
        url: `${_urlOrders}/not-seen-count`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                data && dispatch({
                    type: ORDER_CONSTS.GET_NOT_SEEN_ORDERS_COUNT,
                    payload: data
                });
            })
    }
};

export const SetOrderAsSeen = (id) => {
    const requestData = {
        url: `${_urlOrders}/${id}/seen`,
        token: true,
        method: "PUT",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: ORDER_CONSTS.SET_ORDER_AS_SEEN,
                    payload: id
                });
            })
    }
};
