//file upload
// export const MAX_FILE_SIZE = 209715200; //200mb
// export const ACCEPT_FILE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'pdf', 'wmv', 'mpeg',
//     'avi', 'flv', 'mp3', 'mp4', 'wav', 'zip', 'rar', 'doc', 'docx', 'xls', 'ppt', 'pptx'];
// export const ACCEPT_IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg',];
// export const ACCEPT_IMAGE_SIZE = 10485760; // 10mb
// export const IMAGES_TYPES_PATTERN = "(jpg|jpeg|gif|png|svg|bmp)$";
//
// export const LOCKED_MEDIA_FOLDERS = ['5eb107f2fcf7e3016fb58211', '5ec4e6d0dc866c5f169c0c11'];

//file upload
export const MAX_FILE_SIZE = 544288000; //200mb

export const ACCEPT_FILE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'pdf', 'wmv', 'mpeg',
    'avi', 'flv', 'mp3', 'mp4', 'wav', 'zip', 'rar', 'doc', 'docx', 'xls', 'ppt', 'pptx'];
export const ACCEPT_IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg',];
export const ACCEPT_IMAGE_SIZE = 10485760; // 10mb
export const ACCEPT_COMPANY_IMAGE_SIZE = 524288; // 500kb

export const ACCEPT_AUDIO_TYPES = ['3gp', 'midi', 'mp3', 'ogg', 'wav', 'wma', 'mpeg'];

export const LOCKED_MEDIA_FOLDERS = [];