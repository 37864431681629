// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/products.scss';

// Import components
import InfiniteScroll from "react-infinite-scroller";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetCountries, GetProducts} from "../../redux/actions";
import {ACCESS_PAGES} from "../../constants/accessPages";
import {InputGroup} from "../../components/uiElements/inputGroup";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import ProductCard from "../../components/cards/ProductCard";

const MAX_PRICE = 1000000;
const MIN_PRICE = 0;

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            filterValueId: undefined,
            categoryId: undefined,
            minPrice: '',
            maxPrice: '',
        };
        this.getSearchValue = this.getSearchValue.bind(this);
        this.handleChangePrice = this.handleChangePrice.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount() {
        this.props.GetProducts();
        this.props.GetCountries();
    }

    loadMoreItems() {
        const {products} = this.props;
        const {hasMore} = products;
        const {categoryId, filterValueIds, minPrice, maxPrice, text} = this.state;
        // console.log(name, value)
        const filters = {
            filterValueIds,
            categoryId,
            minPrice,
            maxPrice,
            text,
        };
        hasMore && this.props.GetProducts({
            reset: false,
            offset: products.itemsList.length,
            ...filters,
        });
    };

    getSearchValue(value) {
        if (!value) {
            this.handleChange({name:'text', value})
        } else {
            this.setState({
                'text':value,
            })
        }
    }

    async handleChangePrice(target) {
        const {minPrice, maxPrice,} = this.state;
        // console.log(target?.name === 'minPrice')
        if (target?.value < MIN_PRICE || target?.value > MAX_PRICE) {
            return;
        }        // if (target?.name === 'minPrice') {
        //     if (target?.value < MIN_PRICE || target?.value > MAX_PRICE) {
        //         return;
        //     }
        //     if (maxPrice && Number(target?.value) > Number(maxPrice)) {
        //        await this.setState({
        //             maxPrice: target.value
        //         })
        //     }
        // } else {
        //
        //     if (target?.value < MIN_PRICE || target?.value > MAX_PRICE) {
        //         return;
        //     }
        //     if (minPrice && Number(target?.value) < Number(minPrice)) {
        //      await   this.setState({
        //             minPrice: target.value
        //         })
        //     }
        // }
        this.handleChange(target);
    }

    handleChange({name, value} = {}) {
        const {categoryId, filterValueIds, minPrice, maxPrice, text} = this.state;
        // console.log(name, value)
        const newFilter = {
            filterValueIds,
            categoryId,
            minPrice,
            maxPrice,
            text: text,
        };
        name && (newFilter[name] = value)

        this.setState({
            ...newFilter,
        });
        if (newFilter?.text?.length === 1) {
            return;
        }
        this.props.GetProducts(newFilter);
    }

    render() {
        const {text, minPrice, maxPrice, categoryId, filterValueId, errMinPrice, errMaxPrice} = this.state;
        const {products, accessPages, isAdmin, filtersList, categoriesList} = this.props;
        // console.log(categoriesList, 'categoriesList');
        const haveProductsAccess = isAdmin || accessPages?.includes(ACCESS_PAGES.products?.key);

        this.categoriesOptions = [];
        Array.isArray(this.props.categoriesList) && this.props.categoriesList.forEach(item => {
            this.categoriesOptions.push({
                name: item?.title,
                id: item?.id,
            });
        });

        const filters = <div className={'product-filters'}>
            <InputGroup inputType="selectCustom"
                        placeholder="Ընտրել Կատեգորիա"
                        name="categoryId"
                        showSearch={false}
                        value={categoryId}
                        onChange={this.handleChange}
                        options={this.categoriesOptions }
                        withClear={'Բոլորը'}>
            </InputGroup>
            <InputGroup inputType="selectCustom"
                        placeholder="Ֆիլտրեր"
                        name="filterValueId"
                        showSearch={false}
                        value={filterValueId}
                        onChange={this.handleChange}
                        options={filtersList}
                        withClear={'Բոլորը'}>
            </InputGroup>
            <InputGroup inputType="input"
                        placeholder="Գին․ սկսած"
                        name="minPrice"
                        regExp={/^[1-9]\d*$/}
                        showSearch={false}
                        value={minPrice}
                        error={errMinPrice}
                        onChange={this.handleChangePrice}/>
            <InputGroup inputType="input"
                        placeholder="Գին․ մինչև"
                        name="maxPrice"
                        regExp={/^[1-9]\d*$/}
                        showSearch={false}
                        value={maxPrice}
                        error={errMaxPrice}
                        onChange={this.handleChangePrice}/>

        </div>;
        return <PageWrapper pageTitle={'Ապրանքներ'}>
            <section className="dashboard">
                <PageHeader linkTitle={haveProductsAccess && "Ավելացնել Ապրանք"}
                            addingLink={haveProductsAccess && "/products/add"}
                            getSearchValue={this.getSearchValue}
                            searchItems={this.handleChange}
                            searchValue={text}
                            search={haveProductsAccess}
                            filters={filters}>
                </PageHeader>
                <InfiniteScroll
                    hasMore={products.hasMore}
                    loadMore={this.loadMoreItems}
                    pageStart={0}
                    className={'products'}
                    useWindow={true}
                    initialLoad={false}>
                    {products && !!products.itemsList.length ? products.itemsList.map((item) => {
                        return <ProductCard key={item?.id}
                                            data={item}/>
                    }):
                    <div className={'empty-view'}>
                        <img src={require('../../assets/images/ic_empty.svg')}/>
                    </div>}
                </InfiniteScroll>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetProducts,
    GetCountries
};

export default connect(mapStateToProps, mapDispatchToProps)(Products)
