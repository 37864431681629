// Import packages
import React, {PureComponent} from "react";

// Import components

// Import styles
import "./image-uploader.scss";
import {ReactComponent as UploadIcon} from "../../assets/images/file-manager/ic_choose_from_computer.svg";
import {ReactComponent as CloseIcon} from "../../assets/images/ic_close.svg";

//import utils
import {hasExtension} from "../../utils/hasExtension";
import {ACCEPT_COMPANY_IMAGE_SIZE} from "../../constants/acceptedTypes";
import {notificationError} from "../uiElements/Notifications";


export default class ImageUploader extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            image: props.image,
            imageExist: true,
        };
        this.imageSelector = React.createRef();
        this.deleteFile = this.deleteFile.bind(this);
        this.onUploadClick = this.onUploadClick.bind(this);
    }

    componentDidMount() {
        this.setState({
            image: this.props.image,
        })
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.image && this.props.image) {
            this.setState({
                image: this.props.image
            })
        }
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Read the image via FileReader API and save image result in state.
            reader.onload = function (e) {
                let dataURL = e.target.result;
                resolve(dataURL);
            };
            reader.onerror = function (e) {
                reject(e);
            };
            reader.readAsDataURL(file);
        });
    }

    deleteFile() {
        this.setState({
            image: "",
        })
        this.props.onChange('')
    }

    handleImageChange(e) {
        let file = e && e.target && e.target.files && e.target.files[0];

        if (file) {
            if (!hasExtension(file.name)) {
                notificationError({
                    title: 'Not Supported file format!'
                })
                this.props.onChange('')
                return;
            }
            if (file.size > ACCEPT_COMPANY_IMAGE_SIZE) {
                notificationError({
                    title: 'File size is too big'
                })
                this.props.onChange('')
                return;
            }
            //data.append('file', file, file.name);
            this.readFile(file)
                .then(dataURL => {
                    this.setState({
                        image: dataURL,
                        imageExist: true,
                    }, () => this.props.onChange(dataURL))
                });
        }
    }

    onUploadClick = (e) => {
        e.target.value = null;
    }

    render() {
        let {image, invalid} = this.props;

        return <div
            className={`image-uploader ${!image ? "with-border" : ""}`}>

            <div className={`img-selector ${invalid ? 'invalid' : ''}`}
                 onClick={() => this.imageSelector.current.click()}>
                <UploadIcon title={''}/>
                <span>Նկար*</span>
            </div>

            {image && <div className={'image-wrapper'}>
                <img src={image} alt=" "/>
                <div className="clear-icon-wrapper">
                    <CloseIcon title="" className={'clear-icon'}
                               onClick={this.deleteFile}
                    />
                </div>
            </div>}
            <input type="file"
                   accept="image/*"
                   ref={this.imageSelector}
                   onClick={this.onUploadClick}
                   onChange={(e) => {
                       this.handleImageChange(e)
                   }}/>
        </div>
    }
}
