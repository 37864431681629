import {NOTIFICATIONS_CONSTS} from "../constants";

export const initialState = {
    notificationsList: {
        itemsList: [],
        hasMore:false,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATIONS_CONSTS.GET_NOTIFICATIONS:
            return {
                ...state,
                notificationsList: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.notificationsList.itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                }
            };
        default:
            return state;
    }
}
