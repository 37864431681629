import {FILTER_CONSTS} from "../constants";

export const initialState = {
    filtersList: [],
    filterById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FILTER_CONSTS.GET_FILTERS:
            return {
                ...state,
                filtersList: action.payload || [],
            };
        case FILTER_CONSTS.GET_FILTER_BY_ID:
            return {
                ...state,
                filterById: action.payload
            };
        case FILTER_CONSTS.CREATE_FILTER:
            return {
                ...state,
                filtersList: [action.payload].concat(state.filtersList)
            };
        case FILTER_CONSTS.DELETE_FILTER_BY_ID:
            return {
                ...state,
                filtersList: state.filtersList.filter(filter => filter.id !== action.payload)
            };
        default:
            return state;
    }
}