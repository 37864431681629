// Import packages
import React from "react";
import {Menu, Dropdown} from "antd";
import swal from "sweetalert";

// Import components
import {Icon} from "../utils/Icon";

// Import styles
import "../../assets/styles/headerStyles/menuDropdown.scss";

export function MenuDropdown(props) {


    const DropDownMenu = (
        <Menu>
            <Menu.Item onClick={() => logUserOut()}>
                Դուրս գալ
            </Menu.Item>
        </Menu>
    );

    const logUserOut = () => {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք դուրս գալ?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                props.LogOut();
            }
        });
    };
    let title = "Nest CMS";
    !props.isAdmin && props?.user?.firstName && props?.user?.lastName && (title = `${props?.user?.firstName} ${props?.user?.lastName}`)
    return <div className="menu-dropdown">
        <Dropdown overlay={DropDownMenu}
                  placement="bottomRight"
                  trigger={['click']}>
            <div className="dropdown-inner">
                <span className="username"
                      title={"Nest CMS"}>
                    {title}
                    </span>
                <Icon icon="down"/>
            </div>
        </Dropdown>

    </div>
}
