// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {InputNumber, Slider, Switch, Tree} from "antd";
import {history} from "../../configs/history";
import Nestable from "react-nestable";

import 'suneditor/dist/css/suneditor.min.css';
import plugins from 'suneditor/src/plugins'
// Import Styles
import '../../assets/styles/containerStyles/appended-product-section.scss';
import '../../assets/styles/containerStyles/product-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {Editor} from "../../components/uiElements/Editor";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {MediaFileCard} from "../../components/media/MediaFileCard";


// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    arraysEquals,
    changeStateField,
    getInputValues,
    getPatchReqData,
    getPostReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField,
} from "../../utils/helperFunctions";
import {AddProduct, GetProductById, UpdateProduct} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {CURRENCY_TYPES, DEFAULT_IMAGE_ID, MEASUREMENT_TYPES, MEDIA_MODAL_TYPES} from "../../constants/constTypes";

const measurementUnitOptions = Object.keys(MEASUREMENT_TYPES).map(key => ({
    id: key,
    name: MEASUREMENT_TYPES[key]
}));

const currencyOptions = Object.keys(CURRENCY_TYPES).map(key => ({
    id: key,
    name: CURRENCY_TYPES[key].name
}));

class AddEditProduct extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'details',
            'description',
        ];

        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                price: "",
                oldPrice: "",
                categories: [],
                filterValues: [],
                medias: [],
                quantity: 0,
                wholesaleQuantity: 0,
                measurementUnit: undefined,
                facilities: undefined,
                currency: undefined,
                country: props.countriesList?.[0]?.id || undefined,
                shippingPrice: "",
                isFullFilledByNest: false,
                isHidden: false,
                isHotel: false,
                rate: 0
            },
            attributeNewValue: '',
            addAttributeKey: null,
            currentData: null,
            errors: {},
            errorsTabs: [],
            loading: false,
            requestLoading: false,
            languageTab: props.mainLanguage,
            isEditing: !!props?.id,
            mediaModalOpen: false,
        };

        this.facilitiesOptions = Array.isArray(props.facilitiesList) ? props.facilitiesList.map(item => ({
            name: item?.title,
            id: item?.id,
            img: item?.icon
        })) : [];

        this.updatedDataMap = new Map();
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.onMediaPositionChange = this.onMediaPositionChange.bind(this);
        this.checkMediaListDifferent = this.checkMediaListDifferent.bind(this);
        this.productCardWrapper = this.productCardWrapper.bind(this);
        this.mediaCardWrapper = this.mediaCardWrapper.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.addUpdateProduct = this.addUpdateProduct.bind(this);

        this.customPlugin = {
            // @Required @Unique
            name: 'Media',
            // @Required
            display: 'command',

            // @options
            // * You can also set from the button list
            // HTML title attribute (tooltip) - default: plugin's name
            title: 'Open Media Modal',
            // HTML to be append to button (icon)
            // Recommend using the inline svg icon. - default: "<span class="se-icon-text">!</span>"
            innerHTML: "<span class='material-icons'>perm_media</span>",
            // The class of the button. - default: "se-btn"
            // "se-code-view-enabled": It is not disable when on code view mode.
            // "se-resizing-enabled": It is not disable when on using resizing module.
            buttonClass: '',

            // @Required
            add: function (core, targetElement) {
                const context = core.context;
                const rangeTag = core.util.createElement('div');
                core.util.addClass(rangeTag, '__se__format__range_custom');
                context.customCommand = {
                    targetButton: targetElement,
                    tag: rangeTag
                };
            },

            // @Override core
            // Plugins with active methods load immediately when the editor loads.
            // Called each time the selection is moved.
            active: function () {
            },

            // @Required, @Override core
            // The behavior of the "command plugin" must be defined in the "action" method.
            action: () => {
                this.toggleMediaModal()
            }
        }
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;

        const {filtersList, countriesList} = this.props;

        if (isEditing) {
            const id = this.props?.id;
            await this.props.GetProductById(id);
            const {productById} = this.props;
            if (!productById) {
                history.push('/products');
                return;
            }
            // Translations
            const translationsData = {};
            productById.translations.forEach(item => {
                translationsData[item?.languageId] = {
                    ...translationsData[item?.languageId],
                    title: item?.title || '',
                    description: item?.description || '',
                    details: item?.details || '',
                };
            });


            // Filter ids
            const filterValues = [];
            productById.filters && productById.filters.forEach(filter => {
                // filterValues.push(filter.id);
                const correspondingFilter = filtersList.find(f => f.id === filter.id);
                let allValuesChecked = true;

                correspondingFilter.values.forEach(v => {
                    if (!filter?.values?.some(value => value.id === v.id)) {
                        allValuesChecked = false;
                    }
                })
                filter.values && filter.values.forEach(value => {
                    filterValues.push(value.id);
                });
                allValuesChecked && filterValues.push(filter.id);
            });

            //Medias
            const medias = [];
            if (Array.isArray(productById?.medias)) {
                productById.medias && productById.medias.forEach(media => {
                    media.id !== DEFAULT_IMAGE_ID && medias.push(media);
                })
            }

            let facilities = !!productById?.facilities?.length
                && productById.facilities.map(item => item.id);
            // let facilities = [];
            // productById.facilities && productById.facilities.forEach(item => {
            //     facilities.push(item.id)
            // });

            const initStateData = {
                translations: translationsData,
                price: productById?.price || '',
                oldPrice: productById?.oldPrice || '',
                categories: productById.categories || [],
                filterValues: productById.filterValues || [],
                medias: medias,
                quantity: productById.quantity || '',
                wholesaleQuantity: productById.wholesaleQuantity || '',
                measurementUnit: productById?.measurementUnit || '',
                currency: productById?.currency || '',
                country: productById?.country?.id || countriesList?.[0]?.id || undefined,
                shippingPrice: productById?.shippingPrice || '',
                isFullFilledByNest: !!productById?.isFullFilledByNest,
                isHidden: !!productById?.isHidden,
                isHotel: !!productById?.isHotel,
                facilities: facilities,
                rate: productById?.rate || 0,
            }

            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.fieldsData.isHotel !== this.state.fieldsData.isHotel) {
            this.setState({
                fieldsData: {
                    ...this.state.fieldsData,
                    measurementUnit: !this.state.fieldsData?.isHotel ? undefined : 'DAY'
                }
            })
        }
    }

    checkMediaListDifferent(currMedias) {
        let result = false;
        const initMedias = this.currentData.medias;
        if (!Array.isArray(currMedias) || (currMedias?.length !== initMedias.length)) {
            result = true;
        } else {
            currMedias.forEach((item, index) => {
                if (initMedias[index]?.id !== item?.id) {
                    result = true;
                }
            })
        }
        result ? this.updatedDataMap.set('medias', currMedias?.map(m => m.id))
            : this.updatedDataMap.delete('medias');
    }

    onMediaPositionChange(items) {
        const {fieldsData, isEditing} = this.state;
        const medias = items.map(item => item.media);
        if (isEditing) {
            this.checkMediaListDifferent(medias)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                medias: medias
            }
        })
    }

    getMedia(mediaArray) {
        const {fieldsData, isEditing} = this.state;
        /*  const medias = [...fieldsData.medias];
          mediaArray.forEach(p => {
              if (!medias.some(media => media.id === p.id)) {
                  medias.push(p);
              }
          })*/
        if (isEditing) {
            this.checkMediaListDifferent(mediaArray)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                medias: mediaArray,
            },
        })
    }

    deleteMedia(id) {
        const {fieldsData, isEditing} = this.state;
        let medias = fieldsData.medias.filter(m => m.id !== id);

        if (isEditing) {
            this.checkMediaListDifferent(medias)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                medias: medias,
            }
        })
    }

    getMultiSelectData = (checkedKeys, name) => {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            const updatedValues = checkedKeys?.length ? checkedKeys : [];
            arraysEquals(this.currentData?.[name], checkedKeys)
                ? this.updatedDataMap.delete(name)
                : this.updatedDataMap.set(name, updatedValues);

        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [name]: checkedKeys,
            }
        })
    };

    treeData = (data, children) => {
        const treeData = [];
        !!data?.length && data.forEach(item => {
            const treeItem = {
                title: item.title,
                key: item.id,
            };
            if (item[children] && item[children]?.length) {
                treeItem.children = this.treeData(item[children], children)
            }
            treeData.push(treeItem);
        });
        return treeData;
    };

    getAttributeNewValue = ({value}) => {
        this.setState({
            attributeNewValue: value
        })
    }

    toggleAddAttribute(key = null) {
        this.setState({
            addAttributeKey: key,
            attributeNewValue: ''
        })
    }

    productCardWrapper(data) {
        // return <ProductCard data={data?.item?.product}
        //                     onDelete={this.deleteProduct}
        //                     viewCard={true}/>
    }

    mediaCardWrapper(data) {
        return <MediaFileCard item={data?.item?.media}
                              customDelete={this.deleteMedia}/>
    }

    async addUpdateProduct() {
        await this.setState({
            requestLoading: true
        });
        const {fieldsData} = this.state;
        const {translations, price, currency, isHotel, measurementUnit} = fieldsData;
        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (!price) {
            errors.price = true;
            result = false;
        }
        if (!currency) {
            errors.currency = true;
            result = false;
        }
        if (!measurementUnit) {
            errors.measurementUnit = true;
            result = false;
        }
        if (result) {
            if (!this.state.isEditing) {
                let reqData = this.getPostReqData(fieldsData);
                reqData.isHotel = isHotel
                this.props.AddProduct(reqData).finally(() => {
                    this.setState({requestLoading: false})
                })
            } else {
                let trData = getTranslationData(translations);
                const reqData = this.getPatchReqData(trData);
                reqData.isHotel = isHotel

                this.props.UpdateProduct(this.props.id, reqData).finally(() => {
                    this.setState({loading: false});
                });
            }
        } else {

            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    // onChange = value => {
    //     console.log(value, 'val');
    //     this.setState({
    //         inputValue: value,
    //     });
    // };
    render() {
        const {filtersList, categoriesList, mainLanguage, countriesList, facilitiesList} = this.props;
        const {
            fieldsData, languageTab, isEditing, loading, errors, mediaModalOpen, errorsTabs, country
        } = this.state;
        // console.log(facilitiesList, 'fieldsData');
        const {translations} = fieldsData;
        const trData = translations[languageTab] || {};
        const initTrData = this.currentData?.translations[languageTab] || {};
        const mediaItems = [];

        fieldsData.medias.forEach(media => {
            mediaItems.push({
                id: media?.id,
                media: media
            })
        });
        let countriesOptions = countriesList?.map(item => {
            return {
                name: item?.title,
                id: item?.id,
            };
        });

        return <PageWrapper pageTitle={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'} Ապրանք`}>
            <section className="product-add-edit">
                <div className="fixed-top-right">
                    <label>Հյուրանոց</label>
                    <Switch checked={fieldsData.isHotel}
                            onChange={(checked) => this.getInputValues({
                                name: 'isHotel',
                                value: checked,
                                haveChanges: this.currentData?.isHotel !== checked
                            })}/>
                </div>
                <div className="fixed-top-right is-active">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: 'isHidden',
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className="fixed-top-right fulfilled">
                    <label>{'Fulfilled'}</label>
                    <Switch checked={fieldsData.isFullFilledByNest}
                            onChange={(checked) => this.getInputValues({
                                name: 'isFullFilledByNest',
                                value: checked,
                                haveChanges: this.currentData?.isFullFilledByNest !== checked
                            })}/>
                </div>
                <div className={'products-medias-list-wrapper '}>
                    <div className={'label-and-action'}>
                        <label>Նկարներ</label>
                        <LinkButton title="Ընտրել Մեդիադարանից"
                                    className={`media-select-btn `}
                                    cb={this.toggleMediaModal}/>
                    </div>
                    <section className={'items-wrapper media-items'}>
                        {mediaItems.length ? <Nestable
                                items={mediaItems}
                                maxDepth={1}
                                onChange={this.onMediaPositionChange}
                                renderItem={this.mediaCardWrapper}
                            /> :
                            <p className={'empty-text'}>Նկարներ չկան</p>}
                    </section>
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              activeTab={languageTab}
                              errorsTabs={errorsTabs}/>
                <div className="product-wrapper">
                    <div className="left-part">

                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Անվանում"}
                                        placeholder="Անվանում"
                                        name="title"
                                // maxLength={100}
                                        value={trData?.title}
                                        required={true}
                                        initValue={initTrData?.title}
                                        error={errors?.['title' + languageTab]}
                                        onChange={this.getTranslatableInputValues}/>
                        </div>
                        <div className={fieldsData.isHotel ? "quarter-wrapper" : "third-wrapper"}>
                            {!fieldsData?.isHotel && <InputGroup inputType="input"
                                                                 type="text"
                                                                 number={true}
                                                                 label="Հին գին"
                                                                 minValue={1}
                                                                 maxValue={9999999}
                                                                 placeholder="Հին գին"
                                                                 name="oldPrice"
                                                                 value={fieldsData?.oldPrice}
                                                                 initValue={this.currentData?.oldPrice}
                                                                 onChange={this.getInputValues}/>}

                            <InputGroup inputType="input"
                                        type="text"
                                        number={true}
                                        minValue={1}
                                        maxValue={9999999}
                                        label="Նոր գին"
                                        placeholder="Նոր գին"
                                        name="price"
                                        value={fieldsData?.price}
                                        initValue={this.currentData?.price}
                                        error={errors?.price}
                                        required={true}
                                        onChange={this.getInputValues}/>
                            <InputGroup inputType="selectCustom"
                                        label={"Արժույթ"}
                                        placeholder="Արժույթ"
                                        name="currency"
                                        showSearch={false}
                                        error={errors?.currency}
                                        value={fieldsData?.currency}
                                        initValue={this.currentData?.currency}
                                        withClear={true}
                                        onChange={this.getInputValues}
                                        options={currencyOptions}/>
                        </div>


                    </div>
                    <div className="right-part">
                        <div className={!fieldsData?.isHotel ? "quarter-wrapper" : "input-form"}>
                            {
                                fieldsData.isHotel ? <InputGroup
                                        value={fieldsData.facilities}
                                        inputType="selectCustom"
                                        label={'Ծառայություններ'}
                                        mode="multiple"
                                        name="facilities"
                                        className={'select-facilities'}
                                        placeholder={'Ընտեք ծառայություն'}
                                        onChange={this.getInputValues}
                                        options={this.facilitiesOptions}
                                    />
                                    : <>
                                        <InputGroup inputType="input"
                                                    type="text"
                                                    integerNumber={true}
                                                    minValue={1}
                                                    label="Քանակ"
                                                    placeholder="Քանակ"
                                                    name="quantity"
                                                    value={fieldsData?.quantity}
                                                    initValue={this.currentData?.quantity}
                                                    error={errors?.quantity}
                                                    onChange={this.getInputValues}/>

                                        <InputGroup inputType="input"
                                                    type="text"
                                                    integerNumber={true}
                                                    minValue={1}
                                                    label="Մեծածախի Քանակ"
                                                    placeholder="Մեծածախի Քանակ"
                                                    name="wholesaleQuantity"
                                                    value={fieldsData?.wholesaleQuantity}
                                                    initValue={this.currentData?.wholesaleQuantity}
                                                    error={errors?.wholesaleQuantity}
                                                    onChange={this.getInputValues}/>
                                    </>
                            }
                        </div>
                        <div className="quarter-wrapper">
                            <InputGroup inputType="selectCustom"
                                        label={"Երկիր"}
                                        placeholder="Երկիր"
                                        name="country"
                                        showSearch={false}
                                        value={fieldsData?.country}
                                        initValue={this.currentData?.country}
                                        onChange={this.getInputValues}
                                        options={countriesOptions}/>
                            <InputGroup inputType="selectCustom"
                                        label={"Չափման միավոր"}
                                        placeholder="Չափման միավոր"
                                        name="measurementUnit"
                                        showSearch={false}
                                        required={true}
                                        error={errors?.measurementUnit}
                                        value={fieldsData?.measurementUnit}
                                        disabled={fieldsData?.isHotel}
                                        initValue={this.currentData?.measurementUnit}
                                        withClear={true}
                                        onChange={this.getInputValues}
                                        options={measurementUnitOptions}/>
                        </div>
                    </div>
                    <div className="trees-wrapper">
                        <div className="tree-item">
                            <label>Կատեգորիաներ</label>
                            <Tree
                                checkable
                                onCheck={(keys) => this.getMultiSelectData(keys, 'categories')}
                                checkedKeys={fieldsData.categories}
                                showLine={true}
                                treeData={this.treeData(categoriesList, "subCategories")}
                            />
                        </div>
                        <div className="tree-item">
                            <label>Ֆիլտրեր</label>
                            <Tree
                                checkable
                                checkedKeys={fieldsData.filterValues}
                                onCheck={(keys) => this.getMultiSelectData(keys, 'filterValues')}
                                showLine={true}
                                treeData={this.treeData(filtersList, "values")}
                            />
                        </div>
                        <div className={'rate-slider'}>
                            <label>Գնահատական {fieldsData.rate}</label>
                            <Slider
                                min={0}
                                max={5}
                                onChange={(value)=>this.getInputValues({
                                    name: 'rate',
                                    value,
                                    haveChanges: this.currentData?.rate !== value
                                })}
                                value={fieldsData.rate}
                                step={0.1}
                            />
                        </div>
                    </div>
                </div>
                <div className={'editors'}>
                    <div className={'editor-wrapper'}>
                        <label>Ապրանքի մասին</label>
                        <Editor value={trData.details || ""}
                                name={'details'}
                                initValue={initTrData.details}
                                error={errors['details' + languageTab]}
                                onChange={this.getTranslatableInputValues}/>
                    </div>
                    <div className={'editor-wrapper'}>
                        <label>Նկարագրություն</label>
                        <Editor value={trData.description || ""}
                                name={'description'}
                                initValue={initTrData.description}
                                error={errors['description' + languageTab]}
                                onChange={this.getTranslatableInputValues}/>
                    </div>
                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'}`}
                                loading={loading}
                                disabled={!this.updatedDataMap.size && isEditing}
                                cb={this.addUpdateProduct}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                mediaType={MEDIA_MODAL_TYPES.MEDIA_ARRAY}
                appendMedias={fieldsData.medias}
                multiSelect={true}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />

        </PageWrapper>
    }
}

const mapDispatchToProps = {
    AddProduct,
    UpdateProduct,
    GetProductById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditProduct)
