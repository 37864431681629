import {AUTH_CONSTS, INSURANCES_CONSTS} from "../constants";


export const initialState = {
    insurancesList: {
        itemsList: [],
        hasMore: false,
        startDate: null,
        endDate: null,
        types: null,
        status: null,
        text: '',
    },
    awaitingInsurancesList: {
        itemsList: [],
        hasMore: false,
        startDate: null,
        endDate: null,
        types: null,
        status: null,
        text: '',
    },
    pendingInsurancesList: {
        itemsList: [],
        hasMore: false,
        startDate: null,
        endDate: null,
        types: null,
        status: null,
        text: '',
    },
    insuranceById: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case INSURANCES_CONSTS.GET_INSURANCES:
            const stateField = action.payload.key;
            return {
                ...state,
                [stateField]: {
                    itemsList: action.payload.reset ? action.payload.data :
                        [...state[stateField].itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                    startDate: action.payload.startDate,
                    endDate: action.payload.endDate,
                    types: action.payload.types,
                    text: action.payload.text,
                    status: action.payload.status
                }
            };
        case INSURANCES_CONSTS.GET_INSURANCES_BY_ID:
            return {
                ...state,
                insuranceById: action.payload
            };
        case INSURANCES_CONSTS.CLEAR_INSURANCES_BY_ID:
            return {
                ...state,
                insuranceById: {...initialState.insuranceById}
            };
        case INSURANCES_CONSTS.CLEAR_HISTORY:
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
