// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/reviews.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";

// Import utils
import {reviewListHeaderInfo, reviewsDataParser} from "../../utils/listDataHelpers/review";
import {GetReviews, GetNotSeenReviewsCount, DeleteReview, setPageOffset} from "../../redux/actions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";


class Reviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ''
        };
        this.listRef = React.createRef();
        this.getSearchValue = this.getSearchValue.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.deleteReview = deleteItem.bind(this, props.DeleteReview, 'մեկնաբանությունը');
    }

    componentDidMount() {
        const {pageOffset} = this.props;
        // if (pageOffset.pageName==='reviews' && pageOffset.offset) {
        //     this.listRef.current.scrollTop = pageOffset.offset
        //     setPageOffset(null);
        // } else {
        // }
            this.props.GetReviews();

        // this.props.GetNotSeenReviewsCount();
    }

    getSearchValue(text) {
        this.setState({
            text
        }, () => !text && this.searchItems())

    }

    searchItems() {
        const {text} = this.state;
        (!text || text?.length > 1) && this.props.GetReviews({
            text,
        });
    }

    loadMoreItems() {
        const {reviews} = this.props;
        const {hasMore} = reviews;
        hasMore && this.props.GetReviews({
            reset: false,
            offset: reviews?.itemsList?.length,
        });
    };


    render() {
        const {text} = this.state;
        const {reviews, requestLoading} = this.props;

        const count = reviews?.itemsList?.length;
        const itemListInfo = reviewsDataParser(reviews, this.deleteReview);

        return <PageWrapper pageTitle={'Մեկնաբանություններ'}>
            <section className="reviews">

                <PageHeader renderingItemsTitleWithCount={`Մեկնաբանությունների քանակ ${count}`}
                            search={true}
                            getSearchValue={this.getSearchValue}
                            searchItems={this.searchItems}
                            searchValue={text}/>
                <ItemsList itemListHeaderInfo={reviewListHeaderInfo}
                           itemListInfo={itemListInfo}
                           loading={requestLoading}
                           detailsLink={"reviews/review"}
                           infiniteScroll={true}
                           listRef={this.listRef}
                           hasMore={reviews?.hasMore}
                           loadMoreItems={this.loadMoreItems}
                           useWindow={false}/>
            </section>

        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetReviews,
    DeleteReview,
    GetNotSeenReviewsCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reviews)
