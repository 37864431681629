// Import packages
import React from "react";
import {Route, Switch, Router, Redirect} from "react-router-dom";

// Import utils
import {history} from "../configs/history";
import {ProtectedRoute} from "./protectedRoute";

// Import pages
import AdminLayout from '../containers/AdminLayout';
import Login from '../containers/Login';
import Dashboard from '../containers/Dashboard';
import UserDetails from '../containers/users/UserDetails';

import Moderators from '../containers/Moderators/Moderators';

import AddEditModerator from "../containers/Moderators/AddEditModerator";

import Media from "../containers/media/Media";
import EditFile from "../containers/media/EditFile";

import NotFound from '../containers/NotFound';
import Users from "../containers/users/Users";
import Products from "../containers/product/Products";
import AddEditProduct from "../containers/product/AddEditProduct";
import Category from "../containers/category/Category";
import AddEditCategory from "../containers/category/AddEditCategory";
import Orders from "../containers/orders/Orders";
import Status from "../containers/status/Status";
import AddEditStatus from "../containers/status/AddEditStatus";
import Brands from "../containers/brands/Brands";
import AddEditBrand from "../containers/brands/AddEditBrand";
import Section from "../containers/section/Section";
import AddEditSection from "../containers/section/AddEditSection";
import Languages from "../containers/languages/Languages";
import EditLanguage from "../containers/languages/EditLanguage";
import Reviews from "../containers/reviews/Reviews";
import ReviewDetails from "../containers/reviews/ReviewDetails";
import Insurances from "../containers/insurances/Insurances";
import InsuranceDetails from "../containers/insurances/InsuranceDetails";
import Filters from "../containers/filters/Filters";
import AddFilter from "../containers/filters/AddFilter";
import EditFilter from "../containers/filters/EditFilter";
import OrderDetailsWrapper from "../containers/orders/OrderDetailsWrapper";
import Notifications from "../containers/notification/Notifications";
import Shops from "../containers/shop/Shop";
import News from "../containers/news/News";
import AddEditNews from "../containers/news/AddEditNews";
import Companies from "../containers/companies/Companies";
import EditCompany from "../containers/companies/EditCompany";
import StaticTexts from "../containers/staticTexts/StaticTexts";
import CompanyDetails from "../containers/companies/CompanyDetails";
import Directions from "../containers/directions/Directions";
import AddEditDirection from "../containers/directions/AddEditDirection";
import AddEditBranch from "../containers/companies/AddEditBranch";
import Transactions from "../containers/transactions/Transactions";
import BankTransactions from "../containers/bankTransactions/BankTransactions";
import TermsConditions from "../containers/TermsConditions";
import Facilities from "../containers/facilities/Facilities";
import AddEditFacility from "../containers/facilities/AddEditFacility";

export default () => {

    return <Router history={history} >
        <Switch>
            <Redirect exact from="/" to="/login"/>
            <Route exact path="/login" component={Login}/>
            <AdminLayout>
                <Switch>
                    <Route exact path="/dashboard" component={Dashboard}/>

                    <ProtectedRoute exact path="/users" component={Users}/>
                    <Route exact path="/users/:id" component={UserDetails}/>

                    <ProtectedRoute exact path="/products" component={Products}/>
                    <ProtectedRoute exact path="/products/add" component={AddEditProduct}/>
                    <ProtectedRoute exact path="/products/edit/:id" component={AddEditProduct}/>

                    <ProtectedRoute exact path="/categories" component={Category}/>
                    <ProtectedRoute exact path="/categories/add" component={AddEditCategory}/>
                    <ProtectedRoute exact path="/categories/edit/:id" component={AddEditCategory}/>

                    <ProtectedRoute exact path="/facilities" component={Facilities}/>
                    <ProtectedRoute exact path="/facilities/add" component={AddEditFacility}/>
                    <ProtectedRoute exact path="/facilities/edit/:id" component={AddEditFacility}/>

                    <ProtectedRoute exact path="/filters" component={Filters}/>
                    <ProtectedRoute exact path="/filters/add" component={AddFilter}/>
                    <ProtectedRoute exact path="/filters/edit/:id" component={EditFilter}/>

                    <ProtectedRoute exact path="/moderators" component={Moderators}/>
                    <ProtectedRoute exact path="/moderators/add" component={AddEditModerator}/>
                    <ProtectedRoute exact path="/moderators/edit/:id" component={AddEditModerator}/>

                    <ProtectedRoute exact path="/reviews" component={Reviews}/>
                    <ProtectedRoute exact path="/reviews/review/:id" component={ReviewDetails}/>

                    <ProtectedRoute exact path="/media" component={Media}/>
                    <ProtectedRoute exact path="/media/edit/:id" component={EditFile}/>

                    <ProtectedRoute exact path="/notifications" component={Notifications}/>

                    <ProtectedRoute exact path="/orders" component={Orders}/>
                    <ProtectedRoute exact path="/orders/order/:id" component={OrderDetailsWrapper}/>

                    <ProtectedRoute exact path="/status" component={Status}/>
                    <ProtectedRoute exact path="/status/add" component={AddEditStatus}/>
                    <ProtectedRoute exact path="/status/edit/:id" component={AddEditStatus}/>

                    <ProtectedRoute exact path="/brands" component={Brands}/>
                    <ProtectedRoute exact path="/brands/add" component={AddEditBrand}/>
                    <ProtectedRoute exact path="/brands/edit/:id" component={AddEditBrand}/>

                    <ProtectedRoute exact path="/section" component={Section}/>
                    <ProtectedRoute exact path="/section/add" component={AddEditSection}/>
                    <ProtectedRoute exact path="/section/edit/:id" component={AddEditSection}/>

                    <ProtectedRoute exact path="/insurances" component={Insurances}/>
                    <ProtectedRoute exact path="/insurances/:id" component={InsuranceDetails}/>

                    <ProtectedRoute exact path="/companies" component={Companies}/>
                    <ProtectedRoute exact path="/companies/company/:id" component={EditCompany}/>
                    <ProtectedRoute exact path="/companies/company-details/:id" component={CompanyDetails}/>
                    <ProtectedRoute exact path="/companies/:id/branch/add" component={AddEditBranch}/>
                    <ProtectedRoute exact path="/companies/:id/branch/:branchId" component={AddEditBranch}/>

                    <ProtectedRoute exact path="/languages" component={Languages}/>
                    <ProtectedRoute exact path="/languages/edit/:id" component={EditLanguage}/>

                    <ProtectedRoute exact path="/News" component={News}/>
                    <ProtectedRoute exact path="/News/add" component={AddEditNews}/>
                    <ProtectedRoute exact path="/News/edit/:id" component={AddEditNews}/>

                    <ProtectedRoute exact path="/directions" component={Directions}/>
                    <ProtectedRoute exact path="/directions/add" component={AddEditDirection}/>
                    <ProtectedRoute exact path="/directions/edit/:id" component={AddEditDirection}/>

                    <ProtectedRoute exact path="/transactions" component={Transactions}/>

                    <ProtectedRoute exact path="/bankTransactions" component={BankTransactions}/>

                    <ProtectedRoute exact path="/shops" component={Shops}/>

                    <ProtectedRoute exact path="/termsConditions" component={TermsConditions}/>

                    <ProtectedRoute exact path="/staticTexts" component={StaticTexts}/>

                    <Route exact path="/404" component={NotFound}/>
                    <Route component={NotFound}/>
                </Switch>
            </AdminLayout>
        </Switch>
    </Router>
}
