import {PARTNER_CONSTS} from "../constants";

export const initialState = {
    partnersList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PARTNER_CONSTS.GET_PARTNERS:
            return {
                ...state,
                partnersList: action.payload || [],
            };
        case PARTNER_CONSTS.CREATE_PARTNER:
            return {
                ...state,
                partnersList: [action.payload].concat(state.partnersList)
            };
        case PARTNER_CONSTS.UPDATE_PARTNER_BY_ID:
            return {
                ...state,
                partnersList: state.partnersList.map(item => item.id === action.payload.id
                    ? action.payload : item)
            };
        case PARTNER_CONSTS.DELETE_PARTNER_BY_ID:
            return {
                ...state,
                partnersList: state.partnersList.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
