// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch} from "antd";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {ColorPicker} from "../../components/uiElements/ColorPicker";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreateStatus, UpdateStatusById} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";


class AddEditStatus extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                color: '#ffffff',
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.addEditStatus = this.addEditStatus.bind(this);
    }

    async componentDidMount() {
        const {isEditing} = this.state;
        const translationsData = {};

        if (isEditing) {
            const editingData = this.props?.statusList?.find(item => item.id === this.props.id);
            if (editingData) {
                editingData.translations.forEach(item => {
                    translationsData[item.languageId] = {
                        ...translationsData[item.languageId],
                        title: item.title || '',
                    };
                });

            }
            const initStateData = {
                translations: translationsData,
                color: editingData?.color,
                isHidden: editingData?.isHidden,
            };
            this.currentData = JSON.parse(JSON.stringify({
                ...initStateData,
                isBasic: editingData?.isBasic
            }));
            this.setState({
                ...this.state,
                fieldsData: initStateData
            });

        }
    }

    addEditStatus() {
        this.setState({
            requestLoading: true
        });
        const {translations, color, isHidden} = this.state.fieldsData;
        const translationRequiredFields = ['title']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (result || this.currentData?.isBasic) {
            const trData = getTranslationData(translations)
            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    translations: trData,
                };
                color && (reqData.color = color);
                this.props.CreateStatus(reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    })
            } else {
                let reqData = this.getPatchReqData(trData);
                if (this.currentData?.isBasic){
                    reqData={
                        color
                    }
                }
                console.log(reqData);
                this.props.UpdateStatusById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {mainLanguage} = this.props;
        const {fieldsData, errors, languageTab, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};


        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Կարգավիճակ' : 'Փոփոխել Կարգավիճակը'}>
            <section className="general-add-edit">
                {!this.currentData?.isBasic && <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>}
                <ColorPicker color={fieldsData.color}
                             initColor={this.currentData?.color}
                             onChange={this.getInputValues}/>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <InputGroup inputType="input"
                            type="text"
                            label="Անվանում"
                            placeholder="Անվանում"
                            name="title"
                            disabled={isEditing && this.currentData?.isBasic}
                            value={currentData.title}
                            initValue={initData.title}
                            error={errors['title' + languageTab]}
                            maxLength={100}
                            required={true}
                            onChange={this.getTranslationValues}/>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditStatus}/>
                </div>

            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateStatus,
    UpdateStatusById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditStatus)
