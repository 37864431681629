export const ModeratorAddingList = [
    {
        label: "Անուն*",
        inputType: "input",
        type: "text",
        name: "firstName",
        maxLength: 50
    },
    {
        label: "Ազգանուն*",
        inputType: "input",
        type: "text",
        name: "lastName",
        maxLength: 50
    },
    {
        label: "Էլ․ հասցե*",
        inputType: "input",
        type: "text",
        name: "username",
        maxLength: 50
    },
];
