// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/transactions.scss'
// Import components
import {InputGroup} from "../../components/uiElements/inputGroup";
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import ItemsList from "../../components/ItemsList";
import PageHeader from "../../components/pageContentViews/pageHeader";
import {DatePicker} from "antd";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetTransactions, GetBranches, ClearTransactions} from "../../redux/actions";
import {
    TRANSACTION_SOURCE_TYPES,
    TRANSACTION_STATUSES_TYPES,
    TRANSACTIONS_TYPES
} from "../../constants/constTypes";
import {disabledEndDate, disabledStartDate} from "../../utils/helperFunctions";
import moment from "moment";
import {history} from "../../configs/history";

const dateFormat = 'DD.MM.YYYY';

const statusOptions = Object.keys(TRANSACTION_STATUSES_TYPES).map(key => {
    return {
        id: TRANSACTION_STATUSES_TYPES[key].key,
        name: TRANSACTION_STATUSES_TYPES[key].name
    }
})
const typeOptions = Object.keys(TRANSACTIONS_TYPES).map(key => {
    return {
        id: TRANSACTIONS_TYPES[key].key,
        name: TRANSACTIONS_TYPES[key].name
    }
})
const sourceOptions = Object.keys(TRANSACTION_SOURCE_TYPES).map(key => {
    return {
        id: TRANSACTION_SOURCE_TYPES[key].key,
        name: TRANSACTION_SOURCE_TYPES[key].name
    }
})

class Transactions extends Component {
    constructor() {
        super();
        this.state = {
            text: '',
            transactionNumber: '',
            cardNumber: ''
        };
        this.listRef = React.createRef();

        this.getInputValues = this.getInputValues.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.getSearchValue = this.getSearchValue.bind(this);
    }

    componentDidMount() {
        this.props.GetTransactions()
    }

    componentWillUnmount() {
        this.props.ClearTransactions()
    }

    handleChangeData(name, value) {
        if (name === 'company') {
            value && this.props.GetBranches(value)
            this.searchItems({[name]: value, branch: undefined})
        } else {
            this.searchItems({[name]: value})
        }
    }

    getSearchValue(text) {
        this.setState({
            text
        }, () => !text && this.searchItems({text}))
    }

    searchItems(updatedData = {}) {
        const {
            text,
            startDate,
            endDate,
            type,
            branch,
            status,
            source,
            cardNumber,
            transactionNumber,
            company
        } = this.props.transactionList;
        let filterData = {
            startDate, endDate, text, type, branch, status, source, cardNumber, transactionNumber, company,
            ...updatedData,
        };
        if (filterData?.text && filterData.text?.length === 1) {
            return;
        }
        this.props.GetTransactions(filterData);
    }

    async getInputValues({name, value}) {
        await this.setState({
            [name]: value,
        });
        // console.log(name, 'name');
        if (name === 'transactionNumber' && value) {
            if (value.length > 13) {
                this.handleChangeData(name, value)
            }
        } else if (name === 'cardNumber' && value) {
            if (value.length > 7) {
                this.handleChangeData(name, value)
            }
        } else {
            this.handleChangeData(name, value)
        }

    }

    async loadMoreItems() {
        const {transactionList} = this.props;

        transactionList.hasMore && this.searchItems({
            reset: false,
            offset: transactionList.itemsList.length,
        });
    };

    render() {
        const {text, transactionNumber, cardNumber} = this.state;
        const {transactionList, requestLoading, branchList, companiesList} = this.props;

        const hasMore = transactionList?.hasMore;
        const {startDate, endDate, type, status, source, branch, company} = this.props.transactionList;

        const branchOptions = branchList?.map(item => {
            return {
                id: item?.id,
                name: item?.name
            }
        })
        const companyOptions = companiesList?.itemsList?.map(item => {
            return {
                id: item?.id,
                name: item?.name
            }
        })

        const itemsInfo = transactionList?.itemsList?.map(item => {
            return {
                id: item.id,
                status: {
                    title: TRANSACTION_STATUSES_TYPES?.[item?.status]?.name || '',
                    color: TRANSACTION_STATUSES_TYPES?.[item?.status]?.color || '',
                },
                values: [
                    item?.transactionNumber || '-',
                    item?.branch?.name || '-',
                    item?.user?.name ? <div className={'username'} onClick={() => {
                        history.push({
                            pathname: `/users/${item?.user?.id}`,
                        })
                    }}>{`${item?.user?.name} ${item?.user?.surname}`}</div> : '-',
                    item?.cardNumber || '-',
                    TRANSACTIONS_TYPES[item?.type]?.name || '-',
                    TRANSACTION_SOURCE_TYPES[item?.source]?.name || '-',
                    item?.quantity ?? '-',
                    item?.amount ?? '-',
                    <div className={'bonus'} style={{
                        color: TRANSACTIONS_TYPES?.[item?.type]?.key === TRANSACTIONS_TYPES.SPEND.key ? '#ef5151': '#2ECC71'
                    }}>
                        {TRANSACTIONS_TYPES?.[item?.type]?.key === TRANSACTIONS_TYPES.SPEND.key ?
                            Number.isInteger(item?.amount) ? `-${item?.amount || 0}` : `-${item?.amount?.toFixed(2) || 0}`
                            : Number.isInteger(item?.bonus) ? `+${item?.bonus || 0}` : `+${item?.bonus?.toFixed(2) || 0}`}
                    </div>,
                    Number.isInteger(item?.commission)
                        ?  item?.commission
                        : item?.commission?.toFixed(2) ?? '-',

                    Number.isInteger(item?.transfer)




                        ?  TRANSACTIONS_TYPES[item?.type]?.key === TRANSACTIONS_TYPES.SPEND.key ? '-' : item?.transfer
                        : item?.transfer?.toFixed(2) ?? '-',
                    Number.isInteger(item?.addedDebt) ? item?.addedDebt : item?.addedDebt?.toFixed(2) ?? 0,
                    item?.createdAt ? moment(item?.createdAt).format("DD.MM.YY / HH:mm") : '-',
                ],
            }
        });
        const transactionsListHeaderInfo = {
            generalInfo: [
                "Գործարքի համարը", "Մասնաճյուղ","Օգտատեր", "Քարտի համար", "Տեսակ", "Աղբյուր", "Քանակ", "Գումար",
                "Բոնուս", "Միջնորդավճար", "Գանձում", "Պարտք", "Ամսաթիվ",
            ],
            status: true,
            largeItems: [0,2,12],
        };

        return <PageWrapper pageTitle={'Գործարքներ'}>
            <section className="transactions">
            <PageHeader
                getSearchValue={this.getSearchValue}
                searchItems={() => this.searchItems({text})}
                searchValue={text}
                search={true}
                renderingCustomLeftPart={<div className="history-filters-date-part">
                    <InputGroup
                        inputType={'input'}
                        type={'text'}
                        placeholder={'Գործարքի համարը'}
                        name={'transactionNumber'}
                        value={transactionNumber}
                        maxLength={50}
                        onChange={this.getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={'text'}
                        placeholder={'Քարտի համար'}
                        name={'cardNumber'}
                        value={cardNumber}
                        maxLength={12}
                        onChange={this.getInputValues}
                    />
                    <InputGroup inputType={"wrapper"}>
                        <DatePicker
                            // value={startDate}
                            format={dateFormat}
                            showToday={false}
                            allowClear={true}
                            disabledDate={(date) => disabledStartDate(date, endDate)}
                            placeholder="Սկիզբ"
                            className={`date-picker`}
                            onChange={(value) =>
                                this.handleChangeData('startDate', value)}>
                        </DatePicker>
                    </InputGroup>
                    <InputGroup inputType={"wrapper"}>
                        <DatePicker
                            // value={endDate}
                            format={dateFormat}
                            showToday={false}
                            allowClear={true}
                            disabledDate={(date) => disabledEndDate(date, startDate)}
                            placeholder="Ավարտ"
                            className={`date-picker`}
                            onChange={(value) =>
                                this.handleChangeData('endDate', value)}>
                        </DatePicker>
                    </InputGroup>
                </div>}
                filters={<div className="history-filters-wrapper">
                    <div className="history-filters-part">
                        <InputGroup inputType="selectCustom"
                                    placeholder="Տեսակ"
                                    name="type"
                                    showSearch={false}
                                    value={type || undefined}
                                    withClear={true}
                                    onChange={({value}) => this.handleChangeData('type', value)}
                                    options={typeOptions}/>
                        <InputGroup inputType="selectCustom"
                                    placeholder="Կարգավիճակ"
                                    name="status"
                                    showSearch={false}
                                    value={status || undefined}
                                    withClear={true}
                                    onChange={({value}) => this.handleChangeData('status', value)}
                                    options={statusOptions}/>
                        <InputGroup inputType="selectCustom"
                                    placeholder="Աղբյուր"
                                    name="source"
                                    showSearch={false}
                                    value={source || undefined}
                                    withClear={true}
                                    onChange={({value}) => this.handleChangeData('source', value)}
                                    options={sourceOptions}/>
                        <InputGroup inputType="selectCustom"
                                    placeholder="Կազմակերպություն"
                                    name="company"
                                    showSearch={false}
                                    value={company || undefined}
                                    withClear={true}
                                    onChange={({value}) => this.handleChangeData('company', value)}
                                    options={companyOptions}/>
                        <InputGroup inputType="selectCustom"
                                    placeholder="Մասնաճյուղ"
                                    name="branch"
                                    disabled={!company}
                                    showSearch={false}
                                    value={branch || undefined}
                                    withClear={true}
                                    onChange={({value}) => this.handleChangeData('branch', value)}
                                    options={branchOptions}/>
                    </div>

                </div>}
            />
            <ItemsList itemListInfo={itemsInfo}
                       itemListHeaderInfo={transactionsListHeaderInfo}
                       listRef={this.listRef}
                       loading={requestLoading}
                       infiniteScroll={true}
                       hasMore={hasMore}
                       loadMoreItems={this.loadMoreItems}
            />
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetTransactions,
    GetBranches,
    ClearTransactions
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)
