import {DIRECTION_CONSTS} from "../constants";

export const initialState = {
    directionList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case DIRECTION_CONSTS.GET_DIRECTION_LIST:
            return {
                ...state,
                directionList: action.payload || [],
            };
        case DIRECTION_CONSTS.CREATE_DIRECTION:
            return {
                ...state,
                directionList: [action.payload].concat(state.directionList)
            };
        case DIRECTION_CONSTS.UPDATE_DIRECTION_BY_ID:
            return {
                ...state,
                directionList: state.directionList.map(item => item.id === action.payload?.id ? action.payload : item)
            };
        case DIRECTION_CONSTS.DELETE_DIRECTION_BY_ID:
            return {
                ...state,
                directionList: state.directionList.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
