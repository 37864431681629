// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Checkbox, Col, Icon, Row, Switch} from "antd";
import swal from "sweetalert";

// Import styles
import "../../assets/styles/components/add-moderator.scss";

// Import components
import PageHeader from "../../components/pageContentViews/pageHeader";
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {LinkButton} from "../../components/buttons/buttons";
import {InputGroup} from "../../components/uiElements/inputGroup";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {EditModerator, AddModerator} from "../../redux/actions/moderatorsActions";
import {ModeratorAddingList} from "../../constants/moderatorAddingList";
import {getInputValues, toggleStateField} from "../../utils/helperFunctions";
import {validateData} from "./utils/actionFunctions";
import {ACCESS_PAGES} from "../../constants/accessPages";

class AddEditModerator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fieldsData: {
                firstName: "",
                lastName: "",
                username: "",
                password: "",
                accessPages: [],
                isBlocked: false
            },
            isEditing: !!props?.id,
            passwordIsShown: false,
        };
        this.editingData = null;
        this.errorsSet = new Set();
        this.updatedDataMap = new Map();
        this.getInputValues = getInputValues.bind(this);
        this.validateData = validateData.bind(this);
        this.changePassVisibility = toggleStateField.bind(this, 'passwordIsShown');
        this.getMultiSelectValues = this.getMultiSelectValues.bind(this);
        this.AddUpdateModerator = this.AddUpdateModerator.bind(this);
    }

    componentDidMount() {
        const {moderatorsList, id} = this.props;
        const {isEditing, fieldsData} = this.state;

        if (isEditing) {

            const moderatorData = id && moderatorsList?.itemsList?.find(m => m.id === id);
          //  console.log('moderatorData', moderatorData)
            this.editingData = {...moderatorData};
            if (moderatorData) {
                const userData = {};
                Object.keys(fieldsData).forEach(field => {
                    userData[field] = moderatorData[field] ?? '';
                });
                this.setState({
                    fieldsData: userData
                })
            }

        }
    }

    async AddUpdateModerator() {
        await this.setState({loading: true});
        const {fieldsData, isEditing} = this.state;
        const errors = {
            requiredFields: ["username", 'firstName', 'lastName',],
        };
        if (!isEditing || fieldsData.password) {
            errors.requiredFields.push('password',);
        }
        const validationResult = this.validateData(errors, fieldsData);
        if (validationResult) {
            if (isEditing) {
                const reqData = Object.fromEntries(this.updatedDataMap);
             //   console.log(reqData);
                this.props.EditModerator(this.editingData.id, reqData).catch((error) => {
                    if (error && error.response && error.response.status === 409) {
                        swal({
                            title: "Չհաջողվեց!",
                            text: "Էլ․ հասցեն զբաղված է",
                            icon: "error",
                            button: "Լավ",
                        })
                    }
                }).finally(() => {
                    this.setState({loading: false});
                })
            } else {
                this.props.AddModerator(fieldsData).catch((error) => {
                    if (error && error.response && error.response.status === 409) {
                        swal({
                            title: "Չհաջողվեց!",
                            text: "Էլ․ հասցեն զբաղված է",
                            icon: "error",
                            button: "Լավ",
                        })
                    }
                }).finally(() => {
                    this.setState({loading: false});
                })
            }
        } else {
            this.setState({loading: false});
        }
    }

    getMultiSelectValues(name, values) {
        const {isEditing} = this.state;
        let haveChanges = false;
        if (isEditing) {
            haveChanges = (this.editingData?.[name]?.length !== values.length)
                || (this.editingData?.[name].some(page => !values.some(_page => _page === page)));
        }
        this.getInputValues({
            name: name,
            value: values,
            haveChanges,
        })
    }

    render() {
        const {requestLoading} = this.props;
        const {fieldsData, passwordIsShown, isEditing} = this.state;
        const checkbox = <div className="right-side">
            <label>{!fieldsData.isBlocked ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
            <Switch checked={!fieldsData.isBlocked}
                    onChange={(checked) => this.getInputValues({
                        name: "isBlocked",
                        value: !checked,
                        haveChanges: this.editingData?.isBlocked !== !checked
                    })}/>
        </div>;
        return <PageWrapper>
            <PageHeader pageTitle={`${isEditing ? 'Փոփոխել Մոդերատորին' : 'Ավելացնել Մոդերատոր'} `}
                        rightTopProps={checkbox}/>
            {ModeratorAddingList.map((field, index) => {
                return <InputGroup {...field}
                                   key={index}
                                   value={fieldsData[field.name]}
                                   initValue={this.editingData?.[field.name]}
                                   error={this.errorsSet.has(field.name)}
                                   onChange={this.getInputValues}/>
            })}
            <div className={'password-wrapper'}>
                <InputGroup value={fieldsData.password}
                            label={"Գաղտնաբառ*"}
                            inputType={"input"}
                            type={!passwordIsShown ? "password" : 'text'}
                            name={"password"}
                            maxLength={20}
                            autocomplete={'new-password'}
                            error={this.errorsSet.has('password')}
                            onChange={this.getInputValues}>
                </InputGroup>
                <span className={`visibility-icon ${passwordIsShown ? "visible" : ''}`}
                      onClick={this.changePassVisibility}>
                                <Icon type={`${passwordIsShown ? "eye" : "eye-invisible"}`}/>
                            </span>
            </div>
            <div className="moderator-services-wrapper">
                <h3>Հասանելի Էջեր</h3>
                <div className="service-checkboxes">
                    <Checkbox.Group
                        onChange={this.getMultiSelectValues.bind(this, 'accessPages')}
                        value={fieldsData.accessPages}>
                        <Row>
                            {Object.keys(ACCESS_PAGES).map((key) => {
                                const page = ACCESS_PAGES[key];
                                return !page.isHidden && <Col span={8} key={page?.key}>
                                    <Checkbox value={page?.key}>
                                        {page?.name}
                                    </Checkbox>
                                </Col>
                            })}
                        </Row>
                    </Checkbox.Group>
                </div>
            </div>
            <div className="flex-wrapper-right mt-30">
                <LinkButton cb={this.AddUpdateModerator}
                            loading={requestLoading}
                            disabled={!this.updatedDataMap.size && isEditing}
                            title={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'}`}/>
            </div>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    EditModerator,
    AddModerator
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditModerator);
