export const HISTORY_CONSTS = {
    GET_HISTORY_BY_MODERATOR_ID: "GET_HISTORY_BY_MODERATOR_ID",
    GET_HISTORY_BY_COMPANY_ID: "GET_HISTORY_BY_COMPANY_ID",
    GET_HISTORY_BY_STATION_ID: "GET_HISTORY_BY_STATION_ID",
    GET_HISTORY_BY_USER_ID: "GET_HISTORY_BY_USER_ID",

    GET_HISTORY: "GET_HISTORY",

    CLEAR_HISTORY: 'CLEAR_HISTORY',
    CLEAR_BY_ID_HISTORY: 'CLEAR_BY_ID_HISTORY'
};
