//import Packages
import React, {useEffect, useRef, useState} from "react";
import debounce from 'lodash.debounce';
import Modal from 'react-modal';
import {connect} from "react-redux";
import {
    YMaps,
    Map,
    Placemark,
    GeolocationControl,
    ZoomControl,
    SearchControl,

} from 'react-yandex-maps';
//import assets
import "./addressMapModal.scss";
import {ReactComponent as ClearIcon} from "../../../assets/images/ic_clear.svg";

//import Components
import {LinkButton} from "../../buttons/buttons";
import {InputGroup} from "../inputGroup";

//import utils
import {MAP_DEFAULT_CENTER, MAP_DEFAULT_ZOOM} from "../../../constants/acceptedConsts";
import {mapStateToProps} from "../../../redux/mapStateToProps";


function AddressMapModal(props) {
    const {isOpen, selectedLanguage} = props;
    const yandexSearch = useRef(null);
    const ymaps = useRef(null);
    const mapRef = useRef(null);
    let initialRef = useRef(debounce(autoCompleteAddress, 200));

    //STATE
    const [address, setAddress] = useState({
        mainAddress: props?.address?.mainAddress || "",
        lat: '',
        lng: '',
    });
    const [suggestions, setSuggestions] = useState([]);
    const [correctAddress, setCorrectAddress] = useState(true);
    const [loading, setLoading] = useState(false);
    const [suggestionsAreShow, setSuggestionsAreShow] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setAddress({
            mainAddress: props?.fieldsData?.address,
            lat: props?.fieldsData?.latitude,
            lng: props?.fieldsData?.longitude,
        })
    }, []);

    //FUNCTIONS
    function closeModal() {
        props.closeModal()
        setAddress({
            mainAddress: props?.fieldsData?.address,
            lat: props?.fieldsData?.latitude,
            lng: props?.fieldsData?.longitude,
        })
    }

    function onChange(locaton) {
        let center = {
            lat: locaton?.[0],
            lng: locaton?.[1]
        }
        getAddressFromLatLng(center);
    }

    function getAddressFromLatLng(location) {
        setAddress({
            mainAddress: address.mainAddress,
            lat: location.lat,
            lng: location?.lng,
        });

        getAddress([[location?.lat, location?.lng]])
    }

    function handleChangeAddress({value}) {
        setAddress({
            ...address,
            mainAddress: value
        });
        initialRef.current(value)

        setCorrectAddress(false)
        setErrors({
            mainAddress: false
        })
    }

    function autoCompleteAddress(value) {
        let suggestionsList = [];
        // console.log(ymaps?.current?.suggest, 'ymaps');
        ymaps.current.suggest && ymaps.current.suggest(value).then((res) => {
            console.log(res, 'res');
            suggestionsList = res?.map(item => {
                return {description: item?.value}
            })
            setSuggestions(suggestionsList)
            setLoading(true)
        })
    }

    function selectSuggestion(suggestion) {
        yandexSearch?.current?.search && yandexSearch.current.search(suggestion.description).then(async () => {
            let geoObjectsArray = await yandexSearch?.current?.getResultsArray?.();

            if (geoObjectsArray?.length) {
                let cord = geoObjectsArray[0]?.geometry?._coordinates
                setAddress({
                    ...address,
                    mainAddress: suggestion.description,
                    lat: cord?.[0],
                    lng: cord?.[1],
                });
            }
        }).catch(errors => console.log('errors', errors))
        setSuggestions([]);
        setCorrectAddress(true)
    }

    const getAddress = (coords) => {
        ymaps.current.geocode(coords).then((res) => {
            const firstGeoObject = res.geoObjects.get(0);
            const newAddress = [
                firstGeoObject.getLocalities().length
                    ? firstGeoObject.getLocalities()
                    : firstGeoObject.getAdministrativeAreas(),
                firstGeoObject.getThoroughfare() || firstGeoObject.getPremise()
            ]
                .filter(Boolean)
                .join(", ");

            setAddress({
                ...address,
                lat: coords?.[0]?.[0],
                lng: coords?.[0]?.[1],
                mainAddress: newAddress
            });
        });
        setCorrectAddress(true)
    };

    const onMapClick = (e) => {
        const coords = e.get("coords");
        getAddress([coords]);
        setErrors({
            mainAddress: false
        })
    };

    const validate = () => {
        const err = {};
        let result = true;

        if (!address?.mainAddress) {
            result = false;
            err.mainAddress = true;
        }

        if (!correctAddress) {
            result = false;
            err.mainAddress = true;
        }

        setErrors(err);
        return result;
    };

    function sendAddress() {
        if (validate()) {
            let setData = {
                address: address?.mainAddress,
                latitude: address?.lat,
                longitude: address?.lng,
            }

            // props.validateLocation();
            props.getAddress(setData)
        }
    }

    let defaultAddress = props.fieldsData?.latitude && props.fieldsData?.longitude ? {
        lat: props.fieldsData?.latitude,
        lng: props.fieldsData?.longitude
    } : MAP_DEFAULT_CENTER

    return <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className={'modal-wrapper'}
        closeTimeoutMS={150}

        appElement={document.getElementById('root')}
        overlayClassName={'modal-overlay'}>
        <div className={`address-map-modal`}>
            <div className={'top-part'}>
                <header className={'modal-header'}>
                    <span>Select a Branche Addres</span>
                    <ClearIcon className={"close-icon"} onClick={closeModal}/>
                </header>
                <div className={'main-address'}>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        ref={yandexSearch}
                        name={'mainAddress'}
                        error={errors.mainAddress}
                        value={address?.mainAddress}
                        placeholder={'Address'}
                        maxLength={200}
                        onChange={handleChangeAddress}
                        onFocus={() => {
                            setSuggestionsAreShow(true)
                        }}
                        onBlur={() => {
                            setSuggestionsAreShow(false)
                        }}
                        id={'address-content'}>
                        <ul className={`suggestions-wrapper ${suggestionsAreShow ? 'active' : ''}`}>
                            {
                                suggestions?.length && address?.mainAddress?.length > 1 ? suggestions?.map((item, index) => {
                                        return <li key={index}
                                                   className={'item'}
                                                   onClick={() => selectSuggestion(item)}>
                                            {item?.description}
                                        </li>
                                    })
                                    :
                                    address?.mainAddress?.length > 2 && !correctAddress && loading &&
                                    <li className={'no-result'}>Select a Branch Address</li>
                            }
                        </ul>
                        {errors?.mainAddress &&
                        <li className={'address-error'}>Select a Branch Address</li>}
                    </InputGroup>
                </div>
            </div>
            <div className="map-wrapper">
                <div className={'map'} id={'yandex-map'}>
                    <YMaps query={{
                        apikey: 'c5f70ecb-be1a-4260-9292-9ecafceda596',
                        lang: `hy_HY`,
                        // lang: `${selectedLanguage?.code}_${selectedLanguage?.code?.toUpperCase()}`,
                    }}>
                        <Map
                            state={{
                                center: [address?.lat || defaultAddress?.lat, address.lng || defaultAddress?.lng],
                                width: 500,
                                zoom: MAP_DEFAULT_ZOOM,
                            }}
                            modules={["Placemark", "geocode", "geoObject.addon.balloon", 'suggest']}
                            instanceRef={mapRef}
                            onLoad={(ympasInstance) => {
                                ymaps.current = ympasInstance
                            }}
                            onClick={onMapClick}
                            width={'100%'} height={'100%'}
                        >
                            <Placemark
                                geometry={[address?.lat || defaultAddress?.lat, address.lng || defaultAddress?.lng]}
                                onDragEnd={e => onChange(e.get('target').geometry.getCoordinates())}
                                options={{
                                    draggable: true,
                                    // iconLayout: 'default#image',
                                    // iconImageHref: 'https://app.autonome.am/files/files/resources/ic_map.svg',
                                    // iconImageSize: [30, 42],
                                    // iconImageOffset: [-3, -42]
                                }}

                            />
                            <SearchControl
                                instanceRef={ref => {
                                    yandexSearch.current = ref
                                }}
                                options={{
                                    float: 'right',
                                    noPlacemark: true,
                                    position: {
                                        // top: -350
                                    },
                                    // provider: 'yandex#search',
                                    noSuggestPanel: true,
                                }}
                            />
                            <ZoomControl options={{
                                float: 'right',
                                position: {
                                    top: 50,
                                    right: 10,
                                },
                            }}/>
                            {/*<FullscreenControl/>*/}
                            <GeolocationControl options={{float: 'right'}}/>
                        </Map>
                    </YMaps>
                </div>
            </div>

            <div className="flex-wrapper-left modal-actions">
                <LinkButton
                    // disabled={!correctAddress}
                    title={'Choose'}
                    cb={sendAddress}/>
            </div>
        </div>
    </Modal>
}


const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddressMapModal)
