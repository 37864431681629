import moment from "moment";
import {deleteItem, archiveItem} from "../helperFunctions";
import {ORDER_STATUS_IDS} from "../../constants/constTypes";

export const tabs = [
    {
        name: "Պատվերներ",
        key: "ordersList",
        archived: false,
        isReservation: false,
    },
    {
        name: "Ամրագրումներ",
        key: "reservationsList",
        archived: false,
        isReservation: true,
    },
    {
        name: "Արխիվացված",
        key: "archivedOrders",
        archived: true,
    },
];

export const ordersListHeaderInfo = {
    generalInfo: [
        "Օգտատեր", "Հեռախոս", "Ընդհանուր", "Պատվերի համար", "Ամսաթիվ"
    ],
    status: true,
    largeItems: [],
    actions: ["archive", 'delete'],
};

export function ordersDataParser(ordersList, deleteCb, archiveCb) {
    let itemListInfo = [];
    ordersList.itemsList.forEach(item => {
        const name = item?.name + " " + item?.surname;
        const amount = item.isReservation ? item.reservationAmount:
            (item?.paidByCashAmount + item?.paidByCardAmount + item?.paidByBonusAmount)
        itemListInfo.push({
            id: item.id,
            status: {
                title: item?.status?.title || '',
                color: item?.status?.color || '',
            },
            newItem: !(item?.seen ?? true),
            details: {
                orderById: item,
            },
            archived: item?.archived,
            // onClick: this.setOrderAsSeen.bind(this, item.id),
            values: [
                name || "",
                item?.phone || item?.username,
                amount +" "+ (item?.products?.[0]?.currency ?? "AMD"),
                item?.orderNumber || "-",
                moment(item.createdAt).format('DD.MM.YY, HH:mm'),
            ],
            deleteCb: deleteItem.bind(this, deleteCb, 'պատվերը', item.id, item?.archived),
            archiveCb: archiveItem.bind(this, archiveCb, 'պատվերը', item.id, item?.archived),
            // deleteCb: (item?.status?.id === ORDER_STATUS_IDS.CANCELLED ||
            //     item?.status?.id === ORDER_STATUS_IDS.COMPLETED ||
            //     item?.status?.id === ORDER_STATUS_IDS.REJECTED )
            //     && deleteItem.bind(this, deleteCb, 'պատվերը', item.id, item?.archived),
            // archiveCb: (item?.status?.id === ORDER_STATUS_IDS.CANCELLED ||
            //     item?.status?.id === ORDER_STATUS_IDS.COMPLETED ||
            //     item?.status?.id === ORDER_STATUS_IDS.REJECTED )
            //     && archiveItem.bind(this, archiveCb, 'պատվերը', item.id, item?.archived),
        });
    });
    return itemListInfo;
}
