import React from "react";
import {notification} from "antd";

notification.config({
    placement: 'topRight',
});

export function notificationSuccess({title = null, description = null, duration = 2} = {}) {
    notification.success({
        className: 'notification-success',
        message: title !== null ? title : 'Հաջողվեց!',
        description: description !== null ? description : 'Գործողությունը հաջողությամբ կատարվեց',
        // icon: <img src={require('../../assets/images/ic_notif_success.svg')} alt={''}/>,
        duration: duration,
    })
}

export function notificationError({title = null, description = null, duration = 3,} = {}) {
    notification.error({
        className: 'notification-error',
        message: title !== null ? title : 'Ինչ որ բան այն չէ!',
        description: description !== null ? description : 'Խնդրում ենք  փորձել կրկին',
        // icon: <img src={require('../../assets/images/ic_notif_reject.svg')} alt={''}/>,
        duration: duration,
    })
}
