// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import swal from "sweetalert";

// Import Styles
import '../../assets/styles/containerStyles/order-details.scss';
import {ReactComponent as TickIcon} from "../../assets/images/ic_tick.svg";
import {ReactComponent as TickGreyIcon} from "../../assets/images/ic_tick_grey.svg";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import DetailsHeader from "../../components/uiElements/DetailsHeader";
import ItemsList from "../../components/ItemsList";
import {LinkButton} from "../../components/buttons/buttons";
import {InputGroup} from "../../components/uiElements/inputGroup";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    DeleteOrderById,
    GetNotSeenOrdersCount,
    UpdateOrderStatus,
    ClearOrders,
    SetOrderAsSeen
} from "../../redux/actions";
import {changeStateInputValue} from "../../utils/helperFunctions";
import {ORDER_STATUS_IDS, ORDERS_TYPE} from "../../constants/constTypes";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";

class OrderDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderStatus: undefined,
            orderType: undefined,
            status: undefined,
        };
        this.getInputValues = changeStateInputValue.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
    }


    async componentDidMount() {
        const {singleOrder} = this.props;
        singleOrder && (singleOrder.seen === false) && this.props.SetOrderAsSeen(singleOrder?.id).then(() => {
            this.props.GetNotSeenOrdersCount();
        });

        // this.setState({
        //     orderStatus: singleOrder?.status?.id,
        //     status: singleOrder?.status
        // });
        this.setState(() => ({
            orderStatus: singleOrder?.status?.id,
            status: singleOrder?.status
        }));

        if (singleOrder?.paymentType === ORDERS_TYPE.MIXED.key) {
            if (singleOrder?.paidByCashAmount) {
                this.setState({
                    orderType: ORDERS_TYPE.MIXED.CASH.title
                })
            } else {
                this.setState({
                    orderType: ORDERS_TYPE.MIXED.CREDIT_CARD.title
                })
            }
        } else {
            this.setState(() => ({
                orderType: ORDERS_TYPE[singleOrder?.paymentType]?.title
            }));
            // this.setState({
            //     orderType: ORDERS_TYPE[singleOrder?.paymentType]?.title
            // })
        }
    }

    componentWillUnmount() {
        this.props.ClearOrders();
    }

    deleteOrderProduct = id => {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել ապրանքը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteOrderById(this.props.singleOrder?.id, id);
            }
        });
    };

    updateStatus() {
        const {orderStatus} = this.state;
        const {singleOrder} = this.props;
        const reqData = {
            statusId: orderStatus
        };
        this.props.UpdateOrderStatus(singleOrder?.id, reqData).then((res) => {
            let status = {};
            let item = this.props.statusList.find(item => item.id === orderStatus);
            status.id = item?.id;
            status.color = item?.color;
            status.title = item?.translations.find(tr => tr.languageId === this.props?.mainLanguage)?.title || '';
            this.setState({
                ...this.state,
                status,
            })
        });
    }

    render() {
        const {orderStatus, orderType, status} = this.state;
        const {singleOrder} = this.props;
        const {requestLoading, statusList, mainLanguage} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Նկար", "Անվանում", "Ընդհանուր", "Հին գին", "Նոր գին", "Քանակ", "Նեսթ", "Կուպոն"],
            largeItems: [1, 2, 7],
        };
        let itemListInfo = [];
        let sum = 0;

        singleOrder?.products?.length && singleOrder.products.forEach(item => {
            // let couponPrice = item?.couponPrice ? item.couponPrice + " Դրամ" : "-";
            let total = item?.price ? item.price * item?.purchasedQuantity + " Դրամ" : "-";
            let price = item?.price ? item.price + " Դրամ" : "-";
            let oldPrice = item?.oldPrice ? item.oldPrice + " Դրամ" : "-";
            sum = item?.price + item?.couponPrice;

            itemListInfo.push({
                id: item?.productId,
                orderStatus: item?.status,
                values: [
                    <img src={generateImageMediaUrl(item?.mediaMain?.path)} alt=""/>,
                    item?.title || "-",
                    total,
                    oldPrice,
                    price,
                    item?.purchasedQuantity || "-",
                    <span className="is-nest">{item?.isNestOn ? <TickIcon title={''}/> :
                        <TickGreyIcon title={''}/>}</span>,
                    (item?.oldPrice && item?.price < item?.oldPrice) ? <div className={
                        `get-in-off ${item?.salesType ? item?.salesType : ''} 
                        ${item?.salesType === 'GET_OFF' && item?.isNestOn ? 'disabled' : ''}`
                    }>
                        {
                            item?.salesType !== 'NONE' ? <>
                                    GET {Math.trunc(item?.couponPercent)}% {item?.salesType === 'GET_IN' ? "IN" : "OFF"}
                                    <span>{item?.couponPrice} Դ</span>
                                </>
                                : ''
                        }
                    </div> : '-'
                ],
                deleteCb: this.deleteOrderProduct.bind(this, item?.productId)
            });
        });

        const orderData = {
            id: singleOrder?.id,
            name: (singleOrder?.name || '') + " " + (singleOrder?.surname || ''),
            email: singleOrder?.email || '',
            phone: singleOrder?.phone || '',
            createdAt: singleOrder?.createdAt ? moment(singleOrder?.createdAt).format('DD.MM.YYYY') : '',
            address: singleOrder?.address || '',
            isPaid: orderType,
            userId: singleOrder?.user?.id,
        };

        const statusOptions = [];
        statusList.forEach(status => {
            const title = status.translations.find(tr => tr.languageId === mainLanguage)?.title || '';
            !status.isHidden &&
            statusOptions.push({
                name: title,
                id: status.id,
                disabled: status.id === ORDER_STATUS_IDS.NEW || status.id === ORDER_STATUS_IDS.CANCELED || status.id === ORDER_STATUS_IDS.INITIAL
            })
        });

        return <PageWrapper>
            <section className="order-details">
                <DetailsHeader
                    data={orderData}
                    loading={requestLoading}
                />
                <div className="separator"/>
                <h1 className="orders-header">Պատվիրված ապրանքների ցուցակը</h1>
                <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                           itemListInfo={itemListInfo}
                           loading={requestLoading}
                />
                <div className="down-part">
                    <table className="down-left-part">
                        <tbody>
                       {!!singleOrder?.orderNumber && <tr className={'status'}>
                            <td className="description">Պատվերի համար։</td>
                            <td className="text-bold">
                                {singleOrder?.orderNumber}
                            </td>
                        </tr>}
                        {!!singleOrder?.paidByCashAmount && <tr className={'status'}>
                            <td className="description">Կանխիկ։</td>
                            <td className="text-bold">
                                {singleOrder?.paidByCashAmount} Դրամ
                            </td>
                        </tr>}
                        {!!singleOrder?.paidByCardAmount && <tr className={'status'}>
                            <td className="description">Քարտով։</td>
                            <td className="text-bold">
                                {singleOrder?.paidByCardAmount} Դրամ
                            </td>
                        </tr>}
                        {!!singleOrder?.paidByBonusAmount && <tr className={'status'}>
                            <td className="description">Բոնուսով։</td>
                            <td className="text-bold">
                                {singleOrder?.paidByBonusAmount} Դրամ
                            </td>
                        </tr>}
                        <tr className={'status'}>
                            <td className="description">Կարգավիճակ։</td>
                            <td className="text-bold" style={{color: status?.color}}>
                                {status?.title}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {(singleOrder?.paidByCashAmount ||
                        singleOrder?.paidByCardAmount ||
                        singleOrder?.paidByBonusAmount) && <div
                        className="amount">{singleOrder?.paidByCashAmount + singleOrder?.paidByCardAmount + singleOrder?.paidByBonusAmount + " Դրամ"}</div>}
                </div>
                <div className="status-change-part">
                    <InputGroup
                        inputType="selectCustom"
                        value={orderStatus}
                        placeholder="Ընտրել"
                        name="orderStatus"
                        disabled={
                            status?.id === ORDER_STATUS_IDS.CANCELLED ||
                            status?.id === ORDER_STATUS_IDS.COMPLETED ||
                            status?.id === ORDER_STATUS_IDS.REJECTED}
                        onChange={this.getInputValues}
                        options={statusOptions}
                    />
                    <LinkButton title="Փոփոխել Կարգավիճակը"
                                disabled={orderStatus === status?.id}
                                cb={this.updateStatus}/>
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    DeleteOrderById,
    UpdateOrderStatus,
    GetNotSeenOrdersCount,
    ClearOrders,
    SetOrderAsSeen
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails)
