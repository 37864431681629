import {request, _urlSection} from "../api";
import {SECTION_CONSTS} from "../constants";
import swal from "sweetalert";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetSections = () => {
    const requestData = {
        url: _urlSection,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: SECTION_CONSTS.GET_SECTIONS,
                    payload: data
                });
            })
    }
};

export const GetSectionsById = (id) => {
    const requestData = {
        url: `${_urlSection}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: SECTION_CONSTS.GET_SECTION_BY_ID,
                    payload: data
                });
            })
    }
};


export const DeleteSectionById = id => {
    const requestData = {
        url: `${_urlSection}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SECTION_CONSTS.DELETE_SECTION_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateSection = data => {
    const requestData = {
        url: _urlSection,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SECTION_CONSTS.CREATE_SECTION,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Բաժինը հաջողությամբ ստեղծվեց!"
                })
                history.push('/section')
            })
            .catch(() => {
            })
    }
};

export const UpdateSectionById = (id, data) => {
    const requestData = {
        url: `${_urlSection}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SECTION_CONSTS.UPDATE_SECTION_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Բաժինը հաջողությամբ փոփոխվեց!"
                })
                history.push('/section')
            })
            .catch(() => {
            })
    }
};

export const UpdateSectionsPositions = items => {
    const requestData = {
        url: _urlSection,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SECTION_CONSTS.UPDATE_SECTIONS_POSITIONS
                });
                notificationSuccess({
                    description: "Բաժինը հաջողությամբ թարմացվեց!"
                })
            })
            .catch(() => {
            })
    }
};

export const AddProductsToSection = (bannerId, items) => {
    const requestData = {
        url: `${_urlSection}/${bannerId}/products`,
        token: true,
        method: "POST",
        data: items
    };
    return dispatch => {
        return request(requestData).catch(() => {

        })
    }
};

export const DeleteProductsFromSection = (bannerId, productIds ) => {
    const requestData = {
        url: `${_urlSection}/${bannerId}/products?productIds=${productIds}`,
        token: true,
        method: "DELETE"
    };
    return dispatch => {
        return request(requestData).catch(() => {

        })
    }
};

export const UpdateProductsPositionsInSection = (bannerId, items) => {
    const requestData = {
        url: `${_urlSection}/${bannerId}/products`,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData).catch(() => {

        })
    }
};
