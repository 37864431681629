// Import packages
import React, {Component} from "react";

// Import Styles
import '../assets/styles/components/items-list.scss';
import noDataImage from '../assets/images/ic_empty.svg';

// Import components
import InfiniteScroll from 'react-infinite-scroller';
import ListItemCard from "./cards/ListItemCard";
import {SkeletonItem} from "./uiElements/SkelatonItem";
import {Checkbox} from "antd";

class ItemsList extends Component {

    render() {
        const {
            itemListInfo, remove, details, detailsLink, editLink, selectingActive, allSelected,
            infiniteScroll, hasMore, listRef, useWindow, loading, className,
        } = this.props;

        const {generalInfo, largeItems, actions, status} = this.props.itemListHeaderInfo || {};
        const largeItemsCount = largeItems?.length || 1;
        const defaultWidth = 100 / generalInfo?.length || 100 / itemListInfo?.length;
        const largeItemsWidth = defaultWidth * 1.3;
        const smallItemsWidth = (100 - largeItemsCount * largeItemsWidth) / (generalInfo?.length - largeItemsCount);
        return <div className={`items-list-wrapper ${className ? className : ""}`} ref={listRef}>
            {generalInfo && itemListInfo && !!itemListInfo.length &&
            <div className={`items-list-header`}>
                {selectingActive && <Checkbox checked={allSelected}
                                              onChange={this.props.selectAll}/>}
                <div className="general-info">
                    {!!generalInfo.length && generalInfo.map((item, index) => {
                        const width = (index === 1 || largeItems.includes(index))
                            ? largeItemsWidth
                            : smallItemsWidth;
                        return <div className={"general-info-item"} style={{width: `${width}%`}}
                                    key={index}>{item}</div>
                    })}
                </div>
                <div className="items-header-actions-part">
                    {status && <div className="status">Կարգավիճակ</div>}
                    {actions && !!actions.length &&
                    <div className="actions"> Գործողություններ</div>}
                </div>
            </div>}
            {loading && (!itemListInfo || !itemListInfo.length)
                ? <div className={'items-list-content'}>
                    <SkeletonItem/>
                </div>
                : itemListInfo && !!itemListInfo.length ? (infiniteScroll ? <InfiniteScroll
                        hasMore={hasMore}
                        loadMore={this.props.loadMoreItems}
                        className={'items-list-content'}
                        pageStart={0}
                        useWindow={!!useWindow}
                        initialLoad={false}>
                        {itemListInfo.map((item) => {
                            return <ListItemCard key={item.id ? item.id : item._id}
                                                 data={item}
                                                 status={status}
                                                 actions={actions}
                                                 selectingActive={selectingActive}
                                                 largeItems={largeItems}
                                                 largeItemsWidth={largeItemsWidth}
                                                 smallItemsWidth={smallItemsWidth}
                                                 remove={remove}
                                                 details={details}
                                                 detailsLink={detailsLink}
                                                 editLink={editLink}/>

                        })}
                    </InfiniteScroll>
                    :
                    <div className={'items-list-content'}>
                        {itemListInfo.map((item) => {
                            return <ListItemCard key={item.id ? item.id : item._id}
                                                 data={item}
                                                 status={status}
                                                 actions={actions}
                                                 selectingActive={selectingActive}
                                                 largeItems={largeItems}
                                                 largeItemsWidth={largeItemsWidth}
                                                 smallItemsWidth={smallItemsWidth}
                                                 remove={remove}
                                                 details={details}
                                                 detailsLink={detailsLink}
                                                 editLink={editLink}/>

                        })}
                    </div>) :
                    <div className={'no-items'}>
                        <img src={noDataImage} alt={'no-items'}/>
                    </div>}
        </div>
    }
}

export default ItemsList
