//import icons

import general from '../../../assets/images/file-icons/general.svg';
import pdf from '../../../assets/images/file-icons/pdf.svg';
import video from '../../../assets/images/file-icons/video.svg';
import audio from '../../../assets/images/file-icons/audio.svg';
import zip from '../../../assets/images/file-icons/zip.svg';
import word from '../../../assets/images/file-icons/word.svg';
import ppt from '../../../assets/images/file-icons/ppt.svg';
import excel from '../../../assets/images/file-icons/excel.svg';
import unspecified from '../../../assets/images/file-icons/unspecified.svg';

export const getFileIcon = (extension) => {
    switch (extension) {
        case 'pdf':
            return pdf;
        case 'wmv':
            return general;
        case 'mpeg':
        case 'avi':
        case 'flv':
        case 'mp4':
            return video;
        case 'mp3':
        case 'wav':
            return audio;
        case 'zip':
        case 'rar':
            return zip;
        case 'doc':
        case 'docx':
            return word;
        case 'ppt':
        case 'pptx':
            return ppt;
        default:
            return unspecified;
    }
};
