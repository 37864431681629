import {LANGUAGES_CONSTS} from "../constants";

export const initialState = {
    languages: [],
    activeLanguages: [],
    mainLanguage: '5db99765e2019423d6e0df83'
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LANGUAGES_CONSTS.GET_LANGUAGES:
            return {
                ...state,
                languages: action.payload,
                mainLanguage: action.payload?.find(lg => lg.isMain)?.id || '5db99765e2019423d6e0df83',
                activeLanguages: action.payload?.filter(lg => !lg.isHidden)
            };
        default:
            return state;
    }
}
