import moment from "moment";
import React from "react";
import {history} from "../../../configs/history";
import {ATM_HISTORIES} from "../../../constants/constTypes";

export function parseAtmHistoryData(historyData) {
    return historyData?.itemsList?.map(item => {
        const {transactionNumber, type, user, sum, date, bonuse, bonusCardNumber} = item || {};
        let itemSum = '-'
        if (sum) {
            itemSum = type?.includes('FILLED')
                ?  <span className={'filled'}>+{Number.isInteger(sum) ? sum : sum?.toFixed(2)}</span>
                : <span className={'used'}>-{Number.isInteger(sum) ? sum : sum?.toFixed(2)}</span>;
        }else if(bonuse){
            itemSum = type?.includes('IN')
                ? <span className={'filled'}>+{Number.isInteger(bonuse) ? bonuse : bonuse?.toFixed(2)}</span>
                : <span className={'used'}>-{Number.isInteger(bonuse) ? bonuse : bonuse?.toFixed(2)}</span>;
        }
        return {
            id: item.id,
            values: [
                transactionNumber ?? '-',
                user && user.name ? <div className={'username'} onClick={() => {
                    history.push({
                        pathname: `/users/${user?.id}`,
                    })
                }}>{`${user?.name} ${user?.surname}`}</div> : '-',
                bonusCardNumber || '-',
                ATM_HISTORIES?.[type]?.type ?? '-',
                item?.bonuseType ? item?.bonuseType : ATM_HISTORIES?.[type]?.from ?? '-',
                itemSum,
                moment(date).format("DD.MM.YY / HH:mm"),
            ],
        }
    });
}
// export function parseAtmHistoryData(historyData) {
//     return historyData?.itemsList?.map(item => {
//         const {transactionNumber, type, user, sum, date,} = item || {};
//
//         let itemSum = sum ?  type?.includes('FILLED')
//             ? <span className={'filled'}>{'+'+ sum?.toFixed(0)}</span>
//             : <span className={'used'}>{'-'+ sum?.toFixed(0)}</span> : '-';
//         return {
//             id: item.id,
//             values: [
//                 transactionNumber ?? '-',
//                 ATM_HISTORIES[type]?.type ?? '-',
//                 ATM_HISTORIES[type]?.from ?? '-',
//                 user && user.name ? <div className={'username'} onClick={() => {
//                     history.push({
//                         pathname: `/users/${user?.id}`,
//                     })
//                 }}>{`${user?.name} ${user?.surname}`}</div> : '-',
//                 itemSum,
//                 moment(date).format("DD.MM.YY / HH:mm"),
//             ],
//         }
//     });
// }
