// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";

// Import Styles
import '../../assets/styles/containerStyles/insurance-details.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import {SkeletonSmallItem} from '../../components/uiElements/SkelatonItem'

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";

import {
    ClearUserById,
    GetInsuranceById,
} from "../../redux/actions";
import {INSURANCE_STATUS_TYPES, PAYMENT_TYPE} from "../../constants/constTypes";
import {history} from "../../configs/history";

class InsuranceDetails extends Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        this.props.GetInsuranceById(this.props.id);
    }

    componentWillUnmount() {
        this.props.ClearUserById();
    }

    render() {
        const {insuranceById, requestLoading} = this.props;
        const user = insuranceById?.user;
         let insuranceDate = moment(insuranceById?.endDate).diff(insuranceById?.startDate, 'months') >= 12 ?
             moment(insuranceById?.endDate).diff(insuranceById?.startDate, 'years') + " տարի" :
             moment(insuranceById?.endDate).diff(insuranceById?.startDate, 'months') + " ամիս";
        
        return <PageWrapper>
            <section className="insurance-details">
                <div className="insurance-details-title">Պայմանագիր</div>
                <div className="info-container">
                    <div className="info-section">
                        <div className="section-title">
                            <span className='description'>Օգտատեր։</span>
                            <span className="user" onClick={() => {
                                history.push({
                                    pathname: `/users/${user?.id}`,
                                })
                            }}>
                                {`${user?.name || ""} ${user?.surname || ""}`}
                            </span>
                        </div>
                        <table className="info-part">
                            <tbody>
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceById?.insuredUser && <tr>
                                    <td className="description">Ապահովադիր։</td>
                                    <td className="text-bold">{insuranceById?.insuredUser || ""}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceById?.idNumber && <tr>
                                    <td className="description">ԱնձնագԻր/ID քարտ/ՀՎՀՀ։</td>
                                    <td className="text-bold">{insuranceById?.idNumber}</td>
                                </tr>}
                            </>}

                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceById?.bonusMalus && <tr>
                                    <td className="description">Բոնուս-Մալուս։</td>
                                    <td className="text-bold">{insuranceById?.bonusMalus}</td>
                                </tr>}
                            </>}

                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceById?.phone && <tr>
                                    <td className="description">Հեռախոսահամար։</td>
                                    <td className="text-bold">{insuranceById?.phone}</td>
                                </tr>}
                            </>}

                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceById?.email && <tr>
                                    <td className="description">Էլ․ հասցե։</td>
                                    <td className="text-bold">{insuranceById?.email}</td>
                                </tr>}
                            </>}
                            </tbody>
                        </table>
                    </div>
                    <div className="info-section">
                        <div className="section-title"><span className="description">Պայմանագրի</span></div>
                        <table className="info-part">
                            <tbody>
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceById?.contractNumber && <tr>
                                    <td className="description">Համար։</td>
                                    <td className="text-bold">{insuranceById?.contractNumber}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceDate && <tr>
                                    <td className="description">Ժամկետ։</td>
                                    <td className="text-bold">{insuranceDate}</td>
                                </tr>}
                            </>}

                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceById?.startDate && <tr>
                                    <td className="description">Սկիզբ/Ավարտ։</td>
                                    <td className="text-bold">{
                                        moment(insuranceById?.startDate).format("DD.MM.YY") + '-' +
                                        moment(insuranceById?.endDate).format("DD.MM.YY")}</td>
                                </tr>}
                            </>}

                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {!!insuranceById?.paidByBonusAmount && <tr>
                                    <td className="description">Վճարած բալանսից։</td>
                                    <td className="text-bold">{insuranceById?.paidByBonusAmount} AMD</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {!!insuranceById?.paidByCardAmount && <tr>
                                    <td className="description">Վճարած քարտից։</td>
                                    <td className="text-bold">{insuranceById?.paidByCardAmount} AMD</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceById?.amount && <tr>
                                    <td className="description">Վճարման գումար։</td>
                                    <td className="text-bold">{
                                        PAYMENT_TYPE.MIXED.key === insuranceById?.paymentType
                                            ? insuranceById?.paidByCardAmount + insuranceById?.paidByBonusAmount : insuranceById?.amount
                                    } AMD</td>
                                </tr>}
                            </>}

                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceById?.paymentType && <tr>
                                    <td className="description">Վճարման եղանակ։</td>
                                    <td className="text-bold">{
                                        PAYMENT_TYPE[insuranceById?.paymentType].title
                                    }</td>
                                </tr>}
                            </>}

                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceById?.insuranceCompany && <tr>
                                    <td className="description">Ապահովագրական ընկերություն։</td>
                                    <td className="text-bold">{insuranceById?.insuranceCompany}</td>
                                </tr>}
                            </>}

                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceById?.useType && <tr>
                                    <td className="description">Օգտագործման տեսակ։</td>
                                    <td className="text-bold">{insuranceById?.useType}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceById?.status && <tr>
                                    <td className="description">Կարգավիճակ։</td>
                                    <td className="text-bold status" style={{
                                        color: INSURANCE_STATUS_TYPES?.[insuranceById?.status]?.color
                                    }}>
                                        {INSURANCE_STATUS_TYPES?.[insuranceById?.status]?.title}
                                    </td>
                                </tr>}
                            </>}
                            </tbody>
                        </table>
                    </div>
                    <div className="info-section">
                        <div className="section-title">
                            <span className="description">Տրանսպորտային միջոց</span>
                        </div>
                        <table className="info-part">
                            <tbody>
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceById?.plateNumber && <tr>
                                    <td className="description">Հաշվառման համարանիշ։</td>
                                    <td className="text-bold">{insuranceById?.plateNumber}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceById?.vehicleMark && <tr>
                                    <td className="description">Մեքենայի մակնիշ։</td>
                                    <td className="text-bold">{`${insuranceById?.vehicleMark || ""}`}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceById?.vehicleModel && <tr>
                                    <td className="description">Մեքենայի մոդել։</td>
                                    <td className="text-bold">{`${insuranceById?.vehicleModel || ""}`}</td>
                                </tr>}
                            </>}
                            {requestLoading ? <tr>
                                <td><SkeletonSmallItem/></td>
                            </tr> : <>
                                {insuranceById?.horsePower && <tr>
                                    <td className="description">Ձձիաուժ։</td>
                                    <td className="text-bold">{insuranceById?.horsePower}</td>
                                </tr>}
                            </>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetInsuranceById,
    ClearUserById,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceDetails)
