// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {mapStateToProps} from "../../redux/mapStateToProps";

// Import components
import OrderDetails from './OrderDetails';
import ReservationDetails from './ReservationDetails';

class Orders extends Component {
    constructor(props) {
        super(props);
        this.orderById = props?.location?.state?.orderById;
    }
    render() {
        return this.orderById?.isReservation ? <ReservationDetails singleOrder={ this.orderById}/>
            : <OrderDetails singleOrder={this.orderById}/>
    }
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
