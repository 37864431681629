import {FACILITIES_CONSTS} from "../constants";

export const initialState = {
    facilitiesList: [],
    facilityById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FACILITIES_CONSTS.GET_FACILITIES:
            return {
                ...state,
                facilitiesList: action.payload || [],
            };
        case FACILITIES_CONSTS.GET_FACILITIES_BY_ID:
            return {
                ...state,
                facilityById: action.payload
            };
        case FACILITIES_CONSTS.CREATE_FACILITIES:
            return {
                ...state,
                facilitiesList: [action.payload].concat(state.facilitiesList)
            };
        case FACILITIES_CONSTS.DELETE_FACILITIES_BY_ID:
            return {
                ...state,
                facilitiesList: state.facilitiesList.filter(facility => facility.id !== action.payload)
            };
        default:
            return state;
    }
}
