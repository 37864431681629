import {AUTH_CONSTS, HISTORY_CONSTS} from "../constants";


export const initialState = {
    historyById: {
        itemsList: [],
        hasMore: false,
        startDate: null,
        endDate: null,
        text: ''
    },

    filledBalance: {
        itemsList: [],
        hasMore: false,
        startDate: null,
        endDate: null,
        types: null,
        text: '',

        // operationsQuantity: 0,
        // operationsSum: [],
        // status: null,
        // stationId: null,
        // stationNetworkId: null
    },
    cancelPayment: {
        itemsList: [],
        hasMore: false,
        startDate: null,
        endDate: null,
        types: null,
        text: ''
    },
    allHistories: {
        itemsList: [],
        hasMore: false,
        startDate: null,
        endDate: null,
        types: null,
        text: ''
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case HISTORY_CONSTS.GET_HISTORY_BY_MODERATOR_ID:
        case HISTORY_CONSTS.GET_HISTORY_BY_COMPANY_ID:
        case HISTORY_CONSTS.GET_HISTORY_BY_STATION_ID:
        case HISTORY_CONSTS.GET_HISTORY_BY_USER_ID:
            return {
                ...state,
                historyById: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.historyById.itemsList, ...action.payload.data],
                    startDate: action.payload.startDate,
                    endDate: action.payload.endDate,
                    hasMore: action.payload.hasMore,
                    text: action.payload.text
                }
            };

        case HISTORY_CONSTS.GET_HISTORY:
            const stateField = 'allHistories';
            const historyData = {};

            // Object.keys(state[stateField]).forEach(key => {
            //     historyData[key] = action.payload[key];
            // });
            // let itemsList = action.payload.data;
            // if (action.payload.urlPart === 'stations') {
            //     itemsList = action.payload.data?.history;
            //     historyData.operationsQuantity = action.payload.data?.operationsQuantity
            //     historyData.operationsSum = action.payload.data?.operationsSum
            // }
            //
            // historyData.itemsList = action.payload.reset ? itemsList
            //     : [...state[stateField].itemsList, ...itemsList]
            //
            // return {
            //     ...state,
            //     [stateField]: historyData
            // };
            return {
                ...state,
                [stateField]: {
                    itemsList: action.payload.reset ? action.payload.data :
                        [...state[stateField].itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                    startDate: action.payload.startDate,
                    endDate: action.payload.endDate,
                    type: action.payload.type,
                    text: action.payload.text,
                }
            }

        case HISTORY_CONSTS.CLEAR_BY_ID_HISTORY:
            return {
                ...state,
                historyById: {...initialState.historyById}
            };
        case HISTORY_CONSTS.CLEAR_HISTORY:
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
