import {MODERATOR_CONSTS, AUTH_CONSTS} from "../constants";

export const initialState = {
    moderatorsList: {
        itemsList: [],
        count: 0,
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MODERATOR_CONSTS.GET_MODERATORS:
            return {
                ...state,
                moderatorsList: {
                    itemsList: action.payload.data,
                    count: action.payload.count,
                }
            };
        case MODERATOR_CONSTS.ADD_MODERATOR:
            return {
                ...state,
                moderatorsList: {
                    itemsList: [action.payload, ...state.moderatorsList.itemsList],
                    count: state.moderatorsList.count + 1,
                }
            };
        case MODERATOR_CONSTS.EDIT_MODERATOR:
            return {
                ...state,
                moderatorsList: {
                    itemsList: state.moderatorsList.itemsList.map(moderator =>
                        moderator.id === action.payload.id
                            ? action.payload : moderator
                    ),
                    count: state.moderatorsList.count + 1,
                }
            };
        case MODERATOR_CONSTS.DELETE_MODERATOR:
            const newList = state.moderatorsList.itemsList &&
                state.moderatorsList.itemsList.filter(moderator => moderator.id !== action.payload);
            return {
                ...state,
                moderatorsList: {
                    itemsList: newList,
                    count: state.moderatorsList.count - 1,
                }
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
