import {BRANDS_CONSTS} from "../constants";

export const initialState = {
    brandsList: [],
    brandById: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case BRANDS_CONSTS.GET_BRANDS:
            return {
                ...state,
                brandsList: action.payload || [],
            };
        case BRANDS_CONSTS.GET_BRAND_BY_ID:
            return {
                ...state,
                brandById: action.payload
            };
        case BRANDS_CONSTS.CREATE_BRAND:
            return {
                ...state,
                brandsList: [action.payload].concat(state.brandsList)
            };
        case BRANDS_CONSTS.UPDATE_BRAND_BY_ID:
            return {
                ...state,
                brandsList: state.brandsList.map(item => item.id === action.payload?.id ? action.payload : item)
            };
        case BRANDS_CONSTS.DELETE_BRAND_BY_ID:
            return {
                ...state,
                brandsList: state.brandsList.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
