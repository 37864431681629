export const COMPANY_CONSTS = {
    GET_COMPANIES: "GET_COMPANIES",
    CLEAR_COMPANIES: "CLEAR_COMPANIES",
    DELETE_COMPANY_BY_ID: "DELETE_COMPANY_BY_ID",
    GET_NOT_SEEN_COMPANIES_COUNT: "GET_NOT_SEEN_COMPANIES_COUNT",
    SET_COMPANY_AS_SEEN: "SET_COMPANY_AS_SEEN",
    SET_ALL_COMPANIES_AS_SEEN: "SET_ALL_COMPANIES_AS_SEEN",

    GET_PAYMENTS: "GET_PAYMENTS",
    GET_COMPANY_CARD: "GET_COMPANY_CARD",

    ATTACH_BUSINESS_CARD: "ATTACH_BUSINESS_CARD",
    DELETE_BUSINESS_CARD: "DELETE_BUSINESS_CARD",
};