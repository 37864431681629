// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetFacilities,
    DeleteFacilityById,
    UpdateFacilityById,
    UpdateFacilitiesPositions
} from "../../redux/actions";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import {deleteItem} from "../../utils/helperFunctions";


class Facilities extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.deleteFacilityItem = this.deleteFacilityItem.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    componentDidMount() {
       this.props.GetFacilities();
    }

    deleteFacilityItem(id){
        this.props.DeleteFacilityById(id).then(()=>{
            this.props.GetFacilities();
        })
    }

    findParent(currentItem, id) {
        if (currentItem.children && currentItem.children.length &&
            currentItem.children.find(item => item.id === id)) {
            let movedItemNewIndex = -1;
            const positionedItems = currentItem.children.map((item, index) => {
                item.id === id && (movedItemNewIndex = index);
                return {id: item.id, position: index}
            });
            return {
                parent: currentItem.id,
                movedItemNewIndex: movedItemNewIndex,
                positionedItems
            };
        } else {
            let resData = null;
            currentItem.children && currentItem.children.length && currentItem.children.forEach(item => {
                let result = this.findParent(item, id);
                if (result) {
                    resData = result;
                }
            });
            return resData;
        }
    }

    getNestableItems(list, parent = null) {
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            const title = item?.title || '';

            items.push({
                id: item.id,
                parent: parent,
                index: index,
                title: title,
                withImage: true,
                isHidden: item.isHidden,
                path: item?.icon?.path,
                link: {
                    pathname: `facilities/edit/${item.id}`,
                },
                deleteCb: deleteItem.bind(this, this.deleteFacilityItem, '', item.id),
                children: !!item.subFacilities?.length ? this.getNestableItems(item.subFacilities, item.id) : []
            })
        });
        return items;
    }

    async onPositionChange(items, item) {
        const movedFacilityId = item.id;
        const {facilitiesList} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~facilitiesList.findIndex(m => m.id === movedFacilityId) &&
            ~positionedItems.findIndex(m => m.id === movedFacilityId)) {
            // first level menu was re-positioned in first level
            await this.props.UpdateFacilitiesPositions(positionedItems);
            this.props.GetFacilities();
        }
    }

    render() {
        let {facilitiesList} = this.props;
        let items = this.getNestableItems(facilitiesList);

        return <PageWrapper pageTitle={'Ծառայություններ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/facilities/add"}
            />
            {items && items.length !== 0 &&
            <Nestable
                items={items}
                maxDepth={1}
                onChange={this.onPositionChange}
                renderItem={NestableItem}
            />}
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetFacilities,
    DeleteFacilityById,
    UpdateFacilityById,
    UpdateFacilitiesPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(Facilities);
