import {_urlBrands, request} from "../api";
import {BRANDS_CONSTS} from "../constants";
import {notificationSuccess} from "../../components/uiElements/Notifications";
import {history} from "../../configs/history";

export const GetBrands = () => {
    const requestData = {
        url: _urlBrands,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: BRANDS_CONSTS.GET_BRANDS,
                    payload: data
                });
            })
    }
};


export const GetBrandById = (id) => {
    const requestData = {
        url: `${_urlBrands}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: BRANDS_CONSTS.GET_BRAND_BY_ID,
                    payload: res.data
                })
            })
            .catch(() => {
            })
    }
};


export const DeleteBrandById = id => {
    const requestData = {
        url: `${_urlBrands}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: BRANDS_CONSTS.DELETE_BRAND_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateBrand = data => {
    const requestData = {
        url: _urlBrands,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: BRANDS_CONSTS.CREATE_BRAND,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ապրանքանիշը հաջողությամբ ստեղծվեց!"
                })
                history.push('/brands')
            })
            .catch(() => {
            })
    }
};

export const UpdateBrandById = (id, data) => {
    const requestData = {
        url: `${_urlBrands}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: BRANDS_CONSTS.UPDATE_BRAND_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ապրանքանիշը հաջողությամբ փոփոխվեց!"
                })
                history.push('/brands')
            })
            .catch(() => {
            })
    }
};

export const UpdateBrandsPositions = items => {
    const requestData = {
        url: _urlBrands,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: BRANDS_CONSTS.UPDATE_BRANDS_POSITIONS
                });
                notificationSuccess({
                    description: "Ապրանքանիշը հաջողությամբ թարմացվեց!"
                })
            })
            .catch(() => {
            })
    }
};

export const AddProductsToBrand = (brandId, items) => {
    const requestData = {
        url: `${_urlBrands}/${brandId}/products`,
        token: true,
        method: "POST",
        data: items
    };
    return dispatch => {
        return request(requestData).catch(() => {

        })
    }
};

export const DeleteProductsFromBrand = (brandId, productIds ) => {
    const requestData = {
        url: `${_urlBrands}/${brandId}/products?productIds=${productIds}`,
        token: true,
        method: "DELETE"
    };
    return dispatch => {
        return request(requestData).catch(() => {

        })
    }
};

export const UpdateProductsPositionsInBrand = (brandId, items) => {
    const requestData = {
        url: `${_urlBrands}/${brandId}/products`,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData).catch(() => {

        })
    }
};