import {HOST_MEDIA_URL} from "../redux/api";
import defaultLogo from '../assets/images/no_product.png';
import userAvatar from '../assets/images/no_product.png';

export function generateImageMediaUrl(path, fromNest) {
    if (path?.startsWith('http')) {
        return path;
    }
    if (fromNest) {
        return path ? `https://nestcard.global/api/assets/${path}` : userAvatar;
    } else {
        return path ? `${HOST_MEDIA_URL}/files${path}` : userAvatar;
    }
}

export function generateFileMediaUrl(path) {
    if (path?.startsWith('http')) {
        return path;
    }
    return path ? `${HOST_MEDIA_URL}/files${path}` : defaultLogo;
}

// export function generateUserImageMediaUrl(path) {
//     if (path?.startsWith('http')){
//         return path;
//     }
//     return path ? `${HOST_MEDIA_URL}/images${path}` : defaultUserLogo;
// }