import {_urlInsurances, request} from "../api";
import {INSURANCES_CONSTS, UTIL_CONSTS} from "../constants";
import {LIMIT_GET_INSURANCE} from "../../constants/constLimitCounts";
import {INSURANCE_STATUS_TYPES} from "../../constants/constTypes";

export const GetInsurances = ({
                                  key,
                                  offset = 0,
                                  limit = LIMIT_GET_INSURANCE,
                                  reset = true,
                                  text = '',
                                  startDate = null,
                                  endDate = null,
                                  status = null
                              } = {}) => {

    let url = `${_urlInsurances}?offset=${offset}&limit=${limit}`;
    // status && (url += `&status=${status === INSURANCE_STATUS_TYPES.AWAITING.key ? status +','+ INSURANCE_STATUS_TYPES.ACTIVE.key : status}`);
    status && (url += `&statuses=${status}`);
    text && (url += `&text=${text}`);
    startDate && (url += `&startDate=${startDate?.format("YYYY-MM-DD") + "T00:00:00.000Z"}`);
    endDate && (url += `&endDate=${endDate?.format("YYYY-MM-DD") + "T23:59:59.000Z"}`);

    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({
            type: UTIL_CONSTS.START_LOADING
        });

        return request(requestData)
            .then(async ({data}) => {
                data && await dispatch({
                    type: INSURANCES_CONSTS.GET_INSURANCES,
                    payload: {
                        key,
                        data,
                        hasMore: data.length === limit,
                        reset,
                        text,
                        startDate,
                        endDate,
                        status,
                    }
                });
                //console.log(data);
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
}


export const GetInsuranceById = (id) => {

    let url = `${_urlInsurances}/${id}`;

    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then(async ({data}) => {
                data && await dispatch({
                    type: INSURANCES_CONSTS.GET_INSURANCES_BY_ID,
                    payload: data
                });
                //console.log(data);
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const ClearInsurances = () => {
    return dispatch => {
        dispatch({
            type: INSURANCES_CONSTS.CLEAR_INSURANCES
        })
    }
};

export const ClearByIdInsurances = () => {
    return dispatch => {
        dispatch({
            type: INSURANCES_CONSTS.CLEAR_INSURANCES_BY_ID
        })
    }
};

