import { _urlHistory, request } from "../api";
import { HISTORY_CONSTS, UTIL_CONSTS } from "../constants";
import { LIMIT_GET_HISTORY } from "../../constants/constLimitCounts";
import { tabs } from "../../containers/history/utils/constants";

export const GetHistory = ({
                               key,
                               offset = 0,
                               limit = LIMIT_GET_HISTORY,
                               reset = true,
                               text = '',
                               startDate = null,
                               endDate = null,
                               type = null,
                            } = {}) => {

    let url = `${_urlHistory}?offset=${offset}&limit=${limit}`;
    // const types=tabs.find(tab=>tab.key===key)?.types;

    text && (url += `&text=${text}`);
    startDate && (url += `&startDate=${startDate?.format("YYYY-MM-DD") +"T00:00:00.000Z"}`);
    endDate && (url += `&endDate=${endDate?.format("YYYY-MM-DD") +"T23:59:59.000Z"}`);
    type && (url += `&type=${type}`);
    // types && (url += `&type=${types.join(',')}`);

    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({
            type:UTIL_CONSTS.START_LOADING
        });

        return request(requestData)
            .then(async ({data}) => {
                data && await dispatch({
                    type: HISTORY_CONSTS.GET_HISTORY,
                    payload: {
                        key,
                        data,
                        hasMore: data.length === limit,
                        reset,
                        text,
                        startDate,
                        endDate,
                        type,
                    }
                });
                //console.log(data);
            }).finally(()=>{
                dispatch({
                    type:UTIL_CONSTS.END_LOADING
                })
            })
    }
}

//==================================================================================================


const _GetHistoryById = ({
                                urlPart,
                                actionType,
                                id ,
                                offset = 0,
                                limit = LIMIT_GET_HISTORY,
                                reset = true,

                                text='',
                                startDate = null,
                                endDate = null,
                                type=null,
                                stationId = null,
                                stationNetworkId = null,
                                status=null,
                            } = {}) => {

    let url = `${_urlHistory}/${urlPart}/${id}?offset=${offset}&limit=${limit}`;
    text && (url += `&text=${text}`);
    startDate && (url += `&startDate=${startDate?.format("YYYY-MM-DD") +"T00:00:00.000Z"}`);
    endDate && (url += `&endDate=${endDate?.format("YYYY-MM-DD") +"T23:59:59.000Z"}`);
    type && (url += `&types=${type}`);
    stationId && (url += `&stationId=${stationId}`);
    stationNetworkId && (url += `&stationNetworkId=${stationNetworkId}`);
    status && (url += `&status=${status}`);

    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({
            type:UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then(async ({data}) => {
                data && await dispatch({
                    type: actionType,
                    payload: {
                        urlPart,
                        data,
                        hasMore: data.length === limit,
                        reset,

                        text,
                        startDate,
                        endDate,
                        type,
                        stationId,
                        stationNetworkId,
                        status,
                    }
                });
                //console.log(data);
            }).finally(()=>{
                dispatch({
                    type:UTIL_CONSTS.END_LOADING
                })
            })
    }
}


export const GetHistoryByCompanyId = (fields) => {
   // console.log(fields);
    return _GetHistoryById({
        actionType: HISTORY_CONSTS.GET_HISTORY_BY_COMPANY_ID,
        urlPart: 'companies',
        ...fields
    });
};

export const GetHistoryByUserId = (fields) => {
   // console.log(fields);
    return _GetHistoryById({
        actionType: HISTORY_CONSTS.GET_HISTORY_BY_USER_ID,
        urlPart: 'users',
        ...fields
    });
};

export const GetHistoryByStationId = (fields) => {
   // console.log(fields);
    return _GetHistoryById({
        actionType: HISTORY_CONSTS.GET_HISTORY_BY_STATION_ID,
        urlPart: 'stations',
        ...fields
    });
};

//==================================================================================================


export const ClearHistory = () => {
    return dispatch => {
        dispatch({
            type: HISTORY_CONSTS.CLEAR_HISTORY
        })
    }
};
export const ClearByIdHistory = () => {
    return dispatch => {
        dispatch({
            type: HISTORY_CONSTS.CLEAR_BY_ID_HISTORY
        })
    }
};

