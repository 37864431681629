// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import "../assets/styles/containerStyles/contact.scss"
import {ReactComponent as CloseIcon} from "../assets/images/ic_close_gray.svg";
import {ReactComponent as DeleteIcon} from "../assets/images/ic_delete.svg";

// Import components
import {InputGroup} from "../components/uiElements/inputGroup";
import PageWrapper from '../components/pageContentViews/pageWrapper'
import {LinkButton} from "../components/buttons/buttons";
import {LanguageTabs} from "../components/uiElements/Tabs";
import {Editor} from "../components/uiElements/Editor";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {GetTermsConditions, UpdateTermsConditions} from "../redux/actions";
import {
    changeStateField,
    getInputValues,
    initTranslations,
    getTranslatableInputValues, getTranslationData, toggleStateField
} from "../utils/helperFunctions";
import {validateTranslations} from "../utils/validateTranslations";
import { Collapse } from 'antd';

const { Panel } = Collapse;

class TermsConditions extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = ['title', 'description'];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                conditions: [],
            },
            isEditing: true,
            socialIndex: 0,
            errorsTabs: [],
            errors: {},
            languageTab: props.mainLanguage,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.updatedDataMap = new Map();
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.toggleSocialMediaModal = toggleStateField.bind(this, 'mediaSocialModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.updateContact = this.updateContact.bind(this);
        this.addConditionItem = this.addConditionItem.bind(this);
        this.getConditionValues = this.getConditionValues.bind(this);
        this.toggleSocialMediaItem = this.toggleSocialMediaItem.bind(this);
        this.deleteTermItem = this.deleteTermItem.bind(this);
    }

    async componentDidMount() {
        !this.props?.termsData?.id && await this.props.GetTermsConditions();
        const {termsData} = this.props;
        const {fieldsData} = this.state;

        const translationsData = {};
        Object.keys(fieldsData.translations).forEach(key => {
            const trItem = termsData?.translations?.find(item => item.languageId === key);
            translationsData[key] = {
                ...fieldsData.translations[key],
                title: trItem?.title || '',
                description: trItem?.description || '',
            };
        });
        let conditionsTrData = []
        termsData.conditions && termsData.conditions.forEach(item => {
            let trData = {...initTranslations(this.translationsFields, this.props.activeLanguages)};
            item.translations.forEach(trItem => {
                trData[trItem.languageId] = {
                    title: trItem?.title || '',
                    description: trItem?.description || '',
                };
            })
            conditionsTrData.push(trData);
        })
        termsData && this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                conditions: conditionsTrData,
                translations: translationsData
            },
        });

    }

    toggleSocialMediaItem(index) {
        this.setState({
            ...this.state,
            socialIndex: index
        })
        this.toggleSocialMediaModal()
    }

    validateFields(constacData) {
        const translationRequiredFields = []
        const validationTr = validateTranslations(constacData.translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = {...validationTr.errors};
        const phoneNumberRegExp = /^[+]?[0-9]{9,20}$/;
        if (constacData?.phoneNumber?.length && !phoneNumberRegExp.test(constacData.phoneNumber)) {
            errors.phoneNumber = true;
            result = false;
        }
        // if (url) reqData.url = url;
        if (!result) {
            this.setState({
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
        return result;
    }

    addConditionItem() {
        let conditionList = [...this.state.fieldsData.conditions]
        const translationsFields = ['title', 'description'];

        conditionList.push(initTranslations(translationsFields, this.props.activeLanguages))

        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                conditions: conditionList
            }
        })
        this.updatedDataMap.set(`url${conditionList.length}`, conditionList)
    }

    getConditionValues({name, value, haveChanges}) {
        const {fieldsData, languageTab, isEditing} = this.state;
        let itemName = name.split('_')[0]
        let itemIndex = name.split('_')[1]
        let conditions = fieldsData.conditions;

        conditions[itemIndex][languageTab][itemName] = value
        this.setState({
            fieldsData: {
                ...fieldsData,
                conditions,
            }
        })

        if (isEditing) {
            haveChanges
                ? this.updatedDataMap.set(name + '_' + languageTab, value)
                : this.updatedDataMap.delete(name + '_' + languageTab);

        }
    }

    updateContact() {
        const {fieldsData} = this.state;
        const {translations} = fieldsData;

        if (this.validateFields(fieldsData)) {
            let TR_data = getTranslationData(translations)

            let conditions = fieldsData.conditions.map(item => {
                return {translations: getTranslationData(item)}
            })

            let reqData = {
                conditions,
                translations: TR_data,
            };

            this.props.UpdateTermsConditions(reqData).then(() => {
                this.updatedDataMap.clear();
                this.setState({
                    errors: {},
                    errorsTabs: []
                })
            })
        }
    }

    deleteTermItem(termIndex, name) {
        const {fieldsData} = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                conditions: fieldsData.conditions.filter((item, index) => index !== termIndex),
            }
        })
        !this.updatedDataMap.has(name) ? this.updatedDataMap.set(name, fieldsData.conditions)
            : this.updatedDataMap.delete(name, fieldsData.conditions)
    }

    render() {
        const {requestLoading, termsData} = this.props;
        const {fieldsData, languageTab, errors, errorsTabs} = this.state;

        const {conditions} = fieldsData;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};
        // console.log(conditions, 'conditions');
        return <PageWrapper pageTitle={'Պայմաններ եւ դրույթներ'}>
            <section className="contact">
                <div className="info-adding-fields">
                    <div className="condition-items">
                        <LinkButton title={'Ավելացնել'}
                                    cb={this.addConditionItem}
                        />

                        {
                            !!conditions?.length && <div className="social-items">
                                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                              errorsTabs={errorsTabs}
                                              activeTab={languageTab}/>

                               {/* <InputGroup inputType="input"
                                            type="text"
                                            label="Անվանում"
                                            placeholder="Անվանում"
                                            name="title"
                                            value={currentData.title}
                                            initValue={initData.title}
                                            error={errors['title' + languageTab]}
                                            maxLength={100}
                                            required={true}
                                            onChange={this.getTranslatableInputValues}/>
                                <div className="editor-wrapper">
                                    <label>Նկարագրություն</label>
                                    <Editor value={currentData?.description || ''}
                                            name={'description'}
                                            initValue={initData?.description}
                                            error={errors['description' + languageTab]}
                                            onChange={this.getTranslatableInputValues}/>
                                </div>*/}
                                <label className={'menu-label'}>Ցանկ</label>
                                <Collapse expandIconPosition={'left'}>
                                    {
                                        conditions.map((item, index) => {
                                            const initTr = termsData?.conditions?.[index]?.translations?.find(lg => lg.languageId === languageTab) || {};

                                            return <Panel header={item[languageTab]?.title || 'Title'}
                                                          extra={<DeleteIcon title={''}
                                                              onClick={event => {
                                                                  event.stopPropagation();
                                                                  this.deleteTermItem(index, item[languageTab]?.title)
                                                              }}
                                                          />}
                                                          key={index}>
                                                <InputGroup inputType="input"
                                                            type="text"
                                                            label={'Անվանում'}
                                                            name={`title_${index}`}
                                                            maxLength={100}
                                                            value={item[languageTab]?.title}
                                                            initValue={initTr?.title}
                                                            error={errors['title' + languageTab]}
                                                            onChange={this.getConditionValues}/>
                                                <div className="editor-wrapper">
                                                    <label>Նկարագրություն</label>
                                                    <Editor value={item[languageTab]?.description || ''}
                                                            name={`description_${index}`}
                                                            initValue={initTr?.description}
                                                            error={errors['description' + languageTab]}
                                                            onChange={this.getConditionValues}/>
                                                </div>
                                            </Panel>
                                        })
                                    }
                                </Collapse>

                            </div>
                        }
                    </div>
                </div>


                <div className="flex-wrapper-right">
                    <LinkButton title="Պահպանել"
                                disabled={!this.updatedDataMap.size}
                                loading={requestLoading}
                                cb={this.updateContact}/>
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetTermsConditions,
    UpdateTermsConditions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsConditions);
