export const ORDER_CONSTS = {
    GET_ORDERS: "GET_ORDERS",
    CLEAR_ORDERS: "CLEAR_ORDERS",
    GET_ORDER_BY_ID: "GET_ORDER_BY_ID",
    DELETE_ORDER_BY_ID: "DELETE_ORDER_BY_ID",
    ARCHIVE_ORDER_BY_ID: "ARCHIVE_ORDER_BY_ID",
    UPDATE_ORDER_STATUS: "UPDATE_ORDER_STATUS",
    GET_NOT_SEEN_ORDERS_COUNT: "GET_NOT_SEEN_ORDERS_COUNT",
    SET_ORDER_AS_SEEN: "SET_ORDER_AS_SEEN",
    SET_ALL_ORDERS_AS_SEEN: "SET_ALL_ORDERS_AS_SEEN",
};