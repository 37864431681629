import {request, _urlNotifications} from "../api";
import {UTIL_CONSTS} from "../constants";
import {NOTIFICATIONS_CONSTS} from "../constants/notificationConsts";
import {LIMIT_GET_NOTIFICATION} from "../../constants/constLimitCounts";

export const GetNotifications = ({
                                     reset = true,
                                     offset = 0,
                                     type = '',
                                     limit = LIMIT_GET_NOTIFICATION,
                                 } = {}) => {
    let url = `${_urlNotifications}?offset=${offset}&limit=${limit}`;
    type && (url += `&type=${type}`);

    const requestData = {
        url,
        token: true,
        method: "GET",
    };
    return dispatch => {
       reset && dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: NOTIFICATIONS_CONSTS.GET_NOTIFICATIONS,
                    payload: {
                        data,
                        reset,
                        hasMore: data.length === limit,
                    }
                });
            }).finally(() => {
                reset && dispatch({type: UTIL_CONSTS.END_LOADING})
            })

    }
};

export const SendNotification = (data) => {
    const requestData = {
        url: `${_urlNotifications}`,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
    }
};
