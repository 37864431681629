// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from 'moment';
import {Icon} from "antd";

// Import Styles
import '../../assets/styles/components/page-header.scss';

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";

// Import components
import {LinkButton} from "../buttons/buttons";
import {InputGroup} from "../uiElements/inputGroup";


class PageHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsData: {
                start: '',
                end: '',
                network: undefined,
                station: undefined,
                address: undefined,
                type: undefined,
            },
        };
        this.handleChangeData = this.handleChangeData.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
    }

    handleChangeData(field, value) {
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                [field]: value
            }
        })
    }

    changeFilter({name, value}) {
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                [name]: value
            }
        });
    }

    render() {
        const {
            linkTitle, addingLink, pageTitle, updateTime, renderingItemsTitleWithCount, children, rightBottomProps,
            search, rightTopProps, rightTopStatusProp, renderingCustomLeftPart, searchValue, filters, className
        } = this.props;

        // const mainTr = item?.translations?.find(tr => tr?.language === mainLanguage);

        return <div className={`content-header ${className}`}>
            <div className={"up-part"}>
                <h1 className="page-title">{pageTitle}</h1>
                {updateTime && <span
                    className="update-time">Թարմացվել է {moment(updateTime).format('DD.MM.YY')}</span>}
                {rightTopProps}
                {rightTopStatusProp}
            </div>
            {children}
            <div className={"down-part"}>
                <div className={"down-part-left"}>
                    {addingLink && <LinkButton title={linkTitle}
                                               link={addingLink}/>}
                    {search && <div className="search_box">
                        <button type="button" className=" search_btn" onClick={this.props.searchItems}>
                            <Icon type="search"/>
                        </button>
                        <InputGroup inputType="input"
                                    type="text"
                                    placeholder="Որոնել"
                                    name="search"
                                    maxLength={256}
                                    onKeyDown={(e) => e && e.key === 'Enter' && this.props?.searchItems?.()}
                                    value={searchValue || ''}
                                    onChange={({value}) => this.props?.getSearchValue?.(value)}/>
                    </div>}
                    {renderingCustomLeftPart}
                </div>
                {renderingItemsTitleWithCount &&
                <span className={"count"}>{renderingItemsTitleWithCount}</span>}
                {rightBottomProps &&
                <span className={"right-bottom-part"}>{rightBottomProps}</span>}
            </div>
            {filters}
        </div>
    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader)
