//Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//Import styles
import "../../assets/styles/containerStyles/company-edit.scss"

//Import Component
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import ImageUploader from "../../components/imageUploader/imageUploader";

//Import utils
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {history} from "../../configs/history";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {getInputValues, getPatchReqData, toggleStateField} from "../../utils/helperFunctions";
import {UpdateCompany} from "../../redux/actions";
import {validateFieldsData} from "../../utils/validateFields";

class EditCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsData: {
                activatedByAdmin: true,
                name: "",
                logo: "",
                marketingName: "",
                direction: undefined,
                phoneNumber: "",
                vatNumber: "",
                legalAddress: "",
                activityAddress: "",
                user_firstName: "",
                user_lastName: "",
                user_email: "",
                user_phone: "",
            },
            username: '',
            errors: {},
            loading: false,
            mediaModalOpen: false,
            isEditing: !!props?.id,
        }
        this.updatedDataMap = new Map();
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.getSelectedImageFile = this.getSelectedImageFile.bind(this);
        this.editCompany = this.editCompany.bind(this);
    }

    async componentDidMount() {
        const {isEditing} = this.state;
        const {companiesList} = this.props;
        if (isEditing) {
            const id = this.props?.id;
            let companyById = companiesList.itemsList.find(item => item.id === id)
            // console.log(companyById, 'companyById');
            if (!companyById) {
                history.push('/companies');
                return;
            }

            const initStateData = {
                logo: companyById?.logo || "",
                activatedByAdmin: companyById?.activatedByAdmin,
                name: companyById?.name,
                marketingName: companyById?.marketingName,
                direction: companyById?.direction?.name || undefined,
                phone: companyById?.phone,
                vatNumber: companyById?.vatNumber,
                legalAddress: companyById?.legalAddress,
                activityAddress: companyById?.activityAddress,
                user_firstName: companyById?.user?.firstName,
                user_lastName: companyById?.user?.lastName,
                user_email: companyById?.user?.email,
                user_phone: companyById?.user?.phone,
                isHidden: !!companyById?.isHidden,
            }

            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
                username: companyById?.user?.username || ''
            });
        }

    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.logo?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('logo', mediaArray[0]?.id)
                : this.updatedDataMap.delete('logo');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                logo: mediaArray[0],
            },
            errors: {
                ...errors,
                logo: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.logo ?
                this.updatedDataMap.set("logo", null) :
                this.updatedDataMap.delete("logo");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                logo: null
            }
        })
    }

    getSelectedImageFile = (file) => {
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                logo: file,
            },
            errors: {
                ...this.state.errors,
                logo: false
            }
        })

        this.currentData?.logo !== file ?
            this.updatedDataMap.set('logo', file)
            : this.updatedDataMap.delete('logo')
    };

    async editCompany() {
        await this.setState({
            requestLoading: true
        });
        const {fieldsData} = this.state;
        const requiredFields = [
            'logo',
            'name',
            'marketingName',
            'direction',
            'phone',
            'vatNumber',
            'legalAddress',
            'activityAddress',
            'user_firstName',
            'user_lastName',
            'user_phone',
        ];
        let errors = {
            name: false,
            marketingName: false,
            direction: false,
            phone: false,
            vatNumber: false,
            legalAddress: false,
            activityAddress: false,
            logo: false,
            user_firstName: false,
            user_lastName: false,
            user_phone: false,
            user_email: false,
        };
        let result = validateFieldsData(fieldsData, errors, requiredFields);

        if (result) {
            const reqData = this.getPatchReqData();
            Object.keys(reqData).forEach(item => {
                if (item !== 'user' && item.includes('user')) {
                    reqData.user = {
                        ...reqData.user,
                        [item?.slice(5)]: reqData[item]
                    }
                    delete reqData[item]
                }
            })
            this.props.UpdateCompany(this.props.id, reqData).finally(() => {
                this.setState({loading: false});
            });
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
            })
        }
    }

    render() {
        const {fieldsData, errors, mediaModalOpen, loading, isEditing, username} = this.state;
        const {directionList, mainLanguage} = this.props;

        const businessDirectionOptions = directionList.map(item => ({
            id: item?.id,
            name: item.translations.find(tr => tr.languageId === mainLanguage)?.name || ''
        }));
        console.log(username, 'username');
        return <PageWrapper pageTitle={`Փոփոխել կազմակերպությունը`}>
            <section className="general-add-edit company-edit">

                <div className="top-side">
                    <div className="uploader-wrapper">
                        <ImageUploader noImageError={''}
                                       invalid={errors.logo}
                                       image={fieldsData.logo}
                                       onChange={this.getSelectedImageFile}
                        />
                    </div>
                </div>

                {username && <div className="username">{username}</div>}

                <div className={"page-subtitle"}>Ընկերության տվյալներ</div>
                <div className={"info-wrapper"}>
                    <div className={"left-part"}>
                        <InputGroup inputType="input"
                                    type="text"
                                    label={"Անվանում*"}
                                    placeholder="Անվանում"
                                    name="name"
                                    value={fieldsData?.name}
                                    required={true}
                                    initValue={this.currentData?.name}
                                    error={errors?.name}
                                    onChange={this.getInputValues}/>
                        <InputGroup inputType={'input'}
                                    type={'text'}
                                    label={"Հեռախոսահամար*"}
                                    placeholder="Հեռախոսահամար"
                                    name={'phone'}
                                    value={fieldsData.phone}
                                    maxLength={12}
                                    initValue={this.currentData?.phone}
                                    error={errors.phone}
                                    onChange={this.getInputValues}/>
                        <InputGroup inputType={'input'}
                                    type={'text'}
                                    label={"Իրավաբանական հասցե*"}
                                    placeholder="Իրավաբանական հասցե"
                                    name={'legalAddress'}
                                    value={fieldsData.legalAddress}
                                    maxLength={50}
                                    initValue={this.currentData?.legalAddress}
                                    error={errors.legalAddress}
                                    onChange={this.getInputValues}
                        />
                        <InputGroup inputType="selectCustom"
                                    label={"Բիզնեսի ուղղություն*"}
                                    placeholder="Բիզնեսի ուղղություն"
                                    name="direction"
                                    showSearch={false}
                                    value={fieldsData?.direction}
                                    error={errors?.direction}
                                    initValue={this.currentData?.direction}
                                    onChange={this.getInputValues}
                                    options={businessDirectionOptions}/>
                    </div>
                    <div className={"right-part"}>
                        <InputGroup inputType={'input'}
                                    type={'text'}
                                    label={"Մարկ․ անվանում*"}
                                    placeholder="Մարկ․ անվանում"
                                    name={'marketingName'}
                                    value={fieldsData.marketingName}
                                    maxLength={50}
                                    initValue={this.currentData?.marketingName}
                                    error={errors.marketingName}
                                    onChange={this.getInputValues}/>
                        <InputGroup inputType={'input'}
                                    type={'text'}
                                    label={"VAT համար*"}
                                    placeholder="VAT համար"
                                    name={'vatNumber'}
                                    value={fieldsData.vatNumber}
                                    maxLength={50}
                                    initValue={this.currentData?.vatNumber}
                                    error={errors.vatNumber}
                                    onChange={this.getInputValues}/>
                        <InputGroup inputType={'input'}
                                    type={'text'}
                                    label={"Գործունեության հասցեն*"}
                                    placeholder={"Գործունեության հասցեն"}
                                    name={'activityAddress'}
                                    value={fieldsData.activityAddress}
                                    maxLength={50}
                                    initValue={this.currentData?.activityAddress}
                                    error={errors.activityAddress}
                                    onChange={this.getInputValues}
                        />
                    </div>
                </div>
                <div className={"page-subtitle"}>Տնօրենի տվյալներ</div>
                <div className={"info-wrapper"}>
                    <div className={"left-part"}>
                        <InputGroup inputType={'input'}
                                    type={'text'}
                                    label={"Անուն*"}
                                    placeholder={"Անուն"}
                                    name={'user_firstName'}
                                    value={fieldsData?.user_firstName}
                                    maxLength={50}
                                    initValue={this.currentData?.user_firstName}
                                    error={errors.user_firstName}
                                    onChange={this.getInputValues}
                        />
                        <InputGroup inputType={'input'}
                                    type={'text'}
                                    label={"Էլ․ հասցե"}
                                    placeholder={"Էլ․ հասցե"}
                                    name={'user_email'}
                                    value={fieldsData?.user_email}
                                    error={errors.user_email}
                                    maxLength={50}
                                    initValue={this.currentData?.user_email}
                                    onChange={this.getInputValues}/>
                    </div>
                    <div className={"right-part"}>
                        <InputGroup inputType={'input'}
                                    type={'text'}
                                    label={"Ազգանուն*"}
                                    placeholder={"Ազգանուն"}
                                    name={'user_lastName'}
                                    value={fieldsData?.user_lastName}
                                    maxLength={50}
                                    initValue={this.currentData?.user_lastName}
                                    error={errors.user_lastName}
                                    onChange={this.getInputValues}
                        />
                        <InputGroup inputType={'input'}
                                    type={'text'}
                                    label={"Հեռախոսահամար*"}
                                    placeholder="Հեռախոսահամար"
                                    name={'user_phone'}
                                    value={fieldsData?.user_phone}
                                    maxLength={12}
                                    regExp={/^[+]?[0-9]*$/}
                                    initValue={this.currentData?.user_phone}
                                    error={errors.user_phone}
                                    onChange={this.getInputValues}/>
                    </div>
                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={`Փոփոխել`}
                                loading={loading}
                                disabled={!this.updatedDataMap.size && isEditing}
                                cb={this.editCompany}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}


const mapDispatchToProps = {
    UpdateCompany
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCompany)