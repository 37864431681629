// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";


// Import Styles
import '../../assets/styles/dataDisplay/details-header.scss';

// Import components
import {SkeletonSmallItem} from "./SkelatonItem";
import {history} from "../../configs/history";


class DetailsHeader extends Component {

    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        const {data, rightSideProps, loading} = this.props;

        return <div className="details-header">
            <div className="left-side">

                <div className="left-part">
                    <div>
                        {loading ? <SkeletonSmallItem/> : <>
                            {data?.name && <div>
                                <span className="description">Անուն։</span>
                                <span className="user" onClick={() => {
                                    history.push({
                                        pathname: `/users/${data?.userId}`,
                                    })
                                }}>
                                {data?.name}
                            </span>
                            </div>}
                        </>}
                        {loading ? <SkeletonSmallItem/> : <>
                            {data?.address && <div>
                                <span className="description">Հասցե։</span>
                                <span className="text-bold">{data?.address}</span>
                            </div>}
                        </>}
                        {loading ? <SkeletonSmallItem/> : <>
                            {data?.email && <div>
                                <span className="description">Էլ․ հասցե։</span>
                                <span className="text-bold">{data?.email}</span>
                            </div>}
                        </>}
                        {loading ? <SkeletonSmallItem/> : <>
                            {data?.phone && <div>
                                <span className="description">Հեռախոս։</span>
                                <span className="text-bold">{data?.phone}</span>
                            </div>}
                        </>}
                    </div>
                </div>
            </div>
            {rightSideProps}
            <div>
                {!rightSideProps && <div className="right-side">

                    {data.status && <div className={'isHidden-state'}>
                        <span> {data?.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</span>
                    </div>}
                    {data?.createdAt && <span
                        className="right-part-item">{"Ամսաթիվ։"} {data?.createdAt}
                    </span>}

                    {data?.isPaid && <span
                        className="right-part-item">{data?.isPaid}
                    </span>}

                </div>
                }
            </div>
        </div>
    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsHeader)
