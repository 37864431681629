// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {history} from "../../configs/history";

// Import Styles


// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";


// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getPostReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
} from "../../utils/helperFunctions";
import {CreateDirection, UpdateDirectionById} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {DIRECTION_TYPES} from "../../constants/constTypes";
import {validateFieldsData} from "../../utils/validateFields";

class AddEditDirection extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = ['name'];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                type: undefined,
                bonus: 0,
                commission: 0,
            },
            errors: {},
            errorsTabs: [],
            loading: false,
            requestLoading: false,
            languageTab: props.mainLanguage,
            isEditing: !!props?.id,
        };
        this.updatedDataMap = new Map();
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.addUpdateDirection = this.addUpdateDirection.bind(this);
    }

    async componentDidMount() {
        const {isEditing,} = this.state;
        const {directionList} = this.props;
        if (isEditing) {
            const id = this.props?.id;
            const directionById = directionList.find(item => item.id === id)

            if (!directionById) {
                history.push('/directions');
                return;
            }
            // Translations
            const translationsData = {};
            directionById.translations.forEach(item => {
                translationsData[item?.languageId] = {
                    ...translationsData[item?.languageId],
                    name: item?.name || '',
                };
            });

            const initStateData = {
                translations: translationsData,
                bonus: directionById?.bonus,
                commission: directionById?.commission,
                type: directionById?.type,
            }

            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }


    async addUpdateDirection() {
        await this.setState({
            requestLoading: true
        });
        const {fieldsData} = this.state;
        const {translations} = fieldsData;
        const translationRequiredFields = ['name'];
        const requiredFields = ['type', 'bonus', 'commission'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;

        let errors = {
            type: false,
            commission: false,
            bonus: false,
            ...validationTr.errors,
        };
        const validationFields = validateFieldsData(fieldsData,errors, requiredFields);

        if (result && validationFields) {
            if (!this.state.isEditing) {
                const reqData = this.getPostReqData(fieldsData);
                this.props.CreateDirection(reqData).finally(() => {
                    this.setState({requestLoading: false})
                })
            } else {
                const trData = getTranslationData(translations);
                const reqData = this.getPatchReqData(trData);

                this.props.UpdateDirectionById(this.props.id, reqData).finally(() => {
                    this.setState({loading: false});
                });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {
            fieldsData, languageTab, isEditing, loading, errors, errorsTabs,
        } = this.state;
        const {translations} = fieldsData;
        const trData = translations[languageTab] || {};
        const initTrData = this.currentData?.translations[languageTab] || {};

        const typesOptions = Object.keys(DIRECTION_TYPES).map(key => ({
            id: key,
            name: DIRECTION_TYPES[key].name
        }));

        return <PageWrapper pageTitle={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'} Ուղղություն`}>
            <section className="general-add-edit">
                <div className="row">
                <InputGroup inputType="selectCustom"
                            label="Տեսակ*"
                            placeholder="Տեսակ"
                            name="type"
                            showSearch={false}
                            value={fieldsData.type}
                            error={errors.type}
                            initValue={this.currentData?.type}
                            onChange={this.getInputValues}
                            options={typesOptions}/>
                </div>
                <div className="row">
                    <InputGroup inputType="input"
                                type={'text'}
                                regExp={/^[1-9]\d*$/}
                                placeholder="Բոնուս"
                                label="Բոնուս*"
                                name="bonus"
                                maxLength={3}
                                value={fieldsData.bonus}
                                error={errors.bonus}
                                initValue={this.currentData?.bonus}
                                onChange={this.getInputValues}/>
                    <InputGroup inputType="input"
                                type={'text'}
                                value={fieldsData.commission}
                                error={errors.commission}
                                initValue={this.currentData?.commission}
                                name="commission"
                                maxLength={3}
                                regExp={/^[1-9]\d*$/}
                                placeholder="Կոմիսիոն վճար"
                                label="Կոմիսիոն վճար*"
                                onChange={this.getInputValues}/>
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              activeTab={languageTab}
                              errorsTabs={errorsTabs}/>
                <InputGroup inputType="input"
                            type="text"
                            label={"Անվանում*"}
                            placeholder="Անվանում"
                            name="name"
                            value={trData?.name}
                            required={true}
                            initValue={initTrData?.name}
                            error={errors?.['name' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                <div className="flex-wrapper-right">
                    <LinkButton title={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'}`}
                                loading={loading}
                                disabled={!this.updatedDataMap.size && isEditing}
                                cb={this.addUpdateDirection}/>
                </div>
            </section>


        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateDirection,
    UpdateDirectionById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditDirection)
