import {INSURANCE_STATUS_TYPES} from "../../../constants/constTypes";

export const tabs = [
    {
        name: "Կատարված",
        key: "awaitingInsurancesList",
        status: INSURANCE_STATUS_TYPES.AWAITING.key,
    },
    {
        name: "Ընթացիկ",
        key: "pendingInsurancesList",
        status: INSURANCE_STATUS_TYPES.PENDING.key,
    },
    {
        name: "Բոլորը",
        key: "insurancesList",
        status: null,
    },
];
export const tabsStatuses = {
    awaitingInsurancesList: INSURANCE_STATUS_TYPES.AWAITING.key,
    pendingInsurancesList: INSURANCE_STATUS_TYPES.PENDING.key,
    insurancesList: null,
};

