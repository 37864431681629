import React from "react";
import {Route, Redirect} from "react-router-dom";
import {ACCESS_PAGES} from "../constants/accessPages";

import {store} from "../redux/store";

export const ProtectedRoute = ({component: Component, ...props}) =>
    <Route {...props}
           render={({match}) => {
               const {accessPages, isAdmin} = store.getState().auth;
               const pagePath = props.location.pathname.split('/')[1];
               return (isAdmin || pagePath === 'dashboard' || accessPages?.includes(ACCESS_PAGES?.[pagePath]?.key) ?
                   <Component id={match.params.id}
                              {...props}/> :
                   <Redirect to="/404"/>)
           }}/>;
