import {_urlBankTransaction, _urlTransaction, request} from "../api";
import {TRANSACTION_CONSTS, UTIL_CONSTS} from "../constants";

export const GetTransactions = ({
                                    text = '',
                                    offset = 0,
                                    limit = 20,
                                    reset = true,
                                    startDate = null,
                                    endDate = null,
                                    type = null,
                                    branch = null,
                                    status = null,
                                    source = null,
                                    cardNumber = null,
                                    transactionNumber = null,
                                    company = null,
                                } = {}) => {

    let url = `${_urlTransaction}?offset=${offset}&limit=${limit}`;

    text && (url += `&text=${text}`);
    startDate && (url += `&startDate=${startDate?.format("YYYY-MM-DD") +"T00:00:00.000Z"}`);
    endDate && (url += `&endDate=${endDate?.format("YYYY-MM-DD") +"T23:59:59.000Z"}`);
    type && (url += `&type=${type}`);
    branch && (url += `&branch=${branch}`);
    status && (url += `&status=${status}`);
    source && (url += `&source=${source}`);
    cardNumber && (url += `&cardNumber=${cardNumber}`);
    transactionNumber && (url += `&transactionNumber=${transactionNumber}`);
    company && (url += `&company=${company}`);

    const requestData = {
        url,
        token: true,
        scope: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({
            type: UTIL_CONSTS.START_LOADING
        });

        return request(requestData)
            .then(async ({data}) => {
                data && await dispatch({
                    type: TRANSACTION_CONSTS.GET_TRANSACTION,
                    payload: {
                        data,
                        hasMore: data.length === limit,
                        reset,
                        text,
                        startDate,
                        endDate,
                        type,
                        branch,
                        status,
                        source,
                        cardNumber,
                        transactionNumber,
                        company,
                    }
                });
                return data
                //console.log(data);
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
}


export const ClearTransactions = () => {
    return dispatch => {
        dispatch({
            type: TRANSACTION_CONSTS.CLEAR_TRANSACTION
        })
    }
};

export const GetBankTransactions = ({
                                    text = '',
                                    offset = 0,
                                    limit = 20,
                                    reset = true,
                                    transactionNumber = null,
                                    type = null,
                                    status = null,
                                    startDate = null,
                                    endDate = null,
                                    company = null,
                                } = {}) => {

    let url = `${_urlBankTransaction}?offset=${offset}&limit=${limit}`;

    text && (url += `&text=${text}`);
    startDate && (url += `&startDate=${startDate?.format("YYYY-MM-DD") +"T00:00:00.000Z"}`);
    endDate && (url += `&endDate=${endDate?.format("YYYY-MM-DD") +"T23:59:59.000Z"}`);
    type && (url += `&type=${type}`);
    status && (url += `&status=${status}`);
    transactionNumber && (url += `&transactionNumber=${transactionNumber}`);
    company && (url += `&company=${company}`);

    const requestData = {
        url,
        token: true,
        scope: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({
            type: UTIL_CONSTS.START_LOADING
        });

        return request(requestData)
            .then(async ({data}) => {
                data && await dispatch({
                    type: TRANSACTION_CONSTS.GET_BANK_TRANSACTION,
                    payload: {
                        data,
                        hasMore: data.length === limit,
                        reset,
                        text,
                        startDate,
                        endDate,
                        type,
                        status,
                        transactionNumber,
                        company,
                    }
                });
                return data
                //console.log(data);
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
}