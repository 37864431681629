let USERS_URL, AUTH_URL, ADMIN_URL, HISTORY_URL, FILE_MANAGER_URL, COMPANY_URL, PAYMENT_URL, NEW_LOG, SOCKET_NEW_LOG_URL;

export let HOST_MEDIA_URL;

if(process.env.REACT_APP_ENVIRONMENT === 'production') {
    USERS_URL =  "https://nestcard.global/api";
    AUTH_URL = "https://nestcard.global/api/auth";
    ADMIN_URL = "https://nestcard.global/admin/api/v1";

    HISTORY_URL =  "https://nestcard.global/histories/api/v1";
    FILE_MANAGER_URL =  "https://nestcard.global/resources/api/v1";
    COMPANY_URL =  "https://nestcard.global/business-company/api/v1";
    PAYMENT_URL =  "https://nestcard.global/business-payment/api/v1";
    NEW_LOG =  "https://nestcard.global/";

    HOST_MEDIA_URL =  "https://nestcard.global/resources";
    SOCKET_NEW_LOG_URL =  "https://nestcard.global/";
}else {
        USERS_URL =  "https://app.ibexgold.am/common/api";
        AUTH_URL = "https://app.ibexgold.am/common/api/auth";
        ADMIN_URL = "https://app.ibexgold.am/admin/api/v1";

        HISTORY_URL =  "https://app.ibexgold.am/history/api/v1";
        FILE_MANAGER_URL =  "https://app.ibexgold.am/file/api/v1";
    COMPANY_URL =  "https://nestcard.global/business-company/api/v1";
        PAYMENT_URL =  "https://app.ibexgold.am/payment/api/v1";
    NEW_LOG =  "https://nestcard.global/";

    HOST_MEDIA_URL =  "https://app.ibexgold.am/file";
    SOCKET_NEW_LOG_URL =  "https://app.ibexgold.am";

    // USERS_URL =  "http://173.249.20.192:8800/api";
    // AUTH_URL = "http://173.249.20.192:8800/api/auth";
    // HISTORY_URL =  "http://173.249.20.192:8805/api/v1";
    // ADMIN_URL =  "http://173.249.20.192:8810/api/v1";
    // FILE_MANAGER_URL =  "http://173.249.20.192:8802/api/v1";
    // COMPANY_URL =  "http://173.249.20.192:7101/api/v1";
    // PAYMENT_URL =  "http://173.249.20.192:7102/api/v1";
    // NEW_LOG =  "http://173.249.20.192:7105/api/v1";
    //
    // HOST_MEDIA_URL =  "http://173.249.20.192:8802";
    // SOCKET_NEW_LOG_URL =  "http://173.249.20.192:7105";
}


export const _urlOauth = AUTH_URL;
export const _urlMedia = FILE_MANAGER_URL + "/admin/files";
export const _urlMediaImages = FILE_MANAGER_URL;
export const _urlLanguages = ADMIN_URL + "/languages";
export const _urlHistory = HISTORY_URL + "/admin";
export const _urlUsers = USERS_URL + "/user";
export const _urlModerator = ADMIN_URL + "/moderators";
export const _urlProducts = ADMIN_URL + "/products";
export const _urlCategories = ADMIN_URL + "/categories";
export const _urlReviews = ADMIN_URL + "/reviews";
export const _urlOrders = ADMIN_URL + "/orders";
export const _urlPartner = ADMIN_URL + "/partners";
export const _urlContact = ADMIN_URL + "/contact";
export const _urlStatus = ADMIN_URL + "/order-statuses";
export const _urlSlider = ADMIN_URL + "/slider";
export const _urlBrands = ADMIN_URL + "/brands";
export const _urlShops = ADMIN_URL + "/shops";
export const _urlSection = ADMIN_URL + "/sections";
export const _urlInsurances = ADMIN_URL + "/insurances";
export const _urlFilters = ADMIN_URL + "/filters";
export const _urlNotifications = ADMIN_URL + "/notifications";
export const _urlCountries = ADMIN_URL + "/countries";
export const _urlNews = ADMIN_URL + "/news";
export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlCompany = COMPANY_URL + "/company";
export const _urlPayment = PAYMENT_URL + "/business-card";
export const _urlBranch = COMPANY_URL + "/branch/admin";
export const _urlDirection = COMPANY_URL + "/direction";
export const _urlTransaction = COMPANY_URL + "/transaction/all";
export const _urlBankTransaction = COMPANY_URL + "/bank-transaction/all";
export const _urlTerms = ADMIN_URL + "/terms-conditions";
export const _urlFacilities = ADMIN_URL + "/facilities";

export const _urlSocketLog = SOCKET_NEW_LOG_URL;
export const _urlSocketNewLog = NEW_LOG + "/log";
