// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch} from "antd";

//import styles
import '../../assets/styles/containerStyles/appended-product-section.scss';

// Import components
import Nestable from "react-nestable";
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";
import ProductsSelectorModal from "../../components/ProductsSelectorModal";
import ProductCard from "../../components/cards/ProductCard";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData, getPostReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations, toggleStateField,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetSectionsById,
    CreateSection,
    UpdateSectionById,
    AddProductsToSection,
    DeleteProductsFromSection,
    UpdateProductsPositionsInSection,
} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import swal from "sweetalert";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";


class AddEditSection extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                products: [],
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
            productsModalOpen: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleProductsModal = toggleStateField.bind(this, 'productsModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.checkProductListDifferent = this.checkProductListDifferent.bind(this);
        this.productCardWrapper = this.productCardWrapper.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
        this.addEditSection = this.addEditSection.bind(this);
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;

        if (isEditing) {
            await this.props.GetSectionsById(this.props.id);
            const translationsData = {};
            const editingData = this.props.sectionById;
            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                    };
                });
            }
            // const products = Array.isArray(editingData?.products) ?
            //     editingData.products.map(item => item.product) : [];
            const initStateData = {
                translations: translationsData,
                products: editingData?.products,
                isHidden: editingData?.isHidden,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                ...this.state,
                fieldsData: initStateData,
            });
        }
    }

    checkProductListDifferent(currProducts) {
        let result = false;
        const initProducts = this.currentData.products;
        if (!Array.isArray(currProducts) || (currProducts?.length !== initProducts.length)) {
            result = true;
        } else {
            currProducts.forEach((item, index) => {
                if (initProducts[index]?.id !== item?.id) {
                    result = true;
                }
            })
        }

        if (result) {
            const productsList = currProducts.map(item => item?.id);
            this.updatedDataMap.set('products', productsList)

        } else {
            this.updatedDataMap.delete('products');
        }
    }

    getProducts(productsArray) {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.checkProductListDifferent(productsArray)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                products: productsArray,
            }
        })
    }

    deleteProduct(id) {
        const {fieldsData, isEditing} = this.state;
        let products = fieldsData.products.filter(p => p.id !== id);

        if (isEditing) {
            this.checkProductListDifferent(products)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                products: products,
            }
        })
    }

    onPositionChange(products) {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.checkProductListDifferent(products)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                products: products
            }
        })
    }

    async addEditSection() {
        await this.setState({
            requestLoading: true
        });
        const {translations, isHidden, products} = this.state.fieldsData;
        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};
        if (!products.length) {
            errors.products = true;
            result = false;
        }

        if (result) {

            if (!this.state.isEditing) {
                const reqData = this.getPostReqData(this.state.fieldsData);

                console.log(reqData, 'reqData');
                this.props.CreateSection(reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {
                const trData = getTranslationData(translations);
                const reqData = this.getPatchReqData(trData);
                this.props.UpdateSectionById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    productCardWrapper(data) {
        return <ProductCard data={data?.item}
                            onDelete={this.deleteProduct}
                            viewCard={true}/>
    }

    render() {
        const {fieldsData, errors, languageTab, productsModalOpen, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};
        const items = fieldsData.products;

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Բաժին' : 'Փոփոխել Բաժինը'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <InputGroup inputType="input"
                            type="text"
                            label="Անվանում"
                            placeholder="Անվանում"
                            name="title"
                            required={true}
                            value={currentData.title}
                            initValue={initData.title}
                            error={errors['title' + languageTab]}
                            maxLength={100}
                            onChange={this.getTranslationValues}/>

                <section className={'products-medias-list-wrapper'}>
                    <div className={'label-and-action'}>
                        <label className={`${errors.products ? 'error' : ''}`}>Ապրանքներ <span className={'required-badge'}>*</span></label>
                        <LinkButton title="Ընտրել Ապրանքներից"
                                    className={`media-select-btn ${errors?.products ? 'media-select-error' : ''}`}
                                    cb={this.toggleProductsModal}/>
                    </div>
                    <div className={'items-wrapper'}>

                        {items.length ? <Nestable
                                items={items}
                                maxDepth={1}
                                onChange={this.onPositionChange}
                                renderItem={this.productCardWrapper}
                            /> :
                            <p className={'empty-text'}>Կցված ապրանքներ չկան</p>}
                    </div>
                </section>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditSection}/>
                </div>
            </section>
            <ProductsSelectorModal
                isOpen={!!productsModalOpen}
                getSelectedProducts={this.getProducts}
                appendProducts={fieldsData.products}
                closeModal={this.toggleProductsModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetSectionsById,
    CreateSection,
    UpdateSectionById,
    AddProductsToSection,
    DeleteProductsFromSection,
    UpdateProductsPositionsInSection,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditSection)
