// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import {DatePicker, Select} from "antd";

// Import Styles
import '../../assets/styles/containerStyles/history.scss';

// Import components
import ItemsList from "../../components/ItemsList";
import PageHeader from "../../components/pageContentViews/pageHeader";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import {InputGroup} from "../../components/uiElements/inputGroup";

// Import utils
import {NOTIFICATION_TYPES} from "../../constants/constTypes";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetNotifications} from "../../redux/actions";

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: undefined
        };
        this.listRef = React.createRef();
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.searchItems = this.searchItems.bind(this);
    }

    componentDidMount() {
        this.props.GetNotifications();
        this.typesOptions = Object.keys(NOTIFICATION_TYPES).map(key => ({
            id: key,
            name: NOTIFICATION_TYPES[key].name
        }));
    }

    handleChangeData(name, value) {
        this.setState({
            [name]: value
        })
        this.searchItems({[name]: value})
    }

    searchItems(updatedData = {}) {
        const {activeTab, type} = this.state;
        const {notificationsList} = this.props;

        let filterData = {
            key: activeTab,
            type,
            ...updatedData,
        };
        if (filterData?.text && filterData.text?.length === 1) {
            return;
        }
        this.props.GetNotifications(filterData);
    }

    loadMoreItems() {
        const {notificationsList} = this.props;

        notificationsList?.hasMore && this.searchItems({
            reset: false,
            offset: notificationsList?.itemsList?.length,
        });
    };

    render() {
        const {} = this.state;
        const {notificationsList, requestLoading} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Անվանում", "Նկարագրություն", "Ամսատիվ", "Տիպ"],
            largeItems: [1],
            status: false,
        };

        let itemListInfo = [];

        !!notificationsList?.itemsList?.length && notificationsList.itemsList.forEach(item => {
            itemListInfo.push({
                id: item.id,
                values: [
                    item?.title || "-",
                    item?.description || "-",
                    moment(item?.createdAt).format('DD/MM/YYYY HH:mm'),
                    NOTIFICATION_TYPES[item?.type]?.name
                ],
            });
        });
        return <PageWrapper withActions={true} pageTitle={'Պատմություն'}>
            <section className="history">
                <PageHeader
                    filters={<div className="history-filters-wrapper">
                        <div className="history-filters-part">
                            <InputGroup inputType="selectCustom"
                                        placeholder="Տեսակ"
                                        name="type"
                                        tooltip={true}
                                        showSearch={false}
                                        value={this.state.type}
                                        onChange={({value}) => this.handleChangeData('type', value)}
                                        options={this.typesOptions}>
                                {<Select.Option value={undefined}>Բոլորը</Select.Option>}
                            </InputGroup>
                        </div>

                    </div>}
                />
                <ItemsList itemListInfo={itemListInfo}
                           itemListHeaderInfo={itemListHeaderInfo}
                           loading={requestLoading}
                           infiniteScroll={true}
                           useWindow={false}
                           istRef={this.listRef}
                           hasMore={notificationsList?.hasMore}
                           loadMoreItems={this.loadMoreItems}
                />
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetNotifications
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
