import { stringify } from "query-string";
import { _urlOauth, ClientEncoded, request } from "../api";
import { store } from "../store";
import { history } from "../../configs/history";
import { AUTH_CONSTS, UTIL_CONSTS } from "../constants";
import swal from 'sweetalert';

const URLS = {
    loginUser: `${_urlOauth}/sign-in`,
    refreshToken: `${_urlOauth}/refresh-token`,
    authenticate: `${_urlOauth}/is-sign-in`
};

export const LoginAdmin = loginCreds => {
    const requestData = {
        url: URLS.loginUser,
        method: "POST",
        data: stringify(loginCreds),
        customHeaders: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    };
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        request(requestData)
            .then(async res => {
                //scope: "access:admin"
                const data = res.data?.data;
                await dispatch({
                    type: AUTH_CONSTS.LOG_IN,
                    payload: data
                });
                history.push("/dashboard");
                // if (data?.scope === 'access:admin' || data?.scope === 'access:moderator' ) {
                //     await dispatch({
                //         type: AUTH_CONSTS.LOG_IN,
                //         payload: data
                //     });
                //     history.push("/dashboard");
                // } else {
                //     swal({
                //         title: "Չհաջողվեց!",
                //         text: 'Սխալ մուտքանուն կամ գաղտնաբառ',
                //         icon: "error",
                //         button: 'Լավ'
                //     });
                // }

            }).catch(() => {
            swal({
                title: "Չհաջողվեց!",
                text: 'Սխալ մուտքանուն կամ գաղտնաբառ',
                icon: "error",
                button: 'Լավ'
            });
        })
    }
};

export const LogOut = () => {
    return dispatch => {
        dispatch({
            type: AUTH_CONSTS.LOG_OUT
        });
        history.push('/login');
    }
};

export const TokenValidation = () => {
    const requestData = {
        url: URLS.authenticate,
        method: "GET",
        token:true,
    };
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then(async (res) => {
                await dispatch({
                    type: AUTH_CONSTS.SET_ACCESS_PAGES,
                    payload: res?.data?.user
                });
                return res?.data?.accessToken;
            })
    }
};

export const RefreshToken = () => {

    const refToken = store.getState().auth.refreshToken;
    // console.log('start-refreshing-function');
    const requestData = {
        url: `${_urlOauth}/refresh-token`,
        method: "POST",
        data: stringify({
            grant_type: "refresh_token",
            refresh_token: refToken
        }),
        customHeaders: {
            "Content-Type": "application/x-www-form-urlencoded",
            "authorization": ClientEncoded,
        }
    };
    return request(requestData).then(async (response) => {
        //   console.log(response);
        response && await store.dispatch({
            type: AUTH_CONSTS.REFRESH_TOKEN,
            payload: response.data
        });
        return response && response.data && response.data.accessToken;
    }).catch(() => null)
};
