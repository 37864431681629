import {STATUS_CONSTS} from "../constants";

export const initialState = {
    statusList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case STATUS_CONSTS.GET_STATUSES:
            return {
                ...state,
                statusList: action.payload || [],
            };
        case STATUS_CONSTS.CREATE_STATUS:
            return {
                ...state,
                statusList: [action.payload].concat(state.statusList)
            };
        case STATUS_CONSTS.UPDATE_STATUS:
            return {
                ...state,
                statusList: state.statusList.map(item => item.id === action.payload.id
                    ? action.payload : item)
            };
        case STATUS_CONSTS.DELETE_STATUS_BY_ID:
            return {
                ...state,
                statusList: state.statusList.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
