import React from "react";
import {Link} from "react-router-dom";

import {Icon} from "../utils/Icon";
import "../../assets/styles/headerStyles/headerLogo.scss";


export function HeaderLogo(props) {
    const {toggleMenu, leftSideMenuOpen} = props;
    return <div className={`header-logo ${leftSideMenuOpen ? "menu-open" : ""}`}>
        <Icon icon="align-left" onClick={toggleMenu}/>
        <div className="brand-name">
            <Link to={'/dashboard'}>
                <img src={require("../../assets/images/header-logo.svg")} alt="logo"/>
            </Link>
        </div>
    </div>
}
