// Import packages
import React, {useEffect} from "react";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";
import {connect} from "react-redux";

// Import styles
import "./side-menu.scss";
import {ReactComponent as CloseIcon} from "../../../assets/images/ic_close.svg";

// Import utils

import {generateImageMediaUrl} from "../../../utils/generateMemberMediaUrl";
import {mapStateToProps} from "../../../redux/mapStateToProps";
import {GetNewLog} from "../../../redux/actions/newLogActions";
import {MaterialIcon} from "../../utils/Icon";
import {CRUD_ICONS} from "../../../constants/constTypes";

const logList = [
    {
        user: {
            firstName: 'Edita',
            lastName: 'Sahakyan',
        },
        action: 'deleted',
        description: 'product item',
        createdAt: "2021-12-06T10:09:45.542Z"
    },
    {
        user: {
            firstName: 'Edita',
            lastName: 'Sahakyan',
        },
        action: 'updated',
        description: 'product item',
        createdAt: "2021-12-06T10:09:45.542Z"
    },
    {
        user: {
            firstName: 'Edita',
            lastName: 'Sahakyan',
        },
        action: 'deleted',
        description: 'category item',
        createdAt: "2021-12-06T10:09:45.542Z"
    },
    {
        user: {
            firstName: 'Edita',
            lastName: 'Sahakyan',
        },
        action: 'created',
        description: 'product item',
        createdAt: "2021-12-06T10:09:45.542Z"
    },
]

function SideMenu(props) {
    const {showMenu, toggleMenu, newLogList, newLogHasMore} = props;

    useEffect(() => {
        // console.log('useEffect', newLogList);
        props.GetNewLog()
    }, [])

    function loadMoreItems() {
        console.log('loadMoreItems');
        newLogHasMore && props.GetNewLog({
            reset: false,
            offset: newLogList.length,
        });
    }

    // console.log(newLogHasMore, 'newLogHasMore');
    return <div className={`side-menu ${showMenu ? 'show' : 'hide'}`}>
        <div className="header">
            <span>Menu</span>
            <CloseIcon title={''} className={'close-icon'} onClick={() => toggleMenu(false)}/>
        </div>
        <div className={'new-logs'}>
            <InfiniteScroll
                hasMore={newLogHasMore}
                loadMore={loadMoreItems}
                pageStart={0}
                isReverse={true}
                useWindow={false}
                initialLoad={false}>
                {
                    newLogList?.map((item) => {
                        return <div className={`log-item`} key={item.id}>
                            <span className={`log-icon ${CRUD_ICONS?.[item?.requestMethod]}`}>
                            <MaterialIcon icon={CRUD_ICONS?.[item?.requestMethod]}/>
                            </span>

                            <div className={'log-content'}>
                                {/*<span>{item?.user?.firstName}</span>*/}
                                {/*<span>{item?.user?.lastName}</span>*/}
                                {/*{item?.action}&nbsp;*/}
                                {item?.message}
                                <div className={'log-time'}>
                                    {moment(item?.createdAt).format('DD-MM-YYYY HH:mm')}
                                </div>
                            </div>
                        </div>
                    })
                }
            </InfiniteScroll>
        </div>
    </div>
}

const mapDispatchToProps = {
    GetNewLog
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);