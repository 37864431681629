import isEmail from "validator/es/lib/isEmail";

export function validateFields(validationData, requiredFields) {
    const errors = {};
    let result = true;
    Object.keys(validationData).forEach(key => {
        if (key === "email" && (validationData[key] === "" ||
            (!!validationData[key]?.length && !isEmail(validationData[key])))) {
            errors.username = true;
            result = false;
        }
        if (key === "password" && validationData[key].length < 8) {
            errors.password = true;
            result = false;
        }
        if (key === "repeatPassword" && validationData.repeatPassword !== validationData.password) {
            errors.repeatPassword = true;
            result = false;
        }
        if (requiredFields.find(item => item === key && !validationData[key])) {
            errors[key] = true;
            result = false;
        }

    });
    // console.log(errors, 'errors')
    return {result, errors};
}

export const validateFieldsData = (fieldsData, errors, requiredFields) => {
    let result = true;
    Object.keys(errors).map((field) => {
        if (field === "username" && (fieldsData[field] === "" ||
            (!!fieldsData[field]?.length && !isEmail(fieldsData[field])))) {
            errors.username = true;
            result = false;
        }
        // if (field === "bonus" && parseInt(fieldsData[field]) > 100 || parseInt(fieldsData[field]) < 1) {
        //     errors[field] = true;
        //     result = false;
        // }
        // if (field === "commission" && parseInt(fieldsData[field]) > 100 || parseInt(fieldsData[field]) < 1) {
        //     errors[field] = true;
        //     result = false;
        // }
        if (field === "username" && (fieldsData[field] === "" ||
            (!!fieldsData[field]?.length && !isEmail(fieldsData[field])))) {
            errors.username = true;
            result = false;
        }

        if (field.toLowerCase().includes('phone') && !/^[+]?[0-9]{9,20}$/.test(fieldsData[field]) && !!fieldsData[field]) {
            errors[field] = true;
            result = false;
        }

        if (field.toLowerCase().includes("email") && !!fieldsData?.[field] && !isEmail(fieldsData?.[field])) {
            errors[field] = true;
            result = false;
        }

        if (field === "password" && !/^(?=.*[a-z]).(?=.*[0-9]).{7,}$/.test(fieldsData[field]) && !!fieldsData[field]) {
            errors.password = true;
            result = false;
        }
        if (field === "password" && !!fieldsData[field] && fieldsData[field]?.length < 8) {
            errors.password = true;
            result = false;
        }

        if (field === "repeatPassword" && !!fieldsData[field] && fieldsData.repeatPassword !== fieldsData.password) {
            errors.repeatPassword = true;
        }
        if (requiredFields?.includes(field) && ((Array.isArray(fieldsData[field]) && !fieldsData[field]?.length)
            || (!fieldsData[field] || !fieldsData[field]?.toString()?.length))) {
            // console.log(field, 'field', fieldsData[field], '----', Array.isArray(!fieldsData[field]) ,!fieldsData[field], !fieldsData[field]?.toString()?.length);
            errors[field] = true;
            result = false;
        }
    });

    return result;
};

//
// export const validateFields = (err, data) => {
//     console.log('start-validation');
//     let errors = {};
//     let result = true;
//     Object.keys(err).forEach(item => {
//         //console.log(typeof(err[item]),'subResult');
//         if (typeof(err[item]) === "object" && err[item] !== null) {
//             const subResult = validateFields(err[item], data[item]);
//             //console.log(subResult,'subResult');
//             errors[item] = {...subResult.errors};
//             if (!subResult.result) {
//                 result = false;
//             }
//         }
//         else if (!data[item] || (data[item] && data[item].length === 0) || data[item] === "" || data[item] === 0) {
//             errors[item] = true;
//             result = false;
//         }
//         else {
//             errors[item] = false;
//         }
//     });
//     return {result, errors};
// };
