import {USERS_CONSTS, AUTH_CONSTS} from "../constants";

export const initialState = {
    usersList: {
        itemsList: [],
        hasMore: false,
        isVerified: undefined,
        isBlocked: undefined
    },
    userById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USERS_CONSTS.GET_USERS:
            const itemsList = action.payload?.data;

            return {
                ...state,
                usersList: {
                    itemsList: action.payload.reset ? itemsList : [...state.usersList.itemsList, ...itemsList],
                    isVerified: action.payload.isVerified,
                    isBlocked: action.payload.isBlocked,
                    hasMore: action.payload.hasMore,
                }
            };
        case USERS_CONSTS.GET_USER_BY_ID:
            return {
                ...state,
                userById: action.payload
            };
        case USERS_CONSTS.TOGGLE_USER_BLOCK_STATE:
            return {
                ...state,
                userById: state.userById ? {...state.userById, isBlocked: action.payload.state} : null,
                usersList: {
                    ...state.usersList,
                    itemsList: state.usersList.itemsList.map(user => user?.id === action?.payload?.id ? {
                        ...user,
                        isBlocked: action.payload.state
                    } : user)
                }
            };
        case USERS_CONSTS.CLEAR_USER_BY_ID:
            return {
                ...state,
                userById: null
            };

        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
