// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import Nestable from "react-nestable";

// Import components
import { NestableItem } from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { deleteItem } from "../../utils/helperFunctions";
import {
    GetNewsList,
    DeleteNewsById
} from "../../redux/actions";
import moment from "moment";
import ItemsList from "../../components/ItemsList";
import { generateFileMediaUrl } from "../../utils/generateMemberMediaUrl";


class News extends Component {
    constructor (props) {
        super(props);
        this.listRef = React.createRef();
        this.deleteItem = deleteItem.bind(this, props.DeleteNewsById, 'նորությունը');
    }

    componentDidMount () {
        this.props.GetNewsList();
    }

    render () {
        let { newsList, requestLoading } = this.props;
        let headerInfo = {
            generalInfo: [
                "Նկար",
                "Անվանում",
                "Դիտումներ",
                "Օգտատերեր",
                "Կարգավիճակ",
                "Ստեղծվել է"
            ],
            largeItems: [],
            status: false,
            actions: ["edit", 'delete'],
        };
        const itemsInfo = newsList?.map(item => {
            const { id, title, mediaMain, isHidden, views, createdAt } = item || {};
            return {
                id: item.id,
                values: [
                    <img className={'news-image'} src={generateFileMediaUrl(mediaMain?.path)} alt="news"/>,
                    title ?? '-',
                    views?.count ?? 0,
                    views?.users ?? 0,
                    isHidden ? 'Ոչ Ակտիվ' : 'Ակտիվ',
                    <div className={'activity'}>
                        {moment(createdAt).format("DD.MM.YY")}
                    </div>,
                ],
                deleteCb: deleteItem.bind(this, this.props.DeleteNewsById, 'նորությունը', id),
            }
        });
        return <PageWrapper pageTitle={'Նորություններ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/news/add"}/>
            <ItemsList
                className={'news-list'}
                itemListHeaderInfo={headerInfo}
                itemListInfo={itemsInfo}
                editLink={"news/edit"}
                loading={requestLoading}
                listRef={this.listRef}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetNewsList,
    DeleteNewsById
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
