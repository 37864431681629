// Import packages
import React, {Component} from "react";
import {Link} from "react-router-dom";

// Import Styles
import '../../assets/styles/cards/list-card.scss';

// Import components
import {LinkButton} from "../buttons/buttons";
import {MaterialIcon} from "../utils/Icon";
import {Checkbox} from "antd";
import {STATUS_LIST} from "../../constants/constTypes";


class ListItemCard extends Component {

    render() {
        const {
            data, status, actions, largeItems, largeItemsWidth, smallItemsWidth, detailsLink, editLink,
            selectingActive
        } = this.props;

        return <div className={`list-item-wrapper ${data?.newItem ? 'selected' : ''}`}>
            {selectingActive && <span className={'selecting-checkbox'}>
                <Checkbox checked={data?.selected} onChange={data?.handleSelect}/>
            </span>}
            <div className="general-info-content">
                {data?.values && !!data.values.length && data.values.map((item, index) => {
                    const width = (index === 1 || largeItems.includes(index))
                        ? largeItemsWidth
                        : smallItemsWidth;
                    return detailsLink ?
                        <Link className="general-info-item" style={{width: `${width}%`}} key={index}
                              to={{
                                  pathname: `/${detailsLink}/${data.id ? data.id : data._id}`,
                                  state: data?.details
                              }}>
                            {item}
                        </Link>
                        :
                        <span className="general-info-item" style={{width: `${width}%`}}
                              key={index}>
                            {item}
                        </span>
                })}
            </div>
            <div className="item-actions-part">
                {status && <>
                    {
                        typeof data?.status === 'boolean' ?
                            <div className="status">{data?.status ? 'Ակտիվ' : 'Ոչ ակտիվ'}</div>
                            : <span className={`status-badge`}
                                    style={{backgroundColor: data?.status?.color ? data?.status?.color : '#eccb3b'}}>
                    {data?.status?.title}</span>
                    }
                </>
                }
                {actions && <div className="actions">
                    {actions && !!actions && actions.map((item) => {
                        return item === "edit" ?
                            <LinkButton link={{
                                pathname: `${editLink}/${data.id ? data.id : data._id}`
                            }} key={item} title={<MaterialIcon icon="edit"/>}
                                        className="bg-orange"/>

                            : (item === "delete" ?
                                <LinkButton key={item}
                                            title={<MaterialIcon icon="delete"/>}
                                            className="bg-red"
                                            disabled={!data.deleteCb}
                                            cb={data.deleteCb}/>
                                : (item === "details" ?
                                    <LinkButton link={{
                                        pathname: `/${detailsLink}/${data.id ? data.id : data._id}`,
                                        state: data?.details
                                    }} key={item} title={<MaterialIcon
                                        icon="keyboard_arrow_right"/>}/>
                                    : (item === "archive" ?
                                        <LinkButton cb={data.archiveCb}
                                                    key={item}
                                                    disabled={!data.archiveCb}
                                                    className="bg-grey"
                                                    title={<MaterialIcon
                                                        icon={data?.archived ? "unarchive" : "archive"}/>
                                                    }/> : "")))
                    })}
                </div>}
            </div>
        </div>
    }
}

export default ListItemCard
