import {request, _urlReviews} from "../api";
import {REVIEW_CONSTS, UTIL_CONSTS} from "../constants";
import {LIMIT_GET_REVIEWS} from "../../constants/constLimitCounts";

export const GetReviews = ({
                               reset = true,
                               offset = 0,
                               limit = LIMIT_GET_REVIEWS,
                               text = '',
                               userId = null,
                               getResponse = false,
                           } = {}) => {
    let url = `${_urlReviews}`;
    text && (url += `&text=${encodeURIComponent(text)}`);
    userId && (url += `&userId=${userId}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };
    return dispatch => {
        reset && !getResponse && dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then(({data}) => {
                if (getResponse) {
                    return {
                        data,
                        hasMore: data?.length === limit,
                    };
                }else{
                    data && dispatch({
                        type: REVIEW_CONSTS.GET_REVIEWS,
                        payload: {
                            data,
                            text,
                            reset,
                            hasMore: data?.length === limit,
                        }
                    });
                }
            }).finally(() => {
                reset && !getResponse && dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const GetReviewReplies = (reviewId) => {
    let url = `${_urlReviews}/${reviewId}/replies`;
    const requestData = {
        url,
        token: true,
        method: "GET",
    };
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData).then((res) => res.data)
    }
};

export const SetReviewStatus = (reviewId, data) => {
    let url = `${_urlReviews}/${reviewId}`;
    const requestData = {
        url,
        token: true,
        method: "PUT",
        data
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                /*  dispatch({
                      type: REVIEW_CONSTS.SET_REVIEW_AS_SEEN,
                      payload: reviewId
                  });*/
            })
    }
};

export const DeleteReview = (reviewId) => {
    let url = `${_urlReviews}/${reviewId}`;
    const requestData = {
        url,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData).then(() => {
            dispatch({
                type: REVIEW_CONSTS.DELETE_REVIEW,
                payload: reviewId
            })
        })
    }
};


export const SetReviewAsSeen = (id) => {
    let url = `${_urlReviews}/${id}/seen`;
    const requestData = {
        url,
        token: true,
        method: "PUT",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: REVIEW_CONSTS.SET_REVIEW_AS_SEEN,
                    payload: id
                });
            })
    }
};


export const AddReply = (reviewId, data) => {
    let url = `${_urlReviews}/${reviewId}/replies`;
    const requestData = {
        url,
        token: true,
        method: "POST",
        data
    };
    return dispatch => {
        return request(requestData)
    }
};

export const UpdateReply = (reviewId, replyId, data) => {
    let url = `${_urlReviews}/${reviewId}/replies/${replyId}`;
    const requestData = {
        url,
        token: true,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
    }
};

export const SetAllReviewsAsSeen = () => {
    let url = `${_urlReviews}/seen-all`;
    const requestData = {
        url,
        token: true,
        method: "PUT",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: REVIEW_CONSTS.SET_ALL_REVIEWS_AS_SEEN,
                });
            })
    }
};


export const GetNotSeenReviewsCount = () => {
    let url = `${_urlReviews}/not-seen-count`;
    const requestData = {
        url,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                // console.log('non-seen-count', data);
                data && dispatch({
                    type: REVIEW_CONSTS.GET_NOT_SEEN_REVIEWS_COUNT,
                    payload: data
                });
            })
    }
};
