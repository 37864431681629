import { SHOP_CONSTS } from "../constants";

export const initialState = {
    shopsList: {
        itemsList: [],
        hasMore: false,
        date: null,
    },
    shopById: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOP_CONSTS.GET_SHOPS:
            return {
                ...state,
                shopsList: {
                    itemsList: action.payload.reset ? action.payload.data :
                        [...state.shopsList?.itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                    date: action.payload.date,
                },
            };
        case SHOP_CONSTS.GET_SHOP_BY_ID:
            return {
                ...state,
                shopById: action.payload
            };
        default:
            return state;
    }
}
