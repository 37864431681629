// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import 'moment/locale/hy-am';

// Import Styles
import '../../assets/styles/containerStyles/shop.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";
import { DatePicker } from "antd";
import { InputGroup } from "../../components/uiElements/inputGroup";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { GetShopsActivity, GetShopById } from "../../redux/actions";
import { parseShopsData, dateOptions } from "./utils/actionFunctions";

class Dashboard extends Component {
    constructor () {
        super();
        this.state = {
            date: ''
        };
        this.listRef = React.createRef();
        this.loadMoreItems = this.loadMoreItems.bind(this,);
        this.handleChangeData = this.handleChangeData.bind(this,);
    }

    componentDidMount () {
        this.props.GetShopsActivity()
    }

    loadMoreItems () {
        const { shopsList } = this.props;
        shopsList.hasMore && this.props.GetShopsActivity({
            reset: false,
            offset: shopsList.itemsList.length,
            date: shopsList.date
        });
    };

    handleChangeData ({ value }) {
        let lastActivity
        if(value){
            let currentDate = Date.now();
            lastActivity = new Date(currentDate - value).toISOString();
        }
        this.setState({ date: value })
        this.props.GetShopsActivity({
            date: lastActivity
        });
    }

    render () {
        const { shopsList, requestLoading } = this.props;
        const { date } = this.state;
        const { hasMore } = shopsList;

        let headerInfo = {
            generalInfo: [
                "Անվանում",
                "Հասցե",
                "Օգտ․ քանակ",
                "Վերջին ակտիվիություն"
            ],
            largeItems: [],
            status: false,
        };
        const itemsInfo = parseShopsData(shopsList)

        return <PageWrapper withActions={true} pageTitle={'Պատմություն'}>
            <section className="shop">
                <PageHeader
                    search={false}
                    renderingCustomLeftPart={<div className="shop-date-part">
                        <InputGroup inputType="selectCustom"
                                    placeholder="Ոչ Ակտիվ"
                                    name="date"
                                    showSearch={false}
                                    value={date || undefined}
                                    labelInValue={true}
                                    withClear={true}
                                    optionLabelProp={'label'}
                                    onChange={this.handleChangeData}
                                    options={dateOptions}>
                            {/*{type && <Select.Option value={undefined}>{'Բոլորը'}</Select.Option>}*/}
                        </InputGroup>
                    </div>}
                />
                <ItemsList
                    className={'shops-list'}
                    itemListHeaderInfo={headerInfo}
                    itemListInfo={itemsInfo}
                    loading={requestLoading}
                    infiniteScroll={true}
                    hasMore={hasMore}
                    listRef={this.listRef}
                    loadMoreItems={this.loadMoreItems}/>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetShopsActivity,
    GetShopById
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
