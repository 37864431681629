import {request, _urlBranch} from "../api";
import {BRANCH_CONSTS, UTIL_CONSTS} from "../constants";
import {notificationSuccess} from "../../components/uiElements/Notifications";
import {history} from "../../configs/history";

export const GetBranches = (id) => {
    const requestData = {
        url: `${_urlBranch}/${id}`,
        method: "GET",
        token: true,
        scope: true,
    };

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: BRANCH_CONSTS.GET_BRANCHES,
                    payload: data
                })
            })
            .finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const ClearBranches = () => {
    return dispatch => {
        dispatch({
            type: BRANCH_CONSTS.CLEAR_BRANCHES,
        });
    }
};

export const AddBranch = (id, data) => {
    const requestData = {
        url: `${_urlBranch}/${id}`,
        token: true,
        scope: true,
        method: "POST",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                notificationSuccess({
                    description: 'Մասնաճյուղը հաջողությամբ ստեղծվեց!'
                })
                dispatch({
                    type: BRANCH_CONSTS.ADD_BRANCH,
                    payload: data
                });
                history.push(`/companies/company-details/${id}`)
            })
    }
};

export const UpdateBranch = (id, branchId, data) => {
    const requestData = {
        url: `${_urlBranch}/${branchId}`,
        method: "PATCH",
        token: true,
        scope: true,
        data,
    };

    return (dispatch) => {
        return request(requestData)
            .then(({data}) => {
                notificationSuccess({
                    description: "Մասնաճյուղը հաջողությամբ փոփոխվեց!"
                })
                history.push({
                    pathname: `/companies/company-details/${id}`,
                })
            })
    }
};

export const DeleteBranchById = (id, productId) => {
    const requestData = {
        url: `${_urlBranch}/${id}`,
        method: "DELETE",
        token: true,
        scope: true,
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: BRANCH_CONSTS.DELETE_BRANCH_BY_ID,
                    payload: productId
                });
            })
            .catch(() => {
            })
    }
};



