// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetLanguages} from "../../redux/actions";


class Languages extends Component {
    constructor() {
        super();
        this.state = {
            alertDialogOpen: false,
            alertMode: "",
            removingIndex: null
        };
    }

    componentDidMount() {
         this.props.GetLanguages();
    }

    render() {
        const {languages} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Կոդ", "Անուն"],
            largeItems: [0],
            status: true,
            actions: ["edit"],
        };
        let itemListInfo = [];
        languages && !!languages.length && languages.forEach(language => {
            itemListInfo.push({
                id: language.id,
                values: [language.code, language.name],
                status: !language.isHidden
            })
        });

        return <PageWrapper pageTitle={'Լեզուներ'}>
            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       editLink={"/languages/edit"}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetLanguages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Languages);
