// Import packages
import React, {PureComponent} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/cards/review-card.scss';
import userAvatar from "../../assets/images/user_avatar.png";

// Import components
import {LinkButton} from "../buttons/buttons";
import {Rate} from "antd";
import moment from 'moment';

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {SetReviewStatus, GetReviewReplies, DeleteReview, AddReply, UpdateReply} from "../../redux/actions";
import {generateFileMediaUrl} from "../../utils/generateMemberMediaUrl";
import {InputGroup} from "../uiElements/inputGroup";
import {ADMIN_ID} from "../../constants/constIds";

class ReviewCard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            tempReplyText: '',
            tempReplyRate: 0,
        };
        this.getInputValue = this.getInputValue.bind(this);
        this.cancelEditing = this.cancelEditing.bind(this);
        this.startEditing = this.startEditing.bind(this);
        this.updateReply = this.updateReply.bind(this);
    }

    getRate(name, rate) {
        this.setState({[name]: rate});
    }

    getInputValue({name, value}) {
        this.setState({[name]: value});
    }

    startEditing() {
        const {replyData} = this.props;
        this.setState({
            isEditing: true,
            tempReplyText: replyData?.text,
            tempReplyRate: replyData?.rate,
        });
    }

    cancelEditing() {
        this.setState({
            isEditing: false,
            tempReplyText: '',
            tempReplyRate: '',
        });
    }

    updateReply() {
        const {replyData} = this.props;
        const {tempReplyText, tempReplyRate} = this.state;
        const reqData = {
            text: tempReplyText,
        };
        tempReplyRate && (reqData.rate = tempReplyRate);
        this.props.updateReply(replyData.id, reqData).then(() => {
            this.setState({
                tempReplyText: '',
                tempReplyRate: 0,
                isEditing: false,
            });
        })
    }

    render() {
        const {replyData} = this.props;
        const {isEditing, tempReplyText, tempReplyRate} = this.state;

        return <div className={'review-item-wrapper'}>
            <img className={'delete-review'} alt={''}
                 onClick={()=>this.props.deleteReply(replyData?.id)}
                 src={require('../../assets/images/ic_close_crop.svg')}/>
            <div className={'user-image'}>
                <img src={replyData?.reviewer?.profilePicturePath?.path ?
                    generateFileMediaUrl(replyData?.reviewer?.profilePicturePath?.path)
                    : userAvatar}
                     alt="user"/>
            </div>
            <div className={'info-part'}>
                <div className={'user-name-and-rate'}>
                    <span className={'user-name'}>
                        {replyData?.reviewer?.firstName + " " + replyData?.reviewer?.lastName}
                        {replyData?.reviewer?.id === ADMIN_ID &&
                        <img className={'edit-icon'}
                             onClick={this.startEditing.bind(this, replyData?.id)}
                             src={require('../../assets/images/ic_edit.svg')}
                             alt={''}/>}
                    </span>
                    <div className={'right-part'}>
                        {replyData.updatedAt && <span>
                            {moment(replyData.updatedAt).format("DD.MM.YYYY / HH:mm")}
                        </span>}
                        <Rate disabled={!isEditing}
                              allowClear={false}
                              value={isEditing ? tempReplyRate : replyData?.rate}
                              onChange={this.getRate.bind(this, 'tempReplyRate')}
                              className={'review-rate'}/>
                    </div>
                </div>
                <div className={'review-content'}>
                    {isEditing ? <div className={'editing-reply'}>
                        <InputGroup inputType="textarea"
                                    placeholder="Մեկնաբանություն"
                                    name={"tempReplyText"}
                                    maxLength={256}
                                    value={tempReplyText}
                                    onChange={this.getInputValue}/>
                        <LinkButton title={'Փոփոխել'}
                                    disabled={!tempReplyText || !(replyData?.text !== tempReplyText || replyData?.rate !== tempReplyRate)}
                                    cb={this.updateReply}/>
                    </div> : <p>
                        {replyData?.text}
                    </p>}
                </div>
            </div>
        </div>

    }
}

ReviewCard.defaultProps = {
    reply: false
}
const mapDispatchToProps = {
    SetReviewStatus,
    GetReviewReplies,
    DeleteReview,
    AddReply,
    UpdateReply
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewCard)
