// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch, Tree} from "antd";
import {history} from "../../configs/history";
import Nestable from "react-nestable";

import 'suneditor/dist/css/suneditor.min.css';
import plugins from 'suneditor/src/plugins'
// Import Styles
import '../../assets/styles/containerStyles/appended-product-section.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {Editor} from "../../components/uiElements/Editor";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {MediaFileCard} from "../../components/media/MediaFileCard";


// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getPostReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField,
} from "../../utils/helperFunctions";
import {CreateNews, GetNewsById, UpdateNewsById} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {DEFAULT_IMAGE_ID, MEDIA_MODAL_TYPES} from "../../constants/constTypes";


class AddEditNews extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
            'details',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                medias: [],
                isHidden: false,
                new: false,
            },
            currentData: null,
            errors: {},
            errorsTabs: [],
            loading: false,
            requestLoading: false,
            languageTab: props.mainLanguage,
            isEditing: !!props?.id,
            mediaModalOpen: false,
        };
        this.updatedDataMap = new Map();
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.onMediaPositionChange = this.onMediaPositionChange.bind(this);
        this.checkMediaListDifferent = this.checkMediaListDifferent.bind(this);
        this.mediaCardWrapper = this.mediaCardWrapper.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.addUpdateNews = this.addUpdateNews.bind(this);
    }

    async componentDidMount() {
        const {isEditing,} = this.state;
        if (isEditing) {
            const id = this.props?.id;
            await this.props.GetNewsById(id);
            const {newsById} = this.props;
            console.log('newsById', newsById)
            if (!newsById) {
                history.push('/news');
                return;
            }
            // Translations
            const translationsData = {};
            newsById.translations.forEach(item => {
                translationsData[item?.languageId] = {
                    ...translationsData[item?.languageId],
                    title: item?.title || '',
                    description: item?.description || '',
                    details: item?.details || '',
                };
            });
            //Medias
            const medias = [];
            if (Array.isArray(newsById?.medias)) {
                newsById.medias && newsById.medias.forEach(media => {
                    media.id !== DEFAULT_IMAGE_ID && medias.push(media);
                })
            }

            const initStateData = {
                translations: translationsData,
                medias: medias,
                isHidden: !!newsById?.isHidden,
                new: !!newsById?.new,
            }

            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    checkMediaListDifferent(currMedias) {
        let result = false;
        const initMedias = this.currentData.medias;
        if (!Array.isArray(currMedias) || (currMedias?.length !== initMedias.length)) {
            result = true;
        } else {
            currMedias.forEach((item, index) => {
                if (initMedias[index]?.id !== item?.id) {
                    result = true;
                }
            })
        }
        result ? this.updatedDataMap.set('medias', currMedias?.map(m => m.id))
            : this.updatedDataMap.delete('medias');
    }

    onMediaPositionChange(items) {
        const {fieldsData, isEditing} = this.state;
        const medias = items.map(item => item.media);
        if (isEditing) {
            this.checkMediaListDifferent(medias)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                medias: medias
            }
        })
    }

    getMedia(mediaArray) {
        const {fieldsData, isEditing} = this.state;
        /*  const medias = [...fieldsData.medias];
          mediaArray.forEach(p => {
              if (!medias.some(media => media.id === p.id)) {
                  medias.push(p);
              }
          })*/
        if (isEditing) {
            this.checkMediaListDifferent(mediaArray)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                medias: mediaArray,
            },
        })
    }

    deleteMedia(id) {
        const {fieldsData, isEditing} = this.state;
        let medias = fieldsData.medias.filter(m => m.id !== id);

        if (isEditing) {
            this.checkMediaListDifferent(medias)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                medias: medias,
            }
        })
    }

    mediaCardWrapper(data) {
        return <MediaFileCard item={data?.item?.media}
                              customDelete={this.deleteMedia}/>
    }

    async addUpdateNews() {
        await this.setState({
            requestLoading: true
        });
        const {fieldsData} = this.state;
        const {translations, price} = fieldsData;
        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};
        if (result) {
            if (!this.state.isEditing) {
                const reqData = this.getPostReqData(fieldsData);
                this.props.CreateNews(reqData).finally(() => {
                    this.setState({requestLoading: false})
                })
            } else {
                const trData = getTranslationData(translations);
                const reqData = this.getPatchReqData(trData);

                this.props.UpdateNewsById(this.props.id, reqData).finally(() => {
                    this.setState({loading: false});
                });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {filtersList, categoriesList, countriesList} = this.props;
        const {
            fieldsData, languageTab, isEditing, loading, errors, mediaModalOpen, errorsTabs,
        } = this.state;
        const {translations} = fieldsData;
        const trData = translations[languageTab] || {};
        const initTrData = this.currentData?.translations[languageTab] || {};
        const mediaItems = [];

        fieldsData.medias.forEach(media => {
            mediaItems.push({
                id: media?.id,
                media: media
            })
        });
        let countriesOptions = countriesList?.map(item => {
            return {
                name: item?.title,
                id: item?.id,
            };
        });
        // console.log(countriesOptions, 'this.countriesOptions');
        return <PageWrapper pageTitle={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'} Ապրանք`}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: 'isHidden',
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className="fixed-top-right second-switch">
                    <label>{'Նոր'}</label>
                    <Switch checked={fieldsData.new}
                            onChange={(checked) => this.getInputValues({
                                name: 'new',
                                value: checked,
                                haveChanges: this.currentData?.new !== checked
                            })}/>
                </div>
                <div className={'products-medias-list-wrapper '}>
                    <div className={'label-and-action'}>
                        <label>Նկարներ</label>
                        <LinkButton title="Ընտրել Մեդիադարանից"
                                    className={`media-select-btn `}
                                    cb={this.toggleMediaModal}/>
                    </div>
                    <section className={'items-wrapper media-items'}>
                        {mediaItems.length ? <Nestable
                                items={mediaItems}
                                maxDepth={1}
                                onChange={this.onMediaPositionChange}
                                renderItem={this.mediaCardWrapper}
                            /> :
                            <p className={'empty-text'}>Նկարներ չկան</p>}
                    </section>
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              activeTab={languageTab}
                              errorsTabs={errorsTabs}/>
                <InputGroup inputType="input"
                            type="text"
                            label={"Անվանում*"}
                            placeholder="Անվանում"
                            name="title"
                            value={trData?.title}
                            required={true}
                            initValue={initTrData?.title}
                            error={errors?.['title' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                <div className={'row'}>
                    <div className={'editor-wrapper'}>
                        <label>Նկարագրություն</label>
                        <Editor value={trData.description || ""}
                                name={'description'}
                                initValue={initTrData.description}
                                error={errors['description' + languageTab]}
                                onChange={this.getTranslatableInputValues}/>
                    </div>
                    <div className={'editor-wrapper'}>
                        <label>Նկարագրություն</label>
                        <Editor value={trData.details || ""}
                                name={'details'}
                                initValue={initTrData.details}
                                error={errors['details' + languageTab]}
                                onChange={this.getTranslatableInputValues}/>
                    </div>
                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'}`}
                                loading={loading}
                                disabled={!this.updatedDataMap.size && isEditing}
                                cb={this.addUpdateNews}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                mediaType={MEDIA_MODAL_TYPES.MEDIA_ARRAY}
                appendMedias={fieldsData.medias}
                multiSelect={true}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />

        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateNews,
    UpdateNewsById,
    GetNewsById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditNews)
