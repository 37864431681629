// import React from "react";
import {_urlSocketNewLog, request} from "../api";
import {store} from "../store";


//import utils
import {ACTION_TYPES} from "../../socket/constants";
import {NEW_LOG_CONSTS} from "../constants";


export const GetNewLog = ({
                                     offset = 0,
                                     limit = 20,
                                     reset = true,
                                 } = {}) => {
    const requestData = {
        url: `${_urlSocketNewLog}?offset=${offset}&limit=${limit}`,
        method: "GET",
        languageFlag: true,
        scope : true,
        token: true
    };

    return dispatch => {
        // dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                console.log(data, '-----data');
                data && dispatch({
                    type: NEW_LOG_CONSTS.GET_NEW_LOG,
                    payload: {
                        data,
                        reset,
                        hasMore: data?.length === limit
                    }
                });
                return data;
            })
            .catch(err => {
            }).finally(() => {
                // dispatch({type: UTIL_CONSTS.END_LOADING});
            })
    };
};


export const newLog = (notification) => {
    // let path = history?.location?.pathname;
    // let inNotificationPage = path && path.startsWith('/');
    console.log('newLog');
    store.dispatch({
        type: ACTION_TYPES.NEW_LOG,
        payload: notification
    });

};


