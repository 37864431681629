import { _urlShops, request } from "../api";
import { ORDER_CONSTS, SHOP_CONSTS, UTIL_CONSTS } from "../constants";
import { LIMIT_GET_ORDERS } from "../../constants/constLimitCounts";

export const GetShopsActivity = ({
                                     reset = true,
                                     offset = 0,
                                     limit = 20,
                                     date = null,
                                 } = {}) => {
    const requestData = {
        url: `${_urlShops}/last-activity?offset=${offset}&limit=${limit}`,
        token: true,
        method: "GET",
    };
    if (date) {requestData.url += `&date=${date}`}
    return dispatch => {
        reset && dispatch({ type: UTIL_CONSTS.START_LOADING });
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: SHOP_CONSTS.GET_SHOPS,
                    payload: {
                        data: data,
                        date: date,
                        reset,
                        hasMore: data.length === limit
                    }
                })
            }).finally(() => {
                reset && dispatch({ type: UTIL_CONSTS.END_LOADING });
            })
    }
};


export const GetShopById = (id) => {
    const requestData = {
        url: `${_urlShops}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SHOP_CONSTS.GET_SHOP_BY_ID,
                    payload: res.data
                })
            })
            .catch(() => {
            })
    }
};

