// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import swal from "sweetalert";

// Import Styles
import '../../assets/styles/containerStyles/order-details.scss';
import { ReactComponent as TickIcon } from "../../assets/images/ic_tick.svg";
import { ReactComponent as TickGreyIcon } from "../../assets/images/ic_tick_grey.svg";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import DetailsHeader from "../../components/uiElements/DetailsHeader";
import ItemsList from "../../components/ItemsList";
import { LinkButton } from "../../components/buttons/buttons";
import { InputGroup } from "../../components/uiElements/inputGroup";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    DeleteOrderById,
    GetNotSeenOrdersCount,
    UpdateOrderStatus,
    ClearOrders,
    SetOrderAsSeen
} from "../../redux/actions";
import { changeStateInputValue } from "../../utils/helperFunctions";
import { ORDER_STATUS_IDS, ORDERS_TYPE } from "../../constants/constTypes";
import { generateImageMediaUrl } from "../../utils/generateMemberMediaUrl";

class ReservationDetails extends Component {
    constructor (props) {
        super(props);
        this.state = {
            orderStatus: undefined,
            status: undefined,
            singleOrder:props.singleOrder
        };
        this.getInputValues = changeStateInputValue.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
    }


    async componentDidMount () {
        const { singleOrder } = this.state;
        singleOrder && (singleOrder.seen === false) && this.props.SetOrderAsSeen(singleOrder?.id).then(() => {
            this.props.GetNotSeenOrdersCount();
        });
        this.setState(() => ({
            orderStatus: singleOrder?.status?.id,
            status: singleOrder?.status
        }));
    }

    componentWillUnmount () {
        this.props.ClearOrders();
    }

    deleteOrderProduct = id => {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել ամրագրումը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteOrderById(this.state.singleOrder?.id, id);
            }
        });
    };

    updateStatus (statusId) {
        const { singleOrder } = this.state;
        const reqData = {
            statusId: statusId
        };
        this.props.UpdateOrderStatus(singleOrder?.id, reqData).then((res) => {
            let status = {};
            let item = this.props.statusList.find(item => item.id === statusId);
            status.id = item?.id;
            status.color = item?.color;
            status.title = item?.translations.find(tr => tr.languageId === this.props?.mainLanguage)?.title || '';
            this.setState({
                singleOrder:{
                    ...singleOrder,
                    status
                },
            })
        });
    }

    render () {
        const { status, singleOrder } = this.state;
        const { requestLoading } = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Նկար", "Անվանում", "Գին", "Օրերի Քանակ", "Սկսած", "Մինչև", "Նեսթ"],
            largeItems: [1, 2],
        };
        let itemListInfo = [];
        let sum = 0;

        singleOrder?.products?.length && singleOrder.products.forEach(item => {
            let price = item.price + " " + item?.currency;
            sum = item?.price + item?.couponPrice;

            itemListInfo.push({
                id: item?.productId,
                orderStatus: item?.status,
                values: [
                    <img src={generateImageMediaUrl(item?.mediaMain?.path)} alt=""/>,
                    item?.title || "-",
                    price,
                    item?.purchasedQuantity || "-",
                    singleOrder?.createdAt ? moment(singleOrder?.startDate).format('DD.MM.YYYY') : '',
                    singleOrder?.createdAt ? moment(singleOrder?.endDate).format('DD.MM.YYYY') : '',
                    <span className="is-nest">{item?.isNestOn ? <TickIcon title={''}/> :
                        <TickGreyIcon title={''}/>}</span>,
                ],
                deleteCb: this.deleteOrderProduct.bind(this, item?.productId)
            });
        });

        const orderData = {
            id: singleOrder?.id,
            name: (singleOrder?.name || '') + " " + (singleOrder?.surname || ''),
            email: singleOrder?.email || '',
            phone: singleOrder?.phone || '',
            createdAt: singleOrder?.createdAt ? moment(singleOrder?.createdAt).format('DD.MM.YYYY') : '',
            address: singleOrder?.address || '',
            userId: singleOrder?.user?.id,
        };

        return <PageWrapper>
            <section className="order-details">
                <DetailsHeader
                    data={orderData}
                    loading={requestLoading}
                />
                <div className="separator"/>
                <h1 className="orders-header">Ամրագրում</h1>
                <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                           itemListInfo={itemListInfo}
                           loading={requestLoading}
                />
                <div className="down-part">
                    <table className="down-left-part">
                        <tbody>
                        {!!singleOrder?.orderNumber && <tr className={'status'}>
                            <td className="description">Պատվերի համար։</td>
                            <td className="text-bold">
                                {singleOrder?.orderNumber}
                            </td>
                        </tr>}
                        {!!singleOrder?.reservationAmount && <tr className={'status'}>
                            <td className="description">Ամրագրման գումար։</td>
                            <td className="text-bold">
                                {singleOrder?.reservationAmount} {singleOrder?.products?.[0]?.currency}
                            </td>
                        </tr>}
                        <tr className={'status'}>
                            <td className="description">Կարգավիճակ։</td>
                            <td className="text-bold" style={{ color: status?.color }}>
                                {status?.title}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="amount">
                        {singleOrder?.reservationAmount} {singleOrder?.products?.[0]?.currency}
                    </div>
                </div>
                <div className="status-change-part">
                    <LinkButton title="Մերժել"
                                className={"bg-red"}
                                disabled={singleOrder?.status?.id !== ORDER_STATUS_IDS.NEW}
                                cb={this.updateStatus.bind(null,ORDER_STATUS_IDS.REJECTED )}/>
                    <LinkButton title="Հաստատել"
                                disabled={singleOrder?.status?.id !== ORDER_STATUS_IDS.NEW}
                                cb={this.updateStatus.bind(null,ORDER_STATUS_IDS.ACCEPTED )}/>
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    DeleteOrderById,
    UpdateOrderStatus,
    GetNotSeenOrdersCount,
    ClearOrders,
    SetOrderAsSeen
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationDetails)
