import {ACTION_TYPES} from "../../socket/constants";
import {AUTH_CONSTS, NEW_LOG_CONSTS} from "../constants";

export const initialState = {
    newLogSocketConnected: false,
    newLogList: [],
    newLogHasMore: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.N_AUTHENTICATED:
            return {
                ...state,
                newLogSocketConnected: true
            };
        case ACTION_TYPES.N_UNAUTHORIZED:
            return {
                ...state,
                newLogSocketConnected: false
            };
        case ACTION_TYPES.NEW_LOG:
            return {
                ...state,
                newLogList: [action.payload, ...state.newLogList]
            };
        case NEW_LOG_CONSTS.GET_NEW_LOG:
            // console.log('GET_NEW_LOG', action.payload);
            return {
                ...state,
                newLogHasMore: action.payload.hasMore,
                newLogList: action.payload.reset ? action.payload.data :
                    [...state.newLogList, ...action.payload.data]
            };

        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
