export const StaticTextFields = {
    notifications: {
        pageName: 'Notifications',
        list: [
            {
                label: "Notification default error",
                key: "notification_default_error"
            },
            {
                label: "Notification default error text",
                key: "notification_default_error_text"
            },
            {
                label: "Notification default success",
                key: "notification_default_success"
            },
            {
                label: "Notification default success text",
                key: "notification_default_success_text"
            },
            {
                label: "Notification list title",
                key: "notification_list_title"
            },
            {
                label: "Notification list empty",
                key: "notification_list_empty"
            }
        ]
    },
    errors: {
        pageName: 'Errors',
        list: [
            {
                label: "Error required field",
                key: "error_required_field"
            },
            {
                label: "Error duplicate entry",
                key: "error_duplicate_entry"
            },
            {
                label: "Error phone validation",
                key: "error_phone_validation"
            },
            {
                label: "Error password validation",
                key: "error_password_validation"
            },
            {
                label: "Error short password",
                key: "error_short_password"
            },
            {
                label: "Error repeat password validation",
                key: "error_repeat_password_validation"
            },
            {
                label: "Error user not verified",
                key: "error_user_not_verified"
            },
        ]
    },
    login: {
        pageName: 'Login',
        list: [
            {
                label: "Login - error invalid username",
                key: "login_error_invalid_username"
            },
            {
                label: "Login - error invalid password",
                key: "login_error_invalid_password"
            },
            {
                label: "Login - error not found or already is verified",
                key: "login_error_not_found_or_verified"
            },
            {
                label: "Login - error blocked account",
                key: "login_error_blocked_account"
            },
            {
                label: "Login - error unverified account",
                key: "login_error_unverified_account"
            },
            {
                label: "Login - page title",
                key: "login_page_title"
            },
            {
                label: "Login - page description",
                key: "login_page_description"
            },
            {
                label: "Login - sign in title",
                key: "login_form_title"
            },
            {
                label: "Login - placeholder email",
                key: "login_placeholder_email"
            },
            {
                label: "Login - placeholder password",
                key: "login_placeholder_password"
            },
            {
                label: "Login - forgot password text",
                key: "login_forgot_password_text"
            },
            {
                label: "Login - button title login",
                key: "login_btn_title_login"
            },
            {
                label: "Login - dont have account?",
                key: "login_dont_have_account"
            },
            {
                label: "Login - create an account",
                key: "login_create_an_account"
            },
        ]
    },
    signUp: {
        pageName: 'Sign Up',
        list: [
            {
                label: "SignUp - success",
                key: "sign_up_success"
            },
            {
                label: "SignUp - error exist username",
                key: "sign_up_error_exist_username"
            },
            {
                label: "SignUp - title",
                key: "sign_up_form_title"
            },
            {
                label: "SignUp - description",
                key: "sign_up_form_description"
            },
            {
                label: "SignUp - placeholder firstName",
                key: "sign_up_placeholder_firstName"
            },
            {
                label: "SignUp - placeholder lastName",
                key: "sign_up_placeholder_lastName"
            },
            {
                label: "SignUp - placeholder email",
                key: "sign_up_placeholder_email"
            },
            {
                label: "SignUp - placeholder password",
                key: "sign_up_placeholder_password"
            },
            {
                label: "SignUp - placeholder repeat password",
                key: "sign_up_placeholder_repeat_password"
            },
            {
                label: "SignUp - agreement text",
                key: "sign_up_agreement_text"
            },
            {
                label: "SignUp - terms and conditions",
                key: "sign_up_terms_conditions"
            },
        ]
    },
    accountActivation: {
        pageName: 'Account Activation',
        list: [
            {
                label: "Activation - success",
                key: "activation_success"
            },
            {
                label: "Activation - error link date expired",
                key: "activation_error_link_date_expired"
            },
            {
                label: "Activation - error account invalid code",
                key: "activation_error_account_invalid_code"
            },
            {
                label: "Activation - error account already activated",
                key: "activation_error_account_already_activated"
            },
        ]
    },
    verifyEmail: {
        pageName: 'Verify Email',
        list: [
            {
                label: "Activation - resend email success",
                key: "resend_email_success"
            },
            {
                label: "Activation - resend email error account already activated",
                key: "resend_email_error_account_already_activated"
            },
            {
                label: "Verify code - title",
                key: "verify_code_title"
            },
            {
                label: "Verify code - description",
                key: "verify_code_description"
            },
            {
                label: "Verify code - description not seen",
                key: "verify_code_description_not_seen"
            },
            {
                label: "Verify code - resend email text",
                key: "verify_code_resend_email_text"
            },
            {
                label: "Verify code - dont get code",
                key: "verify_code_dont_get_code"
            },
        ]
    },
    companyInformation: {
        pageName: 'Company Information',
        list: [
            {
                label: "Company Information - title",
                key: "company_information_title"
            },
            {
                label: "Company Information - description",
                key: "company_information_description"
            },
            {
                label: "Company Information - name placeholder",
                key: "company_information_placeholder_companyName"
            },
            {
                label: "Company Information - marketing name placeholder",
                key: "company_information_placeholder_marketingName"
            },
            {
                label: "Company Information - business direction placeholder",
                key: "company_information_placeholder_businessDirection"
            },
            {
                label: "Company Information - phone number placeholder",
                key: "company_information_placeholder_phoneNumber"
            },
            {
                label: "Company Information - VAT number placeholder",
                key: "company_information_placeholder_vatNumber"
            },
            {
                label: "Company Information - legal address placeholder",
                key: "company_information_placeholder_legalAddress"
            },
            {
                label: "Company Information - activity address placeholder",
                key: "company_information_placeholder_activityAddress"
            },
            {
                label: "Company Information - success created",
                key: "company_success_created"
            },
        ]
    },
    imageUploader: {
        pageName: 'Image Uploader',
        list: [
            {
                label: "Image Uploader - card btn",
                key: "upload_card_btn"
            },
            {
                label: "Image Uploader - img title",
                key: "upload_img_title"
            },
            {
                label: "Image Uploader - img description",
                key: "upload_img_description"
            },
            {
                label: "Image Uploader - img logo upload",
                key: "upload_img_logo_upload"
            },
        ]
    },
    directorInformation: {
        pageName: 'Director Information',
        list: [
            {
                label: "Director Information - title",
                key: "director_information_title"
            },
            {
                label: "Director Information - description",
                key: "director_information_description"
            },
            {
                label: "Director Information - firstname placeholder",
                key: "director_information_placeholder_directorName"
            },
            {
                label: "Director Information - lastName placeholder",
                key: "director_information_placeholder_lastName"
            },
            {
                label: "Director Information - phone number placeholder",
                key: "director_information_placeholder_phoneNumber"
            },
            {
                label: "Director Information - phone number email",
                key: "director_information_placeholder_email"
            },
        ]
    },
    forgotPassword: {
        pageName: 'Forgot Password',
        list: [
            {
                label: "Forgot password - email send success",
                key: "forgot_email_send_success"
            },
            {
                label: "Forgot password - email send error",
                key: "forgot_email_send_error"
            },
            {
                label: "Forgot password - user already exist error",
                key: "forgot_user_already_exist_error"
            },
            {
                label: "Forgot password - page title",
                key: "forgot_password_page_title"
            },
            {
                label: "Forgot password - page description",
                key: "forgot_password_page_description"
            },
            {
                label: "Forgot password - placeholder username",
                key: "forgot_password_placeholder_username"
            },
        ]
    },
    resetPassword: {
        pageName: 'Reset Password',
        list: [
            {
                label: "Reset password - title",
                key: "reset_password_title"
            },
            {
                label: "Reset password - description",
                key: "reset_password_description"
            },
            {
                label: "Reset password - placeholder new password",
                key: "reset_password_placeholder_new_password"
            },
            {
                label: "Reset password - placeholder repeat password",
                key: "reset_password_placeholder_repeat_password"
            },
            {
                label: "Reset password - success created",
                key: "reset_password_success"
            },
        ]
    },
    profile: {
        pageName: 'Profile',
        list: [
            {
                label: "Profile - title",
                key: "profile_title"
            },
            {
                label: "Profile - company name",
                key: "profile_company_name"
            },
            {
                label: "Profile - company marketing name",
                key: "profile_marketing_name"
            },
            {
                label: "Profile - company VAT_number",
                key: "profile_VAT_number"
            },
            {
                label: "Profile - company legal address",
                key: "profile_legal_address"
            },
            {
                label: "Profile - company activity address",
                key: "profile_activity_address"
            },
            {
                label: "Profile - responsible person",
                key: "profile_responsible_person"
            },
            {
                label: "Profile - phone number",
                key: "profile_phone_number"
            },
            {
                label: "Profile - company business direction",
                key: "profile_business_direction"
            },
            {
                label: "Profile - card title",
                key: "profile_card_title"
            },
            {
                label: "Profile - remove card",
                key: "profile_remove_card"
            },
            {
                label: "Profile - balance title",
                key: "profile_balance_title"
            },
            {
                label: "Profile - amount placeholder",
                key: "profile_amount_placeholder"
            },
            {
                label: "Profile - fill btn",
                key: "profile_fill_btn"
            },
            {
                label: "Profile - fill btn",
                key: "profile_fill_btn"
            },
            {
                label: "Profile - notification success",
                key: "profile_notification_success"
            },
            {
                label: "Profile - notification error",
                key: "profile_notification_error"
            },
        ]
    },
    buttons: {
        pageName: 'Buttons',
        list: [
            {
                label: "Button - continue",
                key: "continue_btn"
            },
            {
                label: "Button - save",
                key: "save_btn"
            },
            {
                label: "Button - excel",
                key: "excel_btn"
            },
            {
                label: "Button - create",
                key: "create_btn"
            },
            {
                label: "Button - update",
                key: "update_btn"
            },
            {
                label: "Button - close",
                key: "close_btn"
            },
        ]
    },
    leftSideMenu: {
        pageName: 'leftSideMenu',
        list: [
            {
                label: "Menu - branches",
                key: "menu_branches"
            },
            {
                label: "Menu - history",
                key: "menu_history"
            },
            {
                label: "Menu - bank history",
                key: "menu_bank_history"
            },
            {
                label: "Menu - card",
                key: "menu_card"
            },
            {
                label: "Menu - payments",
                key: "menu_payments"
            },
            {
                label: "Menu - employers",
                key: "menu_employers"
            },
            {
                label: "Menu - logout",
                key: "menu_logout"
            },
        ]
    },
    listItemCard: {
        pageName: 'leftSideMenu',
        list: [
            {
                label: "List item card - menu edit",
                key: "list_item_card_menu_edit"
            },
            {
                label: "List item card - menu delete",
                key: "list_item_card_menu_delete"
            },
            {
                label: "List item card - menu active",
                key: "list_item_card_menu_active"
            },
            {
                label: "List item - card menu deactivate",
                key: "list_item_card_menu_deactivate"
            },
            {
                label: "List item card - menu active",
                key: "list_item_card_menu_hide"
            },
            {
                label: "List item card - menu show",
                key: "list_item_card_menu_show"
            },
            {
                label: "List item - status placeholder",
                key: "list_item_status_placeholder"
            },
            {
                label: "List item - actions placeholder",
                key: "list_item_actions_placeholder"
            },
            {
                label: "List item - status active",
                key: "list_item_status_active"
            },
            {
                label: "List item - status inactive",
                key: "list_item_status_inactive"
            },
            {
                label: "List item - no search result",
                key: "list_item_no_search_result"
            },
        ]
    },
    branches: {
        pageName: 'Branches',
        list: [
            {
                label: "Branches - page_title",
                key: "branches_page_title"
            },
            {
                label: "Branches - name placeholder",
                key: "branches_placeholder_branchName"
            },
            {
                label: "Branches - phone number placeholder",
                key: "branches_placeholder_phoneNumber"
            },
            {
                label: "Branches - add button",
                key: "branches_add_btn"
            },
            {
                label: "Branches - empty view title",
                key: "branches_empty_title"
            },
            {
                label: "Branches - empty view description",
                key: "branches_empty_description"
            },
            {
                label: "Branches - back to branches",
                key: "branches_back_to_branches"
            },
            {
                label: "Branches - brand name placeholder",
                key: "branches_placeholder_brand_name"
            },
            {
                label: "Branches - first name placeholder",
                key: "branches_placeholder_first_name"
            },
            {
                label: "Branches - last name placeholder",
                key: "branches_placeholder_last_name"
            },
            {
                label: "Branches - responsible person placeholder",
                key: "branches_placeholder_responsible_person"
            },
            {
                label: "Branches - email address placeholder",
                key: "branches_placeholder_email_address"
            },
            {
                label: "Branches - address placeholder",
                key: "branches_placeholder_address"
            },
            {
                label: "Branches - create password placeholder",
                key: "branches_placeholder_create_password"
            },
            {
                label: "Branches - repeat password placeholder",
                key: "branches_placeholder_repeat_password"
            },
            {
                label: "Branches - phone number placeholder",
                key: "branches_placeholder_phone_number"
            },
            {
                label: "Branches - phone number 1 placeholder",
                key: "branches_placeholder_phone_number_1"
            },
            {
                label: "Branches - choose a weekday title placeholder",
                key: "branches_placeholder_choose_a_weekday_title"
            },
            {
                label: "Branches - success updated",
                key: "branches_success_updated"
            },
            {
                label: "Branches - success deleted",
                key: "branches_success_deleted"
            },
        ]
    },
    history: {
        pageName: 'History',
        list: [
            {
                label: "History - page title",
                key: "history_pate_title"
            },
            {
                label: "History - transaction number placeholder",
                key: "history_placeholder_transactionNumber"
            },
            {
                label: "History - card type placeholder",
                key: "history_placeholder_cardType"
            },
            {
                label: "History - type placeholder",
                key: "history_placeholder_type"
            },
            {
                label: "History - address placeholder",
                key: "history_placeholder_address"
            },
            {
                label: "History - amount placeholder",
                key: "history_placeholder_amount"
            },
            {
                label: "History - transfer amount placeholder",
                key: "history_placeholder_fuel_transferAmount"
            },
            {
                label: "History - debt placeholder",
                key: "history_placeholder_fuel_debt"
            },
            {
                label: "History - date placeholder",
                key: "history_placeholder_date"
            },
            {
                label: "History - status placeholder",
                key: "history_placeholder_status"
            },
            {
                label: "History - branch placeholder",
                key: "history_placeholder_branch"
            },
            {
                label: "History - source placeholder",
                key: "history_placeholder_source"
            },
            {
                label: "History - card number placeholder",
                key: "history_placeholder_card_number"
            },
            {
                label: "History - type of fuel placeholder",
                key: "history_placeholder_type_of_fuel"
            },
            {
                label: "History - fuel count placeholder",
                key: "history_placeholder_fuel_count"
            },
            {
                label: "History - fuel bonus placeholder",
                key: "history_placeholder_fuel_bonus"
            },
            {
                label: "History - fuel commission",
                key: "history_placeholder_fuel_commission"
            },
            {
                label: "History - card number",
                key: "history_placeholder_cardNumber"
            },
            {
                label: "History - date piker title",
                key: "history_date_piker_title"
            },
            {
                label: "History - date piker from",
                key: "history_date_piker_from"
            },
            {
                label: "History - date piker to",
                key: "history_date_piker_to"
            },
            {
                label: "History - empty view title",
                key: "history_empty_title"
            },
            {
                label: "History - empty view description",
                key: "history_empty_description"
            },
            {
                label: "History - bank history page title",
                key: "bank_history_page_title"
            },
        ]
    },
    weekdays: {
        pageName: 'Weekdays',
        list: [
            {
                label: "Weekdays - modal title",
                key: "weekdays_modal_title"
            },
            {
                label: "Weekdays - modal sub title",
                key: "weekdays_modal_sub_title"
            },
            {
                label: "Weekdays - monday",
                key: "weekdays_monday"
            },
            {
                label: "Weekdays - monday short",
                key: "weekdays_monday_short"
            },
            {
                label: "Weekdays - tuesday",
                key: "weekdays_tuesday"
            },
            {
                label: "Weekdays - tuesday short",
                key: "weekdays_tuesday_short"
            },
            {
                label: "Weekdays - wednesday",
                key: "weekdays_wednesday"
            },
            {
                label: "Weekdays - wednesday short",
                key: "weekdays_wednesday_short"
            },
            {
                label: "Weekdays - thursday",
                key: "weekdays_thursday"
            },
            {
                label: "Weekdays - thursday short",
                key: "weekdays_thursday_short"
            },
            {
                label: "Weekdays - friday",
                key: "weekdays_friday"
            },
            {
                label: "Weekdays - friday short",
                key: "weekdays_friday_short"
            },
            {
                label: "Weekdays - saturday",
                key: "weekdays_saturday"
            },
            {
                label: "Weekdays - saturday short",
                key: "weekdays_saturday_short"
            },
            {
                label: "Weekdays - sunday",
                key: "weekdays_sunday"
            },
            {
                label: "Weekdays - sunday short",
                key: "weekdays_sunday_short"
            },
            {
                label: "Weekdays - placeholder start time",
                key: "weekdays_placeholder_start_time"
            },
            {
                label: "Weekdays - placeholder end time",
                key: "weekdays_placeholder_end_time"
            },
        ]
    },
    cards: {
        pageName: 'Cards',
        list: [
            {
                label: "Cards - page title",
                key: "cards_page_title"
            },
            {
                label: "Cards - card title",
                key: "cards_card_title"
            },
            {
                label: "Cards - card created data",
                key: "cards_card_created_data"
            },
            {
                label: "Cards - card info title",
                key: "cards_card_info_title"
            },
            {
                label: "Cards - card all",
                key: "cards_card_all"
            },
            {
                label: "Cards - card available",
                key: "cards_card_available"
            },
            {
                label: "Cards - card cardholders",
                key: "cards_card_cardholders"
            },
            {
                label: "Cards - empty view title",
                key: "cards_empty_title"
            },
            {
                label: "Cards - empty view description",
                key: "cards_empty_description"
            },
            {
                label: "Cards - back to cards",
                key: "cards_back_to_cards"
            },
            {
                label: "Cards - card name placeholder",
                key: "cards_placeholder_card_name"
            },
            {
                label: "Cards - card quantity placeholder",
                key: "cards_placeholder_card_quantity"
            },
            {
                label: "Cards - symbol quantity placeholder",
                key: "cards_placeholder_symbol_quantity"
            },
            {
                label: "Cards - code type",
                key: "cards_placeholder_code_type"
            },
            {
                label: "Cards - upload card image title",
                key: "cards_upload_card_image_title"
            },
            {
                label: "Cards - card id placeholder",
                key: "cards_placeholder_card_id"
            },
            {
                label: "Cards - responsible placeholder",
                key: "cards_placeholder_responsible"
            },
            {
                label: "Cards - phone number placeholder",
                key: "cards_placeholder_phone_number"
            },
            {
                label: "Cards - firstname placeholder",
                key: "cards_placeholder_first_name"
            },
            {
                label: "Cards - lastname placeholder",
                key: "cards_placeholder_last_name"
            },
            {
                label: "Cards - balance placeholder",
                key: "cards_placeholder_balance"
            },
            {
                label: "Cards - empty view title",
                key: "cards_details_empty_title"
            },
            {
                label: "Cards - empty view description",
                key: "cards_details_empty_description"
            },
            {
                label: "Cards - attached title",
                key: "cards_attached_title"
            },
            {
                label: "Cards - attached empty title",
                key: "cards_attached_empty_title"
            },
            {
                label: "Cards - attached empty description",
                key: "cards_attached_empty_description"
            },
            {
                label: "Cards - attached name",
                key: "cards_attached_name"
            },
            {
                label: "Cards - attached active",
                key: "cards_attached_active"
            },
            {
                label: "Cards - attached phone number",
                key: "cards_attached_phone_number"
            },
            {
                label: "Cards - attached balance",
                key: "cards_attached_balance"
            },
            {
                label: "Cards - attached modal title",
                key: "cards_attached_modal_title"
            },
        ]
    },
    payments: {
        pageName: 'Payments',
        list: [
            {
                label: "Payments - page title",
                key: "payments_page_title"
            },
            {
                label: "Payments - button",
                key: "payments_btn"
            },
        ]
    },
    employers: {
        pageName: 'Employers',
        list: [
            {
                label: "Employers - page title",
                key: "employers_page_title"
            },
            {
                label: "Employers - user placeholder",
                key: "employers_placeholder_user"
            },
            {
                label: "Employers - date placeholder",
                key: "employers_placeholder_date"
            },
            {
                label: "Employers - balance placeholder",
                key: "employers_placeholder_balance"
            },
            {
                label: "Employers - empty view title",
                key: "employers_empty_title"
            },
            {
                label: "Employers - description view title",
                key: "employers_empty_description"
            },
        ]
    },
    modals: {
        pageName: 'Modals',
        list: [
            {
                label: "Modals - delete title",
                key: "modal_delete_title"
            },
            {
                label: "Modals - delete description",
                key: "modal_delete_description"
            },
            {
                label: "Modals - delete button",
                key: "modal_delete_btn"
            },
            {
                label: "Modals - activate title",
                key: "modal_activate_title"
            },
            {
                label: "Modals - deactivate title",
                key: "modal_deactivate_title"
            },
            {
                label: "Modals - activate description",
                key: "modal_activate_description"
            },
            {
                label: "Modals - deactivate description",
                key: "modal_deactivate_description"
            },
            {
                label: "Modals - activate button",
                key: "modal_activate_btn"
            },
            {
                label: "Modals - deactivate button",
                key: "modal_deactivate_btn"
            },
            {
                label: "Modals - hide title",
                key: "modal_hide_title"
            },
            {
                label: "Modals - hide description",
                key: "modal_hide_description"
            },
            {
                label: "Modals - hide button",
                key: "modal_hide_btn"
            },
            {
                label: "Modals - map title",
                key: "modal_map_title"
            },
            {
                label: "Modals - address placeholder",
                key: "modal_placeholder_address"
            },
            {
                label: "Modals - map no suggestion result",
                key: "modal_map_no_suggestion_result"
            },
            {
                label: "Modals - map button",
                key: "modal_map_btn"
            },
            {
                label: "Modals - logout title",
                key: "modal_logout_title"
            },
            {
                label: "Modals - logout description",
                key: "modal_logout_description"
            },
            {
                label: "Modals - logout btn",
                key: "modal_logout_btn"
            },
        ]
    },
    privacyPolicy: {
        pageName: 'Privacy policy',
        list: [
            {
                label: "Privacy policy",
                key: "privacy_policy"
            }
        ]
    },
    footer: {
        pageName: 'Footer',
        list: [
            {
                label: "Footer - terms of use",
                key: "footer_terms_of_use"
            },
            {
                label: "Footer - privacy policy",
                key: "footer_privacy_policy"
            },
            {
                label: "Footer - developed by text",
                key: "footer_developed_by_text"
            },
        ]
    },
    types: {
        pageName: 'Types',
        list: [
            {
                label: "Types - transaction spend",
                key: "transaction_types_spend"
            },
            {
                label: "Types - transaction accumulate",
                key: "transaction_types_accumulate"
            },
            {
                label: "Types - transaction debt",
                key: "transaction_types_debt"
            },
            {
                label: "Types - transaction commission",
                key: "transaction_types_commission"
            },
            {
                label: "Types - fill company",
                key: "transaction_types_fill_company"
            },
            {
                label: "Types - status initial",
                key: "status_types_initial"
            },
            {
                label: "Types - status completed",
                key: "status_types_completed"
            },
            {
                label: "Types - status errored",
                key: "status_types_errored"
            },
            {
                label: "Types - source app",
                key: "source_types_app"
            },
            {
                label: "Types - source refuel",
                key: "source_types_refuel"
            },
            {
                label: "Types - branch accumulative",
                key: "branch_types_accumulative"
            },
            {
                label: "Types - branch spendable",
                key: "branch_types_spendable"
            },
        ]
    }
}