// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import 'moment/locale/hy-am';
import moment from "moment";

// Import Styles
import '../../assets/styles/containerStyles/insurances.scss';

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {ClearInsurances, GetInsurances} from "../../redux/actions";
import {history} from "../../configs/history";
import {INSURANCE_STATUS_TYPES} from "../../constants/constTypes";
import {insuranceHeaderInfo, tabs, tabsStatuses} from "../insurances/utils/constants";
import {changeStateField} from "../../utils/helperFunctions";
import {CustomTabs} from "../../components/uiElements/Tabs";

const dateFormat = 'DD.MM.YYYY';

class Insurances extends Component {
    constructor() {
        super();
        this.state = {
            activeTab: tabs[0].key,
            text: ''
        };
        this.listRef = React.createRef();

        this.changeTab = changeStateField.bind(this, "activeTab");
        this.searchItems = this.searchItems.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this,);
    }

    componentDidMount() {
        this.props.GetInsurances({
            key: tabs[0].key,
            status: tabs[0].status
        }).then(() => {
            tabs.slice(1).forEach(tab => {
                this.props.GetInsurances({
                    key: tab.key,
                    status: tab.status
                })
            })
        });
    }

    componentWillUnmount() {
        this.props.ClearInsurances()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {activeTab} = this.state;
        if (activeTab !== prevState.activeTab) {
            this.listRef.current.scrollTop = this[activeTab]?.scrollTop ?? 0;
            this.setState({
                text: this[activeTab]?.text ?? ''
            })
        }
    }

    handleChangeData(name, value) {
        this.searchItems({[name]: value})
    }

    searchItems(updatedData = {}) {
        const {activeTab} = this.state;
        const insurancesData = this.props[activeTab];
        const {text, startDate, endDate} =  insurancesData;
        let filterData = {
            key: activeTab,
            status: tabsStatuses[activeTab],
            startDate, endDate, text,
            ...updatedData,
        };

        if (filterData?.text && filterData.text?.length === 1) {
            return;
        }
        this.props.GetInsurances(filterData);
    }

    loadMoreItems() {
        const {activeTab} = this.state;
        const insurancesData = this.props[activeTab];

        insurancesData.hasMore && this.searchItems({
            reset: false,
            offset: insurancesData.itemsList.length,
        });
    };

    render() {
        const {activeTab} = this.state;
        const {requestLoading} = this.props;
        // console.log(activeTab, 'activeTab');
        const insurancesData = this.props[activeTab];
        const hasMore = insurancesData?.hasMore;

        let headerInfo, itemsInfo;

        headerInfo = {
            generalInfo: [
                "Օգտատեր",
                "Համար",
                "Գումար",
                "Պայմանագրի Սկիզբ",
                "Պայմանագրի Ավարտ",
                "Ամսաթիվ",],
            largeItems: [],
            status: true,
        };
        itemsInfo =  insurancesData?.itemsList?.map(item => {
            const {contractNumber, user, amount, startDate, endDate, updatedAt, status} = item || {};
            return {
                id: item.id,
                status: {
                    title: INSURANCE_STATUS_TYPES?.[status]?.title || '',
                    color:INSURANCE_STATUS_TYPES?.[status]?.color || '',
                },
                values: [
                    user && user.name ? <div onClick={() => {
                        history.push({
                            pathname: `/users/${user?.id}`,
                        })
                    }}>{`${user?.name} ${user?.surname}`}</div> : '-',
                    contractNumber ?? '-',
                    amount ?? '-',
                    startDate ? moment(startDate).format("DD.MM.YY / HH:mm") : '-',
                    endDate ? moment(endDate).format("DD.MM.YY / HH:mm") : '-',
                    updatedAt ? moment(updatedAt).format("DD.MM.YY / HH:mm") : '-',
                ],
            }
        });

        return <PageWrapper withActions={true}  pageTitle={'Ապահովագրություններ'}>
            <section className="insurances">
                <div className="tabs-wrapper" style={{position: "relative"}}>
                    <CustomTabs changeTab={this.changeTab} activeTab={activeTab} tabsList={tabs}/>
                </div>
                <ItemsList
                    itemListHeaderInfo={headerInfo}
                    itemListInfo={itemsInfo}
                    loading={requestLoading}
                    infiniteScroll={true}
                    hasMore={hasMore}
                    detailsLink={'insurances'}
                    listRef={this.listRef}
                    loadMoreItems={this.loadMoreItems}/>
            </section>

        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetInsurances,
    ClearInsurances
};

export default connect(mapStateToProps, mapDispatchToProps)(Insurances)
