// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch} from "antd";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData, initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreateFacility, GetFacilityById, UpdateFacilityById} from "../../redux/actions";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {validateTranslations} from "../../utils/validateTranslations";


class AddEditFacility extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                icon: undefined,
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: '5db99765e2019423d6e0df83',
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);

        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);

        this.addEditFacility = this.addEditFacility.bind(this);
    }

    async componentDidMount() {
        const {isEditing} = this.state;
        const translationsData = {};

        if (isEditing) {
            await this.props.GetFacilityById(this.props.id);
            const editingData = this.props.facilityById;

            if (editingData) {
                editingData.translations.forEach(item => {
                    translationsData[item?.languageId] = {
                        ...translationsData[item?.languageId],
                        title: item.title || '',
                    };
                });

            }
            const initStateData = {
                translations: translationsData,
                icon: editingData?.icon,
                isHidden: editingData?.isHidden,
            };
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                ...this.state,
                fieldsData: initStateData
            });
        }
    }

    getMedia(mediaArray) {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.icon?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('icon', mediaArray[0]?.id)
                : this.updatedDataMap.delete('icon');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                icon: mediaArray[0]
            },
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {

            this.currentData.icon ?
                this.updatedDataMap.set("icon", null) :
                this.updatedDataMap.delete("icon");

        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                icon: null,
            }
        })
    }

    addEditFacility() {
        this.setState({
            requestLoading: true
        });
        const {translations, icon, isHidden} = this.state.fieldsData;

        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (result) {
            const trData = getTranslationData(translations);
            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    translations: trData,
                };
                if (icon) reqData.icon = icon.id;
                this.props.CreateFacility(reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    })
            } else {
                const reqData = this.getPatchReqData(trData);

                this.props.UpdateFacilityById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {
            fieldsData,
            errors,
            languageTab,
            mediaModalOpen,
            requestLoading,
            isEditing,
            errorsTabs
        } = this.state;
        const initData = this.currentData?.translations?.[languageTab] || {};
        const currentData = fieldsData.translations?.[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Կատեգորիա' : 'Փոփոխել Կատեգորիան'}>
            <section className="general-add-edit">
                <div className="top-side">
                    <label>Նկար</label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.icon ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                <div className={'main-media'}>
                    {fieldsData.icon && <MediaFileCard item={fieldsData?.icon}
                                                             customDelete={this.deleteMedia}
                    />}
                </div>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}
                />
                <div className="facility-left-part">
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>

                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditFacility}/>
                </div>

            </section>

            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateFacility,
    GetFacilityById,
    UpdateFacilityById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditFacility)
