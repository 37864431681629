import {AUTH_CONSTS, CONTACT_CONSTS} from "../constants";

export const initialState = {
    contactData: {},
    locationsList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CONTACT_CONSTS.GET_CONTACT:
            return {
                ...state,
                contactData: action.payload,
                locationsList: action.payload.locations,
            };
        case CONTACT_CONSTS.UPDATE_CONTACT:
            return {
                ...state,
                contactData: action.payload
            };
        case CONTACT_CONSTS.DELETE_LOCATION_BY_ID:
            return {
                ...state,
                locationsList: state.contactData.locations.filter(item => item.id !== action.payload)
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
