import {UTIL_CONSTS} from "../constants";
import {store} from "../store";

export const toggleNotificationRow = (data) => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.TOGGLE_NOTIFICATION_ROW,
            payload: data
        })
    }
};


export const ToggleLeftSideMenu = () => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.TOGGLE_LEFT_SIDE_MENU
        })
    }
};

export const RedirectFirstTime = () => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.REDIRECT_FIRST_TIME
        })
    }
};

export const setPageOffset = (data = null) => {
    store.dispatch({
        type: UTIL_CONSTS.SET_PAGE_OFFSET,
        payload: data || {
            pageName: '',
            offset: 0
        }
    })
};