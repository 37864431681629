import {initialState as auth} from "../reducers/auth";
import {initialState as utils} from "../reducers/utils";
import {initialState as languages} from "../reducers/languages";
import {initialState as history} from "../reducers/history";
import {initialState as moderators} from "../reducers/moderators";
import {initialState as media} from "../reducers/media";
import {initialState as users} from "../reducers/users";
import {initialState as product} from "../reducers/product";
import {initialState as category} from "../reducers/category";
import {initialState as order} from "../reducers/order";
import {initialState as partner} from "../reducers/partner";
import {initialState as contact} from "../reducers/contact";
import {initialState as status} from "../reducers/status";
import {initialState as brands} from "../reducers/brands";
import {initialState as section} from "../reducers/section";
import {initialState as reviews} from "../reducers/reviews";
import {initialState as insurances} from "../reducers/insurances";
import {initialState as filter} from "../reducers/filter";
import {initialState as notifications} from "../reducers/notifications";
import {initialState as countries} from "../reducers/countries";
import {initialState as shop} from "../reducers/shop";
import {initialState as news} from "../reducers/news";
import {initialState as staticTexts} from "../reducers/staticTexts";
import {initialState as company} from "../reducers/company";
import {initialState as directions} from "../reducers/directions";
import {initialState as branch} from "../reducers/branch";
import {initialState as transactions} from "../reducers/transactions";
import {initialState as termsConditions} from "../reducers/termsConditions";
import {initialState as newLog} from "../reducers/newLog";
import {initialState as facilities} from "../reducers/facilities";


const reducers = {
    auth,
    utils,
    languages,
    history,
    moderators,
    media,
    users,
    product,
    category,
    order,
    partner,
    contact,
    status,
    brands,
    section,
    reviews,
    insurances,
    filter,
    notifications,
    countries,
    shop,
    news,
    staticTexts,
    company,
    directions,
    branch,
    transactions,
    termsConditions,
    newLog,
    facilities
};

export default state => {
    const sData = {};
    Object.keys(reducers).forEach(selectorKey => {
        Object.keys(reducers[selectorKey]).forEach(key => {
            sData[key] = state[selectorKey][key];
        });
    })
    return sData;
};

